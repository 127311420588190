import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, useLang } from '@shapeable/ui';
import { BlockMetricLayout } from '../entities/block-metric-layout';
import { useSite } from '../../hooks/use-site';
import { MetricValue } from '../elements/metric-value';
import { OCEAN_BLUE, CONTENT_BACKGROUND } from '../../theme';
import { EntityTotalCircleTrailBarChart } from '../entities/entity-total-circle-trail-bar-chart';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ValueStyles = breakpoints({
  base: css`
    
  `,
});

const ChartStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled(BlockMetricLayout)<ContainerProps>`${ContainerStyles}`,
  Value: styled(MetricValue)`${ValueStyles}`,
  Chart: styled(EntityTotalCircleTrailBarChart)`${ChartStyles}`,
};

export const BlockLayoutSpeciesRestoredChart: BlockLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { metrics } = useSite();
  const { countGenuses, countRestorationProjects, topRestoredBySpecies = [] } = metrics;
  const t = useLang();

  return (
    <My.Container showContent entity={entity} className={className}>
      <My.Value suffix={t('genera')} value={countGenuses} />
      <My.Value suffix={t('projects')} value={countRestorationProjects} />
      <My.Chart
        color={OCEAN_BLUE}
        backgroundColor={CONTENT_BACKGROUND} 
        items={topRestoredBySpecies} 
      />
    {children}
    </My.Container>
  )
};