import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, useLang } from '@shapeable/ui';
import { CONTENT_BACKGROUND } from '../../theme';
import { SlicePledgeChart, SlicePledgeChartProps } from '../entities/slice-pledge-chart';
import { useSite } from '../../hooks/use-site';
import { Classable, HasChildren, Shapeable, Slice } from '@shapeable/types';
import { classNames } from '@shapeable/utils';

const cls = classNames('slice-pledge-chart');

// -------- Props -------->

export type SliceRestorationChartProps = HasChildren & Classable & { 
  entity?: Slice;
};

export const SliceRestorationChartDefaultProps: Omit<SliceRestorationChartProps, 'entity' | 'currentValue' | 'firstTarget' | 'finalTarget'> = {

};


// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background: ${CONTENT_BACKGROUND};
    border-bottom: 1px solid ${theme.COLOR('light')};
    min-height: 70px;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled(SlicePledgeChart)`${BodyStyles}`,
};

export const SliceRestorationChart: Shapeable.FC<SliceRestorationChartProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const t = useLang();
  const { metrics } = useSite();
  const { totalRestorationPledged, totalRestorationPledgeTarget2030, totalRestorationPledgeTarget2040 } = metrics;

  return (
    <My.Container className={className}>
      <My.Body
        firstTargetLabel={t('<b>2030</b> <i>Target</i>')}
        finalTargetLabel={t('<b>2040</b> <i>Restoration Target</i> (Hectares)')}
        currentValue={totalRestorationPledged}
        firstTarget={totalRestorationPledgeTarget2030}
        finalTarget={totalRestorationPledgeTarget2040}
        entity={entity} />
    </My.Container>
  );
};

SliceRestorationChart.defaultProps = SliceRestorationChartDefaultProps;
SliceRestorationChart.cls = cls;
