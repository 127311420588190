import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { RestorationProjectsProvider as Provider } from '../../components/context/restoration-projects.context'
import { sortBy } from 'lodash';
import { platformProviderValue } from '@shapeable/ui';

const RESTORATION_PROJECTS_QUERY = graphql`
  query PlatformRestorationProjectsQuery {
    platform {
      allRestorationProjects {
        __typename _schema { label pluralLabel }
        id path slug name latitude longitude
        observations { areaOfRestoration }
        openGraph { description { plain text(truncate: { words: 20 }) } image { url url2x thumbnails { card { url url2x } bubble { url url2x } bubbleLarge { url url2x } } } }
        place { __typename id name slug openGraph { id title description { id text } image { id url } } }
        organisation { __typename id name slug openGraph { id title description { id text } image { id url } } }
        species { __typename id name slug openGraph { id title description { id text } image { id url } } }
        methodologies { __typename id name slug openGraph { id title description { id text } image { id url } } }
        reasons { __typename id name slug openGraph { id title description { id text } image { id url } } }
      }
    }
  }`;

export const RestorationProjectsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy( platformProviderValue( useStaticQuery(RESTORATION_PROJECTS_QUERY), 'allRestorationProjects' ), 'name') }>{children}</Provider>;
};
