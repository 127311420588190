import React from 'react';
import styled, { css } from 'styled-components';
import { Menu, PageLayoutComponent } from '@shapeable/types';
import { breakpoints, ColorTokens, theme } from '@shapeable/theme';
import { SliceLayoutBoundary, useEntity, NextPageLink, useLinearNavigation, ContentEntityLayout, EntityTitleHeader, LinearNavigation } from '@shapeable/ui';
import { DARK_BLUE, DARK_BLUE_OVERLAY, MEDIUM_BLUE, ORANGE } from '../../theme';
import { ORGANISATION_BANNER } from '../../data';
import { AsideLayout } from '../entities/aside-layout';
import { classNames } from '@shapeable/utils';
import { useMenusNavigation } from '../../hooks/use-menus-navigation';

const cls = classNames('page-layout-content');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const ContentLayoutStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
    margin-top: ${theme.UNIT(8)};

    h2 {
      font-weight: 500;
    }
  `,
  tablet: css`
    margin-top: ${theme.UNIT(10)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(12)};
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
  `,
});

const NavigationStyles = breakpoints({
  base: css`
    display: flex;
    background-color: ${theme.COLOR(DARK_BLUE)};
    padding: ${theme.UNIT(4)};
  `,
  desktop: css`
    display: none;
  `,
});

const LinearNavigationStyles = breakpoints({
  base: css`
    flex-grow: 1;

    .shp--slim-select {
      height: 40px;
    }
   
    .shp--slim-select__control {
      background-color: rgba(255, 255, 255, 0.1);
      color: ${theme.COLOR('light')};
      font-size: 1em;
      padding: ${theme.UNIT(1)} ${theme.UNIT(2.2)};
      border-color: transparent;
    }

    .shp--slim-select__single-value {
      color: ${theme.COLOR('light')};
    }

    .shp--slim-select__control--is-focused {
      border-color: ${theme.COLOR('link-hover')};
    }

    .shp--slim-select__menu {
      background-color: #323232;
      color: ${theme.COLOR('light')};
      font-size: 1em;
    }

    .shp--slim-select__input {
      color: ${theme.COLOR('light')};
      font-size: 1em;
    }

    .shp--slim-select__menu-list {
      padding-bottom: 0;
    }

    .shp--slim-select__option {
      border-color: #666;
      color: ${theme.COLOR('light')};
      font-size: 1em;

      &:hover {
        background-color: ${theme.COLOR('link-hover')};
      }

      &:last-child {
        border: none;
      }
    }

    .shp--slim-select__indicator {
      .shp--triangle {
        border-top: 6px solid ${theme.COLOR('light')};
      }
    }
    
    .shp--slim-select__option--is-focused {
      background-color: ${theme.COLOR(ORANGE)};
      color: ${theme.COLOR('light')};
    }

    ${LinearNavigation.cls.child('prev-button')} {
      padding-left: 0;
      border-right: none;
      ${theme.FILL('light')};
    }

    ${LinearNavigation.cls.child('next-button')} {
      padding-right: 0;
      ${theme.FILL('light')};
    }
  `,
  tablet: css`
    .shp--page-navigation__select {
      width: 100%;
    }
    
  `
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    ContentLayout: styled(ContentEntityLayout)`${ContentLayoutStyles}`,
    NextLink: styled(NextPageLink)`${NextLinkStyles}`,

    Navigation: styled.div`${NavigationStyles}`,
    LinearNavigation: styled(LinearNavigation)`${LinearNavigationStyles}`,
};

export const PageLayoutContent: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  const info = useMenusNavigation();

  const { isMenuNav } = info;
  
  return (
    <My.Container className={className}>
      <EntityTitleHeader overlayColor={DARK_BLUE_OVERLAY} themeMode="dark" backgroundImage={ORGANISATION_BANNER} entity={entity} showPretitle />
      {
        isMenuNav && (
        <My.Navigation>
          <My.LinearNavigation variant='default' />
        </My.Navigation>
        )
      }
      <SliceLayoutBoundary>
        <My.ContentLayout 
        showTitles={false}
        showMeta={true}
        showImage={false}
        asideLayout={isMenuNav ? <AsideLayout /> : null}
        isAsideSticky={true}
        entity={entity} />
        <My.NextLink />
      </SliceLayoutBoundary>
    </My.Container>
  )
};