import React from 'react';
import styled, { css } from 'styled-components';
import { Block, Classable, HasChildren, Shapeable, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SliceBlockToutGrid, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('slice-membership-grid');

// -------- Types -------->

export type SliceMembershipGridProps = Classable & HasChildren & { 
  entity?: Slice;
  blocks?: Block[];
};

export const SliceMembershipGridDefaultProps: Omit<SliceMembershipGridProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled(SliceBlockToutGrid)<ContainerProps>`${ContainerStyles}`,
};

export const SliceMembershipGrid: Shapeable.FC<SliceMembershipGridProps> = (props) => {
  const { className, children, blocks } = props;
  const entity = useEntity(props.entity);
  
  // allow override of blocks 
  const sliceEntity = {
    ...entity,
    blocks: props.blocks || entity.blocks
  }
  return (
   <My.Container entity={sliceEntity} className={cls.name(className)} />
  )
};

SliceMembershipGrid.defaultProps = SliceMembershipGridDefaultProps;
SliceMembershipGrid.cls = cls;