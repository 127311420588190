import { useState, useEffect, useCallback, useMemo } from 'react';

export const useProtectionAreaData = (totalItemCount = 7300) => {
  const [fullData, setFullData] = useState([]); // Store the full dataset
  const [displayData, setDisplayData] = useState([]); // Data to display after filtering and slicing
  const [loading, setLoading] = useState(true);
  const [lastIndexFetched, setLastIndexFetched] = useState(199);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [dataLength, setDataLength] = useState(0);

  // Fetch the entire dataset once
  useEffect(() => {
    const fetchInitialBatch = async () => {
      setLoading(true);
      try {
        // Fetch only the initial batch for immediate display
        const response = await fetch(`/kfa-protection-area-metadata.json?start=0&end=100`);
        const initialBatch = await response.json();
        setDisplayData(initialBatch);
        setDataLength(7260);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false);
      }
    };
  
    fetchInitialBatch();
  }, []);

  return { displayData, loading, dataLength, setDisplayData };
};
