import React from 'react';
import { IconComponent } from '@shapeable/types'

export const StatusIvIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_2705_16693)">
    <path d="M16 2.78261C23.3043 2.78261 29.2174 8.69565 29.2174 16C29.2174 23.3043 23.3043 29.2174 16 29.2174C8.69565 29.2174 2.78261 23.3043 2.78261 16C2.78261 8.69565 8.69565 2.78261 16 2.78261ZM16 0C7.16522 0 0 7.16522 0 16C0 24.8348 7.16522 32 16 32C24.8348 32 32 24.8348 32 16C32 7.16522 24.8348 0 16 0Z" fill="black"/>
    <path d="M9 20.772V19.078H10.26V12.694H9V11H13.648V12.694H12.388V19.078H13.648V20.772H9Z" fill="black"/>
    <path d="M17.689 20.772L14.539 11H16.695L18.151 15.69L18.921 18.756H18.963L19.705 15.69L21.161 11H23.247L20.069 20.772H17.689Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_2705_16693">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);