import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';

// -------- Types -------->

export type ContentTitleProps = Classable & HasChildren & {
  tag?: string;
}

export const ContentTitleDefaultProps: ContentTitleProps = {
  tag: 'h1',
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('secondary')};
    text-transform: uppercase;
    font-weight: 600;
    font-size: ${24 / 15}em;
    margin-bottom: 1em;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.h1<ContainerProps>`${ContainerStyles}`,
};

export const ContentTitle: React.FC<ContentTitleProps> = (props) => {
  const { className, tag, children } = props;
  return (
    // @ts-ignore
    <My.Container as={tag} className={className}>
    {children}
    </My.Container>
  )
};

ContentTitle.defaultProps = ContentTitleDefaultProps;