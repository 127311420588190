import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, CitationCard as Card, EntityCard, CitationCell } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('citation-card');

// -------- Types -------->

export type CitationCardProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const CitationCardDefaultProps: Omit<CitationCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    

  `,
});


// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
};

export const CitationCard: Shapeable.FC<CitationCardProps> = (props) => {
  const { className, children, entity } = props;
  
  return (
   <My.Container 
    entity={entity} 
    className={cls.name(className)}
    showDescriptionDivider
    showExternalLink
    labelDeriver={(e) => 'Scientific Paper'}
    > 
    <CitationCell entity={entity} showDisplayName={false} isExtended={false} />
   </My.Container>
  )
};

CitationCard.defaultProps = CitationCardDefaultProps;
CitationCard.cls = cls;