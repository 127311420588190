import React from 'react';
import { IconComponent } from '@shapeable/types'

export const MapPinIcon: IconComponent = (props) => {
  const { className, onClick } = props;
  return (
    <svg onClick={onClick} className={className} viewBox="0 0 24 30">
    <g>
      <path fill="#FFFFFF" d="M11,27.8C7,24.2,0,16.6,0,11C0,4.9,4.9,0,11,0s11,4.9,11,11C22,16.6,15,24.2,11,27.8z"/>
      <path className="shp-map-marker-icon__fill" fill="#2697BE" d="M11,2.2c-4.8,0-8.8,3.9-8.8,8.8c0,4,4.9,10,8.8,13.8c4.3-4.2,8.8-10.1,8.8-13.8C19.8,6.1,15.8,2.2,11,2.2z
      M11,14.3c-1.8,0-3.3-1.5-3.3-3.3S9.2,7.7,11,7.7s3.3,1.5,3.3,3.3S12.8,14.3,11,14.3z"/>
    </g>
    </svg>
  );
};
