import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, EntityTypeName, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, EntityTypePluralLabelsProvider, SidebarButton, useLang } from '@shapeable/ui';
import { WorldMap } from './world-map';
import { includes, without } from 'lodash';
import { IconToggleButton } from './icon-toggle-button';
import { classNames } from '@shapeable/utils';
import { EntityMapToggles, useMapState, useMapUtils } from '@shapeable/maps';
import { LocationIcon, OrganisationIcon, PledgeIcon } from '@shapeable/icons';
const cls = classNames('sidebar-buttons');

// -------- Types -------->

export type SidebarButtonsProps = Classable & HasChildren & {
}

export const SidebarButtonsDefaultProps: SidebarButtonsProps = {
};

// -------- Styles -------->

const ButtonStyles = breakpoints({
  base: css`

  `,
});

const WorldMapStyles = breakpoints({
  base: css`
    width: 36px;
    height: 22px;
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const TogglesStyles = breakpoints({
  base: css`
    
  `,
});



// const TogglesStyles = breakpoints({
//   base: css`
//     ${theme.H_DOT_BG('light', 2, 6)};
//     padding-top: ${theme.UNIT(4)};
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     width: 32px;
//   `,
// });

// const ToggleStyles = breakpoints({
//   base: css`
//     cursor: pointer;
//     margin-bottom: ${theme.UNIT(3)};
//   `,
// });

// const ToggleLabelStyles = breakpoints({
//   base: css`
//     font-weight: 500;
//     font-size: ${theme.FONT_SIZE(10)};
//     margin-bottom: ${theme.UNIT(2)};
//   `,
// });



// -------- Components -------->

const My = {
  Container: React.Fragment,
    Button: styled(SidebarButton)`${ButtonStyles}`,
      WorldMap: styled(WorldMap)`${WorldMapStyles}`,

    Toggles: styled(EntityMapToggles)`${TogglesStyles}`,

  // Toggles: styled.div`${TogglesStyles}`,
  //   ToggleLabel: styled(CellLabel)`${ToggleLabelStyles}`,
  //   Toggle: styled(IconToggleButton)`${ToggleStyles}`,

};

export const SidebarButtons: Shapeable.FC<SidebarButtonsProps> = (props) => {
  const { className } = props;
  const t = useLang();

  const { deselect, toggleSelectedEntity, init, hide, showOnClick } = useMapUtils();
  const [ mapState, setMapState, patchMapState ] = useMapState();
  const { isInitialized, selectedEntity, isVisible, entityTypeNames = [] } = mapState;

  const entityTypeIsVisible: (entityTypeName: EntityTypeName) => boolean = 
    (entityTypeName) => includes(entityTypeNames, entityTypeName);

  const toggleEntityType = (type: EntityTypeName) => {
    const currentSet = entityTypeNames;
    if (entityTypeIsVisible(type)) {
      patchMapState({ entityTypeNames: without(currentSet, type), selectedEntity: null });
    } else {
      patchMapState({ entityTypeNames: [...currentSet, type] });
    }
  };

  return (
    <EntityTypePluralLabelsProvider value={{
      Organisation: 'Orgs'
    }}>
    <My.Container>
      {
        mapState.isVisible && 
        <My.Toggles />
        // <My.Toggles>
        //   <My.ToggleLabel>{t('Show:')}</My.ToggleLabel>
          
        //   <My.Toggle onClick={() => { toggleEntityType('RestorationProject') }} isActive={entityTypeIsVisible('RestorationProject')} icon={<LocationIcon />}>{t('Projects')}</My.Toggle>
        //   <My.Toggle onClick={() => { toggleEntityType('ProtectionArea') }} isActive={entityTypeIsVisible('ProtectionArea')} icon={<LocationIcon />}>{t('Protection')}</My.Toggle>
        //   <My.Toggle onClick={() => { toggleEntityType('Organisation') }} isActive={entityTypeIsVisible('Organisation')} icon={<OrganisationIcon />}>{t('Orgs')}</My.Toggle>
        //   <My.Toggle onClick={() => { toggleEntityType('OrganisationPledge') }} isActive={entityTypeIsVisible('OrganisationPledge')} icon={<PledgeIcon />}>{t('Pledges')}</My.Toggle>
        // </My.Toggles>
      }
      {
        !mapState.isVisible && 
        <My.Button onClick={showOnClick} icon={<My.WorldMap />}>{t('Explore Map')}</My.Button>
      }
    </My.Container>
    </EntityTypePluralLabelsProvider>
  )
};

SidebarButtons.cls = cls;
SidebarButtons.defaultProps = SidebarButtonsDefaultProps;