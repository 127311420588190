import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { MapBoxRefProvider, MapMarker, useMapUtils } from '@shapeable/maps';
import { useProtectionAreaTooltip } from '../../hooks/use-protection-area-tooltip';
import { useRestorationProjects } from '../../hooks/use-restoration-projects';
import { useAppState, useOrganisations } from '@shapeable/ui';
import { useOrganisationPledges } from '../../hooks/use-organisation-pledges';
import { EntityMapPanel as CoreEntityMapPanel } from '@shapeable/maps';
import { ProtectionAreaTooltip } from './protection-area-tooltip';
import { MapLegend } from '../elements/map-legend';
import { greenHatch } from '../../data';

const cls = classNames('entity-map-panel');

// -------- Types -------->

export type EntityMapPanelProps = Classable & HasChildren & {
}

export const EntityMapPanelDefaultProps: EntityMapPanelProps = {
};

// -------- Marker Styles -------->

const MarkerStyles = breakpoints({
  base: css`
    
  `,
});

const ProtectionAreaTooltipStyles = breakpoints({
  base: css`
    
  `,
});

const ProtectionLegendStyles = breakpoints({
  base: css`
    position: absolute;
    top: 110px;
    left: 15px;
    z-index: 500;
  `,
  tablet: css`
    top: 135px;
    left: 20px;
  `,
});

const RestorationLegendStyles = breakpoints({
  base: css`
    position: absolute;
    top: 75px;
    left: 15px;
    z-index: 500;
  `,
  tablet: css`
    top: 85px;
    left: 20px;
  `,
});



// -------- Components -------->

const My = {
  Marker: styled(MapMarker)`${MarkerStyles}`,
  ProtectionAreaTooltip: styled(ProtectionAreaTooltip)`${ProtectionAreaTooltipStyles}`,
  ProtectionLegend: styled(MapLegend)`${ProtectionLegendStyles}`,
  RestorationLegend: styled(MapLegend)`${RestorationLegendStyles}`,
};

export const EntityMapPanel: Shapeable.FC<EntityMapPanelProps> = (props) => {
  const { className } = props;

  const { deselect, zoom, toggleSelectedEntity, selectedEntity, entityTypeIsVisible } = useMapUtils();
  const [ appState, setAppState, patchAppState ] = useAppState<{ isMapReady?: boolean }>();

  const isProtectionAreaVisible = entityTypeIsVisible('ProtectionArea') ? 'visible' : 'none';
  const isProtectionAreaSelected = selectedEntity && (selectedEntity?.selectedType === 'ProtectionArea');

  const isProtectionAreaToggled = entityTypeIsVisible('ProtectionArea');

  const mapRef = React.useRef(null);

  const { hideProtectionTooltip, isProtectionLegendVisible } = useProtectionAreaTooltip();

  const mapInstance = mapRef?.current?.getMap();
  const mapStyle = mapInstance && mapInstance.getLayer && mapInstance.getLayer("kfa-protection-area-data");

  const onMapLoad = () => {
    patchAppState({ isMapReady: true } as any);
  }

  React.useEffect(() => {
    if (mapInstance && mapStyle && appState?.isMapReady) {
      mapInstance?.setLayoutProperty("kfa-protection-area-data", 'visibility', isProtectionAreaVisible);
    }
    return () => {
      if (mapInstance) {
        mapInstance?.setLayoutProperty("kfa-protection-area-data", 'visibility', 'none');
      }
    }
  }, [ mapStyle, mapInstance, appState?.isMapReady, isProtectionAreaVisible ]);


  const onProtectionAreaClick = React.useCallback((event) => {
    event.preventDefault();
    const map = event.target;
    const features = map.queryRenderedFeatures(event.point, {
      layers: ["kfa-protection-area-data"]
    });
  
    if (features.length > 0) {
      const feature = features[0];
      const lngLat = event.lngLat;
      const protectionArea = {
        mapOptions: feature.properties,
        id: feature.properties.WDPAID.toString(),
        selectedType: 'ProtectionArea',
        longitude: lngLat.lng,
        latitude: lngLat.lat,
      };

      toggleSelectedEntity(protectionArea);
      
    } else {
      hideProtectionTooltip(event);
    }
  }, [toggleSelectedEntity, selectedEntity]);

  const restorationProjects = useRestorationProjects();
  const organisations = useOrganisations();
  const organisationPledges = useOrganisationPledges();

  const items = [
    ...restorationProjects,
    ...organisations,
    ...organisationPledges,
  ];


  return (
    <MapBoxRefProvider value={mapRef}>
      <CoreEntityMapPanel 
        className={cls.name(className)} 
        items={items} 
        onClick={onProtectionAreaClick}
        onLoad={onMapLoad} 
      >
        {
          isProtectionAreaSelected &&
          <My.Marker 
            style={{
              opacity: isProtectionAreaSelected ? 1.0 : (!!selectedEntity ? 0.4 : 1.0),
                zIndex: isProtectionAreaSelected ? 200 : 99
            }}
            key={selectedEntity.id}
            isSelected
            longitude={selectedEntity.longitude}
            latitude={selectedEntity.latitude}
            onClose={deselect}
            zoom={zoom}
            popup={
              isProtectionAreaSelected && 
              <My.ProtectionAreaTooltip entity={selectedEntity.mapOptions} />
            }
            >

          </My.Marker>
        }

        <My.RestorationLegend path={greenHatch} title='Restoration Projects' isVisible={true} />
        <My.ProtectionLegend color='#f075f0' title='Protection Area' isVisible={isProtectionAreaToggled} showImage={false} />
      </CoreEntityMapPanel>
      
    </MapBoxRefProvider>
  )
};

EntityMapPanel.cls = cls;
EntityMapPanel.defaultProps = EntityMapPanelDefaultProps;