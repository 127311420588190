import React, { useState } from 'react';
import { Classable, Dictionary, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';

import { 
  ErrorList, Field, useForm, FormButton, FormLoader, Input, 
  Select, SelectOption, TextArea, usePage,
  Button, InputCheckbox, 
} from '@shapeable/ui';
import EmailValidator from 'email-validator';
import { filter, includes, trim, without, pickBy } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import validUrl from 'valid-url';
import { DottedChevronDownIconGlyph } from '@shapeable/icons';
import { onSubmitNetlifyForm } from '@shapeable/utils';

// -------- Types -------->

export type ContactFormProps = Classable & HasChildren & {
}

export const ContactFormDefaultProps: ContactFormProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    min-height: 200px;
  `,
});

const FieldStyles = breakpoints({
  base: css`
    
  `,
});

const TypeFieldStyles = breakpoints({
  base: css`
    label {
      text-transform: none;
    }
  `,
});



const TypeStyles = breakpoints({
  base: css`
    width: 100%;
    font-size: ${theme.FONT_SIZE(14)};

    .shp--select__clear-indicator {
      margin-top: 0;
    }

    .shp--select__value-container {
      min-height: 36px;
    }

    .shp--select__control {
      padding: ${theme.UNIT(3)} 4px;
      border: 2px dotted ${theme.COLOR('secondary')};
    }
    
    .shp--select__control:hover {
      border: 2px dotted ${theme.COLOR('link-hover')};
    }

    .shp--select__control--is-focused,
    .shp--select__control--is-menu-open {
      border: 2px dotted ${theme.COLOR('link-hover')};
    }


    ${(props: any) => props.hasError && css`
      .shp--select__control {
        border-color: red;
      }
    `}
    

  `,
});

const FormStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(3)};
  `,
});

const BaseInputStyles = breakpoints({
  base: css`
    border: 2px dotted ${theme.COLOR('secondary')};
    color: ${theme.COLOR('primary')};

    &:focus {
      outline: none;
      border: 2px dotted ${theme.COLOR('link-hover')};
    }

    ${({ hasError }: any ) => hasError && css`
      outline: none;
      border-color: red;
    `}
  `,
});



const TextAreaStyles = breakpoints({
  base: css`
    width: 100%;
    height: 150px;
    padding: ${theme.UNIT(4)};
    ${BaseInputStyles};
  `,
});


const InputStyles = breakpoints({
  base: css`
    width: 100%;
    padding: ${theme.UNIT(4)};
    color: ${theme.COLOR('primary')};
    ${BaseInputStyles};
  `,
});

const SubmitButtonStyles = breakpoints({
  base: css`  
    width: 200px;
    cursor: pointer;
    margin-top: ${theme.UNIT(8)};
  `,
});


const OverlayStyles = breakpoints({
  base: css`
  `,
});


const LoaderStyles = breakpoints({
  base: css`
    background: none;
    padding-bottom: ${theme.UNIT(100)};
  `,
});

const ThankyouStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)};
    width: 100%;
    flex-grow: 1;
    color: ${theme.COLOR('text')};
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-bottom: ${theme.UNIT(100)};
    p {
      margin: ${theme.UNIT(2)};
    }
  `,
});

const ThankyouMessageStyles = breakpoints({
  base: css`
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 1.25em;
  `,
});


const DoneButtonStyles = breakpoints({
  base: css`
    ${SubmitButtonStyles};
    
  `,
});

const IndicatorStyles = breakpoints({
  base: css`
    width: 20px;
    margin: 0 16px;
  `,
});

const InputCheckboxStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    margin: ${theme.UNIT(6)} 0;
  `,
});


const ErrorsStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('red')};
    padding: ${theme.UNIT(6)} ${theme.UNIT(6)} ${theme.UNIT(7)};
    margin-top: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(4)};
    font-weight: 500;
    border: 2px dotted ${theme.COLOR('red')};
    font-size: ${theme.FONT_SIZE(14)};

    ul {
      padding-left: ${theme.UNIT(4)};
    }

    li {
      margin: ${theme.UNIT(2)} 0; 
      padding-left: 0;
    }
  `,
});

// -------- Components -------->

const TYPE = 'type';
const MESSAGE = 'details';
const LINK = 'link';
const NAME = 'name';
const EMAIL = 'email';
const JOB_TITLE = 'jobTitle';
const COMPANY = 'company';
const URL = 'url';
const FORM_NAME = 'contact';
const AGREE = 'agree';
const BECOME_MEMBER = 'becomeAMember';
const RECEIVE_UPDATES = 'receiveUpdates';

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Loader: styled(FormLoader)`${LoaderStyles}`,
    Thankyou: styled.div`${ThankyouStyles}`,
      ThankyouMessage: styled.div`${ThankyouMessageStyles}`,

    Agree: styled(InputCheckbox).attrs({ id: AGREE, name: AGREE, label: `I confirm that I have read and agree to KFA's terms and privacy policy` })`${InputCheckboxStyles}`,
    BecomeAMember: styled(InputCheckbox).attrs({ id: BECOME_MEMBER, label: `Would you like to become a KFA member` })`${InputCheckboxStyles}`,
    Updates: styled(InputCheckbox).attrs({ id: RECEIVE_UPDATES, label: `I'd like to receive updates from KFA` })`${InputCheckboxStyles}`,
    
    Indicator: styled(DottedChevronDownIconGlyph)`${IndicatorStyles}`,

    Form: styled.form`${FormStyles}`,
      TypeField: styled(Field).attrs({ id: TYPE, label: 'What would you like to contact us about? (Required)' })`${TypeFieldStyles}`,
        Type: styled(Select).attrs({ id: TYPE, placeholder: 'Select' })`${TypeStyles}`,

      NameField: styled(Field).attrs({ id: NAME, label: 'Name (Required):' })`${FieldStyles}`,
        Name: styled(Input).attrs({ id: NAME, placeholder: '' })`${InputStyles}`,
      MessageField: styled(Field).attrs({ id: MESSAGE, label: 'Message (Required):' })`${FieldStyles}`,
        Message: styled(TextArea).attrs({ id: MESSAGE, placeholder: '' })`${TextAreaStyles}`,
      EmailField: styled(Field).attrs({ id: EMAIL, label: 'Email:' })`${FieldStyles}`,
        Email: styled(Input).attrs({ id: EMAIL, placeholder: 'Email' })`${InputStyles}`,
      CompanyField: styled(Field).attrs({ id: COMPANY, label: 'Company:' })`${FieldStyles}`,
        Company: styled(Input).attrs({ id: COMPANY, placeholder: 'Company' })`${InputStyles}`,
      JobTitleField: styled(Field).attrs({ id: JOB_TITLE, label: 'Job Title:' })`${FieldStyles}`,
        JobTitle: styled(Input).attrs({ id: JOB_TITLE, placeholder: '' })`${InputStyles}`,
      UrlField: styled(Field).attrs({ id: URL })`${FieldStyles}`,
        Url: styled(Input).attrs({ id: URL, placeholder: 'Company Website URL' })`${InputStyles}`,
      LinkField: styled(Field).attrs({ id: LINK, label: 'Link (if applicable)' })`${FieldStyles}`,
        Link: styled(Input).attrs({ id: LINK, placeholder: 'https://example.com' })`${InputStyles}`,

      Errors: styled(ErrorList)`${ErrorsStyles}`,

    SubmitButton: styled(Button)`${SubmitButtonStyles}`,
};

export const ContactForm: React.FC<ContactFormProps> = (props) => {
  const { className } = props;
  const form = useForm(FORM_NAME);

  const { values, patchValues } = form;
  const [ token, setToken ] = useState('');
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ errorFields, setErrorFields ] = useState<string[]>([]);
  
  const page = usePage();
  const typeOptions: SelectOption[] = [
    {
      value: 'Membership Request',
      label: 'Membership Request',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
    {
      value: 'Press and Media',
      label: 'Press and Media',
    },
    {
      value: 'Funding',
      label: 'Funding',
    },
    {
      value: 'Careers',
      label: 'Careers',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];
  
  const formState: Dictionary<any> = { 
    ...values, 
  };

  const onChange = (name: string) => (value: string | SelectOption) => { patchValues({ [name]: value }); setErrors([]); setErrorFields(without(errorFields, name)); };

  const onSubmit = async(event: React.FormEvent) => {
    event.preventDefault();
    
    const e = [];
    const ef = [];
    
    if (!token) {
      errors.push('Please check the I\'m not a robot checkbox to validate you are human');
    }

    if (!trim(formState[TYPE])) {
      e.push('Please select a reason for contacting us.');
      ef.push(TYPE);
    }

    if (trim(formState[NAME]) === '') {
      e.push('Please enter your name.');
      ef.push(NAME);
    }

    if (trim(formState[MESSAGE]) === '') {
      e.push('Please provide the details of your request in the Message field.');
      ef.push(MESSAGE);
    }

    if (trim(formState[EMAIL]) !== '' && !EmailValidator.validate(formState[EMAIL])) {
      e.push('Please check that your email address is VALID');
      ef.push(EMAIL);
    }

    if (trim(formState[LINK]) !== '' && !validUrl.isWebUri(formState[LINK])) {
      e.push('Please provide a VALID URL in the link field');
      ef.push(LINK);
    }

    if (trim(formState[URL]) !== '' && !validUrl.isWebUri(formState[URL])) {
      e.push('Please provide a VALID URL in the Company Website URL field');
      ef.push(URL);
    }

    if (trim(formState[AGREE]) === '') {
      e.push('Please confirm you agree with KFA\'s terms and provacy policy');
      ef.push(AGREE);
    }

    setErrorFields(ef);
    setErrors(e);

    if (e.length) {
      return false;
    }

    const data = { ...formState, 'g-recaptcha-response': token };
    form.startSubmit();
    const response = await onSubmitNetlifyForm(FORM_NAME, page.path, data);
    setToken('');
    form.endSubmit();
  };

  const onCaptchaChange = (token: any) => {
    setToken(token);
  };

  const isSubmitDisabled = !token || form.isSubmitting;
  const hasErrors = !!errors.length;
 
  return (
    <My.Container className={className}>
      {
        (form.isSubmitting) && 
        <My.Loader color="primary">Please wait...</My.Loader>
      }
      {
        (form.isSubmitted) ?
        <My.Thankyou>
          <My.ThankyouMessage>
          <p>
          Thankyou, your submission has been received.
          </p>
          <p>
          We will be in touch soon.
          </p>
          </My.ThankyouMessage>
        </My.Thankyou> :
        <My.Form name={FORM_NAME} onSubmit={onSubmit} method="post" data-netlify-recaptcha="true" data-netlify="true">
          <input type="hidden" name="form-name" value={FORM_NAME} />

          <My.TypeField>
            <My.Type 
              components={{
                DropdownIndicator: My.Indicator
              }}
              hasError={includes(errorFields, TYPE)} 
              isClearable={true}
              onChange={onChange(TYPE)}
              value={formState[TYPE]}
              placeholder="Select"
              options={typeOptions}
            />
          </My.TypeField>

          <My.NameField>
            <My.Name hasError={includes(errorFields, NAME)} onChange={onChange(NAME)} value={formState[NAME] || ''} />
          </My.NameField>

          <My.MessageField>
            <My.Message hasError={includes(errorFields, MESSAGE)} onChange={onChange(MESSAGE)}>{formState[MESSAGE] || ''}</My.Message>
          </My.MessageField>

          <My.EmailField>
            <My.Email hasError={includes(errorFields, EMAIL)} onChange={onChange(EMAIL)} value={formState[EMAIL] || ''} />
          </My.EmailField>

          <My.CompanyField>
            <My.Company hasError={includes(errorFields, COMPANY)} onChange={onChange(COMPANY)} value={formState[COMPANY] || ''} />
          </My.CompanyField>

          <My.JobTitleField>
            <My.JobTitle hasError={includes(errorFields, COMPANY)} onChange={onChange(JOB_TITLE)} value={formState[JOB_TITLE] || ''} />
          </My.JobTitleField>

          <My.LinkField>
            <My.Link hasError={includes(errorFields, LINK)} onChange={onChange(LINK)} value={formState[LINK] || ''} />
          </My.LinkField>

          <My.Agree onChange={onChange(AGREE)} valid={!includes(errorFields, AGREE)} checked={formState[AGREE] === 'yes'} />
          <My.BecomeAMember onChange={onChange(BECOME_MEMBER)} checked={formState[BECOME_MEMBER] === 'yes'} />
          <My.Updates name="agree" onChange={onChange(RECEIVE_UPDATES)} checked={formState[RECEIVE_UPDATES] === 'yes'} />

          {
            process.env.SITE_RECAPTCHA_KEY && 
            <ReCAPTCHA
              sitekey={process.env.SITE_RECAPTCHA_KEY}
              onChange={onCaptchaChange}
            />
          }
          {
            hasErrors && 
            <My.Errors items={errors}>
            Sorry, we need a bit more information:
            </My.Errors>
          }
          <My.SubmitButton type="submit" disabled={isSubmitDisabled}>Submit</My.SubmitButton>
        </My.Form>
      } 
      
    </My.Container>
  )
};

ContactForm.defaultProps = ContactFormDefaultProps;