import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, ImageEntity, useEntity, useLink } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('slice-knowledge-hub-intro');
import { first } from 'lodash';

// -------- Types -------->

export type SliceKnowledgeHubIntroProps = Classable & HasChildren & { 
  entity?: Slice;
};

export const SliceKnowledgeHubIntroDefaultProps: Omit<SliceKnowledgeHubIntroProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column; 
  `,
  tablet: css`
    flex-direction: row; 
  `
});

const ContentStyles = breakpoints({
  base: css`
    max-width: 800px;
    padding: ${theme.UNIT(2)} 0 0;

    a {
      font-weight: 500;
    }
  `,
});

const ImageStyles = breakpoints({
  base: css`
    width: 100%;
    flex-shrink: 0;
    margin-top: ${theme.UNIT(8)};
  `,
  tablet: css`
    margin-top: ${theme.UNIT(2)};
    width: 400px;
  `
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Content: styled(ContentNode)`${ContentStyles}`,
    Image: styled(ImageEntity)`${ImageStyles}`,
};

export const SliceKnowledgeHubIntro: Shapeable.FC<SliceKnowledgeHubIntroProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const content = entity.content;
  const { Link } = useLink();

  const link = first(entity.links);
  const image = first(entity.images);

  // 

  return (
    <My.Container className={cls.name(className)}>
      <My.Content entity={content} />
      {
        image && link &&
        <Link style={{ flexShrink: 0 }} href={link.url}>
        <My.Image entity={image} />
        </Link>
      }
    </My.Container>
  );

};

SliceKnowledgeHubIntro.defaultProps = SliceKnowledgeHubIntroDefaultProps;
SliceKnowledgeHubIntro.cls = cls;