import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Page, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, EntityGrid } from '@shapeable/ui';
import { sortBy } from 'lodash';
import { classNames } from '@shapeable/utils';
import { transformPages } from '../../utils/tranforms';
const cls = classNames('publications-layout');

// -------- Types -------->

export type PublicationsLayoutProps = Classable & HasChildren & {
  entity?: Page;
}

export const PublicationsLayoutDefaultProps: PublicationsLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const GridStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} 0;
    
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-weight: 400;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Title: styled(ContentNode)`${TitleStyles}`,
    Grid: styled(EntityGrid)`${GridStyles}`,
};

export const PublicationsLayout: Shapeable.FC<PublicationsLayoutProps> = (props) => {
  const { className, entity } = props;

  const { children, titleRich } = entity;

  const hasChildren = !!children.length;

  const childrenByType = sortBy(transformPages(children), 'orderNumber');
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Title entity={titleRich} />
      {
        hasChildren && 
          <My.Grid 
            items={childrenByType} 
            spacing={1}
            anyCardProps={{ variant: 'chromeless' }}
            desktopAutoColumns={3}
            desktopFullHdAutoColumns={3}
            desktopLargeAutoColumns={3}
            />
      }
    </My.Container>
  )
};

PublicationsLayout.cls = cls;
PublicationsLayout.defaultProps = PublicationsLayoutDefaultProps;