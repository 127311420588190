import React from 'react';
import { IconComponent } from '@shapeable/types'

export const CommunityStatisticsIcon: IconComponent = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 48 48">
  <path d="M24 8C20.7 8 18 10.7 18 14C18 17.3 20.7 20 24 20C27.3 20 30 17.3 30 14C30 10.7 27.3 8 24 8ZM22.7 17.3L19.7 14.3L20.7 13.3L22.7 15.3L26.7 11.3L27.7 12.3L22.7 17.3Z" fill="black"/>
  <path d="M33 27C35.2 27 37 25.2 37 23C37 20.8 35.2 19 33 19C30.8 19 29 20.8 29 23C29 25.2 30.8 27 33 27ZM33 21C34.1 21 35 21.9 35 23C35 24.1 34.1 25 33 25C31.9 25 31 24.1 31 23C31 21.9 31.9 21 33 21Z" fill="black"/>
  <path d="M15 27C17.2 27 19 25.2 19 23C19 20.8 17.2 19 15 19C12.8 19 11 20.8 11 23C11 25.2 12.8 27 15 27ZM15 21C16.1 21 17 21.9 17 23C17 24.1 16.1 25 15 25C13.9 25 13 24.1 13 23C13 21.9 13.9 21 15 21Z" fill="black"/>
  <path d="M20 27C20 29.2 21.8 31 24 31C26.2 31 28 29.2 28 27C28 24.8 26.2 23 24 23C21.8 23 20 24.8 20 27ZM26 27C26 28.1 25.1 29 24 29C22.9 29 22 28.1 22 27C22 25.9 22.9 25 24 25C25.1 25 26 25.9 26 27Z" fill="black"/>
  <path d="M33 28C30.1 28 27.6 30.1 27.1 32.9C26.2 32.3 25.1 32 24 32C22.9 32 21.8 32.3 20.9 32.9C20.4 30.1 17.9 28 15 28C11.7 28 9 30.7 9 34H13C13 32.9 13.9 32 15 32C16.1 32 17 32.9 17 34H19.5C18.5 35.1 18 36.5 18 38H22C22 36.9 22.9 36 24 36C25.1 36 26 36.9 26 38H30C30 36.5 29.4 35.1 28.5 34H31C31 32.9 31.9 32 33 32C34.1 32 35 32.9 35 34H39C39 30.7 36.3 28 33 28Z" fill="black"/>
  <path d="M28 1.29999V5.39999C36.2 7.19999 42.5 14.3 42.9 22.9L45 25L47 23C46.5 12.1 38.5 3.19999 28 1.29999Z" fill="black"/>
  <path d="M3 23L1 25C1.5 35.9 9.5 44.8 20 46.7V42.6C11.7 40.8 5.5 33.7 5 25L3 23Z" fill="black"/>
  <path d="M22.9998 1C12.0998 1.5 3.1998 9.5 1.2998 20H5.39981C7.19981 11.7 14.2998 5.5 22.8998 5L24.9998 3L22.9998 1Z" fill="black"/>
  <path d="M46.6 28H42.5C40.7 36.2 33.6 42.5 25 42.9L23 45L25 47C35.8 46.5 44.8 38.5 46.6 28Z" fill="black"/>
  </svg>
);