import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, EntityPercentage, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useLang } from '@shapeable/ui';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { ChartTooltip } from '../elements/chart-tooltip';
import { pieDataForEntityPercentages } from '../../utils/pie-data-for-entity-percentages';
import { classNames } from '@shapeable/utils';

const cls = classNames('trend-variable-pie-chart');

// -------- Types -------->

export type EntityPercentagePieChartDataItem = {
  value: number;
  label: string;
  color: string;
};

export type EntityPercentagePieChartProps = Classable & HasChildren & { 
  items: EntityPercentage[];
  size?: number;
  indent?: number;
  color?: string;
};

export const EntityPercentagePieChartDefaultProps: EntityPercentagePieChartProps = {
  items: [],
  color: 'dark',
  size: 200,
  indent: 10,
};

// -------- Child Component Props -------->


type CountProps = {
  _color: string;
}

type ContainerProps = {
  size: number;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
 `,
});

const TitleStyles = breakpoints({
  base: css`
    margin: 0 0 ${theme.UNIT(4)} ${theme.UNIT(4)};
    font-weight: 600;
    color: ${theme.COLOR('dark')};
    font-size: ${theme.FONT_SIZE(20)};
  `,
});


const PieChartStyles = breakpoints({
  base: css`
  `,
});

const TooltipStyles = breakpoints({
  base: css`
    
  `,
});

const EntityStyles = breakpoints({
  base: css`
    
  `,
});

const CountStyles = breakpoints({
  base: css`
    display: inline-block;
    padding-left: ${theme.UNIT(1)};
    ${({ _color }: CountProps ) => css`
      color: ${theme.COLOR(_color)};
    `}
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    flex-direction: row;
    align-items: center;
    
  `
});

const PieContainerStyles = breakpoints({
  base: css`
    position: relative;
  `,
  tablet: css`
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  Title: styled.h1`${TitleStyles}`,
  
  Body: styled.div`${BodyStyles}`,

    PieContainer: styled.div`${PieContainerStyles}`,
      PieChart: styled(PieChart)`${PieChartStyles}`,
      
  Tooltip: styled(ChartTooltip)`${TooltipStyles}`,
    Entity: styled.span`${EntityStyles}`,
    Count: styled.b<CountProps>`${CountStyles}`,

};

export const EntityPercentagePieChart: React.FC<EntityPercentagePieChartProps> = (props) => {
  const { className, children, indent, color, items, size } = props;
  const t = useLang();

  const baseColor = color;
  const data = pieDataForEntityPercentages(items, baseColor);

  const CustomTooltip = ({ payload }: any) => {
    const result = payload.length && payload[0];

    if (result) {
      const { name, value } = result;

      return (
        <My.Tooltip>
          <My.Entity>{name}:</My.Entity>
          <My.Count _color={baseColor}>{value.toFixed(1)}</My.Count>
        </My.Tooltip>
      );
    }
  
    return null;
  };
  
  return (
    <My.Container size={size} className={cls.name(className)}>
      <My.Body>
      <My.PieContainer style={{ width: `${size}px` }}>
        <My.PieChart 
          width={size}
          height={size}
          margin={{ top: 0, right: -20, left: -20, bottom: 0 }}
        >
          <Pie
            animationDuration={500}
            animationBegin={0}
            animationEasing="ease-out"
            stroke={null}
            data={data}
            innerRadius={size / 6}
            outerRadius={(size - (indent * 2)) / 2}
            dataKey="value"
            nameKey="label"
          >
            {data.map((result, index) => (
              <Cell key={`cell-${index}`} fill={result.color} />
            ))}
          </Pie>
          <Tooltip content={CustomTooltip} />
        </My.PieChart>


        </My.PieContainer>
        </My.Body>
    </My.Container>
  )
};

EntityPercentagePieChart.defaultProps = EntityPercentagePieChartDefaultProps;