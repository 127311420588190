import { ChartGrid, ChartGridProps, CircleTrailBar, HTML, useInViewEffect, useLang } from '@shapeable/ui';
import React from 'react';
import { classNames } from '@shapeable/utils';
import styled, { css } from 'styled-components'; const cls = classNames('pledge-chart');
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import millify from 'millify';
import { PledgeCircle } from './pledge-circle';
import { OCEAN_BLUE, ORANGE } from '../../theme';
import { MetricValue } from './metric-value';
import { TargetIcon } from '@shapeable/icons';

// -------- Types -------->

export type PledgeChartProps = Classable & HasChildren & ChartGridProps & {
  currentValue: number;
  firstTarget?: number;
  finalTarget: number;
  firstTargetLabel?: string;
  finalTargetLabel?: string;
  color?: string;
  targetColor?: string;
}

export const PledgeChartDefaultProps: Omit<PledgeChartProps, 'firstTarget' | 'finalTarget' | 'currentValue'> = {
  targetColor: ORANGE
};

// -------- Child Component Props -------->

type ContainerProps = {

};

type TargetProps = {
  _color: string;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 0.9em;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    position: relative;
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    flex-direction: row;
    align-items: flex-start;
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    min-height: 20px;
    font-size: 1em;
    display: flex;
    align-items: center;
    font-weight: 300;
    padding-bottom: ${theme.UNIT(1)};
    color: ${theme.COLOR('text')};
    text-transform: uppercase;
    position: relative;
  `,
});


const ChartStyles = breakpoints({
  base: css`
    position: relative;
    width: 100%;
    margin: 0 ${theme.UNIT(5)};
  `,
  tablet: css`
    margin: 0;
  `
});

const GridStyles = breakpoints({
  base: css`
    .shp--x-axis__tick {
      color: ${theme.COLOR('text-subtle')};
    }
    width: 100%;
  `,
  tablet: css`
  `
});

const PledgeLabelStyles = breakpoints({
  base: css`
    margin: 0;
    z-index: 10;
    font-size: 1em;
    font-weight: 300;
    color: ${theme.COLOR('text-subtle')};
    text-transform: uppercase;
  `,
});

const FirstTargetLabelStyles = breakpoints({
  base: css`
    ${PledgeLabelStyles};
    top: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    flex-wrap: nowrap;
    white-space: nowrap;

    ${({ _color }: TargetProps ) => css`
      i {
        color: ${_color};
        font-style: normal;
      }

      b {
        font-weight: 500;
        color: ${theme.COLOR('strong')};
      }
    `}
  `,
});

const FinalTargetLabelStyles = breakpoints({
  base: css`
    ${PledgeLabelStyles};
    height: 18px;
    display: flex;
    align-items: center;
    ${theme.FILL('strong')};
    svg {
      width: 24px;
      height: 24px;
      margin-right: ${theme.UNIT(1.5)};
    }
    
    ${({ _color }: TargetProps ) => css`
      i {
        color: ${_color};
        font-style: normal;
      }

      b {
        font-weight: 500;
        color: ${theme.COLOR('strong')};
      }
    `}
  `,
});


const TrailStyles = breakpoints({
  base: css`
    position: absolute;
    top: ${((100 - 48) / 2) + 30}px;
    left: 0;
    z-index: 100;

    b {
      font-size: ${theme.FONT_SIZE(15)};
      color: ${theme.COLOR('strong')};
      font-weight: 500;
    }
  `,
});


const ValueStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(36)};
    margin-left: 2px;
    margin-top: ${theme.UNIT(4)};
  `,
  tablet: css`
    margin-top: 46px;
    margin-left: ${theme.UNIT(8)};
    font-size: ${theme.FONT_SIZE(48)};
  `
});

const FirstTargetStyles = breakpoints({
  base: css`
    height: calc(100% - 30px);
    position: absolute;
    width: 0;
    z-index: 50;
    top: 30px;
    ${({ _color }: TargetProps ) => css`
      border-left: 2px solid ${_color};
    `}
  `,
});

const FinalTargetStyles = breakpoints({
  base: css`
    ${FirstTargetStyles};
    border-width: 3px;
  `,
});

const TargetHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const ChartHeaderStyles = breakpoints({
  base: css`
    position: relative;
  `,
});

const StartStyles = breakpoints({
  base: css`
    /* prevents cliping of final target */
    width: 95%;
  `,
  tablet: css`
    width: 60%;
  `
});

const EndStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(6)};
  `,
  tablet: css`
    margin-top: 0;
    width: 40%;
  `
});






// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  
    Start: styled.div`${StartStyles}`,
    End: styled.div`${EndStyles}`,
    
    Header: styled.div`${HeaderStyles}`,
      ChartHeader: styled.div`${ChartHeaderStyles}`,
        PledgeLabel: styled.h3`${PledgeLabelStyles}`,
        FirstTargetLabel: styled.h4<TargetProps>`${FirstTargetLabelStyles}`,
      TargetHeader: styled.div`${TargetHeaderStyles}`,
        FinalTargetLabel: styled.h4<TargetProps>`${FinalTargetLabelStyles}`,

      Body: styled.div`${BodyStyles}`,
      Value: styled(MetricValue)`${ValueStyles}`,

      FirstTarget: styled.span<TargetProps>`${FirstTargetStyles}`,
      FinalTarget: styled.span<TargetProps>`${FinalTargetStyles}`,
    
      Chart: styled.div`${ChartStyles}`,
        Grid: styled(ChartGrid)`${GridStyles}`,
        Trail: styled(CircleTrailBar)`${TrailStyles}`,
};

export const PledgeChart: React.FC<PledgeChartProps> = (props) => {
  const { className, firstTarget, finalTarget, firstTargetLabel, finalTargetLabel, targetColor, currentValue, ...gridProps } = props;

  const t = useLang();

  const [ value, setValue ] = React.useState<number>(0);

  const ref = useInViewEffect(() => {
    setValue(currentValue);
  });

  const formatTickValue = (value: number) => millify(value, {
    units: ['', 'K', 'M', 'B'],
    precision: 1,    
  });

  const percentageValue = `${(value / finalTarget) * 100}%`;

  const percentageFirstTargetValue = `calc(${(firstTarget / finalTarget) * 100}% - 1px)`;
  const percentageFinalTargetValue = `calc(100% - 2px)`;

  return (
    <My.Container ref={ref} className={cls.name(className)}>
      <My.Body>
        <My.Start>
          <My.ChartHeader>
            <My.PledgeLabel>&nbsp;</My.PledgeLabel>
            <My.FirstTargetLabel _color={targetColor} style={{ left: percentageFirstTargetValue }}><HTML text={firstTargetLabel} /></My.FirstTargetLabel>
          </My.ChartHeader>
        <My.Chart>
          <My.Grid 
            {...gridProps} 
            minorDivisions={10}
            minorOpacity={0.8}
            middleMinorOpacity={0.8}
            majorDivisions={4}
            showTicks
            xAxisLocation='top'
            showAxisLine={false}
            showMarks={false}
            formatTickValue={formatTickValue}
            maxValue={finalTarget}
            showAxis={true}
            gridHeight="100px"
          />
          <My.FirstTarget _color={targetColor} style={{ left: percentageFirstTargetValue }} />
          <My.FinalTarget _color={targetColor} style={{ left: percentageFinalTargetValue }} />

          <My.Trail value={currentValue} showCountUp={false} formatValue={formatTickValue} showValue color={OCEAN_BLUE} size='medium' width={percentageValue} circleComponent={PledgeCircle} />
        </My.Chart>
        </My.Start>
        
        <My.End>
          <My.FinalTargetLabel _color={targetColor} style={{ left: percentageFirstTargetValue }}><TargetIcon /><HTML text={finalTargetLabel} /></My.FinalTargetLabel>
          <My.Value showCountUp={false} formatValue={formatTickValue} suffix={t('ha')} value={finalTarget} />
        </My.End>
      </My.Body>
    </My.Container>
  )
};

PledgeChart.defaultProps = PledgeChartDefaultProps;