import React from 'react';
import { IconComponent } from '@shapeable/types'

export const RestorationMeasurementIcon: IconComponent = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 48 48">
    <path d="M11.5 22.3C11 22.3 10.6 22.5 10.3 22.8V20.5H9V27.1H10.3V24C10.3 23.6 10.7 23.4 11.1 23.4C11.4 23.4 11.7 23.5 11.7 24.2V27H13V24.1C13 23 12.5 22.3 11.5 22.3Z" fill="black"/>
    <path d="M18.3999 26V24C18.3999 22.9 17.6999 22.3 16.3999 22.3C15.4999 22.3 14.8999 22.6 14.5999 23.2L14.4999 23.3L15.2999 24L15.3999 23.9C15.5999 23.5 15.8999 23.4 16.2999 23.4C16.7999 23.4 16.9999 23.6 16.9999 24.1V24.3H16.3999C15.0999 24.3 14.3999 24.8 14.3999 25.8C14.3999 26.7 14.9999 27.2 15.8999 27.2C16.4999 27.2 16.9999 27 17.1999 26.5C17.2999 26.9 17.6999 27.1 18.0999 27.1H18.7999V26H18.3999ZM16.2999 26.2C15.6999 26.2 15.6999 25.9 15.6999 25.8V25.7C15.6999 25.4 15.8999 25.3 16.3999 25.3H16.9999V25.8C16.9999 26 16.5999 26.2 16.2999 26.2Z" fill="black"/>
    <path d="M33 13H31V17H33V13Z" fill="black"/>
    <path d="M33 31H31V35H33V31Z" fill="black"/>
    <path d="M23 14H21V19H23V14Z" fill="black"/>
    <path d="M23 29H21V34H23V29Z" fill="black"/>
    <path d="M17 14H15V19H17V14Z" fill="black"/>
    <path d="M17 29H15V34H17V29Z" fill="black"/>
    <path d="M11 14H9V19H11V14Z" fill="black"/>
    <path d="M11 29H9V34H11V29Z" fill="black"/>
    <path d="M33 12H5V14H33V12Z" fill="black"/>
    <path d="M33 34H5V36H33V34Z" fill="black"/>
    <path d="M32 16C27.6 16 24 19.6 24 24C24 28.4 27.6 32 32 32C36.4 32 40 28.4 40 24C40 19.6 36.4 16 32 16ZM30.2 28.4L26.2 24.4L27.5 23.1L30.2 25.8L35.5 20.5L36.8 21.8L30.2 28.4Z" fill="black"/>
    <path d="M28 1.3V5.4C36.2 7.2 42.5 14.3 42.9 22.9L45 25L47 23C46.5 12.1 38.5 3.2 28 1.3Z" fill="black"/>
    <path d="M3 23L1 25C1.5 35.9 9.5 44.8 20 46.7V42.6C11.7 40.8 5.5 33.7 5 25L3 23Z" fill="black"/>
    <path d="M22.9998 1C12.0998 1.5 3.1998 9.5 1.2998 20H5.39981C7.19981 11.7 14.2998 5.5 22.8998 5L24.9998 3L22.9998 1Z" fill="black"/>
    <path d="M46.6 28H42.5C40.7 36.2 33.6 42.5 25 42.9L23 45L25 47C35.8 46.5 44.8 38.5 46.6 28Z" fill="black"/>
  </svg>
);