import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SideNavigationList } from './side-navigation-list';
import { classNames } from '@shapeable/utils';
import { useMenusNavigation } from '../../hooks/use-menus-navigation';

const cls = classNames('aside-layout');

// -------- Types -------->

export type AsideMenuProps = Classable & HasChildren & {
}

export const AsideMenuDefaultProps: AsideMenuProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: none;
  `,
  tablet: css`
    display: block;
  `,
});

const MenuStyles = breakpoints({
  base: css`
    padding: 0 !important;

    .shp-menu-items-list__item-link {
      padding-left: ${theme.UNIT(3)};
      padding-right: ${theme.UNIT(3)};
    }
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Menu: styled(SideNavigationList)`${MenuStyles}`,
};

export const AsideMenu: React.FC<AsideMenuProps> = (props) => {
  const { className } = props;
  const mainMenu = useMenusNavigation();

  return (
    <My.Container className={cls.name(className)}>
      <My.Menu entity={mainMenu} />
    </My.Container>
  )
};

AsideMenu.defaultProps = AsideMenuDefaultProps;