import { Banner, Block, Color, EntityTypeName, ImageEntity, Link, Page, Slice } from "@shapeable/types";
import { omit } from 'lodash';
import { breakpoints, theme } from '@shapeable/theme';
import { css } from 'styled-components';
import { CONTENT_BACKGROUND, DARK_BLUE, MEDIUM_BLUE, PRIMARY_BLUE } from "./theme";
import { COLOR_WHITE } from "@shapeable/ui";
import GREEN_HATCH from './images/green-hatch.png';
import BLUE_HATCH from './images/blue-hatch.png';

const CONTENT_FONT_SIZE = 16;

export const OVERLAY_SHADOW = '0 1px 3px rgba(0, 0, 0, 0.7)';
export const NOT_FOUND_PAGE: Page = {
  id: 'not-found',
  slug: 'not-found',
  name: 'Page Not Found',
  intro: {
    text: 'Sorry, this page could not be found',
  },
}

/* Canned entities to speed things up a bit */

export const COLOR_MEDIUM_BLUE: Color = {
  value: MEDIUM_BLUE,
};

export const COLOR_DARK_BLUE: Color = {
  value: DARK_BLUE,
};

export const COLOR_PRIMARY_BLUE: Color = {
  value: PRIMARY_BLUE,
};

export const COLOR_CONTENT_BACKGROUND: Color = {
  value: CONTENT_BACKGROUND,
};

export const BANNER_OVERLAY = '#00000099';

export const LINK_EMAIL_SIGN_UP: Link = {
  id: 'email',
  name: 'Email Sign Up',
  url: 'http://eepurl.com/hXQ8fj',
};

export const LINK_INSTITUTION_EMAIL_SIGN_UP: Link = {
  id: 'email',
  name: 'Sign Up',
  url: 'https://forms.office.com/pages/responsepage.aspx?id=pM_2PxXn20i44Qhnufn7o4g01UsnWLNGv48SHZ9cem1UMVQxNlQ4TFUyTE1HVFJNSURTNkpUQUM3SC4u&web=1&wdLOR=c720CB6B8-2737-411A-98AD-A5E489A61DB4',
};



export const LINK_SEE_BELOW: Link = {
  id: 'see-below',
  name: 'Sign Up Below',
  url: '',
}

export const PAGE_CONTACT: Page = {
  __typename: 'Page',
  id: 'contact',
  path: '/contact',
  name: "Join Us",
};

export const BLOCK_OBSERVER_MEMBER: Block = {
  id: 'block-observer',
  title: 'Observer Member',
  color: COLOR_WHITE,
  backgroundColor: COLOR_MEDIUM_BLUE,
  content: {
    text: `
Members will receive communications to stay up-to-speed on the latest in kelp restoration around the world but are not active contributors to the community
    `
  },
  link: LINK_EMAIL_SIGN_UP,
};


export const BLOCK_PARTICIPATORY_MEMBER: Block = {
  id: 'block-participatory',
  title: 'Participatory Member',
  subtitle: '(Individual)',
  color: COLOR_WHITE,
  backgroundColor: COLOR_PRIMARY_BLUE,
  content: {
    text: `
Members are invited to participate in an active community of kelp restoration practice
    `
  },
  link: LINK_SEE_BELOW
};

export const BANNER_KNOWLEDGE_HUB: Banner = {
  "id": "banner-knowledge-hub",
  "name": "Banner Knowledge Hub",
  "slug": "banner-knowledge-hub",
  "image": {
      "id": "banner-knowledge-hub-image",
      "url": "https://res.cloudinary.com/shapeable/image/upload/v1668995153/kelp-forest-alliance/banner/knowledge-banner_image__knowledge-hub-banner_zucmkv.jpg",
      "width": 1920,
      "height": 576
  }
};


export const BLOCK_PARTICIPATORY_MEMBER_INSTITUTION: Block = {
  id: 'block-participatory-institution',
  title: 'Participatory Member',
  subtitle: '(Institution)',
  color: COLOR_WHITE,
  backgroundColor: COLOR_DARK_BLUE,
  content: {
    text: `
Members are invited to participate in an active community of kelp restoration practice
    `
  },
  link: LINK_INSTITUTION_EMAIL_SIGN_UP
};

export const BLOCK_PARTICIPATORY_MEMBER_INSTITUTION_CONTACT: Block = {
  ...BLOCK_PARTICIPATORY_MEMBER_INSTITUTION
};

export const BLOCK_PARTICIPATORY_MEMBER_CONTACT: Block = {
  ...omit(BLOCK_PARTICIPATORY_MEMBER, 'link'),
  page: PAGE_CONTACT,
};
export const DEFAULT_MAP_ENTITY_TYPE_NAMES: EntityTypeName[] = [
  'RestorationProject',
]

export const PAGE_RESTORATION_PROJECTS: Page = {
  id: 'restoration-projects',
  path: '/restoration-projects',
};

export const FAQ_BANNER: ImageEntity = {
  id: 'faq-banner',
  image: {
    url: "https://res.cloudinary.com/shapeable/image/upload/v1668994269/kelp-forest-alliance/banner/species-cystoseira-01-jome-jome_image__species-cystoseira-01-jome-jome.jpg"
  }
}

export const ORGANISATION_BANNER: ImageEntity = {
  id: 'organisation-banner',
  image: {
    url: 'https://res.cloudinary.com/shapeable/image/upload/v1668995253/kelp-forest-alliance/banner/vanishing-forests_image__vanishing-forests-banner_lomidh.jpg'
  }
};

export const LOCATION_BANNER: ImageEntity = {
  id: 'location-banner',
  image: {
    url: 'https://res.cloudinary.com/shapeable/image/upload/v1675407018/kelp-forest-alliance/banner/impact-metrics-banner_image__impact-metrics-banner_t18qfe.jpg.jpg'
  }
};

export const ROADMAP_THUMBNAIL: ImageEntity = {
  id: 'roadmap-thumbnail',
  image: {
    url: 'https://res.cloudinary.com/shapeable/image/upload/v1707104641/kelp-forest-alliance/page/roadmap_thumbnail__Roadmap_thumb_uqr1xt.webp'
  }
};

export const SLICE_MEMBERSHIP_CONTACT: Slice = {
  id: 'slice-membership',
  slug: 'slice-membership',
  blocks: [
    BLOCK_OBSERVER_MEMBER,
    BLOCK_PARTICIPATORY_MEMBER,
    BLOCK_PARTICIPATORY_MEMBER_INSTITUTION,
  ]
};

export const PAGE_KNOWLEDGE_HUB: Page = {
  id: 'page-knowledge-hub',
  slug: 'knowledge-hub',
  path: '/knowledge-hub',
};

export const SLICE_MEMBERSHIP: Slice = {
  id: 'slice-membership-contact',
  slug: 'slice-membership-contact',
  blocks: [
    BLOCK_OBSERVER_MEMBER,
    BLOCK_PARTICIPATORY_MEMBER_CONTACT,
    BLOCK_PARTICIPATORY_MEMBER_INSTITUTION_CONTACT,
  ]
};

export const greenHatch = GREEN_HATCH;
export const blueHatch = BLUE_HATCH;

const greenHatchObj = {
  id: 'green-hatch',
  title: 'Distribution Areas',
  image: {
    path: GREEN_HATCH,
  }
};

const blueHatchObj = {
  id: 'blue-hatch',
  title: 'Protection Areas',
  image: {
    path: BLUE_HATCH,
  }
};

export const legendImages = [greenHatch, blueHatch];

export const createIntroSlice = (entity: Page) => ({
  id: 'intro-slice',
  intro: entity.intro,
  backgroundColor: COLOR_CONTENT_BACKGROUND,
  color: COLOR_DARK_BLUE,
  layout: {
    id: 'slice-layout-intro',
    slug: 'slice-layout-intro',
    component: 'SliceLayoutIntro',
  }
});

