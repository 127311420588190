import { MenuItem } from '@shapeable/types';
import { useEntity, useEnv } from '@shapeable/ui';
import { MenuItemsContext, MenuItemsNavigation } from '../components/context/menu-items-context';
import { findIndex, find } from 'lodash';
import { useContext, useMemo } from 'react';

export type MenuNavigationDefinition = {
  selectedIndex: number;
  items: MenuItem[];
  name?: string;
  current?: MenuItem;
  next?: MenuItem;
  prev?: MenuItem;
  hasNext?: boolean;
  hasPrev?: boolean;
  isMenuNav?: boolean;
};

const emptyInfo: MenuNavigationDefinition = {
  items: [],
  selectedIndex: -1,
  hasNext: false,
  hasPrev: false,
};

export const useMenusNavigation: () => MenuNavigationDefinition = 
  () => {
    const { items, name } = useContext(MenuItemsContext) as MenuItemsNavigation;
    const entity = useEntity();
    const env = useEnv(); 

    if (!(items && items.length && entity)) {
      return { ...emptyInfo, name };
    }

    return useMemo(() => {
        // the value here needs to be the actual option object (could we abstract over this and look for the value??)
      let selectedIndex = findIndex(items, item => item.path === entity.__path);
      let current = find(items, item => item.path === entity.__path);
        
      const next = selectedIndex !== -1 ? (selectedIndex < items.length - 1 ? items[selectedIndex + 1] : null) : null;
      const prev = selectedIndex > 0 ? items[selectedIndex - 1] : null;

      const hasNext = !!next;
      const hasPrev = !!prev;
      
      const ret = {
        items,
        name,
        selectedIndex,
        current,
        next,
        prev,
        hasNext,
        hasPrev,
        isMenuNav: !!current,
      };

      if (env.DEBUG_LINEAR_NAVIGATION) {
        console.log(ret);
      }
    
      return ret; 

    }, [entity, items, name]);
  };
