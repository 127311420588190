import React from 'react';
import { LinearNavigationProvider as CoreProvider, LinearNavigationItem, useActiveEntity, useMenus } from "@shapeable/ui"
import { Menu, MenuItem } from '@shapeable/types';

export const LinearNavigationProvider: React.FC<any> = ({ children }) => {

  const menus = useMenus();
  const currentPage = useActiveEntity();
  const items: LinearNavigationItem[] = [];

  const currentMenu = menus?.find((menuItem: Menu) => 
    menuItem.items.some((item: Menu) => item?.slug === currentPage?.slug)
  ) || null;

  if (currentMenu) {
    currentMenu.items.forEach((menuItem: MenuItem) => {
      items.push({
        id: menuItem.id,
        name: menuItem.name,
        depth: 0,
        path: menuItem.path,
        slug: menuItem.slug,
      });
    });
  };

  return (
    <CoreProvider value={items}>
    {children}
    </CoreProvider>
  )
}