import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityContent, ImageEntityBackground, useEntity } from '@shapeable/ui';
import { BlockMetricLayout } from '../entities/block-metric-layout';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(14)};
    color: ${theme.COLOR('light')};
    height: 100%;
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(18)};
  `,
});

const BackgroundStyles = breakpoints({
  base: css`
    position: relative;

  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} ${theme.UNIT(3)};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    color: inherit;
    font-size: 1em;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Background: styled(ImageEntityBackground)`${BackgroundStyles}`,
  Body: styled.div`${BodyStyles}`,
  Content: styled(ContentEntityContent)`${ContentStyles}`,
};

export const BlockLayoutImageBackground: BlockLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { image } = entity;

  return (
    <My.Container className={className}>
    <My.Background aspectRatio={{ base: 16/9, tablet: 1 }} entity={image}>
    <My.Body>
      <My.Content entity={entity} />
      {children}
    </My.Body>
    </My.Background>
    </My.Container>
  )
};