import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { CitationsProvider as Provider, platformProviderValue } from '@shapeable/ui'
import { sortBy } from 'lodash';

const CITATIONS_QUERY = graphql`
  query PlatformCitationsQuery {
    platform {
      allCitations {
        __typename _schema { label pluralLabel }
        id path slug url name
        openGraph { image { url } }
        organisations { id name slug __typename _schema { label pluralLabel } }
        economies { id name slug __typename _schema { label pluralLabel } }
        genera { id name slug __typename openGraph { id image { id url } } _schema { label pluralLabel } }
        species { id name slug __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        methodologies { id name slug __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        authors { id name slug __typename _schema { label pluralLabel } openGraph { id image { id url } } } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
        restorationProjects { 
          id path slug name latitude longitude __typename _schema { label pluralLabel }
          openGraph { description { text(truncate: { words: 20 }) } image { url } }
        }
      }
    }
  }`;

export const CitationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(CITATIONS_QUERY), 'allCitations' ), 'name') }>{children}</Provider>;
};
