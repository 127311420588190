import React from 'react';
import { IconComponent } from '@shapeable/types'

export const StatusIiiIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_2705_16686)">
      <path d="M16 2.78261C23.3043 2.78261 29.2174 8.69565 29.2174 16C29.2174 23.3043 23.3043 29.2174 16 29.2174C8.69565 29.2174 2.78261 23.3043 2.78261 16C2.78261 8.69565 8.69565 2.78261 16 2.78261ZM16 0C7.16522 0 0 7.16522 0 16C0 24.8348 7.16522 32 16 32C24.8348 32 32 24.8348 32 16C32 7.16522 24.8348 0 16 0Z" fill="black"/>
      <path d="M7.7002 21V19.306H8.9602V12.922H7.7002V11.228H12.3482V12.922H11.0882V19.306H12.3482V21H7.7002Z" fill="black"/>
      <path d="M13.7432 21V19.306H15.0032V12.922H13.7432V11.228H18.3912V12.922H17.1312V19.306H18.3912V21H13.7432Z" fill="black"/>
      <path d="M19.7861 21V19.306H21.0461V12.922H19.7861V11.228H24.4341V12.922H23.1741V19.306H24.4341V21H19.7861Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_2705_16686">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);