import { CellLabel, EntityTooltip, EntityTooltipProps, useEntity, useLang } from '@shapeable/ui';
import React from 'react';
import styled, { css } from 'styled-components'; 
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Organisation } from '@shapeable/kelp-forest-alliance-types';
import { OrganisationPledgeValue } from './organisation-pledge-value';
import { PledgeCircle } from '../elements/pledge-circle';
import { ORANGE } from '../../theme';
import { classNames } from '@shapeable/utils';

const cls = classNames('organisation-tooltip');

// -------- Types -------->

export type OrganisationTooltipProps = EntityTooltipProps & { 
  entity?: Organisation;
};

export const OrganisationTooltipDefaultProps: Omit<OrganisationTooltipProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size ${theme.FONT_SIZE(14)};
    text-align: left;
  `,
});

const PledgeStyles = breakpoints({
  base: css`
     margin-bottom: ${theme.UNIT(2)};
  `,
});

const BodyStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG('line', 2, 4)};
    margin-top: ${theme.UNIT(4)};
    padding-top: ${theme.UNIT(2)};
 `,
});

const PledgesLabelStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled(EntityTooltip)<ContainerProps>`${ContainerStyles}`,
    PledgesLabel: styled(CellLabel)`${PledgesLabelStyles}`,
    Pledge: styled(OrganisationPledgeValue)`${PledgeStyles}`,
    Body: styled.div`${BodyStyles}`,
};

export const OrganisationTooltip: React.FC<OrganisationTooltipProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const { pledges = [] } = entity;

  const t = useLang();

  return (
    <My.Container imageStyle='logo' entity={entity} className={cls.name(className)}>
      {
        !!pledges.length && 
        <My.Body>
        <My.PledgesLabel>{t('Pledges:')}</My.PledgesLabel>
        {
          pledges.map(pledge => (
            <My.Pledge entity={pledge} />
          ))
        }
        {children}
      </My.Body>

      }
    </My.Container>
  )
};

OrganisationTooltip.defaultProps = OrganisationTooltipDefaultProps;