import React from 'react';
import { useActiveEntity, useMenus } from "@shapeable/ui"
import { Menu } from '@shapeable/types';
import { MenuItemsProvider } from '../../components/context/menu-items-context';

export const MenusNavigationProvider: React.FC<any> = ({ children }) => {

  // const { items } = useLinearMenu() as { items: Menu[] } || { items: [] } as { items: Menu[] };

  const menus = useMenus();
  const currentPage = useActiveEntity();

  const currentMenu = menus?.find((menuItem: Menu) => 
    menuItem.items.some((item: Menu) => item?.slug === currentPage?.slug)
  ) || null;

  const value = {
    items: currentMenu?.items || [],
    name: currentMenu?.name || undefined,
  };

  return (
    <MenuItemsProvider value={value}>
      {children}
    </MenuItemsProvider>
  );
}