
import { PlainObject } from "@shapeable/types";
import { useAppState as useAppStateCore } from "@shapeable/ui";

export type RestorationProjectState = {
  page?: number;
  filters?: PlainObject;
};

export type AppState = {
  restorationProjects?: RestorationProjectState;
};

export const defaultAppState: AppState = {
  restorationProjects: {
    page: 0,
    filters: {},
  }
};

// not sure there's better way to auto-type this, but it'll do!
export const useAppState = () => useAppStateCore<AppState>();
