import React from 'react';
import { IconComponent } from '@shapeable/types'

export const EcosystemServicesIcon: IconComponent = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 48 48">
  <path d="M31.5 21C27.4 21 24 24.4 24 28.5C24 32.6 27.4 36 31.5 36C35.6 36 39 32.6 39 28.5C39 24.4 35.6 21 31.5 21ZM29.8 32.7L26 28.9L27.2 27.7L29.7 30.2L34.7 25.2L35.9 26.4L29.8 32.7Z" fill="black"/>
  <path d="M35 13H33C33 15.2 31.2 17.1 29 17.1C26.8 17.1 25 15.3 25 13H23C23 15.2 21.2 17.1 19 17.1C16.8 17.1 15 15.3 15 13H13C13 15.2 11.2 17.1 9 17.1V19C11.1 19 12.9 17.9 14 16.3C15.1 18 16.9 19 19 19C21.1 19 22.9 17.9 24 16.2C25.1 17.9 26.9 19 29 19C31.1 19 32.9 17.9 34 16.3C35.1 18 36.9 19 39 19V17.1C36.8 17.1 35 15.2 35 13Z" fill="black"/>
  <path d="M18.0002 36H16.0002V33.2C16.0002 32 15.8002 31.7 15.7002 31.6C15.2002 30.7 15.8002 30.2 16.3002 29.7C16.6002 29.4 16.7002 29.2 16.7002 29C16.7002 28.8 16.5002 28.6 16.3002 28.3C15.6002 27.6 15.2002 26.9 15.2002 26.2C15.2002 25.5 15.6002 24.7 16.3002 24.1C16.6002 23.8 16.3002 22.9 16.1002 22.5L17.8002 21.6C18.3002 22.5 18.8002 24.4 17.6002 25.6C17.3002 25.9 17.2002 26.1 17.2002 26.3C17.2002 26.5 17.4002 26.7 17.6002 27C18.3002 27.7 18.7002 28.4 18.7002 29.1C18.7002 29.8 18.4002 30.5 17.7002 31.2C17.8002 31.6 18.0002 32.2 18.0002 33.3V36Z" fill="black"/>
  <path d="M14.0002 36H12.0002V33.2C12.0002 32 11.8002 31.7 11.7002 31.6C11.2002 30.7 11.8002 30.2 12.3002 29.7C12.6002 29.4 12.7002 29.2 12.7002 29C12.7002 28.8 12.5002 28.6 12.3002 28.3C11.6002 27.6 11.2002 26.9 11.2002 26.2C11.2002 25.5 11.6002 24.7 12.3002 24.1C12.6002 23.8 12.3002 22.9 12.1002 22.5L13.8002 21.6C14.3002 22.5 14.8002 24.4 13.6002 25.6C13.3002 25.9 13.2002 26.1 13.2002 26.3C13.2002 26.5 13.4002 26.7 13.6002 27C14.3002 27.7 14.7002 28.4 14.7002 29.1C14.7002 29.8 14.4002 30.5 13.7002 31.2C13.8002 31.6 14.0002 32.2 14.0002 33.3V36Z" fill="black"/>
  <path d="M22.0002 36H20.0002V33.2C20.0002 32 19.8002 31.7 19.7002 31.6C19.2002 30.7 19.8002 30.2 20.3002 29.7C20.6002 29.4 20.7002 29.2 20.7002 29C20.7002 28.8 20.5002 28.6 20.3002 28.3C19.6002 27.6 19.2002 26.9 19.2002 26.2C19.2002 25.5 19.6002 24.7 20.3002 24.1C20.6002 23.8 20.3002 22.9 20.1002 22.5L21.8002 21.6C22.3002 22.5 22.8002 24.4 21.6002 25.6C21.3002 25.9 21.2002 26.1 21.2002 26.3C21.2002 26.5 21.4002 26.7 21.6002 27C22.3002 27.7 22.7002 28.4 22.7002 29.1C22.7002 29.8 22.4002 30.5 21.7002 31.2C21.8002 31.6 22.0002 32.2 22.0002 33.3V36Z" fill="black"/>
  <path d="M28 1.30005V5.40005C36.2 7.20005 42.5 14.3 42.9 22.9L45 25L47 23C46.5 12.1 38.5 3.20005 28 1.30005Z" fill="black"/>
  <path d="M3 23L1 25C1.5 35.9 9.5 44.8 20 46.7V42.6C11.7 40.8 5.5 33.7 5 25L3 23Z" fill="black"/>
  <path d="M22.9998 1C12.0998 1.5 3.1998 9.5 1.2998 20H5.39981C7.19981 11.7 14.2998 5.5 22.8998 5L24.9998 3L22.9998 1Z" fill="black"/>
  <path d="M46.6 28H42.5C40.7 36.2 33.6 42.5 25 42.9L23 45L25 47C35.8 46.5 44.8 38.5 46.6 28Z" fill="black"/>
  </svg>
);