import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentTitle, useLang, useLinearNavigation } from '@shapeable/ui';
import { AsideMenu } from './aside-menu';
import { classNames } from '@shapeable/utils';
import { useMenusNavigation } from '../../hooks/use-menus-navigation';
const cls = classNames('aside-layout');

// -------- Types -------->

export type AsideLayoutProps = Classable & HasChildren & {
}

export const AsideLayoutDefaultProps: AsideLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: none;
  `,
  desktop: css`
    display: block;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    border-bottom: 1px solid ${theme.COLOR('line-mid')};
    padding: ${theme.UNIT(4)} 0;
  `,
});

const MenuStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Title: styled(ContentTitle)`${TitleStyles}`,
    Menu: styled(AsideMenu)`${MenuStyles}`,
};

export const AsideLayout: React.FC<AsideLayoutProps> = (props) => {
  const { className } = props;

  const t = useLang();
  const mainMenu = useMenusNavigation();

  return (
    <My.Container className={cls.name(className)}>
      <My.Title>{t(mainMenu?.name)}</My.Title>
      <My.Menu />
    </My.Container>
  )
};

AsideLayout.defaultProps = AsideLayoutDefaultProps;