import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Icon } from '@shapeable/ui';
import { OCEAN_BLUE } from '../../theme';

// -------- Types -------->

export type IconHeadingProps = Classable & HasChildren & {
  icon: React.ReactNode;
  iconColor?: string;
  iconSize?: number;
}

export const IconHeadingDefaultProps: Omit<IconHeadingProps, 'icon'> = {
  iconColor: 'dark',
  iconSize: 48,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    color: ${OCEAN_BLUE};
    text-transform: uppercase;
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(16)};
    display: flex;
    align-items: center;
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(18)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(20)};
  `
});

const IconStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(3)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.h2<ContainerProps>`${ContainerStyles}`,
  Icon: styled(Icon)`${IconStyles}`,
};

export const IconHeading: React.FC<IconHeadingProps> = (props) => {
  const { className, children, iconSize, iconColor } = props;
  const icon = props.icon;

  return (
    <My.Container className={className}>
      <My.Icon size={iconSize} color={iconColor}>{icon}</My.Icon>
      {children}
    </My.Container>
  )
};

IconHeading.defaultProps = IconHeadingDefaultProps;