import { graphql, useStaticQuery } from "gatsby";
import * as React from "react"; // tslint:disable-line
import { FeedEntriesProvider as Provider, platformProviderValue } from "@shapeable/ui";

const FEED_ENTRIES_QUERY = graphql`
  query PlatformFeedEntriesQuery {
    platform {
      allFeedEntries {
        name id slug path __typename _schema { label pluralLabel }
        published author
        economies { id slug name __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        genera { id name slug __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        species { 
          id name slug __typename _schema { label pluralLabel } 
          openGraph { image { url url2x thumbnails { card { url url2x } bubble { url url2x } bubbleLarge { url url2x } } } }
        }
        organisations { id name slug __typename _schema { label pluralLabel } }
        methodologies { id name slug __typename _schema { label pluralLabel } }
        types { id name slug __typename _schema { label pluralLabel } }
        openGraph { description { text(truncate: { words: 20 }) } image { url } }
      }
    }
  }
`

export const FeedEntriesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={platformProviderValue(useStaticQuery(FEED_ENTRIES_QUERY), 'allFeedEntries')}>{children}</Provider>;
}