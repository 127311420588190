import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { RestorationProject } from '@shapeable/kelp-forest-alliance-types';
import { EntityTextValueList, EntityValue, useLang } from '@shapeable/ui';
const cls = classNames('restoration-project-card-meta');

// -------- Types -------->

// -------- Props -------->

export type RestorationProjectCardMetaProps = Classable & HasChildren & { 
  entity?: RestorationProject;
};

export const RestorationProjectCardMetaDefaultProps: Omit<RestorationProjectCardMetaProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BodyStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG()};
    margin-top: ${theme.UNIT(2)};
    padding-top: ${theme.UNIT(3)};
  `,
});

const LinkedStyles = breakpoints({
  base: css`
    line-height: 1.2em;
    margin-top: ${theme.UNIT(2)};
    font-size: ${theme.FONT_SIZE(14)};
    &:first-child {
      margin-top: ${theme.UNIT(1)};
    }
  `,
});

const OrganisationStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    Linked: styled(EntityTextValueList)`${LinkedStyles}`,
    Organisation: styled(EntityValue)`${OrganisationStyles}`,
};

export const RestorationProjectCardMeta: Shapeable.FC<RestorationProjectCardMetaProps> = (props) => {
  const { className, children, entity } = props;

  const { methodologies = [], reasons = [], species = [], organisation } = entity;

  const hasMethodologies = !!methodologies.length;
  const hasReasons = !!reasons.length;
  const hasSpecies = !!species.length;

  const t = useLang();

  const hasBody = hasMethodologies || hasSpecies || hasReasons;
  
  return (
    <My.Container className={cls.name(className)}>
    {
      hasBody &&
      <My.Body>
        {
          organisation && <My.Organisation imageStyle="logo" entity={organisation} />
        }
        <My.Linked items={species} />
        <My.Linked items={methodologies} />
        <My.Linked items={reasons} />
      </My.Body>
    }
    {children}
    </My.Container>
  )
};

RestorationProjectCardMeta.defaultProps = RestorationProjectCardMetaDefaultProps;
RestorationProjectCardMeta.cls = cls;