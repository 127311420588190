import { Page } from "@shapeable/types";
import { isGuidebookPage } from "../types";


export const transformPage: (entity: Page) => Page = (entity) => {
  let transformedPage = { ...entity };

  
  if (isGuidebookPage(transformedPage)) {
    // @ts-ignore
    transformedPage.__typename = 'Guidebook';
    // transformedPage.typeLabel = 'Guidebook';
    transformedPage._schema = { label: 'Guidebook', pluralLabel: 'Guidebooks' };

  }
  // console.log('transformPage', transformedPage);
  return transformedPage;
}

export const transformPages: (entities: Page[]) => Page[] = (entities) => {
  return entities.map((entity) => transformPage(entity));
}