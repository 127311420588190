import React from 'react';
import styled, { css } from 'styled-components';
import { Block, SliceLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Grid, useBlockLayouts, useEntity } from '@shapeable/ui';

import { IconHeading } from '../elements/icon-heading';
import { filter, groupBy, get, includes, isArray } from 'lodash';
import { useIcons } from '@shapeable/icons';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    border-bottom: 1px solid ${theme.COLOR('light')};
  `,
});

const HeadingStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(4)} ${theme.UNIT(2)} ${theme.UNIT(3)};
  `,
  tablet: css`
    margin: ${theme.UNIT(4)} ${theme.UNIT(2)} ${theme.UNIT(4)};
  `
});

const GridStyles = breakpoints({
  base: css`
    margin-bottom: -${theme.UNIT(4)};

    .shp--grid__item {
      display: block;
    }
  `,
  tablet: css`
    margin-bottom: -${theme.UNIT(4)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
    Heading: styled(IconHeading)`${HeadingStyles}`,
};

export const SliceMetricsBlocks: SliceLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { icon, title, blocks = [] } = entity;
  const icons = useIcons();
  const Icon = icon && icons[icon.component];

  const blockLayouts = useBlockLayouts();
  
  const GROUPABLE_LAYOUTS = ["BlockLayoutTotalNutrientsRemoved", "BlockLayoutTotalVisitorDays"];
  
  const groupedBlocks = blocks.reduce((acc, block) => {
    if (includes(GROUPABLE_LAYOUTS, get(block, 'layout.component'))) {
      acc.grouped.push(block);
    } else {
      acc.single.push(block);
    }

    return acc;
  }, { single: [], grouped: [] });
  
  const blocksToUse: (Block | Block[])[] = [...groupedBlocks.single, groupedBlocks.grouped];

  const renderBlock = React.useCallback((block: Block) => {
    const { layout } = block;
    
    if (layout) {
      const BlockLayout = blockLayouts[layout.component];

      if (BlockLayout) {
        return <BlockLayout key={block.id} entity={block} />;
      }
    }

    return null;
  }, []);

  return (
    <My.Container className={className}>
      <My.Heading icon={Icon && <Icon />}>{title}</My.Heading>
      <My.Grid 
        desktopAutoColumns={3} 
        desktopFullHdAutoColumns={3} 
        desktopLargeAutoColumns={3}
        spacing={2} 
        items={filter(blocksToUse.map(item => {
        if (isArray(item)) {
          return <>{item.map(renderBlock)}</>
        }

        return renderBlock(item);
      }))} />
      {children}
    </My.Container>
  )
};