import { EntityTypeName, EntityWithMap } from '@shapeable/types';
import { useMapState } from '@shapeable/maps';
import { useState, useCallback } from 'react';

export type ProtectionAreaDataTypes = {
  DESIG_ENG?: string;
  GIS_AREA?: number;
  GIS_M_AREA?: number;
  ISO3?: string;
  MARINE?: string;
  METADATAID?: number;
  IUCN_CAT?: string;
  NAME?: string;
  NO_TK_AREA?: number;
  PA_DEF?: string;
  REP_AREA?: number;
  REP_M_AREA?: number;
  STATUS_YR?: number;
  WDPAID?: number;
  WDPA_PID?: string;
};

export type ProtectionTooltipData = {
    mapOptions?: ProtectionAreaDataTypes;
    id?: string;
    point?: { x: number; y: number };
    type?: EntityTypeName;
    longitude?: number;
    latitude?: number;
};

export const useProtectionAreaTooltip = () => {

  const [ mapState, setMapState, patchMapState ] = useMapState();
  const [protectionAreaData, setProtectionAreaData] = useState<ProtectionTooltipData | null>(null);
  const [mapInstance, setMapInstance] = useState(null);

  const isProtectionLegendVisible = mapState && mapState?.entityTypeNames?.includes('ProtectionArea') ? true : false;

  const showProtectionTooltip = useCallback((data: ProtectionTooltipData) => {
    setProtectionAreaData(data);
  }, []);

  const hideProtectionTooltip = useCallback((event) => {
    setProtectionAreaData(null);
  }, []);

  //set up function to check

  return { 
    protectionAreaData, 
    mapInstance,
    isProtectionLegendVisible,
    setMapInstance,
    showProtectionTooltip, 
    hideProtectionTooltip, 
    setProtectionAreaData,
  };
};
