import React from 'react';
import { useInViewEffect, useLang } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import styled, { css } from 'styled-components'; const cls = classNames('metric-value');
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PRIMARY_BLUE } from '../../theme';
import Countup from 'react-countup';

// -------- Types -------->

export type MetricValueProps = Classable & {
  suffix?: React.ReactNode;
  value?: number;
  comingSoon?: boolean;
  showCountUp?: boolean;
  formatValue?: (value: number) => string;
}

export const MetricValueDefaultProps: MetricValueProps = {
  formatValue: null,
  showCountUp: true,
  comingSoon: false,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ValueProps = {
  comingSoon: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('dark')};
    font-weight: 300;
    font-size: ${theme.FONT_SIZE(28)};
    line-height: 1.15em;
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(34)};
  `
});

const ValueStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-size: 1em;
    margin-right: ${theme.UNIT(2)};
    color: ${theme.COLOR(PRIMARY_BLUE)};

    ${({ comingSoon }: ValueProps ) => comingSoon && css`
      font-size: 0.75em;
    `}
  `,
});

const SuffixStyles = breakpoints({
  base: css`
    font-size: 0.8em;
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Value: styled.b.attrs(cls.attr('value'))<ValueProps>`${ValueStyles}`,
  Suffix: styled.span`${SuffixStyles}`,
};

export const MetricValue: React.FC<MetricValueProps> = (props) => {
  const { className, value, suffix, showCountUp, comingSoon, formatValue } = props;
  
  const [currentValue, setCurrentValue] = React.useState<number>(0);

  const ref = useInViewEffect(() => { setCurrentValue(value) });
  const t = useLang();

  return (
    <My.Container ref={ref} className={cls.name(className)}>
    <My.Value comingSoon={comingSoon}>
    {
      (
        comingSoon ? 
        t('Coming Soon') :
        (
          showCountUp ? 
          <Countup separator="," formattingFn={formatValue} duration={0.5} start={0} end={currentValue} /> :
          formatValue(value)
        )
      )
    }
    </My.Value>
    {
      !comingSoon &&
      <My.Suffix>
      {suffix}
      </My.Suffix>
    }
    </My.Container>
  )
};

MetricValue.defaultProps = MetricValueDefaultProps;