import { Entity, FeedEntry, FeedEntryType } from "@shapeable/types";

// export const filterArticleByType = (entities: FeedEntry[], newType: string) => {
//   return entities.map((entity) => {
//     const updatedTypes = entity.types.map((type) => {
//       return {
//         ...type,
//         _schema: {
//           ...type._schema,
//           label: newType,
//           pluralLabel: newType + 's',
//         }
//       };
//     });

//     return {
//       ...entity,
//       types: updatedTypes
//     };
//   });
// };

export const filterNonNewsArticles = (entities: FeedEntry[]) => {
  const filteredEntities = entities.filter(entity => 
    !entity?.types?.some(type => type.name === 'News')
  );
  return filteredEntities.map(entity => ({
    ...entity,
    types: entity.types.map(type => ({
      ...type,
      _schema: {
        ...type._schema,
        label: 'Resource Type',
        pluralLabel: 'Resource Types',
      }
    }))
  }));
};

// filterArticleByType filters articles that are of type news and updates the Article type to Platform_News to create a 'psuedo entity'
export const filterNewsArticles = (articles: FeedEntry[]) => {
  return articles.filter((article: FeedEntry) => 
    article?.types?.some((type: FeedEntryType) => type.name === 'News')
    ).map((article: any) => {
      return {
      ...article,
      types: [],
      __typename: 'Platform_News',
      // path: article.url,
      _schema: {
        ...article?._schema,
        pluralLabel: 'News',
        label: 'News',
      }
  }})
};