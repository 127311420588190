import React from 'react';
import { IconComponent } from '@shapeable/types'

export const StatusIaIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_2705_16665)">
    <path d="M16 2.78261C23.3043 2.78261 29.2174 8.69565 29.2174 16C29.2174 23.3043 23.3043 29.2174 16 29.2174C8.69565 29.2174 2.78261 23.3043 2.78261 16C2.78261 8.69565 8.69565 2.78261 16 2.78261ZM16 0C7.16522 0 0 7.16522 0 16C0 24.8348 7.16522 32 16 32C24.8348 32 32 24.8348 32 16C32 7.16522 24.8348 0 16 0Z" fill="black"/>
    <path d="M9.7002 20V18.306H10.9602V11.922H9.7002V10.228H14.3482V11.922H13.0882V18.306H14.3482V20H9.7002Z" fill="black"/>
    <path d="M21.4412 20C21.0678 20 20.7552 19.8834 20.5032 19.65C20.2605 19.4167 20.1065 19.104 20.0412 18.712H19.9572C19.8452 19.188 19.5978 19.552 19.2152 19.804C18.8325 20.0467 18.3612 20.168 17.8012 20.168C17.0638 20.168 16.4992 19.972 16.1072 19.58C15.7152 19.188 15.5192 18.67 15.5192 18.026C15.5192 17.2514 15.7992 16.6774 16.3592 16.304C16.9285 15.9214 17.6985 15.73 18.6692 15.73H19.8312V15.268C19.8312 14.9134 19.7378 14.6334 19.5512 14.428C19.3645 14.2134 19.0518 14.106 18.6132 14.106C18.2025 14.106 17.8758 14.1947 17.6332 14.372C17.3905 14.5494 17.1898 14.75 17.0312 14.974L15.7992 13.882C16.0978 13.4434 16.4712 13.1027 16.9192 12.86C17.3765 12.608 17.9925 12.482 18.7672 12.482C19.8125 12.482 20.5965 12.7107 21.1192 13.168C21.6418 13.6254 21.9032 14.2927 21.9032 15.17V18.39H22.5892V20H21.4412ZM18.5712 18.726C18.9165 18.726 19.2105 18.6514 19.4532 18.502C19.7052 18.3527 19.8312 18.11 19.8312 17.774V16.906H18.8232C18.0112 16.906 17.6052 17.1814 17.6052 17.732V17.942C17.6052 18.2127 17.6892 18.4134 17.8572 18.544C18.0252 18.6654 18.2632 18.726 18.5712 18.726Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_2705_16665">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);