import React from 'react';
import styled, { css } from 'styled-components'; 
import { Classable, HasChildren, EntityPercentage } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { get } from 'lodash';
import { pieDataForEntityPercentages } from '../../utils/pie-data-for-entity-percentages';
import { classNames } from '@shapeable/utils';
const cls = classNames('entity-percentage-legend');

// -------- Types -------->

export type EntityPercentageLegendProps = Classable & HasChildren & { 
  items?: EntityPercentage[];
  baseColor?: string;
};

export const EntityPercentageLegendDefaultProps: EntityPercentageLegendProps = {
  baseColor: '#000000',
  items: [],
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type DotProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(12)};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
  `,
});

const ItemStyles = breakpoints({
  base: css`
    font-size: 1em;
    padding-right: ${theme.UNIT(2)};
    display: flex;
    align-items: center;
    flex-basis: 45%;
  `,
});

const DotStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)};
    border-radius: 20px;
    text-align: center;
    min-width: 32px;
    box-sizing: border-box;
    margin-right: ${theme.UNIT(1.5)};

    ${({ _color }: DotProps ) => css`
      background: ${_color};
    `}
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Item: styled.span`${ItemStyles}`,
    Dot: styled.span<DotProps>`${DotStyles}`,
};

export const EntityPercentageLegend: React.FC<EntityPercentageLegendProps> = (props) => {
  const { className, children, baseColor, items } = props;
  const dataItems = pieDataForEntityPercentages(items, baseColor);

  return (
    <My.Container className={cls.name(className)}>
      {
        dataItems.map(item => (
          <My.Item key={get(item, 'entity.id')}>
            <My.Dot _color={item.color}>{item.value.toFixed(1)}</My.Dot>
            {item.label}
          </My.Item>
        ))
      }
    </My.Container>
  )
};

EntityPercentageLegend.defaultProps = EntityPercentageLegendDefaultProps;