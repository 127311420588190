import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { HTML, useEntity, useLang } from '@shapeable/ui';
import { BlockMetricLayout } from '../entities/block-metric-layout';
import { MetricValue } from '../elements/metric-value';
import { useSite } from '../../hooks/use-site';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ValueStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
    align-self: center;
  `,
});


// -------- Components -------->

const My = {
  Container: styled(BlockMetricLayout)<ContainerProps>`${ContainerStyles}`,
    Value: styled(MetricValue)`${ValueStyles}`,
};

export const BlockLayoutTotalNutrientsRemoved: BlockLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const t = useLang();
  
  const { metrics } = useSite();
  const { totalNutrientsRemoved } = metrics;

  return (
    <My.Container ratio={2} showContent entity={entity} className={className}>
      <My.Value suffix={<HTML text={t('(tons N)')} />} value={totalNutrientsRemoved} />
    </My.Container>
  )
};