export const PRIMARY_BLUE = '#03577D';
export const SECONDARY_BLUE = '#2496BD';
export const ORANGE = '#F7921D';


export const BRAND_1 = '#05567D';

export const MEDIUM_BLUE = '#2496BD';
export const DARK_BLUE = '#02394B';

export const OCEAN_BLUE = '#1999B5';
export const OCEAN_TIEL = '#d4f2f9';

export const AQUA_BLUE = '#009DA5';

export const OCEAN_BLUE_OVERLAY = `${OCEAN_BLUE}EE`;
export const DARK_BLUE_OVERLAY = `${DARK_BLUE}EE`;
export const DARK_BLUE_MENU_BAR_OVERLAY = `${DARK_BLUE}FA`;
 

export const BLOCK_METRIC_HEADING = '#969696';

export const VALUE_BUBBLE = '#89CDD6';
import { breakpoints, theme, ThemeOverrides } from "@shapeable/theme";
import { Color } from "@shapeable/types";
import { SliceIntro } from "@shapeable/ui";
import { css } from "styled-components";

export const CONTENT_BACKGROUND = '#ECEDE8';
export const CONTENT_BACKGROUND_LIGHT = '#f4f4f1';
export const CONTENT_BACKGROUND_VERY_LIGHT = '#F7F7F5';

export const COLOR_LIGHT_BROWN: Color = {
  id: 'light-brown',
  slug: 'light-brown',
  value: CONTENT_BACKGROUND,
};

export const themeOverrides: ThemeOverrides = {
  font: {
    family: {
      serif: "'Cormorant Garamond'",
      sans: "'IBM Plex Sans'",
      heading: "'IBM Plex Sans'",
      copy: "'IBM Plex Sans'",
      condensed: "'IBM Plex Sans Condensed'",

    }
  },
  light: {
    color: {
      primary: OCEAN_BLUE,
      secondary: SECONDARY_BLUE,
      // navigationActive: ORANGE,
    
      heading: OCEAN_BLUE,
      button: OCEAN_BLUE,

      link: PRIMARY_BLUE,
      linkHover: ORANGE, 

      buttonHover: ORANGE,
      bullet: OCEAN_BLUE,
      h2: OCEAN_BLUE,

      // invertedLinkHover: ORANGE,
      // invertedLinkActive: '#FFF',
    
      // invertedMenuHover: '#FFF',
      // invertedMenuActive: ORANGE,
      // invertedMenuHoverBackground: ORANGE,
    },
  },
  dark: {
    color: {
      bright: '#001218',
      brightWarm: '#011e28',
      secondary: SECONDARY_BLUE,
      heading: AQUA_BLUE,
    }
  }
};

export const BubbleStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(6)};
    margin-bottom: ${theme.UNIT(6)};
    align-self: center;
  `,
  tablet: css`
    margin-top: ${theme.UNIT(4)};
  `,
});

/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`

    
    
    .shp--organisation-powered-by {

      opacity: 0.6;

      img {
        mix-blend-mode: overlay;
        filter: grayscale(1);
      }

      &:hover {
        filter: none;
      }
    }

    .shp--block-thumbnail {
      width: 100%;
    }

    .shp--entity-grid__filter {
      width: 100%;
    }
    
    .shp--page-layout-standard__title {
      margin-bottom: ${theme.UNIT(28)};
      letter-spacing: -0.02em;
      margin-top: ${theme.UNIT(8)};
      font-family: ${theme.FONT('sans')};
      font-size: 1.75em;
      font-weight: 500;

      strong {
        color: ${ORANGE};
        text-transform: uppercase;
        font-weight: 400;
      }
    }

    .shp--slice-layout-featured-posts {
      .shp--grid__item {
        a {
          flex-grow: 1;
        }
      }
    }

    .shp--tabs__body {
      font-size: 1.2em;
    }

    .shp--slice-blurb-video {
      h1 {
        color: ${theme.COLOR('heading')};
        font-size: 1.75em;
      }
      span {
        font-weight: 300;
      }
    }

    .shp--content-title {
      font-size: 1.25em;
    }

    sup {
      vertical-align: top;
      font-size: 0.7em;
      position: relative;
      top: -0.1em;
    }

    ${SliceIntro.cls.dot()} {
      font-size: 1.25em;
      line-height: 1.35em; 
      text-align: center;
      color: ${PRIMARY_BLUE};
      font-family: ${theme.FONT('serif')};
      
      p, span {
        margin-bottom: 1em;
      }
    }

    .shp--react-tooltip {
      line-height: 1.6em !important;
      padding: 16px !important;
      font-size: 16px !important;
      box-shadow: 0 0px 2px rgba(0,0,0,0.2) !important;
    }

    :focus-visible {
      outline: 1px solid #1999B5;
      border-radius: 2px;
    }

    .shp--image-entity-info-tooltip__tooltip {
      padding: 12px !important;
    }

    .shp--image-entity-info-tooltip__tooltip p {
      margin: 0;
      margin-bottom: 8px;
    }

    .shp--image-entity-info-tooltip__tooltip p:last-of-type {
      margin-bottom: 0;
    }

    .shp--block-content .shp--content-node { 
    }

    .shp--slice-featured-organisation__body {
     padding: 0;
     .shp--slice-header {
        margin-bottom: 0;
     }
    }
    
    .shp--slice-featured-organisation h2 {
      margin-bottom: 0;
    }

    .shp--person-bio-cell__position {
      font-weight: 500;
    }
    

  `,
  tablet: css`
    ${SliceIntro.cls.dot()} {
      font-size: 1.75em;
    }

    .shp--content-title {
      font-size: 1.5em;
    }

    .shp--page-layout-standard__title {
      margin-top: ${theme.UNIT(4)};
      font-size: 2em;
    }
  `,
  desktop: css`
    ${SliceIntro.cls.dot()} {
      font-size: 2em;
    }

    .shp--page-layout-standard__title {
      margin-top: ${theme.UNIT(12)};
      font-size: 3em;
    }
  `
});


