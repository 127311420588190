import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Classable, EntityTypeName, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { RestorationProject } from '@shapeable/kelp-forest-alliance-types';
import { EntityGrid, EntityGridFilterValuesChangeHandler, EntityGridProps, EntityTypeTogglePill } from '@shapeable/ui';
import { useRestorationProjects } from '../../hooks/use-restoration-projects';
import { useAppState } from '../../hooks/use-app-state';
import { RestorationProjectCard } from './restoration-project-card';
import { ProtectionAreasGrid } from './protection-areas-grid';
import { without, includes } from 'lodash';

// -------- Types -------->

export type RestorationProjectGridProps = Classable & HasChildren & Omit<EntityGridProps, 'items'> & {
  items?: RestorationProject[];
}

export const RestorationProjectGridDefaultProps: RestorationProjectGridProps = {
  items: []
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

    // Applied temporary override to selects placeholder text colour
    .shp--entity-select__placeholder {
      color: ${theme.COLOR('primary')};
    }
  `,
});

const TogglesStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(2)};
    padding: 0 ${theme.UNIT(3)} ${theme.UNIT(3)} ${theme.UNIT(3)};
  `,
  desktop: css`
    
  
  `,
});

const ToggleStyles = breakpoints({
  base: css`
    
  `,
});

const ProtectionAreaGridStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Toggles: styled.div`${TogglesStyles}`,
  Toggle: styled(EntityTypeTogglePill)`${ToggleStyles}`,
  Grid: styled(EntityGrid)<ContainerProps>`${ContainerStyles}`,
  ProtectionAreaGrid: styled(ProtectionAreasGrid)`${ProtectionAreaGridStyles}`,
};

export const RestorationProjectGrid: React.FC<RestorationProjectGridProps> = (props) => {
  const { className } = props;
  
  const [ selectedTypes, setSelectedTypes ] = useState<string[]>(['RestorationProjects']);

  // const { protectionAreaData, loading } = useProtectionAreaData();
  const restorationProjects = useRestorationProjects();
  const [ appState, setAppState, patchAppState ] = useAppState();
  const { restorationProjects: state } = appState;
  const filters = (state && state.filters) || {};

  const onFilterValuesChange: EntityGridFilterValuesChangeHandler = (values) => {
    const newState = { 
      restorationProjects: { 
        ...(appState.restorationProjects || {}),
        filters: values,
      }
    };
    patchAppState(newState);
  };

  const toggleTypes = [
    {
      name: 'RestorationProjects',
      label: 'Restoration Projects',
    },
    {
      name: 'ProtectionAreas',
      label: 'Protection Areas',
    }
    ];

  const hasToggles = true;

  return ( <>
    {
      hasToggles && <>
        <My.Toggles>
          {
            toggleTypes.map((type) => {
              
              const isOn = includes(selectedTypes, type.name);
              return (
                <My.Toggle
                  key={type.name}
                  label={type.label}
                  entityTypeName={type.name as EntityTypeName}
                  isOn={isOn}
                  onClick={() => {
                    if (isOn) return;
                    const newSelectedTypes = isOn ? without(type.name) : [type.name];
                    setSelectedTypes(newSelectedTypes);
                  }}
                />
              )
            })
          }
        </My.Toggles>
        </>
    } 
    {
      includes(selectedTypes, 'RestorationProjects') ? <>
      <My.Grid
        className={className}
         card={RestorationProjectCard}
         filterValues={filters}
         filterSize="slim"
         filterSpacing={3}
         anyCardProps={{ variant: 'chromeless' }}
         filterFieldNames={['species', 'organisation', 'methodologies', 'reasons']}
         items={restorationProjects}
         onFilterValuesChange={onFilterValuesChange}
         desktopFullHdAutoColumns={3}
         desktopAutoColumns={3}
         desktopLargeAutoColumns={3}
       /> 
       </> : <>
       {
          <My.ProtectionAreaGrid />
       }
      </>
    }
    </>
  )
};

RestorationProjectGrid.defaultProps = RestorationProjectGridDefaultProps;