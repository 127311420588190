import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable,  } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { RestorationProject } from '@shapeable/kelp-forest-alliance-types';
import { EntityCard, EntityCardDefaultProps, EntityCardProps } from '@shapeable/ui';
import { RestorationProjectCardMeta } from './restoration-project-card-meta';
const cls = classNames('restoration-project-card');

// -------- Types -------->

// -------- Props -------->

export type RestorationProjectCardProps = EntityCardProps & { 
  entity?: RestorationProject;
};

export const RestorationProjectCardDefaultProps: Omit<RestorationProjectCardProps, 'entity'> = {
  ...EntityCardDefaultProps,
  showDescription: false,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
};

export const RestorationProjectCard: Shapeable.FC<RestorationProjectCardProps> = (props) => {
  const { className, children, entity, ...cardProps } = props;
  
  return (
    <My.Container 
      {...cardProps}
      className={cls.name(className)}
      entity={entity}

      meta={
        <RestorationProjectCardMeta entity={entity} />
      }
      >
    
    </My.Container>
  )
};

RestorationProjectCard.defaultProps = RestorationProjectCardDefaultProps;
RestorationProjectCard.cls = cls;