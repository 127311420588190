import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityIntro, useEntity, useLang } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { first } from 'lodash';
import { PledgeButton } from '../elements/pledge-button';
const cls = classNames('slice-intro-pledge');

// -------- Types -------->

export type SliceIntroPledgeProps = Classable & HasChildren & { 
  entity?: Slice;
};

export const SliceIntroPledgeDefaultProps: Omit<SliceIntroPledgeProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});


const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  `,
  tablet: css`
    flex-direction: row;
  `
});

const IntroStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(14)};
  `,
  tablet: css`
    max-width: 600px;
    font-size: ${theme.FONT_SIZE(16)};
  `
});

const ButtonStyles = breakpoints({
  base: css`
    flex-shrink: 0;
    .shp--button {
      font-size: ${theme.FONT_SIZE(24)};
      white-space: nowrap;
    }
    width: 300px;
    margin-top ${theme.UNIT(6)};
  `,
  tablet: css`
    margin-top: 0;
    margin-left: ${theme.UNIT(6)};
  `
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    Intro: styled(ContentEntityIntro)`${IntroStyles}`,
    Button: styled(PledgeButton)`${ButtonStyles}`,

};

export const SliceIntroPledge: Shapeable.FC<SliceIntroPledgeProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const t = useLang();

  const link = first(entity.links);
  const linkHref = link && (link.url || (link.page && link.page.path));

  return (
    <My.Container className={className}>
      <My.Body>
        <My.Intro entity={entity} />
        <My.Button href={linkHref}>{t('Make Pledge')}</My.Button>
      </My.Body>
    </My.Container>
  );
};

SliceIntroPledge.defaultProps = SliceIntroPledgeDefaultProps;
SliceIntroPledge.cls = cls;