import React from 'react';
import { IconComponent } from '@shapeable/types'

export const StatusViIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_2705_16700)">
    <path d="M16 2.78261C23.3043 2.78261 29.2174 8.69565 29.2174 16C29.2174 23.3043 23.3043 29.2174 16 29.2174C8.69565 29.2174 2.78261 23.3043 2.78261 16C2.78261 8.69565 8.69565 2.78261 16 2.78261ZM16 0C7.16522 0 0 7.16522 0 16C0 24.8348 7.16522 32 16 32C24.8348 32 32 24.8348 32 16C32 7.16522 24.8348 0 16 0Z" fill="black"/>
    <path d="M11.15 20.772L8 11H10.156L11.612 15.69L12.382 18.756H12.424L13.166 15.69L14.622 11H16.708L13.53 20.772H11.15Z" fill="black"/>
    <path d="M17.6095 20.772V19.078H18.8695V12.694H17.6095V11H22.2575V12.694H20.9975V19.078H22.2575V20.772H17.6095Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_2705_16700">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);