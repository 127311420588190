import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, useLang } from '@shapeable/ui'; 
import { CONTENT_BACKGROUND } from '../../theme';
import { SlicePledgeChart, SlicePledgeChartProps } from '../entities/slice-pledge-chart';
import { useSite } from '../../hooks/use-site';
import { Classable, HasChildren, Shapeable, Slice } from '@shapeable/types';
import { classNames } from '@shapeable/utils';

const cls = classNames('slice-pledge-chart');

// -------- Props -------->

export type SliceProtectionChartProps = HasChildren & Classable & { 
  entity?: Slice;
};

export const SliceProtectionChartDefaultProps: Omit<SliceProtectionChartProps, 'entity' | 'currentValue' | 'firstTarget' | 'finalTarget'> = {

};


// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background: ${CONTENT_BACKGROUND};
    min-height: 70px;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled(SlicePledgeChart)`${BodyStyles}`,
};

export const SliceProtectionChart: Shapeable.FC<SliceProtectionChartProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { metrics } = useSite();
  const { totalProtectionPledged, totalProtectionPledgeTarget2030, totalProtectionPledgeTarget2040 } = metrics;

  const t = useLang();

  return (
    <My.Container className={className}>
      <My.Body 
        firstTargetLabel={t('<b>2030</b> <i>Target</i>')}
        finalTargetLabel={t('<b>2040</b> <i>Protection Target</i> (Hectares)')}
        currentValue={totalProtectionPledged}
        firstTarget={totalProtectionPledgeTarget2030}
        finalTarget={totalProtectionPledgeTarget2040}
        entity={entity} />
    </My.Container>
  )
};

SliceProtectionChart.defaultProps = SliceProtectionChartDefaultProps;
SliceProtectionChart.cls = cls;
