import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellHeader, CellLabel, useLang } from '@shapeable/ui';
import { LegendKey, LegendKeyProps } from './legend-key';
import { classNames } from '@shapeable/utils';
const cls = classNames('map-legend');

// -------- Types -------->

export type MapLegendProps = Classable & HasChildren & LegendKeyProps & {
  title?: string;
  isVisible?: boolean;
}

export const MapLegendDefaultProps: MapLegendProps = {
  isVisible: true,
};

// -------- Child Component Props -------->

type ContainerProps = {
  isVisible?: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: inline-flex;
    align-items: center;
    padding: ${theme.UNIT(1)} ${theme.UNIT(2)};
    background: ${theme.COLOR('light')};
    border-radius: 10px;
    opacity: 0.8;
    ${theme.TRANSITION('opacity')};

    ${({ isVisible }: ContainerProps) => !isVisible && css`
      opacity: 0.3;
    `}
  `,
  tablet: css`
    padding: ${theme.UNIT(2)} ${theme.UNIT(3)};
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    justify-content: centre;
    gap: ${theme.UNIT(2)};
  `,
});



const LegendKeyStyles = breakpoints({
  base: css`
    
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-size: 8px;
    font-weight: 500;
    color: ${theme.COLOR('dark')};
  `,
  tablet: css`
    font-size: 12px;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      LegendKey: styled(LegendKey)`${LegendKeyStyles}`,
      Title: styled(CellLabel)`${TitleStyles}`
};

export const MapLegend: Shapeable.FC<MapLegendProps> = (props) => {
  const { className, children, title, isVisible, ...rest } = props;

  const t = useLang();

  return (
    <My.Container className={cls.name(className)} isVisible={isVisible}>
      <My.Body>
        <My.LegendKey {...rest} />
        <My.Title>{t(title)}</My.Title>
      </My.Body>
    </My.Container>
  )
};

MapLegend.cls = cls;
MapLegend.defaultProps = MapLegendDefaultProps;