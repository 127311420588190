import { ContentEntityContent, ContentEntityIntro, Image, ImageAsset, useEntity, useImage } from '@shapeable/ui';
import React from 'react';
import { classNames } from '@shapeable/utils';
import styled, { css } from 'styled-components'; const cls = classNames('block-image-value-intro');
import { Classable, HasChildren, Block } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { MetricValue } from '../elements/metric-value';
import { first } from 'lodash';

// -------- Types -------->

export type BlockImageValueIntroProps = Classable & HasChildren & { 
  entity?: Block;
  value?: number;

};

export const BlockImageValueIntroDefaultProps: Omit<BlockImageValueIntroProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
});


const BodyStyles = breakpoints({
  base: css`
    
  `,
});

const ValueStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    font-size: 1em;
    flex-shrink: 0;
  `,
});

const ImageStyles = breakpoints({
  base: css`
    width: 40% !important;
    margin-right: ${theme.UNIT(4)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  Layout: styled.div`${LayoutStyles}`,
    Body: styled.div`${BodyStyles}`,
    Value: styled(MetricValue)`${ValueStyles}`,
    Content: styled(ContentEntityContent)`${ContentStyles}`,
  Image: styled(ImageAsset)`${ImageStyles}`,
};

export const BlockImageValueIntro: React.FC<BlockImageValueIntroProps> = (props) => {
  const { className, children, value } = props;
  const entity = useEntity(props.entity);
  const { image, links } = entity;
  const link = first(links);

  return (
    <My.Container className={cls.name(className)}>
      <My.Layout>
        <My.Image entity={image} />
        <My.Body>
          <My.Value value={value} />
          <My.Content entity={entity} />
        </My.Body>
      </My.Layout>
    </My.Container>
  )
};

BlockImageValueIntro.defaultProps = BlockImageValueIntroDefaultProps;