import React from 'react';
import { IconComponent } from '@shapeable/types'

export const NewsIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
    <g clip-path="url(#clip0_6109_25000)">
      <path d="M70.9565 25.0435H41.7391V29.2174H70.9565V25.0435Z" fill="black"/>
      <path d="M70.9565 33.3914H41.7391V37.5653H70.9565V33.3914Z" fill="black"/>
      <path d="M37.5652 37.5652V25.0435H25.0435V37.5652H37.5652ZM29.2174 29.2174H33.3913V33.3913H29.2174V29.2174Z" fill="black"/>
      <path d="M70.9565 41.7391H41.7391V45.9131H70.9565V41.7391Z" fill="black"/>
      <path d="M70.9565 50.0869H41.7391V54.2608H70.9565V50.0869Z" fill="black"/>
      <path d="M37.5652 54.2609V41.7391H25.0435V54.2609H37.5652ZM29.2174 45.913H33.3913V50.087H29.2174V45.913Z" fill="black"/>
      <path d="M70.9565 58.4348H41.7391V62.6087H70.9565V58.4348Z" fill="black"/>
      <path d="M70.9565 66.7826H41.7391V70.9565H70.9565V66.7826Z" fill="black"/>
      <path d="M37.5652 70.9566V58.4348H25.0435V70.9566H37.5652ZM29.2174 62.6087H33.3913V66.7826H29.2174V62.6087Z" fill="black"/>
      <path d="M48 8.34783C69.913 8.34783 87.6522 26.087 87.6522 48C87.6522 69.913 69.913 87.6522 48 87.6522C26.087 87.6522 8.34783 69.913 8.34783 48C8.34783 26.087 26.087 8.34783 48 8.34783ZM48 0C21.4957 0 0 21.4957 0 48C0 74.5043 21.4957 96 48 96C74.5043 96 96 74.5043 96 48C96 21.4957 74.5043 0 48 0Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_6109_25000">
        <rect width="96" height="96" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);