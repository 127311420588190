import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { createIntroSlice } from '../../data';
import { SliceLayoutIntro } from '../slice-layouts';
import { COLOR_WHITE, PageLayoutStandard, useEntityOptions } from '@shapeable/ui';

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
  tablet: css`
  `,
  desktop: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled(PageLayoutStandard)`${ContainerStyles}`,
};

export const PageLayoutImpactMetrics: PageLayoutComponent = (props) => {
  const { className, entity } = props;
  const { content, intro } = entity;
  const hasIntro = intro && intro.text;

  const introSlice = createIntroSlice(entity);
  
  const { lightIntro } = useEntityOptions(entity);

  if (lightIntro) {
    introSlice.backgroundColor = COLOR_WHITE;
  }
  
  const slices = <>
    {
      hasIntro && <SliceLayoutIntro entity={introSlice} />
    }
    {props.slices}
  </>

  return (
    <My.Container 
      headerProps={{ aspectRatio: { base: 5 }, showSlideControls: true, showOverlayGradient: true, variant: 'overlay' }}
      slices={slices}
      className={className}
      entity={entity} 
    /> 
  )
};
