import React from 'react';
import { classNames } from '@shapeable/utils';
import styled, { css } from 'styled-components'; const cls = classNames('value-bubble');
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { VALUE_BUBBLE } from '../../theme';
import { MetricValue } from './metric-value';

// -------- Types -------->

export type ValueBubbleProps = Classable & HasChildren &  {
  value: number;
  comingSoon?: boolean;
  name?: string;
  unit?: string;
}

export const ValueBubbleDefaultProps: Omit<ValueBubbleProps, 'value'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

type ValueProps = {
  comingSoon: boolean;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background: ${VALUE_BUBBLE};
    display: flex;
    flex-direction: column;
    color: ${theme.COLOR('light')};
    border: 6px solid #FFF;
    border-radius: 400px;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    justify-content: center;
    font-size: ${theme.FONT_SIZE(14)};
    font-weight: 400;
    min-width: 180px;
    min-height: 180px;
  `,
  tablet: css`
    min-width: 200px;
    min-height: 200px;
  `,
  desktop: css`
    min-width: 180px;
    min-height: 180px;
  `,
  desktopLarge: css`
    min-width: 220px;
    min-height: 220px;
  `
});

const ValueStyles = breakpoints({
  base: css`
    font-size: 2.25em;

    ${({ comingSoon }: ValueProps ) => comingSoon && css`
      font-size: 1.75em;
    `}
  `,
});

const NameStyles = breakpoints({
  base: css`
    font-weight: 600;
  `,
});

const UnitStyles = breakpoints({
  base: css`
    font-weight: 600;
  `,
});


const DividerStyles = breakpoints({
  base: css`
    width: ${theme.UNIT(8)};
    border: none;
    height: 2px;
    ${theme.H_DOT_BG('light', 2, 4)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Value: styled(MetricValue)<ValueProps>`${ValueStyles}`,
    Divider: styled.hr`${DividerStyles}`,
    Name: styled.span`${NameStyles}`,
    Unit: styled.span`${UnitStyles}`,
};

export const ValueBubble: React.FC<ValueBubbleProps> = (props) => {
  const { className, value, name, unit, comingSoon } = props;
  return (
    <My.Container className={cls.name(className)}>
      <My.Value comingSoon={comingSoon} value={value} />
      <My.Divider />
      <My.Name>{name}</My.Name>
      <My.Unit>{unit}</My.Unit>
    </My.Container>
  )
};

ValueBubble.defaultProps = ValueBubbleDefaultProps;