import { EntityPercentage } from "@shapeable/types";
import { EntityPercentagePieChartDataItem } from "../components/entities/entity-percentage-pie-chart";
import { get, sortBy, keyBy } from 'lodash';
import { tint } from 'polished';

export const pieDataForEntityPercentages: (items: EntityPercentage[], baseColor: string) => EntityPercentagePieChartDataItem[] = 
  (items, baseColor) => {
  const ranked = sortBy(items, 'percentage');
  const rankedById = keyBy(ranked.map((result, index) => ({
    ...result,
    index,
  })), 'entity.id');


  return items.map(item => {
    const { index } = rankedById[item.entity.id];
    const percentage = 1 - ((index + 1) / (items.length + 1));
    
    // note the percentage used in color cannot be the ACTUAL percentage, it's a percentage TINT

    return {
      value: item.percentage,
      label: get(item, 'entity.name'),
      color: tint(percentage, baseColor),
    };
  });
}
