import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Image } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { LinkButton, useImage, useLang, useLink } from '@shapeable/ui';

import PLEDGE_ICON from '../../images/pledge-icon.png';
import { ORANGE, SECONDARY_BLUE } from '../../theme';
import { DottedChevronRightIcon, DottedChevronRightIconGlyph } from '@shapeable/icons';

// -------- Types -------->

export type PledgeButtonProps = Classable & HasChildren & {
  href: string;
  
}

export const PledgeButtonDefaultProps: Omit<PledgeButtonProps, 'href'> = {

};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--button {
      box-sizing: border-box;
      height: 58px;
    }

    a {
      border-color: ${ORANGE};
    }
  `,
});

const ImageStyles = breakpoints({
  base: css`

    flex-shrink: 0;
    img {
      display: block;
      height: 36px;
      width: 36px;
    }
  `,
});





// -------- Components -------->

const My = {
  Container: styled(LinkButton)<ContainerProps>`${ContainerStyles}`,
  Image: styled.span`${ImageStyles}`,
};

export const PledgeButton: React.FC<PledgeButtonProps> = (props) => {
  const { className, href, children } = props;
  const { Link } = useLink();
  const ImageComponent = useImage();
  const t = useLang();
  const image: Image = { id: 'pledge-icon', url: PLEDGE_ICON };

  return (
    <My.Container 
      href={href} 
      color={ORANGE}
      hoverColor={SECONDARY_BLUE}
      className={className}
      preIcon={<My.Image><ImageComponent image={image} /></My.Image>}
      icon={DottedChevronRightIconGlyph}
    >
    {children}
    </My.Container>
  )
};

PledgeButton.defaultProps = PledgeButtonDefaultProps;