import { CircleTrailBarChart, CircleTrailBarChartDataItem, CircleTrailBarSize } from '@shapeable/ui';
import React from 'react';
import styled, { css } from 'styled-components'; 
import { Classable, HasChildren, Entity, EntityTotal } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CONTENT_BACKGROUND } from '../../theme';
import { classNames } from '@shapeable/utils';
const cls = classNames('entity-total-circle-trail-bar-chart');

// -------- Types -------->

export type EntityTotalCircleTrailBarChartProps = Classable & HasChildren & { 
  items: EntityTotal[];
  color?: string;
  size?: CircleTrailBarSize;
  backgroundColor?: string;
};

export const EntityTotalCircleTrailBarChartDefaultProps: Omit<EntityTotalCircleTrailBarChartProps, 'entity'> = {
  items: [],
  color: 'dark',
  size: 'default',
  backgroundColor: CONTENT_BACKGROUND,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ChartStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)} 0;

    .shp--circle-trail-bar-chart-label {
      font-size: 1em;
    }
  `,
});


// -------- Components -------->

const My = {
  Chart: styled(CircleTrailBarChart)<ContainerProps>`${ChartStyles}`,
};

export const EntityTotalCircleTrailBarChart: React.FC<EntityTotalCircleTrailBarChartProps> = (props) => {
  const { className, items, color, backgroundColor } = props;
  
  const chartItems: CircleTrailBarChartDataItem[] = items.map(({ total, entity }) => {

    return {
      label: entity.name,
      size: 'default',
      value: total,
      color,
    };

  });

  return (
    <My.Chart
      className={cls.name(className)}
      showTicks={false}
      minorDivisions={0}
      majorDivisions={0}
      showDividers={false}
      showAxis={false}
      heightSizeMultipliers={{
        small: 2,
        medium: 1.65,
        default: 1.65,
        large: 2,
      }}
      backgroundColor={backgroundColor} 
      items={chartItems} 
    />
  )
};

EntityTotalCircleTrailBarChart.defaultProps = EntityTotalCircleTrailBarChartDefaultProps;