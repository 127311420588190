import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { VideosProvider as Provider, platformProviderValue } from '@shapeable/ui'
import { sortBy } from 'lodash';

export const VIDEOS_QUERY = graphql`
  query PlatformVideosQuery {
    platform {
      allVideos {
        id name path url slug __typename updated created
        openGraph { image { url url2x thumbnails { card { url url2x } bubble { url url2x } bubbleLarge { url url2x } } } }
        economies { id slug name __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        genera { id name slug __typename _schema { label pluralLabel } openGraph { id image { id url } } }
        methodologies { id name slug __typename _schema { label pluralLabel } openGraph { id image { id url } } }
      }
    }
  }
`;

export const VideosProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(VIDEOS_QUERY), 'allVideos' ), 'name') }>{children}</Provider>;
}
