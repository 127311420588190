import React from 'react';
import { IconComponent } from '@shapeable/types'

export const KelpIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="51" height="62" viewBox="0 0 51 62" fill="none">
    <path d="M44.8596 32.6835C44.931 34.4475 44.3518 36.6105 43.3994 38.8162C42.4557 41.0016 41.19 43.1277 39.9988 44.784C39.6845 45.221 39.3886 45.6454 39.1034 46.0543C37.0467 49.0037 35.5491 51.1513 31.737 51.4724C30.7755 51.5534 30.2818 51.2711 30.0016 50.9219C29.6787 50.5196 29.4596 49.8048 29.5241 48.7279C29.6527 46.5812 30.8863 43.5778 33.1809 40.8125C35.5023 38.015 38.7725 35.2303 41.443 33.6523C42.8094 32.8448 43.8704 32.4436 44.5094 32.4006C44.6914 32.3884 44.7811 32.409 44.8157 32.4208C44.8303 32.4541 44.8535 32.5313 44.8596 32.6835ZM44.8035 32.3995C44.8038 32.3992 44.806 32.4016 44.8094 32.4077C44.805 32.4028 44.8033 32.3998 44.8035 32.3995Z" stroke="#1A99B5" strokeWidth="2.2"/>
    <path d="M49.6694 14.7495C49.3521 16.2963 48.3736 18.0627 47.0449 19.7965C45.7299 21.5125 44.1406 23.1079 42.7198 24.3065C42.3423 24.625 41.9846 24.9356 41.6405 25.2345C39.1604 27.3884 37.384 28.9313 33.9506 28.3945C33.0922 28.2603 32.7447 27.9163 32.589 27.5857C32.4051 27.1955 32.3595 26.5496 32.6481 25.6285C33.2235 23.7921 34.9637 21.4273 37.6059 19.4937C40.2812 17.5358 43.7994 15.7934 46.518 14.9834C47.9114 14.5682 48.9312 14.449 49.4921 14.5463C49.5903 14.5634 49.6524 14.5832 49.6893 14.5983C49.6884 14.6311 49.6836 14.6801 49.6694 14.7495ZM49.736 14.6221C49.7359 14.6221 49.7355 14.6218 49.7348 14.6212L49.736 14.6221ZM49.6875 14.5523C49.6854 14.5465 49.6849 14.5432 49.6852 14.543C49.6854 14.5428 49.6865 14.5457 49.6875 14.5523Z" stroke="#1A99B5" strokeWidth="2.2"/>
    <path d="M14.9937 14.5675C16.3549 15.4181 17.7198 16.8753 18.9193 18.6138C20.111 20.341 21.0884 22.2718 21.7048 23.9896C21.872 24.4555 22.0386 24.8996 22.1987 25.3265C23.3523 28.4026 24.17 30.5832 22.4815 33.5925C22.0624 34.3394 21.6319 34.5323 21.2881 34.5613C20.8755 34.596 20.2665 34.4212 19.5091 33.8336C17.9995 32.6623 16.3947 30.2172 15.5059 27.0735C14.6014 23.8746 14.1495 20.0696 14.2904 17.3471C14.363 15.9441 14.5859 15.0192 14.8401 14.5867C14.8589 14.5547 14.8756 14.5293 14.8899 14.5093C14.9172 14.5225 14.9516 14.5412 14.9937 14.5675ZM14.9476 14.4428C14.9476 14.4429 14.9463 14.4441 14.9436 14.4459C14.9462 14.4436 14.9476 14.4427 14.9476 14.4428ZM14.8135 14.4796C14.8086 14.4792 14.8061 14.4787 14.806 14.4784C14.806 14.4782 14.8084 14.4784 14.8135 14.4796Z" stroke="#1A99B5" strokeWidth="2.3"/>
    <path d="M1.96545 28.0745C3.78181 28.2131 6.22507 29.4016 8.77158 31.1297C11.2669 32.8231 13.672 34.9021 15.3681 36.5699C15.6971 36.8934 16.0163 37.2023 16.3251 37.5012C17.7118 38.8433 18.8891 39.9828 19.8081 41.3344C20.8812 42.9126 21.5889 44.7813 21.6001 47.5494C21.6048 48.6972 21.2038 49.1246 20.7799 49.3287C20.2188 49.599 19.2331 49.6596 17.8275 49.2799C15.0619 48.533 11.4878 46.2957 8.70997 43.1701C5.91124 40.021 3.48235 35.7857 2.29246 32.4212C1.68691 30.709 1.46185 29.3853 1.55077 28.5914C1.59387 28.2065 1.6936 28.1007 1.69755 28.0969C1.69851 28.096 1.69886 28.0957 1.69869 28.0958C1.69852 28.096 1.69843 28.096 1.69868 28.0959C1.69894 28.0957 1.70019 28.095 1.70284 28.0938C1.7055 28.0926 1.71013 28.0908 1.71716 28.0886C1.74462 28.0801 1.81998 28.0634 1.96545 28.0745Z" stroke="#1A99B5" strokeWidth="2.5"/>
    <path d="M19.2635 1.39797C20.5185 2.01701 22.2774 3.21611 23.8703 4.63165C25.4873 6.06858 26.7827 7.59652 27.2805 8.83672C27.8663 10.2962 28.1193 11.2171 28.1103 12.1085C28.1013 12.9944 27.8309 13.9732 27.1098 15.5253C26.8777 16.0248 26.5861 16.1884 26.2823 16.2348C25.9094 16.2918 25.3439 16.191 24.6272 15.7909C23.2015 14.9951 21.5932 13.2375 20.6025 10.901C19.5856 8.50273 18.9777 5.57656 18.9462 3.46093C18.9302 2.38149 19.0689 1.6947 19.2321 1.38311C19.2418 1.38752 19.2523 1.39245 19.2635 1.39797ZM19.2914 1.29097C19.2914 1.29104 19.2906 1.29198 19.2888 1.29358C19.2905 1.2917 19.2914 1.2909 19.2914 1.29097ZM19.1327 1.34781C19.1294 1.34764 19.1277 1.34734 19.1277 1.34723C19.1277 1.34713 19.1293 1.34722 19.1327 1.34781Z" stroke="#1A99B5" strokeWidth="2.2"/>
    <path d="M43.0161 4.74823C44.0502 4.44654 44.8728 4.32869 45.3807 4.35667C45.2276 5.38668 44.8624 6.77685 44.3367 8.12732C43.7249 9.69875 42.977 11.0037 42.2623 11.651C41.3487 12.4783 40.7801 12.8768 40.1872 13.0907C39.5995 13.3028 38.8586 13.3786 37.5237 13.279C36.7931 13.2245 36.0325 12.5992 35.7561 11.503C35.4948 10.4671 35.7261 9.10868 36.972 7.9358C38.3564 6.63261 40.8573 5.378 43.0161 4.74823Z" stroke="#1A99B5" strokeWidth="2.5"/>
    <path d="M31.1075 6.90136C31.039 6.35334 30.5392 5.96461 29.9912 6.03311C29.4432 6.10162 29.0545 6.60141 29.123 7.14943L31.1075 6.90136ZM29.123 7.14943L30.123 15.1494L32.1075 14.9014L31.1075 6.90136L29.123 7.14943Z" fill="#1A99B5"/>
    <path d="M33.5801 56.3164L32.5258 56.2184C32.1856 56.1867 31.8429 56.1964 31.505 56.2471L31.4298 56.2584C31.1732 56.297 30.9216 56.3636 30.6795 56.457V56.457C29.7822 56.8035 29.0643 57.4999 28.6907 58.3863L28.6442 58.4965" stroke="#1A99B5" strokeWidth="2.5"/>
    <path d="M29.7754 51.0002L29.8126 51.7172C29.8387 52.2195 29.7724 52.7224 29.6172 53.2009V53.2009C29.4905 53.5912 29.3062 53.9603 29.0703 54.2961L27.6101 56.375" stroke="#1A99B5" strokeWidth="2"/>
    <path d="M22.3149 33.9958L22.1079 34.3641C21.7031 35.0845 21.5671 35.9252 21.7242 36.7364V36.7364C21.7583 36.9124 21.8059 37.0854 21.8665 37.254L22.3149 38.5007L23.7754 41.0007" stroke="#1A99B5" strokeWidth="2"/>
    <path d="M29.4316 25L22.2754 40" stroke="#1A99B5" strokeWidth="2"/>
    <path d="M27.2971 60.3235C27.508 61.0673 28.282 61.4994 29.0259 61.2885C29.7698 61.0776 30.2018 60.3036 29.9909 59.5597L27.2971 60.3235ZM26.3923 51.9993L25.0441 52.3767L25.0454 52.3812L26.3923 51.9993ZM25.0454 52.3812L27.2971 60.3235L29.9909 59.5597L27.7392 51.6174L25.0454 52.3812ZM23.3859 44.1712L23.5991 45.9014L26.3781 45.5589L26.1649 43.8288L23.3859 44.1712ZM24.5258 50.525L25.0441 52.3767L27.7405 51.6219L27.2221 49.7702L24.5258 50.525ZM23.5991 45.9014C23.7917 47.4636 24.1014 49.0092 24.5258 50.525L27.2221 49.7702C26.8356 48.3896 26.5535 46.9818 26.3781 45.5589L23.5991 45.9014Z" fill="#1A99B5"/>
    <path d="M20.6312 49.5177V49.5177C21.3167 49.8596 22.0263 50.1511 22.7541 50.39L23.2071 50.5387L23.523 50.6424C24.3376 50.9098 25.1081 51.3171 25.7896 51.8374V51.8374C26.0529 52.0385 26.2836 52.2837 26.4673 52.5594V52.5594C26.6574 52.8446 26.7971 53.1603 26.8804 53.4928L26.9764 53.8756" stroke="#1A99B5" strokeWidth="2.5"/>
    <path d="M27.2188 16.014L27.4275 16.7185C27.527 17.0543 27.5969 17.3982 27.6365 17.7462L27.6664 18.0085C27.6967 18.2746 27.6974 18.5433 27.6686 18.8095V18.8095C27.6024 19.4214 27.4586 20.0224 27.2409 20.5981L27.2052 20.6924" stroke="#1A99B5" strokeWidth="2"/>
    <path d="M31.0383 14.0006L31.1557 18.3635L30.2939 22.7071" stroke="#1A99B5" strokeWidth="2"/>
    <path d="M34.1152 17.7754C32.5965 17.7754 31.3652 16.5442 31.3652 15.0254C31.3652 13.5066 32.5965 12.2754 34.1152 12.2754C35.634 12.2754 36.8652 13.5066 36.8652 15.0254C36.8652 16.5442 35.634 17.7754 34.1152 17.7754Z" stroke="#1A99B5" strokeWidth="2.5"/>
    <path d="M27.2754 25.75C25.7566 25.75 24.5254 24.5188 24.5254 23C24.5254 21.4812 25.7566 20.25 27.2754 20.25C28.7942 20.25 30.0254 21.4812 30.0254 23C30.0254 24.5188 28.7942 25.75 27.2754 25.75Z" stroke="#1A99B5" strokeWidth="2.5"/>
    <path d="M22.2754 45.75C20.7566 45.75 19.5254 44.5188 19.5254 43C19.5254 41.4812 20.7566 40.25 22.2754 40.25C23.7942 40.25 25.0254 41.4812 25.0254 43C25.0254 44.5188 23.7942 45.75 22.2754 45.75Z" stroke="#1A99B5" strokeWidth="2.5"/>
    <path d="M31.2754 33.75C29.7566 33.75 28.5254 32.5188 28.5254 31C28.5254 29.4812 29.7566 28.25 31.2754 28.25C32.7942 28.25 34.0254 29.4812 34.0254 31C34.0254 32.5188 32.7942 33.75 31.2754 33.75Z" stroke="#1A99B5" strokeWidth="2.5"/>
    <path d="M36.2754 58.75C34.7566 58.75 33.5254 57.5188 33.5254 56C33.5254 54.4812 34.7566 53.25 36.2754 53.25C37.7942 53.25 39.0254 54.4812 39.0254 56C39.0254 57.5188 37.7942 58.75 36.2754 58.75Z" stroke="#1A99B5" strokeWidth="2.5"/>
    <path d="M24.5938 35.6358L25.3107 33.9206C25.9725 32.337 28.1468 32.1621 29.0532 33.6196C29.7318 34.7109 29.287 36.1501 28.111 36.6682L26.4099 37.4175C25.4886 37.8233 24.4591 37.1302 24.4885 36.124C24.4934 35.9562 24.5291 35.7907 24.5938 35.6358Z" stroke="#1A99B5" strokeWidth="2.2"/>
  </svg>
);