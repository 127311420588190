import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, IconComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, Icon } from '@shapeable/ui';
import { StatusVIcon } from '../icons/status-v-icon';
import { StatusIvIcon } from '../icons/status-iv-icon';
import { StatusIaIcon } from '../icons/status-ia-icon';
import { StatusIbIcon } from '../icons/status-ib-icon';
import { StatusIiIcon } from '../icons/status-ii-icon';
import { StatusIiiIcon } from '../icons/status-iii-icon';
import { StatusViIcon } from '../icons/status-vi-icon';
import { classNames } from '@shapeable/utils';
const cls = classNames('Icon-status-value');

// -------- Types -------->

export const IconStatusValues: Partial<Record<string, string>> = {
  'Ia': 'Strict nature reserve',
  'Ib': 'Wilderness area',
  'II': 'National Park',
  'III': 'Natural monument or feature',
  'IV': 'Habitat or species management area',
  'V': 'Protected landscape or seascape',
  'VI': 'Protected area with sustainable use of natural resources',
};

const statusToIconMap: Partial<Record<string, IconComponent>> = {
  'Ia': StatusIaIcon,
  'Ib': StatusIbIcon,
  'II': StatusIiIcon,
  'III': StatusIiiIcon,
  'IV': StatusIvIcon,
  'V': StatusVIcon,
  'VI': StatusViIcon,
};

export type IconStatusValueProps = Classable & HasChildren & {
  status?: string;
}

export const IconStatusValueDefaultProps: IconStatusValueProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(11)};
    line-height: 1.5;
  `,
});

const NoStatusBadgeStyles = breakpoints({
  base: css`
    font-weight: 600;
  `,
});

const IconStyles = breakpoints({
  base: css`
    flex-shrink: 0;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    NoStatusBadge: styled.span`${NoStatusBadgeStyles}`,
    Description: styled.span`${DescriptionStyles}`,

    Icon: styled(Icon)`${IconStyles}`,
};

export const IconStatusValue: Shapeable.FC<IconStatusValueProps> = (props) => {
  const { className, status } = props;

  const statusDescription = IconStatusValues[status];

  const Icon = statusToIconMap[status];

  return (
    <My.Container className={cls.name(className)}>
      {
        Icon ? 
          <My.Icon><Icon /></My.Icon> :
          <My.NoStatusBadge>{status}</My.NoStatusBadge>
      }
      {
        statusDescription &&
        <My.Description>{statusDescription}</My.Description>
      
      }
    </My.Container>
  )
};

IconStatusValue.cls = cls;
IconStatusValue.defaultProps = IconStatusValueDefaultProps;