import React from 'react';
import { IconComponent } from '@shapeable/types'

export const DesignatedTypeIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_2729_17254)">
      <path d="M9.00002 12.9131C9.43224 12.9131 9.78262 12.5627 9.78262 12.1304C9.78262 11.6982 9.43224 11.3478 9.00002 11.3478C8.56779 11.3478 8.21741 11.6982 8.21741 12.1304C8.21741 12.5627 8.56779 12.9131 9.00002 12.9131Z" fill="black"/>
      <path d="M9 9C7.27826 9 5.86957 10.4087 5.86957 12.1304C5.86957 13.8522 7.27826 15.2609 9 15.2609C10.7217 15.2609 12.1304 13.8522 12.1304 12.1304C12.1304 10.4087 10.7217 9 9 9ZM9 14.2043C7.86522 14.2043 6.92609 13.2652 6.92609 12.1304C6.92609 10.9957 7.86522 10.0565 9 10.0565C10.1348 10.0565 11.0739 10.9957 11.0739 12.1304C11.0739 13.2652 10.1348 14.2043 9 14.2043Z" fill="black"/>
      <path d="M10.5652 0.117371V1.72172C13.7739 2.42607 16.2392 5.20433 16.3957 8.56954L17.2174 9.39128L18 8.60868C17.8044 4.34346 14.6739 0.860849 10.5652 0.117371Z" fill="black"/>
      <path d="M0.782609 8.6087L0 9.39131C0.195652 13.6565 3.32609 17.1391 7.43478 17.8826V16.2783C4.18696 15.5739 1.76087 12.7957 1.56522 9.39131L0.782609 8.6087Z" fill="black"/>
      <path d="M8.60868 0C4.34346 0.195652 0.860849 3.32609 0.117371 7.43478H1.72172C2.42607 4.18696 5.20433 1.76087 8.56954 1.56522L9.39128 0.782609L8.60868 0Z" fill="black"/>
      <path d="M17.8435 10.5652H16.2391C15.5348 13.7739 12.7565 16.2392 9.39131 16.3957L8.6087 17.2174L9.39131 18C13.6174 17.8044 17.1391 14.6739 17.8435 10.5652Z" fill="black"/>
      <path d="M8.99995 2.7782C7.43473 2.7782 6.18256 4.03037 6.18256 5.59559C6.18256 7.16081 7.43473 8.41298 8.99995 8.41298C10.5652 8.41298 11.8173 7.16081 11.8173 5.59559C11.8173 4.03037 10.5652 2.7782 8.99995 2.7782ZM8.56951 6.96515L8.33473 6.73037L8.09995 6.49559L7.19995 5.59559L7.63038 5.12602L8.53038 6.02602L10.2913 4.18689L10.7217 4.65646L8.56951 6.96515Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_2729_17254">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);