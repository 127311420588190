import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityFilters, SearchBar, useEntity, useTheme } from '@shapeable/ui';
import { AutoSizer, Grid, CellMeasurer, CellMeasurerCache, InfiniteLoader } from 'react-virtualized';
import { ProtectionCard } from './protection-card';
import { ProtectionAreaDataTypes } from '../../hooks/use-protection-area-tooltip';
import { useProtectionAreaData } from '../../hooks/use-protection-area-data';
import { ClipLoader as Spinner } from 'react-spinners';
import { classNames } from '@shapeable/utils';
const cls = classNames('protection-areas-grid');

// -------- Types -------->

export type ProtectionAreasGridProps = Classable & HasChildren & { 
  items?: ProtectionAreaDataTypes[];
  height?: number;
  width?: number;
};

export const ProtectionAreasGridDefaultProps: ProtectionAreasGridProps = {
  items: [],
  height: 600,
  width: 800,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(3)};
  `,
});

const CardStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(2)} ${theme.UNIT(4)};

  `,  
});

const GridContainerStyles = breakpoints({
  base: css`
    height: 100vh;
    width: 100%;
    margin-bottom: ${theme.UNIT(8)};
    align-items: center;
    border: 1px solid #CCCCCC;
  `,
});

const SearchBarStyles = breakpoints({
  base: css`
    .shp--field{
      border: none;
    }
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    
  `,
});

const FilterStyles = breakpoints({
  base: css`
    
  `,
});
const EmptyViewPageStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: ${theme.UNIT(12)};
    width: 100%;
    height: 600px;
  `,
});

const SpinnerStyles = breakpoints({
  base: css`

  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    SearchBar: styled(SearchBar)`${SearchBarStyles}`,
    Filters: styled(EntityFilters)`${FilterStyles}`,
    GridContainer: styled.div`${GridContainerStyles}`,
    Card: styled(ProtectionCard)`${CardStyles}`,

    EmptyViewPage: styled.div`${EmptyViewPageStyles}`,
    Spinner: styled(Spinner)`${SpinnerStyles}`,
};

export const ProtectionAreasGrid: Shapeable.FC<ProtectionAreasGridProps> = (props) => {
  const { className, items = [] } = props;

  const theme = useTheme();

  const { displayData, loading, dataLength, setDisplayData } = useProtectionAreaData();
  const [globalColumnCount, setGlobalColumnCount ] = useState(3);
  const [searchTerm, setSearchTerm] = useState('');

  const onChange = (name: string) => { 
    setSearchTerm(name)
   }

  const filterProtectionItems = useMemo(() => {
    return displayData.filter((area) => {
      return area.NAME.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [searchTerm, displayData]);

  const gridWidth = 1088;
  const minColumnWidth = 300;

  const hasFilteredItems = !!filterProtectionItems.length;

  const cache = useRef(new CellMeasurerCache({
    defaultHeight: 170, 
    fixedHeight: true, 
    fixedWidth: false,
    
  }));

  const columnWidth = ({ index }: { index: number }) => {
    const totalSpacing = 30 * (globalColumnCount - 1); 
    const availableWidth = gridWidth - totalSpacing;
    return availableWidth / globalColumnCount;
  };

  const loadMoreRows = async ({ startIndex, stopIndex }: { startIndex: number, stopIndex: number }) => {
    const response = await fetch(`/kfa-protection-area-metadata.json?start=${startIndex}&end=${stopIndex}`);
    const moreData = await response.json();
    
    setDisplayData((prevData) => [...prevData, ...moreData]);
  };

  const cellRenderer = ({ columnIndex, key, parent, rowIndex, style }: {
    columnIndex: number;
    key: string;
    parent: any;
    rowIndex: number;
    style: React.CSSProperties;
  }) => {
    const index = rowIndex * globalColumnCount + columnIndex;
    const item = filterProtectionItems[index];
    const isLoaded = index < displayData.length;
    return (
      <CellMeasurer
        cache={cache.current}
        columnWidth={columnWidth}
        rowHeight={cache.current.rowHeight}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div style={style} key={key} >
          {
            isLoaded ? (
              <My.Card entity={item} />
            ) : (
              <My.Spinner color={theme?.color?.primary} size={28}  />
            )
          }
        </div>
      </CellMeasurer>
    );
  };

  const rowCount = Math.ceil(filterProtectionItems.length / globalColumnCount);

  return (
    <My.Container className={cls.name(className)}>
      <My.SearchBar
        searchTerm={searchTerm}
        onChange={onChange}
        placeholder='Search Protection Areas...'
      />
      {
        loading ? 
        <My.EmptyViewPage>
            <Spinner color={theme?.color?.primary} size={28}  />
        </My.EmptyViewPage>
        
        : ( hasFilteredItems ?
        <My.GridContainer>
          <InfiniteLoader
              isRowLoaded={({ index }) => !!filterProtectionItems[index]}
              loadMoreRows={loadMoreRows}
              rowCount={rowCount} 
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer>
                  {({ height, width }) => {

                    const columnCount = Math.floor(width / minColumnWidth);
                    setGlobalColumnCount(columnCount);

                    const totalSpacing = 5 * (columnCount - 1);
                    const availableWidth = width - totalSpacing;
                    const columnWidth = availableWidth / columnCount;
                    const rowCount = Math.ceil(filterProtectionItems.length / columnCount);

                    return (
                      <Grid
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        width={width}
                        height={height}
                        rowCount={rowCount}
                        rowHeight={cache.current.rowHeight}
                        columnWidth={columnWidth}
                        columnCount={globalColumnCount}
                        cellRenderer={cellRenderer}
                      />
                    )}}
                </AutoSizer>
              )}
            </InfiniteLoader>
        </My.GridContainer>
        : <My.EmptyViewPage>
            <h3>No Protection Areas Found</h3>
        </My.EmptyViewPage>
        )
    }
    </My.Container>
  );
};

ProtectionAreasGrid.defaultProps = ProtectionAreasGridDefaultProps;
ProtectionAreasGrid.cls = cls;