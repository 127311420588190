import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { LinkButton, useEntity, useLang } from '@shapeable/ui';
import { BlockMetricLayout } from '../entities/block-metric-layout';
import { useSite } from '../../hooks/use-site';
import { EntityQuarterlyGrowthChart } from '../entities/entity-quarterly-growth-chart';
import { DottedChevronRightHeavyIconGlyph } from '@shapeable/icons';
import { coalesce, firstStringProperty } from '@shapeable/utils';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ButtonStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const ChartStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled(BlockMetricLayout)<ContainerProps>`${ContainerStyles}`,
  Button: styled(LinkButton)`${ButtonStyles}`,

  Chart: styled(EntityQuarterlyGrowthChart)`${ChartStyles}`,
};

export const BlockLayoutPersonTimelineChart: BlockLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const t = useLang();

  const { metrics } = useSite();
  const { countPeopleByQuarter } = metrics;
  const linkHref = firstStringProperty(entity, ['link.page.path', 'links.url']);
  const linkLabel = firstStringProperty(entity, ['link.label', 'link.name']);

  return (
    <My.Container 
      showContent 
      entity={entity} 
      className={className}
      controls={
        linkHref &&
         <My.Button variant='slim' href={linkHref} icon={DottedChevronRightHeavyIconGlyph}>{t(linkLabel)}</My.Button>
      }
    >
      <My.Chart
        valueLabel='# People'
        items={countPeopleByQuarter} 
      />
    </My.Container>
  )
};