import { 
  PageKnowledgeHubLayout, PageKnowledgeHubLayoutProps, SliceBlockToutGrid, SliceBlockToutGridProps, 
  SliceContactForm, SliceContactFormProps, SliceContent, SliceFeaturedOrganisation, SliceIntro, 
  SliceKeyMessage, SlicePersonBioGrid, SlicePersonBioGridProps, SliceQuestion, 
  SliceQuestionProps, createSliceLayout 
} from '@shapeable/ui';
import { RestorationProjectGrid, RestorationProjectGridProps } from '../entities/restoration-project-grid';
import { ContactForm } from '../elements/contact-form';
import { SliceKnowledgeHubIntro } from '../entities/slice-knowledge-hub-intro';
import { SliceMetricsBlocks } from '../entities/slice-metrics-blocks';
import { SliceProtectionChart } from '../entities/slice-protection-chart';
import { SliceRestorationChart } from '../entities/slice-restoration-chart';
import { SliceIntroPledge } from '../entities/slice-intro-pledge';
import { SliceNarrative, SliceNarrativeProps } from '../entities/slice-narrative';
import { SlicePersonGrid } from '../entities/slice-person-grid';
import { SliceMembershipGrid, SliceMembershipGridProps } from '../entities/slice-membership-grid';
import { SLICE_MEMBERSHIP, SLICE_MEMBERSHIP_CONTACT } from '../../data';
import { KelpIcon } from '../icons/kelp-icon';
import { SliceBannerTout, SliceBannerToutProps } from '../entities/slice-banner-tout';

export const SliceLayoutIntroPledge = createSliceLayout(
  SliceIntroPledge, {
    verticalPadding: 'small',
  }
);

export const SliceLayoutKeyMessage = createSliceLayout(
  SliceKeyMessage, {
    verticalPadding: 'large',
    overlayColor: '#00000033',
  }
);

export const SliceLayoutIntro = createSliceLayout(
  SliceIntro, {
    verticalPadding: 'small',
  }
);

export const SliceLayoutMetricsBlocks = createSliceLayout(
  SliceMetricsBlocks, {
    boundary: 'content-bleed',
  }
);

export const SliceLayoutNarrative = createSliceLayout<SliceNarrativeProps>(
  SliceNarrative, {
    verticalPadding: 'small',
  }
);

export const SliceLayoutNarrativeReverse = createSliceLayout<SliceNarrativeProps>(
  SliceNarrative, {
    verticalPadding: 'small',
    props: {
      reverse: true,
    }
  },

);

export const SliceLayoutKnowledgeHubIntro = createSliceLayout(
  SliceKnowledgeHubIntro, {
    verticalPadding: 'content',
  }
);

export const SliceLayoutContactForm = createSliceLayout<SliceContactFormProps>(
  SliceContactForm, {
    verticalPadding: 'small',
    props: {
      formComponent: ContactForm,
      variant: 'inline'
    }
  }
);

export const SliceLayoutRestorationChart = createSliceLayout(
  SliceRestorationChart, {
    boundary: 'content',
    verticalPadding: 'none',
  }
);

export const SliceLayoutProtectionChart = createSliceLayout(
  SliceProtectionChart, {
    boundary: 'content',
    verticalPadding: 'none',
  }
);

export const SliceLayoutMembershipGrid = createSliceLayout<SliceMembershipGridProps>(
  SliceMembershipGrid, {
    boundary: 'none',
    props: {
      blocks: SLICE_MEMBERSHIP.blocks
    }
  }
);


export const SliceLayoutMembershipGridContact = createSliceLayout<SliceMembershipGridProps>(
  SliceMembershipGrid, {
    boundary: 'none',
    props: {
      blocks: SLICE_MEMBERSHIP_CONTACT.blocks
    }
  }
);

export const SliceLayoutPersonGrid = createSliceLayout<SlicePersonBioGridProps>(
  SlicePersonBioGrid, {
    verticalPadding: 'small',
    props: {
      showOrganisation: true,
    }
  }
);

export const SliceLayoutRestorationProjectGrid = createSliceLayout<RestorationProjectGridProps>(
  RestorationProjectGrid, {
    boundary: 'content-bleed',
  }
);

export const SliceLayoutQuestion = createSliceLayout<SliceQuestionProps>(
  SliceQuestion, {
    props: {
      iconComponent: KelpIcon,
    }
  }
);

export const SliceLayoutBannerTout = createSliceLayout<SliceBannerToutProps>(
  SliceBannerTout, {
    verticalPadding: 'large',
    props: {
      
    }
  }
);

export const SliceLayoutContent = createSliceLayout(
  SliceContent, {
    verticalPadding: 'small',
  }
);