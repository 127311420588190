import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Organisation } from '@shapeable/types';
import { breakpoints, CSS, HasCss, theme } from '@shapeable/theme';
import { Image, ImageEntity, useLink } from '@shapeable/ui';

// -------- Types -------->

export type OrganisationLinkProps = Classable & HasChildren & {
  entity: Organisation;
  logoStyles?: CSS;
}

export const OrganisationLinkDefaultProps: Omit<OrganisationLinkProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const LogoStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Logo: styled(Image)<HasCss>`${LogoStyles}`,
};

export const OrganisationLink: React.FC<OrganisationLinkProps> = (props) => {
  const { className, entity } = props;
  const { Link } = useLink();
  const { logo, name, url } = entity;

  return (
    <My.Container className={className}>
      <Link style={{ display: 'block' }} target="_blank" href={entity.url}>
        <My.Logo alt={`Logo for ${name}`} image={logo} />
      </Link>
    </My.Container>
  )
};

OrganisationLink.defaultProps = OrganisationLinkDefaultProps;