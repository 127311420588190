import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellHeader, EntityTextValue, Icon, IconLabel, useEntity, useLang, useLink } from '@shapeable/ui';
import { ProtectionAreaDataTypes } from 'hooks/use-protection-area-tooltip';
import { DesignatedTypeIcon } from '../icons/designated-type-icon';
import { classNames } from '@shapeable/utils';
import { ExternalLinkIconGlyph, LocationIcon } from '@shapeable/icons';

const cls = classNames('protection-card');

// -------- Types -------->

export type ProtectionCardProps = Classable & HasChildren & { 
  entity?: ProtectionAreaDataTypes;
};

export const ProtectionCardDefaultProps: Omit<ProtectionCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  display: flex;
  padding: ${theme.UNIT(3)} ${theme.UNIT(3)} ${theme.UNIT(5)};
  border: 1px dotted #CCCCCC;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const DesignatedTypeStyles = breakpoints({
  base: css`
    
  `,
});

const TitleStyles = breakpoints({
  base: css`
    
    font-size: ${theme.FONT_SIZE(18)};
    margin-bottom: ${theme.UNIT(3)};

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 24px;
  `,
});

const IconStyles = breakpoints({
  base: css`
    flex-shrink: 0;
    padding-top: 4px;

  `,
});



const ContentStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.UNIT(2)};
  `,
});

const CountryStyles = breakpoints({
  base: css`
    
  `,
});

const TitleAndIconStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: ${theme.UNIT(2)};

  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(CellHeader)`${HeaderStyles}`,
    Title: styled.div`${TitleStyles}`,
      Icon: styled(Icon)`${IconStyles}`,
    Content: styled.div`${ContentStyles}`,
      DesigatedType: styled(IconLabel)`${DesignatedTypeStyles}`,
      Country: styled(IconLabel)`${CountryStyles}`,

      TitleAndIcon: styled.div`${TitleAndIconStyles}`,
};

export const ProtectionCard: Shapeable.FC<ProtectionCardProps> = (props) => {
  const { className, children, entity } = props;
  // const entity = useEntity(props.entity);

  if (!entity) {
    return null;
  }

  const { Link } = useLink();

  const desigEngIcon = <DesignatedTypeIcon />;
  const countryIcon = <LocationIcon />;
  
  const { DESIG_ENG, ISO3, NAME } = entity;

  const href = `https://www.protectedplanet.net/en/thematic-areas/wdpa?tab=WDPA`;

  const t = useLang();
  
  
  return (
   <My.Container className={cls.name(className)}>
    <My.Header>{t('Protection Area')}</My.Header>
      {
        <Link style={{ textDecoration: 'none' }} href={href}>
          <My.TitleAndIcon>
          <My.Title>
            {NAME}
          </My.Title>
          <My.Icon size={14}><ExternalLinkIconGlyph /></My.Icon>
          </My.TitleAndIcon>
        </Link>
      }
      <My.Content>
      {
        DESIG_ENG &&
        <My.DesigatedType icon={desigEngIcon} >{t(DESIG_ENG)}</My.DesigatedType>
      }
      {
        ISO3 &&
        <My.Country icon={countryIcon} >{ISO3}</My.Country>
      }
      </My.Content>
    {children}
   </My.Container>
  )
};

ProtectionCard.defaultProps = ProtectionCardDefaultProps;
ProtectionCard.cls = cls;