import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, ContentNode, ContentTitle, Icon, LinkButton, PlainLinkButton, useEntity, useLang } from '@shapeable/ui';
import { ProtectionAreaDataTypes, ProtectionTooltipData } from '../../hooks/use-protection-area-tooltip';
import { IconStatusValue } from '../elements/icon-status-value';
import { classNames } from '@shapeable/utils';
import { ExternalLinkIconGlyph } from '@shapeable/icons';
const cls = classNames('protection-area-tooltip');

// -------- Types -------->

export type ProtectionAreaTooltipProps = Classable & HasChildren & { 
  entity?: Entity & ProtectionAreaDataTypes;
};

export const ProtectionAreaTooltipDefaultProps: Omit<ProtectionAreaTooltipProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    display: flex;
    padding: ${theme.UNIT(5)};
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(2)};
  `,
});

const LinkStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    font-weight: 500;
    align-self: center;
    font-size: ${theme.FONT_SIZE(14)};
    align-items: center;
    display: flex;
    ${theme.TRANSITION('color')}

    ${theme.FILL('link')};
    &:hover {
      ${theme.FILL('link-hover')};
    }
  `,
});

const IconStyles = breakpoints({
  base: css`
    margin-left: ${theme.UNIT(2)};
  `,
});

const TooltipContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    padding-bottom: ${theme.UNIT(3)};
    ${theme.H_DOT_BG_END()};
    color: ${theme.COLOR('strong')};
  `,
});

const LabelStyles = breakpoints({
  base: css`

  `,
});

const ValueStyles = breakpoints({
  base: css`
    font-weight: 600;
  `,
});

const ExternalLinkIconStyles = breakpoints({
  base: css`
    
  `,
});

const IconStatusValueStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Title: styled(ContentTitle)`${TitleStyles}`,

    Body: styled.div`${BodyStyles}`,
      TooltipContainer: styled.div`${TooltipContainerStyles}`,
        Label: styled(CellLabel)`${LabelStyles}`,
        Value: styled.span`${ValueStyles}`,
        IucnStatus: styled(IconStatusValue)`${IconStatusValueStyles}`,

  Link: styled(PlainLinkButton)`${LinkStyles}`,
  Icon: styled(Icon)`${IconStyles}`,
  ExternalLinkIcon: styled(ExternalLinkIconGlyph)`${ExternalLinkIconStyles}`,
};

export const ProtectionAreaTooltip: Shapeable.FC<ProtectionAreaTooltipProps> = (props) => {
  const { className, children, entity } = props;
  
  const { DESIG_ENG, REP_AREA, WDPAID, ISO3, NAME, IUCN_CAT, } = entity;

  const hasIucnStatus = !!IUCN_CAT;
  const hasDesignation = !!DESIG_ENG;
  const hasRerpotedArea = !!REP_AREA;
  const hasId = !!WDPAID;
  const hasCountry = !!ISO3;

  const t = useLang();

  const handleLearnMoreClick = () => {
    window.open(`https://www.protectedplanet.net/en/thematic-areas/wdpa?tab=WDPA`, '_blank');
  };

  return (
   <My.Container className={cls.name(className)} >
    <My.TooltipContainer>
      <My.Title>{NAME}</My.Title>
    </My.TooltipContainer>

    {
      hasDesignation &&
      <My.TooltipContainer>
        <My.Label>{t('Designated Type')}</My.Label>
        <My.Value>{t(DESIG_ENG)}</My.Value>
      </My.TooltipContainer>
    }
    {
      hasId &&
      <My.TooltipContainer>
        <My.Label>{t('WDPAID')}</My.Label>
        <My.Value>{WDPAID}</My.Value>
      </My.TooltipContainer>
    }
    {
      hasCountry &&
      <My.TooltipContainer>
        <My.Label>{t('Country')}</My.Label>
        <My.Value>{t(ISO3)}</My.Value>
      </My.TooltipContainer>
    }
    {
      hasIucnStatus &&
      <My.TooltipContainer>
        <My.Label>{t('IUCN Category')}</My.Label>
        <My.IucnStatus status={IUCN_CAT} />
      </My.TooltipContainer>
    }
    {
      hasRerpotedArea &&
      <My.TooltipContainer>
        <My.Label>{t('Reported Area')}</My.Label>
        <My.Value>{REP_AREA}<b> ha</b></My.Value>
      </My.TooltipContainer>
    }
    {
      <My.Link onClick={handleLearnMoreClick}>{t('Learn More')}<My.Icon size={16}><My.ExternalLinkIcon /></My.Icon></My.Link>
    }
   </My.Container>
  )
};

ProtectionAreaTooltip.defaultProps = ProtectionAreaTooltipDefaultProps;
ProtectionAreaTooltip.cls = cls;