import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SiteSidebarProvider as Provider } from '@shapeable/ui';
import { OCEAN_BLUE } from '../../theme';
import { SidebarButtons } from '../elements/sidebar-buttons';
import { useMapUtils } from '@shapeable/maps';
import { classNames } from '@shapeable/utils';
const cls = classNames('site-sidebar-provider');

// -------- Types -------->

export type SiteSidebarProviderProps = Classable & HasChildren & {
}

export const SiteSidebarProviderDefaultProps: SiteSidebarProviderProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

export const SiteSidebarProvider: Shapeable.FC<SiteSidebarProviderProps> = (props) => {
  const { children } = props;
  const map = useMapUtils();

  return (
    <Provider
      value={{ 
      isAvailable: () => true, 
      showFullscreenButton: true,
      color: OCEAN_BLUE, 
      onClick: map.showOnClick,
      buttons: <SidebarButtons />
    }}>
      {children}
    </Provider>
  )
};

SiteSidebarProvider.cls = cls;
SiteSidebarProvider.defaultProps = SiteSidebarProviderDefaultProps;