import { Page } from "@shapeable/types";


export const filterPagesByType = (pages: Page[], typeName: string) => {
  return pages.filter((page: Page) => 
    (page?.type?.name === typeName)
  ).map((page: Page) => {
    return {
      ...page,
      _schema: {
        ...page?._schema,
        label: typeName,
      }
    }
  });
};



