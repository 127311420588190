import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Block } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityContent, LinkButton, ResponsiveContainer, useEntity } from '@shapeable/ui';
import { BLOCK_METRIC_HEADING, CONTENT_BACKGROUND, OCEAN_BLUE } from '../../theme';

// -------- Types -------->

export type BlockMetricLayoutProps = Classable & HasChildren & { 
  entity: Block;
  ratio?: number;
  showContent?: boolean;
  controls?: React.ReactNode;
};

export const BlockMetricLayoutDefaultProps: Omit<BlockMetricLayoutProps, 'entity'> = {
  ratio: 1,
};

// -------- Child Component Props -------->

type ContainerProps = {
  ratio: number;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background-color: ${CONTENT_BACKGROUND};
    font-size: ${theme.FONT_SIZE(13)};
    flex-grow: 1;

    ${(props: any) => css`
      /* ratio handles half-height panels for the final group */
      height: ${100 / props.ratio}%;
    `}

    ${(props: any) => props.ratio !== 1 && css`
      border-bottom: 8px solid ${theme.COLOR('light')};

      &:last-child {
        border-bottom: none;
        margin-bottom: -4px;
      }
    `}

    position: relative;
    box-sizing: border-box;
    .shp--responsive-container__body {
      position: relative;
    }
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(14)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(15)};
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} ${theme.UNIT(3)} ${theme.UNIT(3)};
  `,
});


const TitleStyles = breakpoints({
  base: css`
    font-size: 1em;
    margin: 0 0 ${theme.UNIT(2)} 0;
    text-transform: uppercase;
    font-weight: 300;
    color: ${BLOCK_METRIC_HEADING};
  `,
});

const ContentStyles = breakpoints({
  base: css`
    font-size: 1em;

    b, strong {
      color: ${OCEAN_BLUE};
    }
  `,
  tablet: css`
    min-height: 3em;
  `
});

const ControlsStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    margin-top: ${theme.UNIT(4)};
    width: 100%;
    display: flex;
    flex-direction: row;
  `,
  tablet: css`
    position: absolute;
    bottom: 0px;
    left: 0;
    padding: ${theme.UNIT(3)};
  `
});




// -------- Components -------->

const My = {
  Container: styled(ResponsiveContainer)<ContainerProps>`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
    Title: styled.h3`${TitleStyles}`,
    Content: styled(ContentEntityContent)`${ContentStyles}`,
  Controls: styled.div`${ControlsStyles}`,
};

export const BlockMetricLayout: React.FC<BlockMetricLayoutProps> = (props) => {
  const { className, children, showContent, controls, ratio } = props;
  const entity = useEntity(props.entity);
  const { title, content } = entity;

  return (
    <My.Container ratio={ratio} fill={false} aspectRatio={{ base: null, tablet: ratio }} alignItems='flex-start' justifyContent='flex-start' className={className}>
    <My.Body>
      <My.Title>{title}</My.Title>
      {
        showContent && 
        <My.Content entity={entity} />
      }

      {children}

      <My.Controls>
        {controls}
      </My.Controls>
    </My.Body>      
    </My.Container>
  )
};

BlockMetricLayout.defaultProps = BlockMetricLayoutDefaultProps;