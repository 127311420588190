import { Icon } from '@shapeable/ui';
import React from 'react';
import { classNames } from '@shapeable/utils';
import styled, { css } from 'styled-components'; const cls = classNames('pledge-circle');
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ORANGE } from '../../theme';
import { PledgeIcon } from '@shapeable/icons';

// -------- Types -------->

export type MapPledgeIconProps = Classable & HasChildren & {
  fill?: string;
  onClick?: React.MouseEventHandler;
  size: number,
}

export const MapPledgeIconDefaultProps: MapPledgeIconProps = {
  fill: ORANGE,
  size: 48,
};

// -------- Child Component Props -------->

type ContainerProps = {
  fill: string;
  size: number;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ fill, size }: ContainerProps ) => css`
      background: ${fill};
      width: ${size}px;
      height: ${size}px;
      border-radius: ${size}px;
    `}
  `,
});

const IconStyles = breakpoints({
  base: css`
    display: flex;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Icon: styled(Icon)`${IconStyles}`,
};

export const MapPledgeIcon: React.FC<MapPledgeIconProps> = (props) => {
  const { className, onClick, size, fill } = props;
  return (
    <My.Container onClick={onClick} size={size} fill={fill} className={cls.name(className)}>
      <My.Icon size={size} color="light"><PledgeIcon /></My.Icon>
    </My.Container>
  )
};

MapPledgeIcon.defaultProps = MapPledgeIconDefaultProps;