import React, { useState } from 'react';
import styled, { css } from 'styled-components'; 
import { Classable, HasChildren, Entity, KeyValueIntTuple } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { OCEAN_BLUE } from '../../theme';
import { minBy, get, maxBy } from 'lodash';
import { ChartTooltip } from '../elements/chart-tooltip';
import { classNames } from '@shapeable/utils';
import { useInViewEffect } from '@shapeable/ui';

const cls = classNames('entity-quarterly-growth-chart');

// -------- Types -------->

export type EntityQuarterlyGrowthChartProps = Classable & HasChildren & { 
  items?: KeyValueIntTuple[];
  color?: string;
  gridLineColor?: string;
  tickLabelColor?: string;
  valueLabel?: string; 
};

export const EntityQuarterlyGrowthChartDefaultProps: EntityQuarterlyGrowthChartProps = {
  items: [],
  gridLineColor: '#FFF',
  tickLabelColor: '#969696',
  color: OCEAN_BLUE,
  valueLabel: 'Value',
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type CountProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .recharts-tooltlip-wrapper:focus-visible {
      outline: none;
    }

    width: 100%;
    height: 300px;
    margin-top: ${theme.UNIT(4)};
  `,
  tablet: css`
    margin-top: ${theme.UNIT(2)};
    height: calc(100% - 120px);
  `
});

const TooltipStyles = breakpoints({
  base: css`
    &:focus, &:active {
      outline: none !important;
    }
  `,
});


const EntityStyles = breakpoints({
  base: css`
    
  `,
});


const CountStyles = breakpoints({
  base: css`
    display: inline-block;
    padding-left: ${theme.UNIT(1)};
    ${({ _color }: CountProps ) => css`
      color: ${theme.COLOR(_color)};
    `}
  `,
});

const LabelStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(12)};
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 0.5em 0; 
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Tooltip: styled(ChartTooltip)`${TooltipStyles}`,
      Label: styled.h4`${LabelStyles}`,
      Entity: styled.span`${EntityStyles}`,

    Count: styled.b<CountProps>`${CountStyles}`,

};

export const EntityQuarterlyGrowthChart: React.FC<EntityQuarterlyGrowthChartProps> = (props) => {
  const { className, children, tickLabelColor, gridLineColor, color, items, valueLabel } = props;

  const [isAnimationActive, setIsAnimationActive] = useState<boolean>(false);

  const ref = useInViewEffect(() => {
    setIsAnimationActive(true);
  });

  const data = (items.map(({ key, value }) => {
    let label = '';
    if (key.match(/[0-9]\-Q1/)) {
      label = key.replace(/-Q1/, '');
    }

    return {
      key,
      value: isAnimationActive ? value : 0,
      label
    };
  }))

  const minItem = minBy(data, item => item.value);
  const maxItem = maxBy(data, item => item.value);
  const minValue = get(minItem, 'value', 0);
  const maxValue = get(maxItem, 'value', 0);

  const CustomTooltip = ({ payload }: any) => {
    const result = payload.length && payload[0] && payload[0].payload;

    if (result) {
      const { name, value, key } = result;
      const [all, year, quarter] = key && key.match(/([0-9]*?)-(Q[0-9])/i);
 
      return (
        <My.Tooltip>
          {
            valueLabel &&
            <My.Label>{valueLabel}</My.Label>
          }
          <My.Entity>{quarter} {year}:</My.Entity>
          <My.Count _color={color}>{value.toFixed(0)}</My.Count>
        </My.Tooltip>
      );
    }
  
    return null;
  };
  
  return (
    <My.Container ref={ref} className={cls.name(className)}>
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 15,
          right: 30,
          left: 5,
          bottom: 15,
        }}
      >
        <CartesianGrid 
          stroke={gridLineColor}
          strokeDasharray="3 3" />
          <XAxis 
            axisLine={{ stroke: gridLineColor, strokeWidth: 1.5 }}
            tickLine={{ stroke: gridLineColor }}
            tick={{ fill: tickLabelColor }}
            color={'#CCC'}
            dy={15} tickSize={3} dataKey="label" />
          <YAxis 
            axisLine={{ stroke: gridLineColor, strokeWidth: 1.5 }}
            tick={{ fill: tickLabelColor }}
            tickLine={{ stroke: gridLineColor }}
            domain={[Math.max(0, minValue), maxValue]} 
          />
          <Tooltip content={CustomTooltip} />
          <Line 
            animateNewValues 
            animationDuration={500} 
            isAnimationActive={isAnimationActive} 
            strokeWidth={1.5}
            
            dot={{ fill: OCEAN_BLUE, stroke: 'none' }}
            type="monotone" 
            dataKey="value" 
            stroke={OCEAN_BLUE} 
          />
      </LineChart>
    </ResponsiveContainer>

    </My.Container>
  )
};

EntityQuarterlyGrowthChart.defaultProps = EntityQuarterlyGrowthChartDefaultProps;