import React from 'react';
import { IconComponent } from '@shapeable/types'

export const WorldMap: IconComponent = ({ className }) => (
  <svg className={className} viewBox="0 0 705.5 347.7" preserveAspectRatio="xMidYMid meet">
    <path d="M626.4,234.6c0,0.5-0.9,0.8-2.4,1.4c-1.4,0.6-4.1-0.7-4.2,0.2c-0.1,0.5,1.6,1.4,3.2,1.4c1.6,0,4.1-1.6,4.1-2
    c0-0.5,1-1.3,0.6-1.7C627.2,233.4,626.4,234.1,626.4,234.6"/>
    <path d="M540.7,230.6c0-0.5-1.5-0.3-1,0.5C540.1,231.8,540.7,231.1,540.7,230.6"/>
    <path d="M583.8,248.4c0.4,0.3,2.6-0.1,2.5-0.6C586.2,247.3,583.1,247.9,583.8,248.4"/>
    <path d="M608.1,113.9c0.5,1-0.6,6.6-0.2,7.5c0.5,0.9-0.8,5-0.3,5.5c0.7,0.8,0.2-1.1,1.3-1.3c1-0.2,1,1.2,1.7,1.4
      c0.6,0.3,0.2-1.9-0.3-1.7c-0.6,0.1-1-1.4-1.3-2.3c-0.3-0.9,0.6-2,0.6-3.2c0-1.2,1.4-1.1,2.3-0.2c0.9,0.9,1,0.3,0.6-0.3
      c-0.3-0.6-1.4-5.3-2.1-6.9c-0.6-1.7,0.9-3.3-0.2-4.2c-1.2-0.9-0.7-2.7-1.3-2.3c-0.6,0.3,0,1.9-0.9,2c-0.9,0.1-0.1,1-0.4,2.1
      C607.1,111.2,607.6,112.9,608.1,113.9"/>
    <path d="M594.4,151.4c0.1-0.5-1-0.2-2.4,0.3c-1.3,0.5-2.1-0.6-2.9,0.2c-0.8,0.8-2,2.1-3,2.3c-1,0.2-0.4,1.4,0,1.2
      c0.7-0.3,1.8,0,2.1-0.5c0.3-0.5,3.2-1.2,4.9-1.2c1.7-0.1,0.7,0.8,0.8,1.7c0.1,0.9,1.6,0.8,2.3-0.1c0.8-0.9,1.3-0.8,0.7-1.3
      c-0.6-0.5,0.1-1,0.6-0.3c0.5,0.7,1.9,0.8,2.5-0.2c0.6-0.9,0.8,0.2,1.4,0.1c0.7-0.1,1.1-1.8,1.2-1.2c0.1,0.6,1.2,0.5,1.9-0.3
      c0.7-0.8,0.1-2.1,0.5-2.8c0.5-0.7,0.9-2.1,0.5-3c-0.5-0.9,1-1.4,1.8-2.1c0.8-0.7-0.9-4.2-0.9-5.2c-0.1-1-1.9,0.4-2.5,0.7
      c-0.6,0.3-1,2.1-0.3,2.6c0.6,0.5-1,1.5-1,2.6c0.1,1.1-1,1.1-1.2,1.9c-0.2,0.8-0.8,0.6-1.8,1.4s-1.5-0.1-1.3-0.6
      c0.2-0.5-1.5,0.3-1.4,1.6c0.1,1.3-1.6,1.4-1.2,2C596,151.6,594.3,151.9,594.4,151.4"/>
    <path d="M604.7,134.1c-0.7,0.1,0.1,0.8-0.8,1.2c-0.8,0.3-0.8,0.9-0.3,1.2c0.4,0.2,0.1,1,0,1.5c-0.1,0.5,0.5,0.1,1-0.4
      c0.5-0.5,0.9,0.2,1-0.2c0.1-0.6-1.2-0.9-1.2-1.4c0.1-0.6,0.9,0.2,1.6-0.2c0.7-0.3,2.1-0.1,3,0.7c0.9,0.8,1.1,0.5,1.3-0.5
      c0.2-0.9,1.5-1.3,2.8-1.3c1.3,0,1.4-0.6,1-0.9c-0.4-0.3,2.2-2,2.1-2.4c-0.1-0.5-1.4,1-1.8,0.5c-0.4-0.5-1.1,0.3-2,0.5
      c-0.9,0.2-3.6-2.1-4.3-3.1c-0.7-1-1.9-0.2-1.3,0.6c0.6,0.8-0.1,1-0.1,2c0,1-0.7,1-0.6,1.7C606.4,134.1,605.4,133.9,604.7,134.1"/>
    <path d="M590.1,157.3c0.2-0.7,1.1-0.9,1.7-0.6c0.6,0.3,1.3-0.9,1.3-1.7c0-0.9-1.7-0.9-2-0.2c-0.2,0.6-0.9,0.3-1.9,0.5
      c-0.9,0.2-1,2.4-0.3,2.6C589.5,158,589.9,158,590.1,157.3"/>
    <path d="M617.9,238.4c1,0.1,1.2-0.5,0.4-1.3c-0.8-0.8-3.8-1-3.9-2.1c-0.1-1.1-5.4-3.7-7.7-4.2c-0.4-0.1-0.9-0.2-1.4-0.4
      c-2.5-0.8-6-2.4-6.7-2.4c-0.9,0.1-1,1.6-1.7,1.6c-0.8,0-1.8,1.6-3.2,2c-1.4,0.5-1.4-4-2.1-4.9c-0.6-0.9-4.1-1.6-4.5-0.6
      c-0.3,1-1.6,0.5-1.7,1.3c-0.1,0.8,0.3,0.5,1,0.5c0.8,0,0.8,0.6,1.1,1.3c0.3,0.8,2.4,0.2,3.1,0.2c0.7-0.1,0.8,0.9-0.2,0.6
      c-1-0.3-1,0.5-1.7,0.3c-0.7-0.2-1.6-0.1-1.2,0.3c0.5,0.4,1.2,0.8,1.2,1.7c0,0.9,1.3,0.8,1.3,0c0-0.8,0.7,0.2,2,0.6
      c1.3,0.3,0.4,0.9,1.7,0.9c1.3,0.1,4.3,1,5.5,1.9c1.2,0.9,0.3,1.4,1.1,2.3c0.8,0.8,1,1.7-0.1,1.7s-2.1,1-1.9,1.6
      c0.3,0.5,3.7-0.2,4.5-0.2c0.5,0,1.1,0.9,2.1,1.6c0.5,0.4,1.2,0.6,2.1,0.7c2.5,0.1,1.9-1.3,2.4-1.3c0.5,0,0.6-0.8,1.2-1.4
      c0.5-0.6,2.8-0.1,4.1,0.9c1.3,1,2.9,4.1,4.3,3.8c1.4-0.3,2.8,0.8,4.1,0.8c1.3-0.1,0.1-1-0.3-1.2c-0.4-0.2-1.4-0.9-1.4-1.3
      c0.1-0.5-1.2-0.4-2-0.9c-0.8-0.5-0.5-2.2-1.4-2.6C617.4,239.6,616.8,238.3,617.9,238.4"/>
    <path d="M636.1,238.4c0,0.2,1.3,1.6,1.6,1.3C638,239.5,636.2,238,636.1,238.4"/>
    <path d="M642.3,243.7c0.2,0.9,1.5,1.4,2,1.1C644.8,244.5,642.1,242.8,642.3,243.7"/>
    <path d="M642.6,241.9c0.5-0.3-2.7-2-2.7-1.5C639.8,240.9,642.1,242.2,642.6,241.9"/>
    <path d="M629.8,281.8c0.3-0.3-0.2-1.6-0.5-1.4c-0.3,0.2-0.3-2.2-0.1-3.3c0.2-1.1,0.1-1-0.5-0.4c-0.6,0.6-1.2-1.1-1.3-1.5
      c-0.1-0.4-0.8-1.6-1.5-1.5c-0.7,0.2-0.3-1-1-0.9c-0.7,0.1-0.8-1-0.7-2c0.1-1-0.5-0.2-0.8-0.7c-0.3-0.4-0.7-0.1-1.1,0.1
      c-0.5,0.2-0.6-0.9-0.5-1.3c0.1-0.4-0.1-1-0.7-1.4c-0.7-0.5-0.7-0.9-0.7-1.4s-3.7-2.5-4.5-2.6c-0.8-0.2-0.1-1-0.5-1.3
      c-0.3-0.3-0.5-1.4-0.5-2.4c0-1-1.2-1.3-1.2-2c0-0.8-0.3-1.5-0.3-2.4c0.1-0.9-1.3-1.1-1.3-1.6c0-0.5-0.8,0-1.5,0
      c-0.7,0.1-0.7-1-0.6-2c0.1-1-0.3-2.6-0.9-3.1c-0.6-0.5-0.7-2.1-1-2.4c-0.3-0.3-1.3,0.9-1.3,1.4c0,0.6-0.1,1.2-0.5,1.6
      c-0.4,0.3,0.3,1.7-0.3,2.2c-0.5,0.5,0,2.4-0.2,3.5c-0.1,1.1-0.1,2.3-0.6,3s-0.6,1.9-1.7,2.3c-1,0.4-2.4-0.4-2.4-1
      c0-0.6-1.1-0.9-1.7-0.8c-0.6,0.1-1-0.8-1.9-1.5c-1-0.7-1.7,0.2-2-0.7c-0.3-0.9-0.6-1-1.3-1.5c-0.7-0.5-0.5-0.5,0.2-1
      c0.7-0.5,0.5-1.1,0.2-1.6c-0.3-0.6,0.4-0.4,1.3-0.9c0.9-0.5,0-1,0.7-1.3c0.7-0.3,0.3-0.8-0.4-1c-0.7-0.1-0.5,0.7-0.7,0.8
      c-0.3,0.1-0.5-1.1-0.7-1c-0.2,0-1,1.2-1.5,0.6s-2.9-1-3.8-1.2c-1-0.1-1.3-1.3-1.8-1.1c-0.6,0.2,0.1,0.6,0.7,1.3
      c0.7,0.7-2.2,1.2-3,0.9c-0.8-0.3,0.1,0.6-0.6,0.6c-0.7,0-0.8,1.2-1.4,1.8c-0.6,0.6-0.2,1-0.8,1.4c-0.6,0.4-0.7,0.8,0.1,1.6
      c0.7,0.8-0.3,0.9-1,0.2c-0.7-0.7-1.3-0.7-1.3,0.1c0,0.7-0.8,0.4-0.7-0.4c0.1-0.8-0.9-0.4-1.1-0.9c-0.2-0.5-1.1-1.3-1.7-1.2
      c-0.6,0-0.6,0.7-1.1,0.7c-0.6,0-0.7,0.3-0.6,0.9c0.1,0.6-0.9,0-1.5,0c-0.7,0.1,0,0.8-0.3,0.9c-0.3,0-0.3,0.7-0.9,0.7
      c-0.6,0-0.5,1.3-0.4,1.9c0.1,0.7-0.9-0.3-1.4-0.1c-0.6,0.2,0.1,0.7,0.3,1.5c0.2,0.7-0.3,0.7-0.6,1c-0.3,0.3-0.8-0.8-0.8-1.5
      c0-0.7-0.7,0-1.5,0.8c-0.8,0.8,0.3,2.1-0.1,2.3c-0.3,0.2-1.9,1.7-2.8,2.8c-0.9,1.1-2.9,0.4-3.5,1s-1.3,0.1-1.9,0.6
      c-0.6,0.5-1.7,1.4-1.9,0.8c-0.1-0.6-1.7-0.1-2.3,0.6c-0.7,0.7-1.7,1.3-2.4,1.3c-0.7,0-0.7,1.2-1,1.4c-0.3,0.3-0.2-0.9-0.2-1.2
      c0-0.3-0.7,0.5-1,1.4c-0.3,0.9,0.7,1.3,0.1,2.2c-0.7,1-1.3,1.9-0.3,3.1c0.9,1.2,1.4,2,1.1,2.6c-0.3,0.6-1-1.2-1.2-1
      c-0.2,0.2,0.8,1,0.6,1.4c-0.3,0.4-1.2-1.5-1.4-1c-0.2,0.4,2,3.3,2,4.4c0,1.1,1.7,2.1,1.7,3.7c-0.1,1.6,1.5,4.1,1.7,4.5
      c0.3,0.4-0.6,1.4-0.4,2.6c0.2,1.2-0.4,1.6-1.1,1.6c-0.7,0-0.3,1.2,0.5,1.3c0.8,0.1,0.5,0.7,1.4,1.3c0.9,0.6,3.1,0.4,3.8,0.4
      s1-1.1,2-1.2c1,0,0.9-0.3,1.4-1c0.5-0.6,2.7-0.4,5-0.3c2.3,0.1,3.3-0.6,3.6-1.5c0.3-0.8,2-0.9,2.6-1.5c0.7-0.6,1.2-0.7,2.9-0.6
      c1.7,0.1,3.3-0.8,4.3-1.3c1.1-0.4,3.1-0.3,4.1-0.5c1-0.3,1.2,1.3,2.4,1.2c1.2-0.1,1.7,0.3,2.3,0.3c0.6,0,0.5,0.4,1.1,0.6
      c0.6,0.1,0.5,0.7,0.1,1.2c-0.4,0.6,0.9,0.5,1.7,1.6c0.8,1.1,0,1.3,0.5,2.2c0.4,0.9,0.7,0.7,1.6-0.6c0.9-1.2,1.8-0.4,2-1.5
      c0.1-1,1-2.3,1.7-1.7s-0.4,0.8-0.8,2.8c-0.4,2-1.1,1-1.2,1.5c-0.1,0.6,1.8,0.9,1.8-0.2c0-1,0.3-1.5,1.1-0.6c0.7,1-0.4,1.7-0.3,2.2
      c0.2,0.4,1-0.1,1.4-0.1c0.8,0.1,1.8,1.8,1.7,2.7c-0.2,0.9-0.4,1.8,1,2.5c1.5,0.7,0.9,0.9,2,0.9c1.1,0,3.3,0.9,4,1.4
      c0.6,0.5,2.2-0.4,2.5-1.4c0.3-1,1.4,0,0.8,0.3c-0.6,0.3,0.3,1.2,0.3,0.6c0-0.6,0.3-1,0.5-0.4c0.2,0.7,1,0.7,1.3,1.2
      c0.3,0.5,0.7,0.9,0.7,0.5c0-0.4,0.2-0.8,0.9-1.1s1.1-0.7,1.9-1.3c0.7-0.6,3.8-0.7,4.1-0.7c0.3,0,0.5-2.6,0.7-3.9
      c0.2-1.3,1.3-1.6,1.5-3c0.2-1.4,1.7-4.1,2.1-4.2c0.4-0.1,1.1-0.5,1.2-1.3c0-0.8,1-1.7,1-2.7s0.6-2.2,0.5-2.5s-0.4-1.3,0.2-2
      C630.4,282.9,629.5,282.1,629.8,281.8"/>
    <path d="M644.3,241.8c-0.4,1,1.3,2.6,1.6,2.6C646.1,244.5,644.6,241,644.3,241.8"/>
    <path d="M579.6,231.3c0.2,0.7,1.4,0.4,2.7,0.4c1.2,0,1.9,0.9,2.5,0.9c0.6,0,0.2-1.5-1.3-2C582,230.2,579.4,230.6,579.6,231.3"/>
    <path d="M584,156.3c-0.9,0.6-1.2,0.8-0.8,1.4c0.3,0.6,0.9,0.1,1.1-0.6c0.2-0.6,0.8,0.9,0.3,2c-0.5,1,0.1,2.5,0.8,2.4
      c0.6-0.1,1.3-2.2,2.2-3.3c0.9-1.1-0.4-0.7-0.4-1.3c0-0.6-1.3-0.5-1.3-1C585.8,155.4,584.9,155.7,584,156.3"/>
    <path d="M632.4,236c-0.1,0.5,1.7,3.3,2.4,2.8C636.1,238,632.5,235.4,632.4,236"/>
    <path d="M579.9,222.3c0-0.4-0.4-2-1.3-0.8c-0.9,1.3,0.3,4.4,1,4.3c0.6-0.1-0.5-1.7,0.8-1.6c1.3,0.1,0.7-0.6,0.7-1.4
      C581.1,222,579.9,222.7,579.9,222.3"/>
    <path d="M571,245.3c0.7,0.4,1.8-0.7,2.5-1.4c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.2-0.1
      c1.1-0.7,4-1.5,4.1-1.9c0.2-0.5-1.7,0.1-2.9,0.1c-0.8,0-1.1,0.6-1.7,1.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
      c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.6,0.2C570.8,243.6,570.2,244.9,571,245.3"/>
    <path d="M563.4,244.8c0.4,0.3,1.9,1.1,1.9,0.1c0-1-4-1.6-3.6-1C562,244.5,563,244.5,563.4,244.8"/>
    <path d="M615.7,311.3c-1.2,0.1-2.9-1.1-3.3-1c-0.5,0.1,1.5,3.1,0.9,3.9c-0.6,0.8,1.8,2.9,2.6,3c0.8,0.1,0.5-0.8,1.1-0.8
      c0.6,0,0.3-0.6,0.4-1c0.1-0.4,0.7,0.5,1.1,0.2c0.5-0.3-0.1-2.1,0.6-2.1c0.7,0,0.1-2.3-0.1-3C618.8,309.8,616.9,311.1,615.7,311.3"/>
    <path d="M565.4,180.4c0.6-0.4,3-5.4,2.4-6.1c-0.5-0.7-2.8,0.4-3.5,2.7C563.8,179.3,565.3,180.5,565.4,180.4"/>
    <path d="M570.4,241.8c1-0.1,2.2-0.3,0.6-0.6c-1.6-0.2-2.6,0.9-4.2,0.3c-1.6-0.6-3.9,0-3.6,0.5c0.2,0.5,1.6,0.5,3.2,0.6
      C568,242.7,569.4,241.9,570.4,241.8"/>
    <path d="M541.8,223.6c0.9,0.8,0.6,2.1,1,2.7c0.4,0.6,1.7,0.8,1.7,2c0,1.2,1.1,3.1,1.9,2.6c0.8-0.5,1.3,0.2,1.6,0.8
      c0.2,0.5,2-0.4,2.6-0.3c0.6,0.1,2.4,0.5,2.5,1.2c0.1,0.7,1.7-0.1,2.8-0.5c1.1-0.4,0.5-3.7,1.5-4.3c1-0.6,1.3-1.9,1.2-3
      c-0.1-1.2,2.5-1.2,3-1.7c0.4-0.5-0.6-1.2-1.3-1.6c-0.8-0.3-0.1-1.1-0.6-1.6c-0.5-0.5-1.4-1.7-0.6-1.7c0.8-0.1-0.5-1.1,0.1-1.4
      c0.1,0,0.2-0.1,0.3-0.1c0.7-0.3,1.7-0.8,1.2-1.2c-0.5-0.5,1.6-0.1,1.8-0.8c0.2-0.7-0.8-1.3-1.3-1.2c-0.5,0.1-1.7,0.1-1.7-0.5
      s-1.1-1.5-1.8-1.5c-0.7,0-1.5,1.4-1.6,2c-0.1,0.6-1.2,0.5-1,1.3c0,0.3-0.2,0.5-0.4,0.5c-0.4,0.1-1,0.1-1,0.4c0,0.5-0.9-0.2-1.3-0.2
      c0,0,0,0,0,0c-0.5,0-1.5,1.6-1.7,2.7c-0.2,1.1-1.3,0.8-2.8,0.9c-1.6,0.1-0.9,1.7-1.3,2.5c-0.3,0.7-2-0.6-2.9-0.7c-0.1,0-0.2,0-0.2,0
      C542.3,221,541,223,541.8,223.6"/>
    <path d="M645.8,245.6c0.1,0.2,1.2,1.3,1.8,0.9C648.2,246.1,645.7,245.3,645.8,245.6"/>
    <path d="M545.1,187.4c0.1-0.7,1.2-1.8,1-2.3c-0.1-0.4-1.7-0.2-2.4-0.2c-0.8,0-2.7,1.1-1.9,2.7C542.6,189.1,545.1,188.1,545.1,187.4"
      />
    <path d="M563.7,192.5c-0.2,0.5,0.1,2.7,0.5,2.7c0.5,0,0.8,0.6,0.5,1.5c-0.3,0.9,0.9,1,2,0.6c1.1-0.4,0.7,0.8,1.8,0.6
      c1.1-0.2,2.6,1.2,2.4,1.7c-0.2,0.5,0.1,2.2,0.6,1.7c0.5-0.5,1.3,0.1,0.8,0.9c-0.5,0.8,0.8,1.5,1,2.4c0.2,0.9,0.8,0.2,0.6-0.5
      c-0.1-0.7,0.1-0.6,0.9-1.2c0.8-0.5-0.1-1.4-0.5-2.4c-0.5-1-2.2-0.6-2.2-1.6c0-0.9-1.3-1.2-1.3-1.7c-0.1-0.5-1.7-1-2.7-0.5
      c-1,0.6-0.3-1-1-1.8c-0.7-0.8,0.5-1.9,1.3-2.8c0.8-0.9-0.2-2.5-0.1-3.2c0.1-0.7,0.1-0.9-0.3-0.7c-0.4,0.2-2.1-0.7-2.8-0.2
      c-0.8,0.5-0.1,4.3-0.4,4.6C564.2,193,563.8,192.1,563.7,192.5"/>
    <path d="M558.8,241.1c-0.2,0.5-1.2,0.6-1.8,0.2c-0.6-0.4-1.3,0.5-0.9,1c0.2,0.2,1.4,1.2,2.8,0.5c1.4-0.7,2.4-0.1,2.8-0.8
      C562,241.3,559,240.6,558.8,241.1"/>
    <path d="M575.6,231.5c0.2,0.9,1.9,1.4,2.4,0.6C578.5,231.3,575.4,230.4,575.6,231.5"/>
    <path d="M564.8,198.4c0.2,0.8,0.6,2.5,1.9,2.1C567.9,200,564.6,197.8,564.8,198.4"/>
    <path d="M563,226.2c-0.5,0.3-0.5,2.1-1.6,3.8c-1,1.7,0.3,1.9,1.2,2c0.9,0.1,0.8,1.3,0.6,2.1c-0.2,0.8-0.5,2.5,0.2,2.3
      c0.9-0.3,1.5-1.5,1-1.9c-0.5-0.4-0.5-3-0.2-3.6c0.2-0.6,1.8-0.2,1.3,0.8c-0.5,1,1,0.9,1,2s0.9,0.5,1.8,0.2c0.9-0.3,0.8-0.8,0.1-1.5
      c-0.6-0.7,0.5-1.7-0.4-2.3c-0.9-0.6-1.4-1.4-0.6-1.6c0.8-0.2,3.2-1.6,3-2.1c-0.3-0.6-3.7,0.1-4,1.2c-0.3,1-2.7-0.1-2.4-1.9
      c0.3-1.8,5.1-2.1,6.5-1.4c1.4,0.6,2.9-1,3.4-2c0.5-1-1.1,0.2-2.1,0.8c-1,0.6-3.6-0.1-4.9-0.5c-1.3-0.5-1.3,0.8-2.4,1
      C563.2,223.5,563.5,226,563,226.2"/>
    <path d="M596.1,299.2c0.5,0.4,2.1,0.3,2.3-0.3C598.7,298.3,595.6,298.8,596.1,299.2"/>
    <path d="M561.2,205.1c-0.8,1-2.7,2.8-2.5,3.2c0.3,0.6,2.5-2.5,3.5-3.3c1-0.8,0.9-1.4,0.5-2.1C562.4,202.2,562,204,561.2,205.1"/>
    <path d="M574.8,206.3c0.5,0.9-1,0.2-1.1,1c-0.1,0.9-1.2,0.2-1.2,0.9c0.1,0.7-1.2,0-1.7-0.4c-0.6-0.4-1.4,1.1-2.3,1.3
      c-0.9,0.2-1.3,2.1-0.8,2c0.7-0.1,1-1,1.7-0.9c0.7,0.1,0.5-0.7,1.4-0.5c1,0.2,0.3,3.1,1.6,3.4c1.3,0.2,1.4,1,2,1
      c0.6,0-0.1-1.9-0.1-2.5c0.1-0.6,1.4-0.1,2.1-0.9c0.7-0.8-0.5-3-0.5-4.3C576.2,205,574.3,205.4,574.8,206.3"/>
    <path d="M567.9,201.3c-0.9,0.2-0.6,2.8,0.1,2.7c0.3-0.1,1,0,1-0.6c0.1-0.6,0.7,1,0.2,1.6c-0.5,0.5-0.5,2,0.3,2.1
      c0.8,0.2,2.3-2.6,2.1-3.1c-0.2-0.5-0.9,0.3-0.8-0.3C571,202.9,568.7,201.1,567.9,201.3"/>
    <path d="M572.9,205.3c-0.5-0.6-1.6,0.5-1.4,0.9C571.7,206.8,573.4,205.9,572.9,205.3"/>
    <path d="M627.8,232.5c1.2,1.1,0.5,2,1.2,1.9c1.1-0.3,0.3-1.4-0.6-2C627.4,231.8,626.6,231.4,627.8,232.5"/>
    <path d="M438.1,43.3c1.1,0.1-0.6,0.2-0.5,1.1c0.1,0.9-1.8,0.3-1.7,0.9c0.1,0.6-0.3,1-1.4,1c-1.1,0,0.5,0.7,0.6,1.3
      c0.1,0.6-1.1,0.2-1.2,1.2c-0.1,1-2.1,0-2.4,1.3c-0.3,1.3,1,1.1,2,1.2c0.9,0.1-0.3,0.9,0.3,1.4c0.7,0.5,1.2,0.3,0.8-0.6
      c-0.5-1,2.4,0.3,1.6,1.1c-0.9,0.8,1.4,1.3,2.5,1.4c1.1,0.1,5,0.9,5.1,0c0.1-0.6-1.4-1.1-2.7-2.4c-1.2-1.3-2.1-3.4-0.7-4.2
      c1.4-0.9,0.2-1.3,1.6-2.3c1.4-1,0.6-1.9,1.7-2c1.2-0.1-0.1-1.2,1-1.3c1.2-0.2,1.4-1.4,1.2-1.9c-0.2-0.5,1.2,0.1,1.9-0.6
      c0.6-0.7,2.1-0.2,2.6-1.2c0.5-1,7.1-3.2,10.9-4.1c3.8-0.9,6.4-2.4,5-3.5c-1.3-1-5.1,0.4-6,1.2c-0.9,0.8-2.3,0.2-3.2,0.8
      c-0.9,0.5-2.9,1-3.9,0.3c-1-0.6-2.1,0.8-2.9,0.7c-0.8-0.1-1.6,0.8-2.4,0.7c-0.8-0.1-2.4,0.6-2.5,1.1c-0.1,0.5-1.5,0.3-1.5,0.9
      c0,0.6-1.1,1.1-1.7,0.6c-0.6-0.5-1.3,0.6-0.5,1.3c0.8,0.8-1.5,0.8-1.1,1.3c0.4,0.5-0.9,0.8-0.6,1.3c0.3,0.5-0.8,0.7-1.7,0.9
      C437.5,42.1,437,43.3,438.1,43.3"/>
    <path d="M444.5,13.2c0.2-1.2-1.9-0.2-3.4-0.9c-1.5-0.6-2.4-0.6-1.4,0.3c0.5,0.4-2.6,0.7-2.1,1.2C438.8,14.9,444.3,14.4,444.5,13.2"
      />
    <path d="M457.9,13.8c2.2-0.2,2.1-1.4,1-1.7c-1.2-0.2-1.3,0.7-1.9,0.6c-0.6-0.1-2.8,0.4-2.3,0.9C455.2,14.2,456.8,13.9,457.9,13.8"/>
    <path d="M425.7,60.8c1.6,1.4,2.9-0.6,3.6-0.8c0.8-0.1-0.5-0.9-1.7-1.3C426.3,58.4,424.5,59.7,425.7,60.8"/>
    <path d="M428.2,14.1c0.8,0.6-2.3,0.4-2.7,1c-0.4,0.6-2.2,0.4-1.9,1c0.3,0.7,3.2,0.8,3.2,0.2c0-0.6,1.5-0.1,1.6-0.6
      c0.1-0.5,0.7-1.1,2.3-1.2c1.6-0.1,1.6-0.7,0.1-1.4C429.4,12.4,427.3,13.5,428.2,14.1"/>
    <path d="M453.7,14.1c0.4-0.5-0.8-0.5-0.9-1c-0.1-0.5-3.4-0.6-3.2-0.1c0.2,0.6-2.2,1.2-1.5,1.8C449.5,15.9,453.3,14.6,453.7,14.1"/>
    <path d="M443.8,15.2c0.2,0.5-0.3,0.5-1.4,0.5c-1.2,0-1.8,0.7-1.2,1.2c0.7,0.6,3.8,0.3,4.3-0.3c0.5-0.7,1.9-0.1,2.1-0.8
      C447.8,15,443.6,14.7,443.8,15.2"/>
    <path d="M443.5,12.1c1.4,0,1-0.9,1.9-0.8c0.9,0.1,2.1,0.1,1.7-0.5c-0.5-0.6-3.8-0.3-3.5,0.1c0.3,0.5-2.7,0.3-2.7,0.5
      C441.1,11.7,442.1,12.1,443.5,12.1"/>
    <path d="M436.3,16c0-0.9-3,0.1-2.4,0.3C434.4,16.4,436.3,16.9,436.3,16"/>
    <path d="M317.4,95.4c0.8,0,1.7-1.4,1.3-1.7C318.3,93.3,316.8,95.5,317.4,95.4"/>
    <path d="M656.7,269.9c0.7-0.5-5.8-4.7-5.7-3.9C651.1,266.7,656,270.4,656.7,269.9"/>
    <path d="M312.8,112.7c0.7,0,2.4-0.5,3.4-1.4c1-0.9,1.5,0,2.4-0.5c0.9-0.5,0.3-3.8,0-4.5c-0.1-0.1-0.1-0.2,0-0.3
      c0.1-0.4,1-0.5,1.5-0.9c0.6-0.6-0.3-1.4-1.1-2.2c-0.7-0.7-1.7,0.4-2.4,0.4c-0.1,0-0.2,0-0.2,0c-0.8-0.3-1.9-0.3-2,0.4
      c0,0.7,0.9,0.5,0.9,0.9c0,0.5-0.9,0.3-1.6,0.8c-0.7,0.5-1.4-0.3-2.1,0c-0.7,0.3,0.8,0.8,0.2,1.5c-0.5,0.7,0,0.7,0.9,1.4
      c0.9,0.7-0.5,0.9-0.5,1.6s-1.1,0.8-1.3,1.3C310.6,111.6,311.8,112.7,312.8,112.7"/>
    <path d="M328.6,89.2c0.5-0.5,1.1-2.4,0.3-2.2C328.1,87.2,328.4,89.4,328.6,89.2"/>
    <path d="M353.2,23.9c0.5,0.1,0.9,1,1.4,1c0.2,0-0.7-1.1-1.3-1.7c-0.6-0.6-0.5-1.2-1.3-1S352.7,23.7,353.2,23.9"/>
    <path d="M367,16.8c0.4,0.3-0.6,0.3-0.2,0.7c1.3,1.3,6.4-0.2,7.1,0.2c0.7,0.4-4.3,0.9-4.1,1.4c0.2,0.5,4.3,1,4.6,0.6
      c0.3-0.3,1,0.7,2.3,0.8c1.3,0.1,1.2-0.5,2.2-0.6c1,0,2.5-0.5,2.5-1.1c0-0.6,3-1.3,2.7-2.2c-0.3-1-3.1-0.3-3.9-0.7
      c-0.8-0.4-2.6-0.9-2.9-0.3c-0.3,0.6-0.8,0.8-1.1,0.5c-0.3-0.3,0.1-1.6-1.1-1.3c-1.3,0.4-0.1,1.7-0.3,1.9c-0.3,0.2-1.5-0.3-1.6-0.9
      c-0.1-0.6-1.4,0.7-1.6-0.1c-0.2-0.7-1.8-1.3-2.1-1.2c-0.3,0,0.3,0.5,0.1,0.9c-0.2,0.3-1.1-0.3-1.4-0.4c-0.3,0,0.2,0.7,0,1.1
      c-0.2,0.3-0.9-1-1.5-1s-0.4,0.7-0.8,0.7C365.6,16,366.6,16.5,367,16.8"/>
    <path d="M422.7,14.6c1.3-1.2,3.1-0.1,3.3-0.6c0.2-0.6-2.5-0.8-3.5-0.2c-1,0.5-3.3,0.1-3,0.8C419.7,14.9,421.4,15.8,422.7,14.6"/>
    <path d="M355,20.4c0.8,0.2-0.9,1.1-1.1,1.9c-0.3,0.8,1.3,1.6,2,2.2c0.7,0.6,2.2,0.5,2.8,0.2c0.6-0.3,0.4-1.6,1.1-1.5
      c0.7,0,0.3-0.7,1-0.9c0.6-0.2,0.7,0.6,0.2,1.2c-0.5,0.6,0.9,0.6,1.7-0.2c0.8-0.7,1,0,0.8,0.7c-0.2,0.7-1.8,0.3-2.3,1.1
      c-0.6,0.8-2.3,0.2-3.1,1s0.7,0.9,1.8,0.7c1.1-0.2,3.1-0.3,3.9-0.2c0.8,0.2-0.5,0.4-1.8,0.4c-1.3,0-1.7,0.4-1.7,0.7
      c0,0.3-1.8-0.4-1.9,0.4c-0.1,0.8,1.7,1.1,2,1.6c0.2,0.5,1.4,0,1.9,0.2c0.5,0.2-0.8,0.5-1,0.7c-0.2,0.2,1.3,0.5,1.6,1
      c0.3,0.4,1.3,0.2,1.3-0.4c-0.1-0.8,1-3,2.3-3.5c1.3-0.5,0.6-0.9,0.7-1.6c0.2-0.7,1.2-0.1,0.9-0.7c-0.3-0.6-0.1-0.9,0.7-1.6
      c0.8-0.8,1.3-0.2,2.3-0.7c1-0.5,1.5,0.3,0.4,0.4c-1,0.1-0.5,1.2,0.3,1.5c0.8,0.3,0.1,0.6,0.7,0.7c0.6,0.1,0.4,1-0.3,1.9
      c-0.8,0.8,0.2,1,1.9,0.4s0.6,0.5,1.1,1c0.5,0.5,1.8-0.3,2.5-1.2c0.7-0.8,1.7-0.7,1.7-1.1c0-0.5-0.8-0.6-1.4-0.3
      c-0.6,0.3-2.1,0.1-1.6-0.4c0.5-0.5,0.4-0.9-0.3-0.8s-2.2-0.3-1.5-0.6c0.7-0.3-0.5-1.1-1.1-1.1c-0.6,0-0.4-0.7-0.4-1.1
      c0-0.5-1.7-0.7-1.4-0.9c0.3-0.2-0.7-0.6-1-0.2c-0.3,0.3-0.8,0.3-0.8-0.3c0-0.5-1.3-0.4-1.8-0.3c-0.5,0-0.3-1.1-0.8-1.5
      c-0.5-0.4-1.2,0.8-1.6,0.7c-0.4-0.1,0.3-0.9,0.5-1.4c0.2-0.5-1.8-1.2-2-0.7c-0.2,0.5-0.6-0.8-1-0.9c-0.4-0.1-0.1,0.9-0.5,1
      s-0.9,0.5-0.3,0.9c0.7,0.4,1.3,2.6,1.2,3c-0.1,0.3-2.1-1.6-2.2-2.5c-0.1-0.9-1.1-1.6-1.3-1c-0.3,0.7-1,0.9-0.8,1.6
      c0.2,0.7-0.5,0.9-0.5,0.3s-1.1-1.1-1.5-1.2c-0.4,0,1-0.3,1.7-0.6c0.7-0.3-0.6-0.7-1.1-0.4c-0.6,0.3-1.3-0.2-1.6,0.3
      c-0.3,0.5-0.8,0-1.4-0.1c-0.6-0.1-1.1,0.9-1.6,0.7c-0.4-0.2-0.7,0.9,0,2C353.6,21.6,354.1,20.2,355,20.4"/>
    <path d="M318.2,96.3c-0.6,0.5,0.7,1.1,1.4,1.2c0.8,0.1-0.6,0.6-0.7,1.4c-0.1,0.8,1,0.5,1,1c0.1,0.6-1.6,0.7-1.6,1.2s1-0.3,1.4-0.5
      c0.4-0.2-0.2,1.4,0.8,1.2c1-0.2,0.8-1.6,1.1-1.5c0.4,0.1-0.2,0.8,0.1,1.4c0.3,0.6-0.8,1.5-0.7,2c0.1,0.4,2.1,0.4,2.8-0.3
      s1,0.1,0.5,0.6s-0.3,1,0.3,1.2c0.6,0.2,0.8,0.3,0.5,0.7c-0.3,0.5,0,1.3-0.2,1.8c-0.1,0.4-1.9,0.4-2,0.1c-0.1-0.3-0.8-0.1-0.6,0.3
      c0.2,0.4-0.6,0.8-0.6,1.2s1.1,0.3,1.2,0.8c0,0.5-0.8,1-1.9,1.3c-1.1,0.3,0.2,1.2,0.8,0.8c0.5-0.4,0.4,0.3,1.2,0.3
      c0.8,0,1.2,0.6,2.1,0.3c0.8-0.3,0.8,0,0.2,0.5c-0.6,0.5-1.6,0-2.2,0.3c-0.6,0.3-2.9,2.5-2.5,3.1c0.3,0.3,0.6-0.5,1.4-0.8
      c0.8-0.3,1,0.2,1.4,0.3c0.5,0.1,0.5-1,0.9-0.9c0.4,0.1,0.7-0.3,1.4-0.2c0.7,0.1,1.2-0.1,1.5-0.4c0.3-0.3,1.3,0.6,1.6,0.4
      c0.3-0.2,1.6-0.4,2.2-0.4c0.6,0,2.3-0.7,2.7-1.1c0.4-0.4-0.3-0.5-0.8-0.4c-0.6,0.1-0.5-0.6,0.1-1.2c0.6-0.6,1.6-1,1.6-1.9
      c0.1-0.9-1.9-1.5-2.1-1c-0.2,0.5-0.6,0.4-1-0.1c-0.3-0.5,0.3-1,0-1.1c-0.3-0.1-0.7-0.8-0.6-1.1c0.1-0.3-0.5-2.1-1.5-2.4
      c-1-0.2-1.1-1.6-1.3-2.4c-0.2-0.8-0.8-0.3-1.3-1c-0.5-0.6-1.3-0.3-1.8-0.3c-0.4,0,0-0.8,0.7-1.3c0.7-0.5,1.5-2.9,1.5-3.4
      c0-0.5-3-0.5-3.6-0.2c-0.6,0.3-1.2-0.4-0.7-0.6c0.5-0.2,1.5-1.2,1.5-1.6c-0.1-0.4,0.7-0.8,0.3-1.2c-0.4-0.4-0.5,0.5-0.9,0.8
      c-0.4,0.3-1.2,0.2-2.1,0c-0.9-0.2-1.2,1.1-1.2,1.6c0,0.5-1,0.9-0.9,1.3c0.2,0.5-0.2,0.8-0.5,0.5C319.1,96.5,318.8,95.8,318.2,96.3"
      />
    <path d="M701.9,78.9c-0.4,0.3-3,0.2-2.4,0.8c0.5,0.5,2.7,0.2,3.6,0.8c1,0.6,2.4,0.1,2.4-0.3C705.5,79.6,702.3,78.6,701.9,78.9"/>
    <path d="M469.1,46c0.8,0.1,2.8-0.3,3-0.7c0.2-0.4-0.8-0.9-2.1-0.9C468.6,44.4,468.4,45.9,469.1,46"/>
    <path d="M616.2,38.1c1.4,0.1,2.1,1.4,4.7,1.6c2.7,0.2,4.6-0.3,4.7-0.9c0.1-0.6-2.4-1.4-3.2-1c-0.8,0.5-1.3-0.8-2.3-0.5
      c-0.9,0.3-2.2,0.2-2.7-0.6C617,35.9,615.5,38,616.2,38.1"/>
    <path d="M680.8,54.3c1.2,0.6,1.9-0.7,2.8-0.1c0.9,0.6,3.2-0.3,4.1-0.5c0.9-0.2,0.5-1.3-2.1-1.7C682.9,51.7,679.6,53.6,680.8,54.3"/>
    <path d="M677.2,261.3c0.7,0.7,2.2,0.7,2.3-0.6C679.5,259.4,676.6,260.7,677.2,261.3"/>
    <path d="M657.7,325.8c0.3,0.1,1.3-0.2,0.7-0.9C657.9,324.3,657,325.6,657.7,325.8"/>
    <path d="M670.3,310.9c-0.6-0.4-0.9,0.2-1.6,0.5c-0.7,0.3-1.1-1.4-1.9-0.8c-0.8,0.7-0.1,0.9-1,1.7s-0.5,1-1.6,2.7
      c-1.1,1.7-4.8,2.6-5.4,3.6c-0.6,0.9-2.4,1.6-2.5,2.3c0,0.7-1.2,1.2-0.5,2.1c0.5,0.7,1.2,0.2,1.8,0.8c0.6,0.6,1.1,0.1,1.4,0.6
      c0.3,0.5,2.2,0.5,3.6-0.8c1.4-1.3,0.7-1.9,1.3-2.3c0.6-0.3,0.7-1.4,1-2c0.3-0.7,0.6-1.3,2.2-1.3c1.6,0,0.7-0.3,0.7-1
      c-0.1-0.7,1.3-0.9,1.3-1.8c0-1,1.9-1.8,1.5-2.6C670.1,311.7,670.9,311.3,670.3,310.9"/>
    <path d="M677.4,303.9c-0.4,0.7-1.2-0.2-1.9-0.2c-0.7,0-1.2-0.5-1.2-1.2c0-0.7-0.2-1.6-0.9-1.6c-0.7,0,0.2,0.7,0,1.1
      c-0.3,0.3-0.4-0.4-0.8-0.4c-0.3,0-0.7-0.5-0.7-1.3s-0.7-0.8-0.7-1.8s-1.6-1.8-2.4-1.8s0.2,1.6,0.6,2.1c0.4,0.5,1.4,1,1.3,1.4
      c0,0.4,0.7,1.4,1.2,1.6c0.5,0.2-0.3,1.7-0.3,2.9c0,1.2-1,1-1.6,1.8c-0.7,0.8,1.7,1.6,2.6,2.3c1,0.8-0.8,2.7-0.6,3.1
      c0.1,0.2,0.8,0.5,1.8,0.3c1-0.3,1.5-2.4,2.2-3c0.7-0.6,0.3-1.6,0.6-2c0.3-0.5,0.8-0.4,1.3-0.4c0.5,0,0.2-1.2,0.8-1.2
      c0.6,0,0.3-0.8,0.7-1.7C679.6,302.9,677.7,303.2,677.4,303.9"/>
    <path d="M679.9,259c0.5,0.2,2-0.3,2-1.1S679.2,258.7,679.9,259"/>
    <path d="M507.8,12.7c0.3,0.3,2.4,0.5,2.6-0.3C510.6,11.5,506.8,11.8,507.8,12.7"/>
    <path d="M515,18c0,0.6-1.9,1.3-1.3,1.7c0.5,0.4,0.9,0.3,1.4,0.4c0.6,0.1,0.9,1.6,1.9,1.3c1-0.2,3.9,1.2,5.6,1.2
      c1.7,0,1.9-1.4,1.3-1.4c-0.5-0.1-0.1-0.9,0.9-2.2c0.9-1.3-2.9-2.3-2.9-1.3c0,1-1.1-0.2-1.4-0.9C520.1,16.3,515,17.4,515,18"/>
    <path d="M511.9,16.4c1.1,0.5,0.7,0.8-0.5,0.8c-1.2,0-2.2,0.4-1.4,0.7c0.6,0.2,0.5,0.9,1.7,1.2c1.2,0.3,2.4-0.7,2.3-1.4
      c-0.1-0.7,4.2-1.3,5.4-1.6c1.2-0.3-0.8-1.5,0.5-1.6c1.3-0.1,0.8-0.7-0.8-0.9c-1.6-0.2-0.9-1.6-1.4-2c-0.5-0.5-0.8,0.6-2.8,1
      c-2,0.4-3,0.9-2.2,1.3c0.8,0.4,0,1.4-0.9,1.3C511,15,510.8,16,511.9,16.4"/>
    <path d="M486,46.9c0.9,0.5,1.6,0.3,1.5-0.6C487.4,45.3,485.3,46.5,486,46.9"/>
    <path d="M598.6,38.1c0.8,0.4,1.1,0.8,1.6,1.3c0.5,0.5,2.2-0.2,2.9-0.5c0.7-0.2,0.8,1,1.9,0.3c1.1-0.7,2.2-0.3,3.5-0.3
      c1.3,0-0.2-1.6-0.2-2.3c0.1-0.7,1.5-0.2,1,0.3c-0.5,0.6,0.7,1.9,2.5,1.8c1.9-0.1,0.5-1.3,1.4-1.7c0.9-0.4,0.8-0.9-0.5-1.7
      c-1.3-0.8-3.1-0.3-4.2-0.8c-1.2-0.6-2.7-0.6-2.8,0.7c-0.1,1.3-2.5-1.4-3.8-1.8C600.7,33.1,596.7,37.2,598.6,38.1"/>
    <path d="M610.7,45.5c1-0.2-0.8-2.1-2.5-2.4c-1.8-0.3-3.5,1.2-3.2,1.5C605.5,45.4,609.7,45.8,610.7,45.5"/>
    <path d="M523.9,26.2c0.8,0.5,3.4-0.6,5.2-0.5c1.7,0.1,6.6-1.3,6.8-2.3c0.2-0.9-1.8-0.9-2.5-1.7c-0.8-0.8-2.4-0.1-2.8,0.6
      c-0.4,0.7-0.9,0.1,0.2-1c1.1-1.1-1.1-2-1.2-1.3c-0.1,0.7-2.1,0.1-2.1,0.8c0,0.6-0.8,0.9-0.7,1.6c0.1,0.6-1.4-0.3-1.4,1.1
      C525.3,24.9,523.1,25.7,523.9,26.2"/>
    <path d="M606.1,41.7c0-0.9-3.2,0.3-1.9,0.8C604.9,42.7,606.1,42.6,606.1,41.7"/>
    <path d="M552.6,241.8c0.4-0.6,1.2,0.9,2.3,0.1c1.2-0.9-1.9-0.5-2-1.2c-0.1-0.7-2.3-0.1-3.4-0.5c-1.1-0.5,1.2-0.9,2-1.3
      c0.8-0.5-0.5-0.6-2.3-0.1c-1.9,0.6-4-1.3-4.1-0.5c-0.1,0.8-3.4-0.3-4.2-0.9c-0.8-0.6-3.9-1.3-4.6-0.2c-0.6,1.1-1.2,0.1-0.9,1.2
      c0.2,0.7,0.9,0.3,1.4,0.3s0.3,1,1,1.1c0.8,0.1,3.2,0.8,3.5,0.3c0.2-0.5,2.5,0,3.7,0.9c1.2,0.9,3.8,1,4.9,0.8
      C551,241.5,552.2,242.3,552.6,241.8"/>
    <path d="M96.3,36.1c1.6,0.2,3.1-1.2,3-2C99.2,33.3,95.5,36,96.3,36.1"/>
    <path d="M85.8,50.3c1.6,0.3,1.7,3.1,2.8,3.1c0.7,0,0.2-0.9,1.3-0.9c1.1,0,0.9-0.6,1.9-0.5c1,0.1,1.9-0.3,1.9-1.5
      c0-1.2,0.8-1.6,1.8-1.9c1-0.3,0.1-1.6,1.6-1.9c1.5-0.3,5.6-2.1,6.3-2.6c0.7-0.5-0.9-1.2-2.4-2.2c-1.6-1-2.8-1.2-4-0.4
      c-1.2,0.8-0.3-0.9-1.2-0.4c-1,0.4-3.6-0.7-3.9-1.1c-0.3-0.4-5,1-6.2,1s0.1,1.2,0.9,1.8c0.8,0.6-1.9,2.5-1.4,3s-0.5,1.6-1.7,3
      C82.2,50,84.3,50,85.8,50.3"/>
    <path d="M89.1,33.6c0.6,0.3,0,1.5,0.7,1.1c0.7-0.3,0.9-0.4,1.4-0.3c0.9,0.3,1.1-0.5,1.6-1c0.4-0.4,0.3,1.6,1.4,1.6
      c1,0,0.8-2.1,1.7-1.5c0.9,0.6,1.1-0.3,1.3-0.9c0.2-0.6,0.2-1.6,1.1-1.8c1-0.2,0.2,1,0.6,1.7c0.6,1.1,1.7,0.1,1.8-0.4
      c0.1-0.5,1.7-0.2,1.6-0.8c-0.1-0.6,0.4-0.8,0-1.5c-0.4-0.7,0.7-0.5,1.2-1s-0.7-0.1-1.6-0.9c-0.9-0.8-1.2,0.3-1.2,0.8S99,29,98,28.6
      c-1-0.3-2.2,1.1-3.6,1.8c-1.4,0.7-1.5,1.9-2.8,1.8C90.4,32.2,88.4,33.4,89.1,33.6"/>
    <path d="M102.6,33.2c-1.2,0.1-1.1,1.3,0.3,1.3c1.4,0,1.8,0.4-0.2,0.3c-2-0.1-2.9,1.6-1.5,1.3c1.4-0.3,3-0.3,2,0
      c-1,0.3-3.9,0.6-3.5,1.1c0.3,0.3,1.4,0.3,2.5,0.7c1.1,0.4,1.8,0.7,2.7-0.3c0.9-1,2-1.9,1.6-0.6c-0.4,1.3,1.4,0.3,3.7,0.3
      c2.3-0.1-0.4,1.4-2.9,1.6c-2.4,0.2-2,1.4-0.1,1.8c1.9,0.4,6-1.2,6.9-2.2c1-1,1.8,0.5,2.8-0.2c1-0.7,2.8,0.1,4.4-0.3
      c1.6-0.3,1.9-3.5,0.7-4c-1.2-0.5-1.2,0.6-2.2,0.8s-1.5-1-2.3-1.8c-0.8-0.8-0.3-2.3-1.4-2.1c-1.1,0.2-3.6,2-2.1,2.2
      c1.5,0.2,1,1,0.2,1.3c-0.8,0.3,1.8,1,1.5,1.5c-0.3,0.5-4.3,0.1-4.5-0.8c-0.2-0.9-3.3-2.4-4.6-2.1s-0.8-1-2.1-1.1
      S103.8,33.1,102.6,33.2"/>
    <path d="M107.1,27c0.4-1-2.9-0.9-2.6-0.5C105.1,27.2,106.7,28,107.1,27"/>
    <path d="M213.2,335.7c-1-0.2-3.2,1.8-2.3,2.3C212,338.5,214.1,335.9,213.2,335.7"/>
    <path d="M301.3,76.1c1.1-0.1,1.1-1.3,1.6-1.2c0.5,0.1,1.2-0.9,1.5-1.4c0.3-0.6-0.3-1.6-1-1.6c-0.7,0-1.2-0.6-1.1-1
      c0.1-0.4-0.4-0.9-0.3-1.2c0-0.3-0.9,0-1.3,0.1c-0.5,0.1-1.4-1.1-1.9-0.9c-0.4,0.2-0.1,0.8-0.2,1.2c-0.1,0.3-1.2-0.3-1.2,0.1
      c0,0.4-0.4,0.5-0.8,0c-0.3-0.4-1.2-0.2-1.1,0.2c0,0.3-0.3,0.1-1-0.3c-0.7-0.4-1.4,0.5-1.3,0.9c0.1,0.4-0.2,0.5-0.8-0.4
      c-0.5-1-1.2,0.1-0.9,0.6c0.3,0.5,0.2,1.2-0.3,0.9c-0.4-0.3-1,0.2-1.3,0.6c-0.3,0.4-1.2-1.2-0.5-1.6c0.7-0.4-2.1-2-3-2
      c-0.9,0-0.3,0.6,0.3,1.1c0.6,0.5-0.4,0.3-0.7-0.1c-0.3-0.4-0.9-0.1-1.3,0.5c-0.3,0.6-0.9,0.9-1,1.3c-0.1,0.4,1,0.8,1.9,0.5
      c0.9-0.4,1.9-0.4,2.4,0c0.5,0.4-0.8,0.6-0.4,1c0.5,0.3,0.1,0.6-1,0.6c-1.1-0.1-2.4,0.2-2.2,0.6c0.2,0.4,2.8-0.3,2.8,0.1
      c0,0.4,0,1,0.7,0.7c0.7-0.3,0.2,0.5,0.6,0.7c0.3,0.1,0.3,0.8-0.7,0.9c-0.9,0.1-1.4,0.2-1,0.6c0.5,0.5,1.5,0.1,2.5-0.1
      c1-0.2,2.4,1,3.6,1.4c1.2,0.5,3.2-0.2,3.4-0.8c0.2-0.6,1.8-0.4,2.4-0.8C299.3,76.7,300.1,76.1,301.3,76.1"/>
    <path d="M213.5,338.1c0.7,0.3,3.5-0.8,3.2-1.5C216,335.3,212.8,337.8,213.5,338.1"/>
    <path d="M256.2,343.5c0.2,0.8,0.9,0.4,1.7,1.1c0.8,0.7,1.4,1.9,2.2,0.8C260.8,344.2,256.1,343.1,256.2,343.5"/>
    <path d="M107.7,24.7c0.7,0.5,1.5-0.2,2.3,0.2c0.9,0.3,1.7-0.8,2.3-0.3s3.3,1,3.3-0.4c0-1.4-3.4-1.6-4.3-1
      C110.6,23.7,106.6,23.8,107.7,24.7"/>
    <path d="M140.1,26.6c1.3,0,2.3-0.2,3.5-0.6c1.1-0.4-0.6-0.4,0.2-1.5c0.8-1-1.9-1.1-2.1-0.8c-0.2,0.3-3.6-2.1-4.1-1.3
      S138.8,26.6,140.1,26.6"/>
    <path d="M136.5,31.7c-0.1,0.9-2.1-0.2-3.6-0.8c-1.6-0.6-0.6,1.2,0.6,2.3c1.2,1-0.6,0.5-2.3-0.6c-1.7-1.1-1.5,0.8-0.7,1.4
      c0.8,0.6-0.2,1.5-1.2,0.1c-1-1.4-1.6-2.9-3.2-3s-0.9,1.6-0.3,2.4c0.9,1.1,1.7,1.4,3.1,2.2c1.4,0.8,3.6-0.3,4.7-0.1
      c1,0.3-1.1,1.6-0.4,2.4c0.7,0.9,2.6,0.3,4.1,0.2c1.5-0.1,0.8-1,1.6-1.6c0.9-0.6-0.9-0.5-0.2-2C139.4,33.2,136.6,30.9,136.5,31.7"/>
    <path d="M151.7,9.4c1.2-0.8,2.2-0.3,1.1,0.3c-1,0.5-0.7,0.9,0.8,0.8c1.5-0.1-0.6,0.4-0.6,1.3c0,0.9,1.7,0.3,1.8,1.2s3.8,1.2,5,0.2
      c1.1-1,0.7,0.3,0.6,1c-0.1,0.7,5.3,1.3,5.6,0.4c0.3-0.9,1-0.5,2.1-0.3c1,0.3,6.2-1,6.3-2c0.1-1,1.6,0.5,0,1.6c-1.7,1.1-5,1-6.4,1.4
      c-1.5,0.4,1,1.5,2.4,2.6c1.5,1.1-1.9,0.5-3.3-1c-1.4-1.5-4.5-1.5-6.2-1.4c-1.7,0.1-1.1,3.1,0.1,3.1c1.2,0,2.4,0.4,3.6,2.3
      c1.2,1.9,3.8,1.5,3.8,2.3s-3.4-0.7-5.3-1s-5,0.9-5,2.3c-0.1,1.4,2.8,1.2,4.7,0.1c1.9-1.1,0.5,0.3-0.4,1.2c-1,0.9,2.3,1.6,2.3,2.6
      c0,1-2.3,0.7-2.6-0.3c-0.3-1-1.1-1.9-3.4-1.7s-1.7,1.7-0.3,1.9c1.4,0.2,1.5,1.2-0.1,1.2c-1.6,0-5.1,1-4,2c1.2,1.1,5.6,0,6.4,0.7
      c0.8,0.7,3,1,3.5,0.2s2.3-0.4,4-0.4c1.7,0,2.2,0.3,3,1c0.8,0.7,2.2,0.4,3.1-0.3c1-0.7,1-0.7,2.2-0.7c1.2,0,1.5-0.8,0.9-1.7
      c-0.6-1-2.2,0.7-2.3,0c-0.2-0.9-1.7-1.1-4.1-0.8c-2.4,0.3-1.4-1.6,0.1-1.1c1.5,0.4,3.5,0.3,5.1-0.2c1.7-0.4,0.3-1.2,0.3-1.9
      c0-0.7,2.1-0.3,3.6-0.3c1.6,0,3.8-2.5,3.9-4c0.1-1.5-3.5-1.1-5.2-1.1c-1.7,0,1.1-1.3,4.4-1.1c3.3,0.2,1.8-1.3,2.4-1.8
      c0.6-0.5,2.2,0.6,3.8,0.3c1.7-0.3,0.5-1.7,1.7-1.7c0.8,0,2.6-1.2,6.2-3.2c3.6-2,6.3-1.6,6.5-2.8c0.2-1.1-5,0-5.8-0.3
      c-0.9-0.3,3.6-1.3,4.6-1c1,0.3,2.4,0,6-1.7c3.6-1.7,2-2.2,0.5-1.9c-1.5,0.3-3.1-0.3-3-1.2c0.1-1-2.2-0.1-2.3-0.6
      c-0.1-0.5-2.2-0.3-4.4,0.6c-2.3,0.9-0.2-0.9,0.8-1.2c1-0.3-5.7,0-6.9-0.8s-2.4,1.3-3.5,0.3c-1-1-3.7-0.2-3.4,0.6
      c0.3,0.8-0.4,0.8-1.5-0.1c-1-0.9-3,0.1-4.9-0.2c-1.8-0.3-1,1.5-3,0.6c-2-0.9-4.2-0.4-3.6-0.2c0.6,0.3-0.2,0.8-1,0.5
      c-0.9-0.3-1.7,0.1-1.2,1c0.4,0.9-3.2-0.3-3.2,0.7c0,1-1.3,1.5-2.3,0.6c-1-0.9-4.3-1.1-3.4-0.3c1,0.8-3,0.3-1.9,1.1
      c1,0.8-1.3,1.4-1.3,1c0-0.4-2.2-1.2-3-0.4c-0.9,0.8-1.1,1.7-1.6,1.2c-0.4-0.5-2.3-0.3-4.2,0.3C148.4,9.7,150.5,10.2,151.7,9.4"/>
    <path d="M133.9,17.1c0.6,0.5,2,2,2.6,1C137.1,17.1,132.8,16.2,133.9,17.1"/>
    <path d="M141.2,18c1.3,0.6,0.5,1.5,1.9,2.1c1.4,0.6,5.4-0.8,5.7,0.2c0.3,1-4.1,1-3.5,1.6c0.6,0.6,3.1,1.6,2.8,2.1
      c-0.3,0.5,4.2,2,4.6,1.3c0.4-0.7,1.7-0.5,2.8-0.1s1.2-2.7,2-2.2c0.8,0.5,0.7-1.1,2-1.6c1.3-0.5,3.2-0.5,3.3-1.1
      c0.1-0.4-0.3-1-1.9-0.9c-1.6,0.2-2.6-0.4-1.9-1.3c0.7-0.9-1.7-1.1-0.9-1.8c0.9-0.7-1.7-1.4-1.4-0.3c0.3,1-1.6,0.1-1.8-0.9
      c-0.3-1-3.8-1.3-6.2-3.4c-2.3-2.1-4.8-0.7-3.4-0.4c1.4,0.3,1.3,1,0.2,0.9c-1.1-0.1-3.1,0.1-1.3,0.8s-0.2,1-1.7,1
      c-1.5,0-1.6,1.9,0.3,2.2c1.9,0.3,0.5,1.4-1,1C140.3,16.5,139.8,17.3,141.2,18"/>
    <path d="M150.6,224.4c-0.9,1.3,0.9,2.6,1.2,2C152.3,225.7,151.5,223.1,150.6,224.4"/>
    <path d="M124.6,22.2c1-0.7,3,0.4,2.6,1.2c-0.3,0.8-3.4-0.2-3.1,0.5c0.1,0.3,0.4,1.3,2.5,0.7c2.1-0.6,3.8-0.2,5.2,1
      c1.4,1.1,2.7,1.6,3.6,0.4c1-1.1-1.2-1.3-0.8-2.2c0.4-0.9-1.4-1.4-2.3-1.4c-1,0-1.3-1.7-2.1-1.4c-0.8,0.3-1.3,1-1.3-0.2
      c0-1.1-4.5-1.2-5.6-0.8C122.1,20.5,123.5,22.9,124.6,22.2"/>
    <path d="M125.2,29.5c0.7-0.9-2.8-3.6-3-2.3C122.1,28.4,124.4,30.4,125.2,29.5"/>
    <path d="M114.3,26.5c1.1-0.2,0.6-1-1.2-0.8c-1.8,0.3-5,0.3-5,1.6c0,1,0.6,1.6,2.6,1.7c2,0.2,3.7-1.7,2.8-1.7
      C112.6,27.3,113.2,26.7,114.3,26.5"/>
    <path d="M198.5,188.2c0.8,1.2,3,0.5,3.2,0C202.1,187.4,197.7,187,198.5,188.2"/>
    <path d="M204,117.1c0.2,0.7,5.2,2.8,5.4,1.8C209.5,118,203.8,116.5,204,117.1"/>
    <path d="M184.3,184.2c0.3-0.4-2.3-2.2-3.6-2.2s-5.6-3-9.5-3.8c-3.9-0.9-7.3,1.9-6.9,2.4c0.5,0.7,2.4-0.9,3.4-1.6
      c1-0.7,1.8,0.3,1.9,0.8c0.1,0.4,1.3,0.7,3.3,0.8c2,0.1,2.3,1.7,4.2,2.2s0,1.2,1,1.6C178.9,184.8,183.9,184.7,184.3,184.2"/>
    <path d="M209.1,204.7c0.2,0.2,1.1,0,1.5-0.2c0.4-0.2,0.4-0.8-0.1-1.1c-0.5-0.4-1.9,0.2-1.4,0.4C209.5,204,208.9,204.5,209.1,204.7"
      />
    <path d="M176.2,188.1c0.3,0.6,3.6,1.5,4,0.7C180.5,188,175.7,187.3,176.2,188.1"/>
    <path d="M177.1,174c-0.4-0.7-0.7-0.2-0.7,0.6c0,0.8,0.7,0.9,0.7,1.3c0,0.4,0.7,1,0.8,0.8c0.2-0.5-0.5-0.9-0.5-1.2
      C177.2,175.2,177.5,174.7,177.1,174"/>
    <path fill="#FFFFFF" d="M1.2,107.9c1-0.4,1.5-1,1-1.5C1.8,105.9,0.4,108.3,1.2,107.9"/>
    <path d="M186.4,188.5c1.2-0.4,2,0,2.7,0.4c0,0,0.1,0.1,0.1,0.1c0.7,0.3,0.9-0.3,2.4-0.9c1.6-0.5,4.1,0.4,4.1-0.8
      c0-1.2-4.2-2.5-5.2-2.2c-0.3,0.1-0.7,0-1.1-0.1c-1.1-0.3-2.5-1-3.1-0.5c-0.9,0.6,1.3,1.9,1.3,2.7c0,0.8-3.5-0.1-3.8,0.7
      C183.7,188.3,185.1,188.9,186.4,188.5"/>
    <path d="M15.9,180.7c0.6,0.6,1,0.4,1.1-0.1C17.2,180,15.3,180,15.9,180.7"/>
    <path d="M19,182c0.4,0.6,0.9,0.8,1.2,0.2C20.6,181.6,18.5,181.2,19,182"/>
    <path d="M24.1,184.5c-1.1,0.1-0.5,2.8,0.1,2.4c0.4-0.3,1.2-0.4,1.4-0.8C25.9,185.6,25.2,184.4,24.1,184.5"/>
    <path d="M260.1,234.7c-0.9,0-2.5-0.2-4.7-2.5c-2.2-2.3-4.3-2.2-5.7-1.8c-1.4,0.4-2.9-0.7-3.9-0.9c-1-0.2-1.9,1-2.6,1.3
      c-0.7,0.3,0.3-1,0.4-1.9c0-0.8-2.6-2-4.6-2.8s-2.9-0.4-2.9,0.9c0,1.3-0.8-0.4-1.4,1c-0.6,1.3-1.7,0.5-1,0s1.4-1.4,1.7-2.3
      c0.3-0.8-2.9-1.5-4-1c-1.1,0.4-0.4,2-1.3,1.5c-0.9-0.5-0.2-1.6,0.5-1.6c0.7,0,1.5-1.4,2-2.4c0.5-1-0.8-1.2-1.6-2.1
      c-0.8-0.9-0.6-3.4-1.4-3.8c0,0,0,0,0,0c-0.8-0.3-2.1-2.3-3.3-2.6c-0.5-0.1-1.1-0.3-1.6-0.6c-0.7-0.3-1.4-0.5-2-0.4
      c-1,0.1-2.1,0.6-2.5,0c-0.4-0.6-1-0.5-1.5,0c-0.1,0-0.1,0.1-0.2,0.1c-0.5-0.1-1.3-1.6-1.8-1.6c-0.5,0-1.1-0.4-1-1.2
      c0.1-0.7-1.2-0.8-2-1.6c-0.2-0.2-0.4-0.3-0.7-0.4c-0.9-0.4-2.3-0.3-2.8-0.3c-0.7,0,0.5-0.8,0.5-1.6c0-0.8-2.1-0.9-2.9-1.1
      c-0.8-0.2-0.6-1,0.3-1.1c0.9,0-0.5-0.7-1.7-0.5c-1.2,0.2-2.8,0.8-3.8,1.3c-1,0.5-2.6-1.5-3.7-1.1c-1.1,0.4-2.6,0.4-2.6-0.4
      c0-0.9-0.4-1.6-1.5-1.5c-1.1,0.1-0.8-1.1-1.7-1.1c-0.8,0,0,1-0.6,1.3c-0.6,0.3-2.5,0.7-2.6,1.5s1,2.1,0.7,2.9
      c-0.4,0.8-1.7,0.1-2.1-0.7c-0.4-0.8,1.1-2.1,0.9-2.6c-0.2-0.3-0.4-0.9-0.3-1.4c0.1-0.2,0.2-0.3,0.5-0.4c0.9-0.2,1.3-0.7,0.5-1.2
      c-0.8-0.5-1.3,0.9-2.2,1c-0.9,0.1-1.1,0.7-1.7,1c-0.6,0.3-1.7,0-1.8,0.6c0,0.6-0.4,0-0.9-0.1c-0.5-0.1-1.9,1-1.8,1.7
      c0,0.7,0.3,1.3-0.7,1.9c-1,0.6-1.5,1.7-2,2.3c-0.1,0.2-0.3,0.2-0.4,0.1c-0.3-0.2-0.7-0.8-1.2-1.5c-0.7-1-2-1-2.7-1.3
      c-0.8-0.3-1.8,0.1-2.8,1c-1,0.9-2.3,0.4-3.1-0.1c-0.3-0.1-0.6-0.5-1-0.9c-0.7-0.8-1.5-1.8-1.9-2.4c-0.1-0.2-0.2-0.3-0.3-0.4
      c-0.3-0.6,0.2-1.7,0.5-2.5c0.3-0.8-0.2-2.8,0.3-3.3c0.5-0.5,0.2-0.8,0.4-2c0-0.1,0-0.1,0-0.2c0-1-1.3-0.6-2.2-1.5
      c-1-1-3-0.5-4.2-0.1c-1.1,0.4-2.4-0.5-3.6,0.2c0,0,0,0,0,0c-0.7,0.4-0.9,0-0.8-0.5c0.1-0.3,0.3-0.7,0.6-1c0.8-0.6-0.4-3,0.5-3.3
      c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0.6-0.5,0.8-1.7,0.9-2.7c0.1-1.2-0.3-1.6,0.3-2.2c0.6-0.7,1.3-0.7,1.1-1.6
      c-0.2-0.9-1,0-1.7-0.4c-0.7-0.4-1.3-0.1-3.1,0.1c-1.8,0.3-2.6,1-2.5,2.3c0.1,1.3-0.6,0.7-0.6,1.8c0,1.1-1.4,1.1-1.3,1.8
      c0.1,0.7-1.1,0-1.5-0.2c-0.3-0.2-4.1,0.9-4.6,1c-0.5,0.1-1.5-1.1-2.2-1c-0.7,0-1-1.1-1.1-2c-0.1-0.9-1.5-1.7-2.1-2.6
      c-0.6-0.8-0.7-2.6-0.7-3.8c0-1.2-0.2-3.2,0.7-5.5c0.1-0.3,0.2-0.6,0.2-0.8c0.1-1.6-1.5-1.8-0.6-2.5c1.1-0.8,0-1.5,0.5-1.9
      c0.5-0.3,1.4-0.7,1.4-1.2s0.5-0.5,1.2-0.4c0.7,0.1,2-1.1,1.9-1.6c-0.1-0.5,0.1-0.6,1-0.4c0.8,0.2,0.7-1,1.2-0.8c0.5,0.2,1,0.5,1,0.1
      c0-0.4,0.6-0.3,1,0.2s1.5,0.7,1.6,0c0.1-0.6,0.7,0,1.3,0.6c0.6,0.6,0.8,0.4,1.7,0.5c0.8,0,0.8-0.2,0.9-0.7c0-0.5,0.9,1.1,1.7,1.2
      c0.8,0.1,0.2-0.5-0.4-1c-0.6-0.5,0.1-0.7-0.4-1.1c-0.5-0.4,0.7-0.9,1.7-0.9c1,0,1,0.3,1.4-0.3c0.4-0.6,0.8,0,0.8,0.5
      s1.7-0.3,2.6-0.3s1.7,0.8,1.8,1.3c0.1,0.5,0.7,0.8,1.3,0.2c0.7-0.6,1.2-1.2,1.8-0.6c0.6,0.6,1.2,1.4,2,2.2c0.7,0.7-0.3,1.6,0,2.2
      c0.4,0.6,0,1.3,0.8,2c0.9,0.6,0.1,1.9,0.8,2c0.7,0.2,1.3,1.3,1.3,1.7c0.1,0.4,1.4,1,1.5,0.3c0.1-0.7,0.8-1.6,0.9-2.3
      c0.1-0.7-0.5-3.2-1.1-3.9c-0.6-0.8,0.1-1-0.6-1.9c-0.7-0.9-1.1-2.5-1.1-3.8c0-1.3,1.6-3.4,2.3-4.1c0.7-0.7,1.6-0.3,1.7-1
      c0.2-0.7,1.1-1.6,1.6-1.6s1.1,0.1,1.2-0.4c0.1-0.6,1-1.3,2.1-1.4c1.1-0.2,0.5-0.8,0.2-1.3c-0.3-0.5,0.3-1,0.5-0.7
      c0.2,0.3,0.9,0.4,1.3,0.1c0.4-0.3,1.4-1.1,0.5-1.2c-1,0-1.2-0.4-0.6-0.7c0.6-0.2,0.1-1.3-0.7-1.3c-0.8-0.1-0.5-0.5,0-1
      c0.5-0.5-0.7-1.3-1.3-1.6c-0.6-0.4,0.3-0.6,0.8-0.7c0.4-0.1,0.1-2.1,0.3-2.6c0.2-0.5,1-0.5,0.7,0c-0.3,0.5-0.7,1.3-0.2,1.9
      c0.6,0.7,0.7,1.3,0.5,2.3c-0.2,1,0.2,0.7,0.9-0.6c0.7-1.3,0.7-2.4,0.3-2.6c-0.4-0.1-0.4-1.4,0.1-0.8c0.5,0.6,0.7,0.7,1.4-0.1
      c0.7-0.8,1.5-2.1,1-2.4c-0.5-0.3,0.2-0.6,1.1-0.6c0.9,0,2.7-0.5,2.9-0.8c0.4-0.8-3,0.4-3-0.1s2.2-1,3.2-1c1,0,0.5-1.4,1-0.8
      c0.4,0.6,1.1,0.5,1.7,0.1c0.6-0.3,0.1-1.3-0.5-1.5c-0.7-0.2,0.5-0.6,0.3-1c-0.2-0.4,0.4-2,1.3-2.1c0.8-0.2,0.4-0.7,1.1-0.7
      c0.7,0,0.8-0.8,1.4-1.4c0.6-0.6,1.5,1,2.4,0.1c0.4-0.4,0.9-0.8,1.5-1.1c0.8-0.5,1.6-0.9,2.1-0.9c0.8,0,2-1,2.3-1.6
      c0.3-0.7,0.8,0.6,0.1,0.8c-0.7,0.2,0.6,0.7,1.8,0.7c1.2,0-0.6,0.4-1.5,0.4c-0.9,0-1.3,0.1-2.5,1.3c-1.2,1.2-0.9,1.6-0.2,2.2
      c0.7,0.6,1.6,0.3,2.6-0.5c1-0.8,0.9-1.6,1.9-1.6c1,0,3.4-0.9,4.5-1.2c1.1-0.3,0.4-0.5,0.4-1c0-0.5,1.9-0.5,2.6-1
      c0.7-0.5-0.1-0.9-0.7-0.8c-0.6,0.1-0.1-0.9,0-1.3c0.1-0.5-1.3,0.2-1.3,0.8c0,0.6-1,0.8-1,1.1c0,1-0.1,0.5-0.8,0.8
      c-0.7,0.3-3.4,0-3.5-0.6c-0.1-0.6-2-0.9-2.1-1.7c-0.1-0.8-1-1.1-0.3-2c0.8-0.9-0.1-1.3-0.9-0.7c-0.7,0.7-1.2-0.4-0.2-0.8
      c1-0.4,2.9-0.8,1.9-2.5c-1-1.7-6.9,0.5-8.5,1.4c-1.5,0.9-3.4,3.6-4.3,3.6c-0.8,0,1.4-1.5,1.8-2c0.3-0.6-0.1-1.1,0.4-0.9
      c0.5,0.2,1.8-1.9,2.7-2.6c0.9-0.7,1.9,0,2-0.6c0.1-0.6,0.6-1,1.4-1.7c0.9-0.8,11.2-0.7,12.2-0.6c1,0,3-1.3,3.5-2.2
      c0.6-0.9,1.3-0.9,2.7-0.8s2-1.2,2.7-1.6c0.7-0.5,0.4-0.7-0.2-0.8c-0.6-0.1-1.2-0.5-0.6-0.6c0.6,0,0.9-0.4,1.2-1.2
      c0.3-0.8-0.2-0.4-1-1.5c-0.8-1.1-1.3,0.3-2.1,0.3c-0.8-0.1,0.6-1.1-0.1-1.6c-0.7-0.4-2-0.1-3,0.7c-1,0.9-1,0-1.9,0.8
      c-0.9,0.9-0.9-0.4-0.5-0.8c0.4-0.3,0.7,0,1.1-0.5c0.4-0.5,0.8,0,1.8-0.7c1-0.7,1.9-0.3,2.2-0.8c0.3-0.5-0.5-1.1-1.4-0.9
      c-1,0.2-1.6-1.3-2.2-0.9c-0.6,0.4-0.5-0.7-1.2-0.2c-0.7,0.4-1.1-0.1-1.4-1c-0.3-0.8-2.2-2.2-3.3-2.5c-1.1-0.3-0.4-1,0.2-0.5
      c0.6,0.4,1.3-0.4,1.6-0.9c0.3-0.4-0.4-0.8-0.8-0.9c-0.3,0-0.3-0.6-0.3-1c0.1-0.4-1.2-1-1.3-1.4c0-0.4-0.8-0.7-0.7-1.1
      c0-0.4-0.7-1-0.7-1.5c0-0.5-1.3-1.6-1.3-2.2c0-0.6-0.7-1-1-1.8c-0.3-0.7-0.6-0.4-0.9,0.7c-0.3,1-1.2,1-0.9,1.3
      c0.5,0.7,0.1,0.7-0.3,1.2c-0.4,0.4,0.3,1.1-0.4,1.1c-0.7,0-0.3,1.3-0.7,0.7c-0.3-0.6-1.2-0.4-1.2,0.2c0,0.6-1.4,1.2-2,1.1
      c-0.6,0-0.6-1.2-1-0.9c-0.5,0.3-0.4-1-1.1-1c-0.7,0-1.3,0-1-0.7c0.2-0.7-1.3-1-0.8-1.3c0.5-0.4-0.1-1.1-0.2-2.2
      c-0.1-1.1,0.7-1.2,0.7-1.6c0-0.4-0.5-0.4-1-0.1c-0.5,0.3-0.5-0.5-1.1-0.4c-0.6,0-2.3,0-2.3-0.8c-0.1-0.8-0.7-0.4-0.9-1.1
      c-0.3-0.7-1-0.2-1-0.7s-0.3-0.7-0.8-0.8c-0.5,0-0.7-0.6-1.4-0.7c-0.7-0.1-1.6,0.5-2,0.7c-0.4,0.2-1.4-0.6-1.8-0.3
      c-0.4,0.3-0.5-0.2-1.6-0.5c-1.1-0.3-2.5-0.4-2.6,0c-0.1,0.4-1,0.5-0.8,1.4c0.1,0.9,1,0.7,1.3,1.3c0.2,0.6-0.4,0.4-0.5,1
      c0,0.6-0.6,0.5-0.6,0.9c0,0.4,0.4,0.3,0.6,1.1c0.2,0.8,0.7,0.7,0.8,1.8c0.1,1-0.3,0.8-0.8,1c-0.4,0.1,0,0.7-0.6,1.3
      c-0.6,0.7-1.1,1.4-0.7,1.9s2.3,1.2,3,2.3c0.7,1.2,0.7,3,0.4,4.3c-0.2,1.3-1.7,1.6-2.8,2.7c-1.1,1.1-2.2,1-2.7,1
      c-0.5,0-0.2,1.1,0.5,1.9c0.7,0.8,0.1,1.1,0.5,2c0.4,0.9,0,1.6,0.6,2.3c0.6,0.8,0.2,1.1-0.4,1.6c-0.7,0.6,0.3,0.3,0.3,1
      c0,0.7-0.3,0.3-0.9-0.1c-0.6-0.4-1.1,0.7-1.1,1.2c0,0.5-1.3-0.7-1.6-0.6c-0.3,0,0.1-0.7-0.7-1.3c-0.8-0.6-1.4-0.7-1.4-1.3
      c0-0.6-1.3-1-1.3-1.7c0-0.7,0.2-2.5-0.1-3c-0.3-0.5,0-1.1,0.2-1.6c0.3-0.5-0.3-1.2-1.1-1.2s-1.9-0.3-3-0.2c-1.1,0.1-1.7-0.1-2-0.4
      c-0.3-0.3-1.3-1-2.7-1.3c-1.3-0.3-2-1-2.1-1.4c-0.1-0.4-1.5-0.7-1.8-1.1c-0.3-0.4-1.6-0.6-2.1-0.5c-0.5,0.1-1.8-1.1-2.6-1
      c-0.8,0-2.6,0.8-2.9,0.9c-0.3,0,0-0.7,0.3-1c0.2-0.3-0.7-1.6-0.9-2.5c-0.2-0.9-0.5-1.8-1.4-1.7c-0.9,0.1-1.9-0.3-2.2-0.5
      c-0.3-0.2-0.3-2.6-0.1-3.7c0.2-1,1.3-2.6,1.3-3.3c0-0.7,0.7-1,1.4-1.1c0.7-0.2,0.1-1.4,0.8-1.5c0.7-0.1,0.7-0.3,0.8-0.9
      c0.1-0.5,0.9-0.2,0.5-0.6c-0.4-0.4-0.1-0.6,0.9-0.6c1,0,2.5-0.3,2.3-1.3c-0.2-1-2.8-0.3-2.8-1.1s-3.3-0.9-3.1-1.3
      c0.2-0.5,3.3,0.3,4.4,0.8c1.1,0.4,1.4,0.4,2.3,0.3s0.3-1.2,0.5-1.7c0.2-0.5,1.9,0.7,2.9,0.5c1-0.3,2-2.3,2.8-2.9
      c0.8-0.6,0.1-1.1-1.5-1c-1.6,0.1-2.5-0.5-3.4-1.1c-0.9-0.7-0.8-1.2,0.1-1.1c0.9,0.1,3.1,1.9,3.9,1.9c0.8,0,2.3-1.6,3-2.1
      c0.7-0.5-0.7-0.7-1.1-1.1c-0.5-0.4,0.8-0.7,1.5-0.7c0.7,0,1,0.6,1.3,0.9c0.3,0.3,1.5-0.2,2.1-0.2c0.6,0-0.1-1-1.1-1.4
      c-1-0.4,0-1.1,0.3-0.5c0.3,0.6,1,0.5,1.3,1.2c0.5,1,0.8,0.3,1.7,0c0.9-0.3,1.3-0.8,1.8-1.3s0.6,0.1,1.1-0.7c0.6-0.8-0.3-1.6-1-2.1
      c-0.7-0.5,0-1-0.7-1.4c-0.7-0.3-0.8-1,0-0.8c0.9,0.2,1.6-0.1,2-0.7c0.4-0.6-1.3-0.7-0.5-1.2c0.8-0.4,0.1-0.9-0.7-0.9
      c-0.8,0-1.4-0.4-1.5-1c0-0.7-1.4-0.2-2.2-0.6c-0.8-0.4-2.6-0.3-3.1-0.3c-0.4,0-0.5,1.8,0.2,1.9c0.7,0,1.2,0.7,0.6,1
      c-0.6,0.2,0.1,0.9-0.3,0.9c-0.4,0-1.1-0.3-1.3,0.9c-0.1,1.1-0.2,1.6-1,2c-0.7,0.4-0.2-1.3-0.9-1.2c-0.7,0-0.8,1.4-0.3,1.5
      c0.5,0.1,0.6,0.6,0.7,1.2c0.1,0.7-1,0.2-1.4,1c-0.4,0.8-0.5-0.3-1.1-0.9s-1.3-1.4-1.3-2c0-0.6,0.3-0.8,0.8-0.9c0.5-0.1,0-0.5,0-1.3
      c0-0.8-0.6-0.5-1.3-1.3c-0.7-0.8-1.4-0.8-1.8-0.4c-0.4,0.4-0.1,1.1-0.7,1.4c-0.6,0.3-0.3,1.5-0.9,1.6c-0.5,0.1-0.2-2.1-0.7-2.3
      c-0.4-0.2-0.4-0.7,0.3-0.8c0.7-0.1,1-0.8,0.5-0.8c-0.6,0-1.6-0.6-2-0.8c-0.4-0.2-1,0.7-1.5,0.4c-0.4-0.3,0-1.2,0.1-1.7
      c0-0.5,0.7,0.2,1.1-0.4c0.4-0.6-1.2-0.6-1.2-1.3c0-0.7-1.3-0.8-1.7-1c-0.4-0.2,0.3-1.3,0.1-1.7c-0.2-0.4-1.8-1.9-2.5-1.7
      c-0.7,0.1-0.9-0.9-0.5-0.9c0.4,0,0.7-0.2,1.4-1c0.7-0.8,0.8-1.1,0.3-1.3c-0.5-0.2-0.8-0.7,0.1-0.7c0.9,0,2.5,0.7,3,0.3
      c0.5-0.4,2.2-3,2.8-3.9c0.7-0.9-0.5-1-2-0.9c-1.6,0.1-1.9-0.6-3.2-0.7c-1.3-0.1-3.7,0.3-4.1,0.7c-0.3,0.4,0.7,0.7,0.5,1.2
      c-0.1,0.4-0.9-0.3-1.3-0.1c-0.3,0.2,0.1,0.7,0,2.3c-0.1,1.5,0.3,1.6,0.7,2.2c0.4,0.6,0.3,1.3,0.3,2.3c0,1-0.9,0.9-1.2,1.1
      c-0.3,0.2,0.8,0.6,0.6,1.2c-0.2,0.6-1.1-0.3-1.6-0.2c-0.5,0.2-1,1.6-0.4,2.1c0.6,0.5,0.8,0.4,0.3,1c-0.4,0.6-0.9,1.4,0.1,2.2
      c1,0.8,3,0.7,4,1.4c1,0.7-0.4,0.3,0,1c0.4,0.7-0.7,1-0.6,1.6c0,0.6,0.4,0.3,1-0.7c0.5-1,0.9,0.3,0.9,1.1c0,0.8-0.8,0.3-1.3,1.2
      c-0.6,0.8-1.1,1-1.9,0.8c-0.8-0.2-0.3,1.2,0,1.9c0.3,0.7-0.1,1-1,0.7c-1-0.3-1.5-0.9-1-1.6c0.5-0.7,0.8-1.6,0.3-1.5
      c-0.6,0.1-1.9,0-2.3-0.7c-0.4-0.6,0.3-0.3,1.4-0.2c1.2,0.1,0.2-0.8,1-0.8c0.9,0,1.5,0.5,2.3-0.2c0.7-0.7-0.5-1.9-1.1-1.7
      c-0.6,0.2-1.6,0.5-1.6-0.1c0-0.6,1-0.2,1.6-0.3c0.6-0.1-0.3-0.9-1.2-0.7c-0.9,0.2-1.5,0.3-2.2-0.5c-0.7-0.7-2-1.2-2.3,0.5
      c-0.3,1.7-1.5,0.8-2,1.3c-0.5,0.6,0.6,1,1.7,1c1.2,0,2,1.1,1.5,1.1c-0.6,0-0.3,0.8-0.9,0.5c-0.6-0.3-1.2,0-0.8,0.5
      c0.4,0.5-0.2,0.7-0.1,1.3c0,0.5-0.9,0-1.8,0c-0.9-0.1-2.6,0.2-3.8,0.5c-1.2,0.3-2.6-0.2-3.1-0.7c-0.5-0.6-1.2-0.5-2.1-0.5
      c-0.9,0-0.6-0.8-1.7-0.9c-1.1,0-0.9-1-1-1.6c-0.1-0.6-2.4-0.4-3.2,0.1c-0.8,0.5-2.4,0.3-2.9,1.3c-0.4,1,0.4,0.8,0.9,0.8
      c0.7,0,0.6-0.8,1.8-0.6c1.2,0.2,2.1-1.3,2.6-0.7c0.5,0.5-2.1,1.5-3.2,1.7c-1.1,0.2-0.9,1.1,0.2,2.7c1.1,1.6-0.5,0.7-0.5,1.5
      s-2-0.3-1.2-0.5c0.8-0.3,0.4-1.1-0.2-1.6c-0.7-0.5-1.1,0-1-0.6c0.1-0.6-0.7,0-1.3-0.4c-0.6-0.5-0.9-0.9-1.7-0.4
      c-0.8,0.4-1.8,0.3-3.6,0.6s-5.2,0.2-5.8-0.4c-0.6-0.6,1.6-1.7,2.2-1.5c0.6,0.2,0.3-0.7-0.7-1.5s-3.2-1.3-3.2-0.8
      c0,0.5-1.3,0-2.9-0.3c-1.6-0.3-1.8-0.9-2.9-0.9c-1,0-2.6-0.3-3.8-1.3c-1.2-1-4.1-0.7-4.4,0.2c-0.3,0.9-1.3,0.8-2.3,0.8
      c-1,0,0.4-1.1,0.1-1.3c-0.5-0.2,0-1.4-0.7-1.5c-0.7-0.1-1.3,2.9-2.7,2.8c-1.3-0.1-1.9-3.1-3-3.9c-1.1-0.8-2.1-0.8-1.2,0.3
      c0.9,1.2-0.8,0.3-0.7,1.1c0.1,0.8-1.5,1.7-1.4,1.3c0-0.4-0.9-0.9-1.9,0.3c-1,1.2-1.8,1-1.9,0.6c-0.1-0.4-3.2,1.1-3,1.6
      s-0.5,0.9-1.3,0.9c-0.8,0-0.2-0.7,0.5-1.1c0.7-0.4,2.7-2,3.8-2.2c1-0.2,3-1,3.1-1.5c0-0.5-1-0.1-1.7-0.2c-0.7-0.1-1.9,0.7-3.1,1.2
      c-1.2,0.5-2.5,0.3-2.2,1c0.3,0.7-1.1-0.1-1.4,0.4c-0.3,0.5-0.9,0-0.4-0.3c0.4-0.3-1-0.7-1-0.4s-0.3,0.7-1.3,0.7
      c-1,0-2.1,0.7-1.4,1.3c0.7,0.5,1.1,0.8,0.8,1.1c-0.3,0.3-1.4-0.9-3-0.8c-1.5,0.1-4.6-1.7-4.6-2.1c0-0.4-2.1-0.1-3.6-0.3
      c-0.3,0-0.7-0.1-0.9-0.2c-1.3-0.5-3.1-1.3-4.1-1.1c-1,0.2-3,0.5-3.7,0.1s-1.6-0.7-3.1-0.4c-1.4,0.2-2.3-0.8-4.2-1.2
      c-1.8-0.4-4.4,0.1-5,0.3c-0.6,0.2-0.5-0.7-1.3-0.6c-0.8,0.1-0.2-0.3-0.6-1c-0.4-0.7-2.6,0-3.4,0c-0.8,0.1-1-0.4-1-0.8
      c0-0.4-0.7-0.3-1,0.3c-0.3,0.5-1.2,0.6-1.5,0.3c-0.3-0.3,0.7-0.5,0.8-0.9c0-0.4-1.1-0.6-1.6-0.7c-0.4-0.1-1.1,0.4-2,1.3
      c-0.9,0.8-2.3,0.7-3.1,0.7c-0.8-0.1-1.9,0.1-1.8,0.6c0,0.5,1,0.4,0.4,1c-0.6,0.6-0.8-0.8-1.4-0.2c-0.6,0.7-2.4,0.6-2.9,0.5
      c-0.4-0.1-1.9,1.5-2.3,1.9c-0.4,0.4,0.3,0.8-0.8,2.1c-1.2,1.3-4.3,1-5,1c-0.7,0-0.2,0.7-0.5,1.2c-0.3,0.5,0.4,0.9,2.2,1.4
      c1.8,0.5,3,2.8,3.2,3.4c0.2,0.6,2.4,0,3.3,0.1c0.9,0.1-0.1,1.3,0.7,1.7c0.7,0.3,1.9-0.2,2.1,0.4c0.2,0.6-1.5,0-2.3,0.7
      c-0.9,0.7-1.3,1-1.7,0.5c-0.3-0.5-1.8-0.2-2.5-0.1c-0.7,0.1,0-0.9,0-1.3c0-0.4-1-0.7-2.4,0.1c-1.4,0.8-0.9,0.5-1.1,1.2
      C4.2,71,3.6,70,2.7,70.6c-0.9,0.6-2.4,1-2.7,1.5c-0.3,0.6,2,1,2.8,1.1c0.9,0.2-1,0.7-0.3,1C3.2,74.4,2.9,75,4,75.6
      c1.1,0.6,3.5,0,4.2,0c0.7,0,1.3,0.8,1.8,0.2c0.5-0.6,2.5-2.1,3.5-1.2c1,0.9-1.1,0.8-0.6,1.3c0.5,0.6,1.2,1.9,0.2,2.6
      c-1,0.7-1.9,0.2-2.4,0.2c-0.5,0-0.2,1-1,1.6c-0.8,0.7-1.4-0.7-2.5-0.7c-1,0-0.8,1.3-0.8,2c0,0.7-1.6-0.1-2,1.4c-0.4,1.6-2,0.2-1,1.7
      c1.1,1.5,0.9,0.6,1.8,1.2c0.9,0.6-1,1.7-0.1,1.9C6,88,7,89.6,7.6,90.1c0.6,0.6,1.4-0.3,2.3-0.3c0.9,0,0.5-1.3,1-0.8
      c0.5,0.5,1.3,1.9,0.8,2.2c-0.5,0.3-0.1,1.4-0.2,2c-0.1,0.5,2,0.3,2.1-0.3c0.1-0.6,1.8-0.8,2.7,0.2c0.9,1,1.2,1.2,1.2,0.4
      s0.8-1.6,0.8-1c0,0.7,0.8,0.8,2.1,0.2c1.3-0.6,0.7,0,0,0.9c-1,1.2,0,3-0.7,3.1c-0.7,0.2-1.3,2-2.4,2.1c-1.1,0.1-3,2.3-3.3,2.6
      c-0.3,0.3-2.7-0.7-3,0.3s-1.8,1.7-1.4,2.1c0.3,0.3,2.3-0.7,2.3-1.3c0.1-0.5,0.3-0.4,0.7,0c0.3,0.4,1.2-0.2,1.6-0.5
      c0.4-0.3,1-0.2,1.4-0.2c0.4,0,0.6-0.5,1.7-0.7c1.2-0.2,0.9-0.6,1.2-1.2c0.3-0.6,3-1.9,3.6-2c0.5-0.1,0.2-1,0.8-1
      c0.6,0,1.4-0.7,2.2-1.3c0.7-0.6,0.9-0.3,1.5-0.6c0.6-0.2,0.1-1.3,0.7-1.4c0.6-0.1,1-0.5,1-1c0-0.5-1.4-0.5-1.6-0.8
      c-0.1-0.3,1.1-1.4,1.6-1.4c0.5,0,1.3-0.5,1.3-1.2c0-0.7,0.7-0.8,1.3-1.4c0.6-0.6,0.4-1.1,1-1.2c0.6,0,1.1-0.8,1.7-1.2
      c0.6-0.3,0,0.4,1.2,0.5c1.2,0,0.9,1.1,0,0.7c-0.9-0.4-1.3-0.3-2,0.4c-0.8,0.7-0.2,1-0.8,1.8c-0.6,0.8-0.7,1.3,0.1,1.4
      c0.8,0.1,0,0.7-0.7,0.9c-0.7,0.2-0.2,0.7,0.8,0.7c1,0,2.5-1.4,3.9-2.1s2.4,0,2.8-0.1c0.4-0.1-0.4-0.8,0-1c0.5-0.2-0.6-0.3-0.9-1.2
      s0.5-0.3,0.7-1c0.2-0.7,0.7-0.4,1.1-0.1c0.4,0.3,1-0.3,1.4,0.5c0.4,0.8,0.8-0.2,1.5,0.3c0.7,0.6-0.1,0.5-0.9,0.7
      c-0.7,0.2-0.3,1.2,0.2,0.7c0.6-0.5,1-0.8,1.6-0.3c0.5,0.5,0.7,0.3,1.2-0.2c0.4-0.4,0.4,0,0.4,0.4c0,0.3,0.9,0.5,2.2,0.7
      s3.6-0.1,4.3-0.1c0.6,0,0.4,0.9,1.7,1.2c1.3,0.3,1.7-1.3,2.4-0.8c0.7,0.6,0,0.8-0.4,1.2c-0.4,0.4,0.4,0.5,1,0.7
      c0.5,0.2,1.7,0.6,2.3,1.1c0.6,0.6,0.9,1.2,2.3,1.9c1.4,0.7,0-1.7,1.1-1c1.1,0.8,0.7,0,1.6,0.8c0.9,0.8,0.6-0.3,0.2-1.6
      c-0.4-1.3,0.4-0.4,0.8,0.2c0.4,0.6,0.4,1.8,0.1,2.6c-0.3,0.8-1.6,0.1-1.4-0.3c0.1-0.4-1.3-0.3-1.3,0.3c0,0.6,0.9,1.8,1.6,1.9
      c0.7,0.1,0.2,1.2,0.6,1.3c0.6,0.2,0.6,1.3,0.9,1.1c0.3-0.2,0.2-1.5-0.2-2.1c-0.4-0.6-0.2-1.5,0.3-1.3c0.5,0.2,0,1.1,0.3,1.4
      c0.3,0.3,0.6-0.3,1.2-0.7c0.6-0.3-0.3-1.3,0-1.9c0.2-0.5,0.9,0.4,1.2,1.3c0.3,0.9-0.7,0.9-0.7,1.3s-0.8,0.3-1.1,0.5
      c-0.3,0.3-0.1,2,0.2,2c0.3,0,0.6-1.4,0.8-0.7c0.2,0.7,1-1,1.3-0.3c0.2,0.7,1.3,1.5,1.6,1.3c0.4-0.2-0.2-0.9-0.8-1
      c-0.6,0-0.5-1,0-1.1c0.6,0,1.6,1.4,1.5,2c0,0.5-0.5,0.5-1,1c-0.5,0.5-1.1-0.9-1.7-0.8c-0.6,0,0,0.9,0.3,1.5c0.3,0.6-0.7,1.4,0,1.9
      c0.7,0.5,0.5-0.3,0.7-0.7c0.1-0.3,0.7,0.1,1.1,0.4c0.4,0.3,0.4-0.7,0.4-1.1c0-0.4,0.7-0.2,1,0.3c0.3,0.5,0.4-0.6,0.9-0.5
      c0.5,0.1,0.2,0.9,0.2,1.4c0,0.5,0.9-0.4,0.8,0.1c0,0.1,0,0.2,0,0.2c0,0.4,0.4,0.6,0.8,1c0.5,0.4-1,0.4-0.5,0.8
      c0.4,0.4-0.1,1,0.3,1.5c0.4,0.5,1.2,0.3,1.7-0.1c0.5-0.4,0.8,0.5,0.4,1.1c-0.4,0.7,0.4,1.1,0.8,0.7c0.4-0.5,1.2,0.8,1.6,1
      s0.8,0.9,0.1,0.9c-0.7,0-0.6,1.1-0.1,1.2c0.5,0,0.4,0.7,0.4,1c0,0.4,1.5,1,2.1,0.8c0.6-0.2,1.1,0.3,1.3,0.8c0.3,0.5,1.3,0.8,1.7,0.5
      c0.4-0.3,0.7,0.6,0.7,1.2c0.1,0.6,1,0.3,1.5,0.2c0.5,0,0.8,0.7,1.2,0.6c0.8-0.3,1,1,1.5,1.8c0.1,0.1,0.1,0.2,0.2,0.3
      c0.7,0.7,0.1,1.2,0.3,2s0,2-0.6,2c-0.7,0-0.7-0.7-0.3-1c0.5-0.3,0.2-1.2-0.3-1.2c-0.3,0-0.4-0.3-0.4-0.7c0-0.3,0.1-0.6,0.2-0.8
      c0.3-0.6-0.7-0.2-1.2-0.8c-0.6-0.6-2-0.5-2.3-1s-1.6-2.6-2.3-2.6c-0.7,0-1.7,0.1-2.6-0.6c-0.9-0.7-1.4-0.7-2-0.3
      c-0.6,0.4,0.7,0.5,0.6,0.9c0,0.4,0.4,0.7,0.9,0.6s0.8,1,1.3,1c0.5,0,0.7,0.9,1.3,0.9c0.6,0,0.7,0.7,1,1.1c0.3,0.4,1,0,1,0.4
      c0,0.4,0.7,0.7,0.9,1.1c0,0.1,0,0.1,0,0.2c0,0.5,0.3,1.7,1,2.6c0.7,0.9,0.2,1.5,0.9,1.8c0.7,0.3,0.6,0.7,0,0.7
      c-0.6-0.1-0.2,0.5-0.4,1.8s-0.2,4-0.3,4.8c-0.1,0.8-1.1,2.2-0.5,3c0.6,0.9,1,2.1,0.7,3.2c-0.3,1.1-0.3,1.7,0.2,2.6
      c0.5,0.8,0.1,2.3,0.6,2.6c0.5,0.3,1,0.9,1.5,1.7c0.5,0.8,0.9,0.3,1,1.1c0.1,0.8,0.1,0.9,0.8,1.2c0.7,0.3,0.3,1.1,0.3,1.5
      c0,0.4,0.9,1.2,2,2.2c1.1,1,0.2,1.8,1,1.8c0.8,0,1.6,0.5,2.4,1c0.8,0.5,1.1,0.2,1.7,0.3c0.6,0.1,1.6,1.3,1.8,2.2
      c0.1,0.4,0.2,0.8,0.4,1.2c0.2,0.5,0.6,1,1,1.6c0.9,1.1,1.6,3.1,1.7,3.8c0.1,0.7,1.3,1.3,2.4,2c1.1,0.7,1.1,2.2,1,2.6
      c-0.1,0.4-1.5-0.3-1.6,0.1c-0.1,0.4,1.7,1.9,2.4,1.8c0.7-0.1,1,0.3,2.1,1.3c1.1,1,1,2.1,0.6,2.4c-0.4,0.3,0.9,1.1,2.3,2
      c1.4,0.9,2,2,2.1,2.5c0.1,0.5,0.7,0,1-0.6c0.2-0.7-0.4-0.8-0.4-1.6c0-0.7-1.1-0.6-1.7-0.7c-0.5-0.1,0-1.2-0.5-1.7
      c-0.5-0.5-1-1.8-1.3-2.8s-1.6-1.9-2.2-3c-0.6-1-1.2-1.9-1.6-2.1c-0.4-0.3,0.6-0.7,0.2-1c-0.3-0.4-0.9-0.3-1.5-0.7
      c-0.7-0.3-1.1-0.8-1.1-1.5c0-0.7-0.4-2.3-0.7-2.9c-0.2-0.6,0.5-0.5,0.7-0.2c0.2,0.3,0.4,0.5,0.7,0.3c0.3-0.2,0.8,0,1,0.4
      c0.2,0.5,1,0,1.3,0.3c0.3,0.2-0.8,0.5,0.3,2.5c1.1,2,0.3,0.9,0.3,2.2c0,1.3,0.7,0.7,0.9,0.5c0.2-0.2,0.8,0.4,1.4,1.1
      c0.6,0.7,1.7,0.7,1.7,1.1c0,0.4,0.2,1.2,1,1.3c0.7,0.1,0.9,1,1.3,1s0.6,0.6,0.3,1.2c-0.3,0.6,0,1,1.2,1.5c1.2,0.5,0.7,0.6,1.6,1.5
      c1,0.9,3.6,3.9,4.2,4.7c0.6,0.8,0.7,1.5,1,2.1c0.2,0.6-0.4,0.9-0.1,1.4c0.3,0.5-0.7,0.3-0.6,0.7c0,0.4,0.6,2.3,1.6,2.4
      c1,0.1,2,1.3,2.8,1.9c0.8,0.7,2,0.5,3.1,1c1.2,0.4,2.2,1.5,3.9,2c1.7,0.5,3.3,1.2,4.4,1.9c1.1,0.7,2.4,0.4,4-0.2
      c1.5-0.6,2.7,0.2,3.6,0.5c0.4,0.2,1.3,0.9,2.1,1.6c0.9,0.8,1.7,1.6,2.1,1.8c0.8,0.5,2.2,0,2.7,0.5c0,0,0,0,0.1,0
      c0.7,0.5,3.1,1.3,3.8,1.3c0.3,0,0.5-0.1,0.6-0.3c0.2-0.1,0.3-0.2,0.6,0c0.3,0.2,0.2,0.5,0.2,0.7c0,0.3-0.1,0.5,0.3,0.7
      c0.7,0.4,1.6,1.9,2.4,2.3c0.4,0.2,0.6,0.5,0.7,0.8c0.1,0.3,0.1,0.6-0.1,0.8c-0.4,0.3-0.4,1.3,0.5,1.7c1,0.4,0.7-0.2,1.1-0.2
      c0.5,0,0.4,0.7,0.9,0.7c0.4,0,1.4,0.5,1.4,1.2c0,0.7,0.3,1,1.1,1c0.1,0,0.3,0,0.5,0c0.9,0.1,2,0.5,2.5,1.1c0.7,0.8,1,0.3,1.4,1
      c0.4,0.7,1.3,0,1.5-0.4c0.2-0.4-0.5-0.6-0.6-1.1c-0.1-0.5,0.9-0.3,1-1c0.2-0.7,1.6-1,2.2-0.7c0.6,0.3,1.1,0.7,0.6,1.3
      c-0.4,0.5,0.4,1.4,1.2,2c0.2,0.2,0.5,0.4,0.6,0.6c0.6,0.7,0.2,1.2,0.4,1.5c0.2,0.3,0.3,0.9,0,1.1c-0.3,0.2,0,2.6,0.3,3
      s0,0.8-0.4,1.7c-0.4,0.9-0.8,1.3-1.6,1.3c-0.8,0-0.7,1.3-1,1.3c-0.2,0-0.4,0.4-0.4,0.8c0,0.1,0,0.2,0,0.3c0,0.4-0.6,0.7-1.3,0.7
      c-0.7,0-0.7,0.9-0.7,1.4c0,0.6-0.6,0.6-0.9,1.2c-0.3,0.6,0.1,0.7-0.5,1c-0.6,0.3-0.8,0.8-0.4,1.3c0.3,0.5,0,1-0.1,1.3
      c-0.1,0.3,0.5,0.4,1.1,1c0.6,0.6,0.7-0.2,1,0.2c0.3,0.4-0.2,1.2-0.9,1.2c-0.1,0-0.1,0-0.2,0c-0.6,0.1-1.3,1-1.6,1.7
      c-0.3,0.7,0.6,2.2,0.7,2.7c0.1,0.5-0.5,0-0.6,0.5c-0.1,0.5,1.4,1.2,2.1,1.7c0.7,0.5,1.3,2,2,2.7c0.7,0.7,0.7,1.3,1.4,3
      c0.7,1.7,2.1,4.6,3,5.8c1,1.2,1.4,2.2,1.2,2.5c-0.2,0.3,0,1.3,0.7,1.8c0.7,0.5,1.3,1.3,3.1,2.2c1.8,0.9,5.2,2.6,5.6,3.5
      c0.5,0.8,2,1.6,2.3,1.8c0.1,0,0.1,0.2,0.1,0.4c0,0.5-0.2,1.3,0,1.8c0.3,0.7,0.4,3.8,0.1,5c-0.3,1.2-0.5,2.2-0.8,3
      c-0.3,0.7,0.6,0.8,0.3,1.1c-0.3,0.3-0.2,1.2-0.2,2.6c0,1.4-0.8,3.7-0.9,4.5c0,0.7-0.6,1.2-0.5,2c0.1,0.8-0.4,1.3-0.9,1.7
      c-0.5,0.4,0.8,1.6,0.7,2.3c0,0.7-1,1-0.7,2.1c0.3,1.4,0,3.8,0,4.3c0,0.5-0.6,0.4-0.4,1.3c0.1,0.9-0.6,1.1-0.7,2.4
      c-0.1,1.3-1.1,3.2-1.5,4.2c-0.4,1-0.4,1.6-0.9,1.6c-0.5,0-0.7,0.6-0.3,1.4c0.4,0.9-0.3,1.6,0.3,2.5c0.5,0.9,0.3,1.2,0.1,2
      c-0.3,0.8-0.6,0.9-0.6,1.6s-0.6,1.4-0.3,2.3c0.3,0.9,0.3,1.2-0.2,1.2c-0.4,0-0.7,0.7-0.8,2.1c-0.2,1.4-0.1,1.4,0.9,1.3
      c1-0.1,0.2-3.5,0.9-3.5c0.7,0,0.1-0.6,0.7-0.7c0.7-0.2,0.1,0.6,0.5,0.7c0.4,0.1,0.7,0.5,0.1,0.9c-0.6,0.4,0.1,1.2-0.2,1.6
      c-0.3,0.5-0.7,1-0.5,1.6c0.3,0.6-0.3,0.5-0.3,1.3c0,0.7,0.4,0.8,0.1,1c-0.3,0.2,0.4,0.7,0,0.8c-0.4,0.1-0.2,0.7,0.1,1.2
      c0.3,0.5-0.6,0.2-1,0.6c-0.4,0.3,0.4,0.8,0.2,1.3c-0.3,0.5-1-0.3-1.3-0.5c-0.3-0.3,0.5-0.8,0.9-1.3c0.4-0.5-0.4-1.7-1-1.4
      c-0.7,0.3,0.7,1,0.7,1.6c0,0.6-1.1-0.1-1,0.5c0,0.6-0.7,0.4-1.1,0.8c-0.4,0.4,0.5,0.8,0.1,1c-0.4,0.1-1.2,0.7-1.2,1.2
      c0,0.4,0.5,0.5,0.5,0.1s0.7-0.6,0.9,0s0.5,0,1.1,0.1c0.6,0.1,0.5,1,0.2,1c-0.3,0-0.7,0.6-0.6,1.2c0.1,0.7,1.3,0,1.8,0.2
      c0.5,0.2,0.3,1.1,0,0.8c-0.3-0.3-1-0.8-1.3-0.3c-0.3,0.5-0.8,0.3-1.2,0.1c-0.4-0.2-1.8,0.7-1.3,1.1c0.5,0.3,0.4,0.9,0.7,0.9
      s0.8-0.3,1-0.6c0.2-0.3,0.8-0.7,0.8,0.1c0,0.8-0.8,0.4-1,0.8c-0.2,0.4-0.5,0.8-1,1.2c-0.5,0.4,0,1,0.4,0.8c0.4-0.2-0.1,0.5,0.5,0.6
      c0.6,0.1,0.7-0.3,0.6-0.8c-0.1-0.4,0.3-1.4,0.8-0.9c0.5,0.5-0.3,1-0.3,1.4c0,0.4-0.3,1-0.3,1.5c0,0.5-0.6,0.2-0.5,0.7
      c0,0.5-1.2,0.2-0.7,0.5c0.6,0.3-0.3,0.8,0,1.2c0.3,0.3,0.6-0.5,1-0.3c0.5,0.2,0.4,0.3,1,0c0.6-0.3,1,0.5,0.4,0.7
      c-0.6,0.2-1.2,0.8-0.9,0.9c0.3,0.1,1-0.4,1.2,0.1c0.2,0.6,0.6,0.4,0.7-0.2c0-0.6,1.1-0.1,1.3,0.4c0.2,0.5-1.1,0.8-1.7,0.7
      c-0.7-0.1-1,0.6-0.6,1.2c0.4,0.6,1,0,1.4-0.5c0.5-0.5,2.8-0.3,3,0.2c0.2,0.5-1.1,0.1-1.2,0.4c-0.1,0.3-1.1,0.6-1.2,0.2
      c-0.1-0.3-1,0.1-1.1,0.7c0,0.6-0.7,0.6,0,0.8c0.8,0.2,0.6-0.8,1.8-0.7c1.2,0.1,1.9-1.1,2.2-0.4c0.3,0.7-1,1.3-1.5,0.8
      c-0.5-0.4-1,0.3-1,1c0,0.7-1.4-0.2-2.1-0.3c-0.7-0.1-0.4,0.7,0.2,1.3c0.6,0.7,1-0.1,1.7,0.4c0.7,0.5,0.7,1,1.3,0.5
      c0.5-0.5,0-0.4-0.3-0.7c-0.3-0.2,0-1,0.8-0.6c0.8,0.4,1.8-0.3,1.5-1.4c-0.3-1,0.7-1.5,1.7-1.9c1-0.3,0.5,0.7-0.3,1s-0.9,0.5-0.3,1.1
      c0.6,0.6,1.3-0.4,1.8,0.1c0.5,0.5-0.9,0.4-1.1,0.9s0.8,1,0.1,1.4c-0.7,0.3-0.4-1.4-0.8-1.6c-0.4-0.2-0.8,0.2-0.4,1.2
      c0.4,1-0.5,0.1-1-0.3c-0.4-0.5-1,0.2-0.5,0.7c0.5,0.5-1,0-1,0.8c0,0.8,0.7,0.1,1.4,0.3c0.8,0.2,0.4,1.3,1,1.7
      c0.6,0.4,0.1-1.4,0.7-1.5c0.6-0.1,0,1.4,1.4,1.8c1.3,0.4,0.1-0.3,0.7-0.7c0.6-0.4,1.4,1.3,2,1.5c0.7,0.2,0.2-0.8-0.3-1.3
      c-0.5-0.5,0.5-0.2,1.1,0c0.7,0.2,1.2,0.3,1.3-0.2c0-0.2,0.2-0.2,0.3-0.1c0.3,0.1,0.6,0.3,0.9,0c0.4-0.5,1.7-0.2,2.4-0.7
      c0.7-0.4-0.2-0.6-1.3-0.6c-1.1,0-3.2-2-3.9-2.6c-0.7-0.5-0.2-1-1-0.9c-0.8,0.1-0.3-0.6,0.2-0.8c0.4-0.2-0.2-0.9-0.7-1.1
      c0,0-0.1,0-0.1,0c-0.6,0-1.8,0.1-1.6-0.7c0-0.2,0.1-0.2,0.2-0.3c0.5-0.2,1.6,0.5,1.9,0.2c0.3-0.3-1.1-1.4-1-1.8c0-0.4-0.2-1-0.2-1.5
      s-0.2-1.4,0.4-1.8c0.6-0.4,0.7-0.9,1-0.8s1.2-0.3,1.2-1.4c0-1,0.5-1.4,1.4-2.2c0.9-0.8,2.5-1.6,2.3-1.9c-0.2-0.3,0.3-1.5,0.3-1.9
      c0-0.4-1.5-0.3-2-0.5c-0.5-0.2-2.2-1.4-1.3-3c0.9-1.6,2.7-1.8,3.2-1.8c0.5,0,0-0.8,0.5-1c0.4-0.3,0.5-0.7,0.5-1.3c0-0.7,0-1.9,0.8-2
      c0.8-0.1,1-0.5,0.5-0.7c-0.5-0.1-0.7-0.7,0.1-0.8s0.5,0.7,1.1,0.7c0.6,0,1.5-1,0.8-1.5c-0.7-0.6-1,0.3-1.3,0.3
      c-0.4,0.1-0.7-0.5-1.1-0.5c-0.4,0-0.1-1.1-0.3-1.5c-0.2-0.4-0.4-1.3-0.1-1.8c0.3-0.5,1.8,0.7,3.1,0.8c1.3,0.1,2.3-0.7,2.3-1.1
      c0-0.5-0.6-1.3,0.2-1.9c0.8-0.5,0.1-0.7-0.1-1.4c-0.2-0.7,0.3-1.1,0.7-0.8c0.3,0.3,2.9,0.1,5-0.3c2.1-0.4,3.6-1.2,3.7-1.8
      c0.1-0.6,1.5-2,1.8-2.6c0.3-0.5,0-1.2-0.8-1.3c-0.9-0.1-0.8-1.1-0.3-1.7c0.5-0.6-0.3-1.1-1.1-1.2c-0.8-0.1-2.1-0.7-1.4-1.3
      c0.4-0.3,0.5-0.3,0.7-0.1c0.2,0.2,0.4,0.4,0.8,0.4c0.8-0.1,1.6,0.2,2.5,0.7c1,0.4,0.6-0.1,1.5,0c0.9,0.1,2,0.2,3-0.4
      c0.7-0.4,1-1.1,1.6-1.8c0.2-0.3,0.5-0.6,0.9-0.9c1.2-0.9,0.9-1.4,1.5-2.4c0.6-1,0.1-1.5,1-2.4c1-0.9-0.2-1.5,1-2.1
      c1.2-0.6,1,0.5,0,1.8c-1,1.3-1.2,1.6,0.1,0.7c1.3-1,1.8-2.3,2.5-3.8c0.7-1.5,1.3-2,2-2.1c0.7-0.1,0.7-1.7,0.6-2.8
      c-0.1-1.1-0.4-2.2,0-2.9c0.5-0.7,0-1.4,0.6-1.4s1.5-0.9,2.5-1.9c1-1,1.6-1,2.6-1.1c1-0.1,0.4-0.4,1.1-0.6c0.7-0.2,1.1-0.8,1.5-1.2
      c0.4-0.4,2.2,0,3.3,0c1,0,1.7,0.1,1.7-0.6c0-0.7,0.5-1.2,1.1-1.2s0.9-0.2,0.9-1c0-0.9,0.3-1.4,0.8-1.8c0.5-0.4,0.6-1.3,0.8-1.8
      c0.2-0.6,0.7-0.2,0.7-1c0-0.8,0.3-1.7,0.3-2.4c0-0.7,0.7-0.6,1-1c0.3-0.5-0.3-1.1,0.2-2.4c0.5-1.3,0-2.6,0-3.8c0-1.2,0-2.5,0.4-2.9
      c0.4-0.4,0.2-0.6-0.1-0.8c-0.3-0.2,0-0.7,0.3-0.6c0.3,0.1,0.7,0.7,1.2,0.4c0.6-0.3,1.1-1.8,1.6-3c0.5-1.2,1.3-1.2,1.9-1.4
      s1.9-1.7,2.7-3.9c0.8-2.1,0-4.2-0.4-5.7C261.4,234.4,260.9,234.7,260.1,234.7 M177.7,131.4c1.3,0.2,2.2-0.8,2.7-0.2
      c0,0,0.1,0.1,0.1,0.1c0.6,1.1-1.7,1.8-3.8,1.9c-0.7,0-1.2,0.1-1.6,0.1c-0.9,0.1-1.2,0.2-1.4-0.4
      C173.3,132.1,176.3,131.2,177.7,131.4 M174.6,134.4c0,0.1,0,0.1,0,0.2c-0.3,0.7-3,2-4.5,2.8c-1.7,0.9-3.9,0.4-3.9-0.9s1.2-1,1.5-0.4
      c0.1,0.2,0.4,0.1,0.8-0.2c0.7-0.5,1.6-1.3,2.4-1.3c1.3,0.1,1.7,0.9,2.5,0.3C174.3,134.2,174.4,134,174.6,134.4 M165.1,126.3
      c0-0.1,0.1-0.2,0.2-0.2c0.6-0.2,2,0,3,0.4c1.2,0.4,3-0.5,3.6,1c0.6,1.5,2,2.6,0.7,2.7c-1.1,0.1-2.6-1.5-2.5-0.6
      c0.1,0.9-0.6,0.4-0.6,1.7s-0.9,3.1-1.4,3c0,0,0,0-0.1,0c-0.5-0.2,0.3-2.2-0.7-2.5c-1-0.3-2.3,1.5-2.1,0.3c0.2-1.1,1.7-2.1,0.8-3.6
      c-1-1.5-2.7-1.5-3-0.3c-0.3,1.1-1.6,0.6-2,1.5c-0.4,0.9-0.9,2.8-0.5,4.2c0.3,1.4-0.1,2.1-1.4,2.9c-1.3,0.8-2-1.2-1.5-3.7
      c0.3-1.7,1.3-3.4,0.4-3.4c-0.9,0,1.1-2.1,3-2.5C162.9,126.7,165.1,126.9,165.1,126.3 M153.3,122.2c0.4,0,0.7-0.1,1-0.4
      c1-0.8,1.7-2.4,2.6-2.4c1.1,0,4,0.3,4.2,1.7c0.2,1.5,1.9,0.1,2.1,1.5c0.1,1.2,1.3,2.1,1.1,2.7c0,0.1-0.1,0.2-0.2,0.3
      c-0.7,0.5-0.5-0.8-1.8-0.8c-1.3,0-2.6,1.2-3.6,0.7c-1-0.5-0.5-1.2-1.5-1.2c-1,0,0.7-1.8-0.7-1s-3.5,2.7-4.4,1.8
      c-1-0.9-1.8,0.3-2.5-0.4C148.7,124,151.9,122.1,153.3,122.2 M99.1,69.9c-1.1,1.4-1.5,1.6-2.7,1.4c-1.2-0.2-1.9,0.4-1.3,0.8
      c0.6,0.4,0.6,0.8-0.6,1c-1.2,0.3-2,1.2-2.5,1.1c-0.5-0.1,2.1-1.9,1.4-2.4c-0.7-0.5-2.3,0.8-2.3,1.4c0,0.6-1.1,0.6-1.8,0.6
      c-0.7,0-1.5-0.7-0.9-0.7c0.7-0.1,0.2-0.8,1.1-1.1c0.9-0.3-0.2-0.9,0.1-1.2c0.3-0.4,2.1,0.4,2.1-0.3c0-0.7-1.8-1.3-2.5-0.9
      c-0.6,0.4-2.1,1.4-3.8,0.6c-0.5-0.2,4.4-0.8,5.2-1.5c0.8-0.8,4.6-1.6,4.8-0.9c0.3,0.7-2,1.1-0.9,1.8c1.1,0.6,2.9,0.2,3.3-0.4
      C98.4,68.4,100.2,68.5,99.1,69.9 M110.1,83.9c-1.3,1.2-1.2,0.9-2.4,1.1c-1.2,0.2-0.2,1.4-1.7,1.5c-1.4,0.1-4.8,0.2-5.3-0.6
      c-0.2-0.3,1.9-0.1,2-0.7c0.1-0.6,0.7-1.5,1.6-1.5c0.9,0,0.8-1.1-0.6-1.8c-1.4-0.7,2-1.1,2.8,0c0.8,1.1,2.2,1.9,3,1.1
      c0.8-0.8,5.3-3,5.3-2C114.8,82,111.4,82.7,110.1,83.9 M135.7,107.6c0-1.1,1.7-1.6,2.4-0.8c0.7,0.9,1.7,4.4,2.3,5.6
      c0.6,1.1,0.6,3-0.2,3c-0.9,0.1,0.1-2.5-1.7-3.5C136.6,110.8,135.7,108.7,135.7,107.6 M154.8,195.9
      C154.7,195.9,154.7,196,154.8,195.9C154.7,196,154.7,195.9,154.8,195.9C154.8,195.9,154.8,195.9,154.8,195.9 M191.7,232.8
      c0.4-0.1,0.7,0.1,1,0.1C192.3,232.9,192,232.7,191.7,232.8 M193.5,259.6L193.5,259.6L193.5,259.6L193.5,259.6 M211.5,214.8
      C211.5,214.8,211.5,214.8,211.5,214.8C211.5,214.8,211.5,214.8,211.5,214.8C211.5,214.8,211.5,214.8,211.5,214.8 M223.3,276.2
      c0-0.4,0.2-1.1,0.3-1.8C223.5,275,223.3,275.7,223.3,276.2 M223.7,220.2c0.1,0.1,0.3,0.2,0.4,0.2C224,220.4,223.8,220.3,223.7,220.2
      "/>
    <path d="M30.6,93.8c0-0.5-0.7-0.4-1.5,0.6c-0.9,1-1.6,1.5-1.7,2c-0.1,0.5-1.1-0.1-1.4,0.5c-0.3,0.6,0.2,2,0.8,1.5c0.6-0.4,0.7,0,1,0
      c0.4,0,2.2-1.2,2.6-1.8c0.4-0.6-0.7-0.8-0.7-1.2c0-0.4,0.9-0.4,1.4-0.7C31.5,94.5,30.6,94.3,30.6,93.8"/>
    <path d="M22.7,182.9c-0.6,0.1-0.2,1.3,0.2,1C23.6,183.3,23.3,182.8,22.7,182.9"/>
    <path fill="#FFFFFF" d="M3,88.7c-0.1-0.7-2,0-1.7,0.6c0.2,0.7,2,1.1,2.7,0.8c0.7-0.2,0.9-0.9,0.4-1.3C3.9,88.4,3.1,89.4,3,88.7"/>
    <path d="M8.6,104c-1-0.3-3.5,1.2-2.6,1.3c0.6,0.1,0.9-0.5,1.6-0.5S9.6,104.4,8.6,104"/>
    <path d="M69,106.4c-1.6,0.8,2.2,4.8,3,4.3c0.5-0.3-0.9-2-0.9-3C71.1,106.6,70.6,105.5,69,106.4"/>
    <path d="M135.1,51.9c1.9,1.3,1.3-1,3.5-1c2.2,0.1,2.3-3.5,2.6-4.8c0.3-1.3-1.7-1.2-1.2-0.3c0.5,1-0.1,1.9-0.3,0.8
      c-0.2-1.1-1.8-0.1-2.4-1c-0.6-0.9,1.9-1,2.5-2.1c0.6-1.1-2-1-1.4-1.8c0.6-0.9-3.8,0.1-2.5,0.6c1.3,0.5,0.1,1.2-1.1,0.3
      c-1.2-0.9-4.6,0.6-3.4,1.6c1,0.8,3.8-0.5,2.2,1.7c-1.7,2.3-2.3-0.6-3.9-0.3c-1.6,0.3-0.9,2.2,1.5,2.9
      C133.4,49.3,133.2,50.6,135.1,51.9"/>
    <path d="M349.4,137.6c0.8-0.1,0.7-3.6,0.4-3.6c-0.3,0-0.4,0.6-1.2,1C347.8,135.5,348.6,137.7,349.4,137.6"/>
    <path d="M698.6,72.4c0.7,0.5,1.7,0.5,2.2,0.5c0.5,0,0-0.5-0.5-0.5c-0.5-0.1,0.1-0.5,0.4-0.2c0.3,0.3,1,0.5,1.1,0.1
      c0.1-0.3,0.4-1.1,1-1.1c0.6,0,0.8-0.3,0.2-0.5c-0.6-0.2-2.4-1.4-2.9-2.1c-0.5-0.7-2.7-1-2.7-0.6s-0.7-0.1-0.9-0.5
      c-0.2-0.4-2.5-0.4-2.5,0c0,0.4,0.6,0.4,0.9,0.9c0.3,0.5-0.5,0.7-0.3,1.2c0.2,0.5-0.9-0.3-1.2-0.5c-0.4-0.3-0.2-1.4-0.1-2
      c0.1-0.6-0.7-0.5-0.8-1c-0.1-0.6-2.7-1.8-3.8-2.2c-1.1-0.5-2.5-0.9-2.9-1.4c-0.4-0.6-2.3-0.6-3.1-1.4c-0.8-0.8-4.1-2.2-5.5-2.3
      c-1.4-0.1-1.1-0.9-1.8-0.8c-0.7,0.1-3.7,0-4.6-0.2c-0.9-0.1-1.1,0.6-1.8,0.3c-0.7-0.3-4.1-1.5-4.5-1c-0.4,0.5-0.2,1.1-0.6,1.2
      c-0.4,0.1,0,0.7,1,1.6c1,0.9,0.4,1.5-0.5,1.9c-0.9,0.4-2.3-0.3-2.7-1s-1.6-0.4-1.9-1.2c-0.3-0.9,0.3-1,0.9-0.5
      c0.6,0.5,1.4,0.1,1.4-0.5c0.1-0.6-1.6-1.1-2.5-1c-0.9,0.1-1.4,1.3-2.3,1.8c-0.9,0.5-4.5-0.2-4.9-0.5c-0.4-0.3-5.4,0-5.9,0.3
      c-0.5,0.3-0.1,1.7-0.4,1.8c-0.3,0.1-0.5-1.6-0.5-1.9c0-0.3-0.6-0.5-1.5-0.5c-0.9,0-1.4,0-1-0.4c0.4-0.4-0.3-0.8,0.3-1.2
      c0.7-0.5-1.3-2-3.2-2.8c-1.9-0.8-6.3-0.3-7.4,0c-1.2,0.3-3.5,0.2-4.6,0.3c-1.1,0-0.2-0.4-0.5-1c-0.3-0.5-2.2-1.2-2.5-0.7
      c-0.3,0.4-0.8,0-0.8-0.3c0-0.3-1.8-1-2.5-0.8c-0.7,0.1-1-0.6-0.2-0.6c0.8,0,2.2,0.2,1.6-0.8c-0.6-0.9-5.8-1.2-6.3-1
      c-0.5,0.2,0,0.8-1.2,1.9c-1.1,1.1-2.2,0.3-2.1-0.2c0.1-0.5,1.5-0.4,1.5-1s-1.9-0.2-2.2-0.7c-0.3-0.4,0.4-0.7,1.1-0.5
      c0.7,0.2,1.9,0.3,2,0c0.1-0.3-0.7-0.4-1.9-0.6c-1.2-0.2-4-0.9-5.4-0.7c-1.5,0.1-2.1-0.5-3-0.6c-0.8-0.1-0.8,0.4-0.4,0.9
      c0.4,0.5-0.8,0.8-1.7,0.6c-1-0.1-2.3,0.5-1.8,1.2c0.6,0.7,1.1-0.2,1.6,0c0.5,0.2-1.2,0.7-0.5,1c0.7,0.3,0.6,1,0.6,1.3
      c0,0.3-1.2,0.5-1.6,0c-0.4-0.5-1-0.1-1.9-0.1c-0.8-0.1-1.4,0.3-0.6,0.4c0.9,0.1,1.2,0.7,0,0.8c-1.2,0.1-0.9-1.1-1.6-0.9
      c-0.7,0.1-2.2-0.5-3-0.5c-0.8,0-1.2,0.7-2.3,0.8c-1.1,0.1-2.5-0.6-3-1.1c-0.4-0.6-0.6-0.8-1.1-0.1c-0.5,0.7-0.5,1.9-1.1,2
      c-0.5,0.1-0.7,1.2-1.2,1.5c-0.5,0.3-0.6-0.4-1.2-0.4c-0.7,0.1-2.8-2-2.9-2.6c-0.1-0.6-1.4-1.8-1.8-2c-0.3-0.2,0.3-0.3,0.8,0
      c0.4,0.4,1,0.4,1.4,0.1c0.4-0.2,0.4-1.3-0.4-1.3c-0.8,0-0.5-0.7-0.1-0.8c0.4-0.1-0.9-1.2-0.4-1.4c0.5-0.1,0.3-0.6-0.4-0.7
      c-0.6-0.1-1.3-0.6-1.3-0.9c0-0.3-2.7-0.4-2.6,0c0.1,0.5-0.9,0.3-0.7-0.1c0.1-0.4-1-0.3-2.2-0.6c-1.3-0.3-1.3-1.2-1.7-1.3
      c-0.4-0.1-0.3,1.2-0.9,1c-0.6-0.3-1.3,0.3-1,1c0.3,0.8,0,0.9,0,1.5c-0.1,0.6-0.4,0.3-1.2,0.1c-0.8-0.3-0.8,0.6-2.4,0.2
      c-1.6-0.4-2.3,0-2.6-0.5c-0.3-0.5-0.8-0.6-0.8-0.1c-0.1,0.4-2.5,0.3-2.8-0.4c-0.2-0.6,0.4-0.8,0.8-0.9c0.4-0.2-0.4-0.4-1.8-0.3
      c-1.4,0.1-2.2-0.7-3.6-0.6c-1.4,0.1-4.5,0.4-4.9,0.6c-0.4,0.2,0.4,1-0.3,1c-0.7,0.1-0.2-1.3-0.3-1.9c-0.1-0.6-1.1-0.7-1-0.2
      c0.1,0.5-1.6,0.7-2,0.3c-0.4-0.4-1.1-0.9-2.3-1.2c-1.1-0.3-2.5,0.9-2.2,1.3c0.3,0.3,1.2,0.1,1.2,0.3c0.1,0.5-2.4,0.3-2.4,0.7
      c0,0.4-2.3,1-3,1c-1.9,0.2-2.3,0.2-3.2,1c-0.9,0.8-0.2-0.7,0.5-1.4c0.7-0.7,1.4-0.4,1.8-1c0.4-0.6,1-0.5,2.1-0.7
      c1.1-0.2,1-0.8,1.6-1c0.5-0.2,1.8-0.7,2-1.2c0.2-0.5,2.8-1.4,3.4-1.4c0.5,0,0.6-1,0.9-1c0.3,0,1.8-0.7,2.4-0.9
      c0.6-0.3,0.9-0.8,0.9-1.2c0-0.4-0.8-0.3-0.9-0.7c-0.1-0.3,0.4-0.1,1-0.3c0.6-0.2,0-1-0.5-0.9c-0.5,0.1,0.3-0.4-0.2-1
      c-0.4-0.5-1.3,0.3-1.3-0.2c0-0.5-0.1-1.2-0.4-1.6c-0.3-0.3-1,0.6-1.7,0.1c-0.7-0.6-2.8-0.9-2.8-0.7c0,0.2-1-0.3-1.2-0.1
      c-0.2,0.2-1.9,0.2-2.4,0.1c-0.5-0.1-1.2,0.2-1.3,0.7c0,0.5-2.1,0.3-2.5,0.2c-0.4-0.1,1.4-1.6,1.4-1.8c0-0.3-3,0.1-3.2-0.2
      c-0.2-0.3-1.7-0.4-2.3-0.3c-0.7,0,0-0.2,0.6-0.2c0.6-0.1,1.2-0.6,1.8-0.7c0.7-0.1,0.5-0.3,0.2-0.8c-0.3-0.4-1-0.2-1.7-0.4
      c-0.7-0.2-1.5-0.5-2.1-0.5c-0.6,0-1.2,0.3-2.6,0.6c-1.4,0.3-1.5,1.3-2.2,1.6c-0.7,0.3-1.9,1.6-1.3,1.7c0.6,0.1,0.3,0.5,0.4,0.8
      c0.1,0.4-0.4,0.5-1.1,0.3c-0.7-0.1-3-0.1-3,0.4c0,0.5,1.4,0.7,1.3,1c-0.1,0.3-1,0-1.5-0.3c-0.5-0.3-1.5-0.1-1.7,0.4
      c-0.2,0.5-1,0.4-1.3,0.3c-0.3-0.1-0.8,0.2-1.3,0.1c-0.4-0.1,0.9-0.8,0.5-1.1c-0.3-0.3-1.7,0-1.9,0.5c-0.1,0.5-1-0.4-1.3-0.2
      c-0.3,0.2-0.7,0.4-1.3,0.6c-0.6,0.3-1.7-0.3-1.9,0.2c-0.2,0.5,1.2,0.6,1.2,0.8c0,0.2-3.2,0.2-3.2,0.5c-0.1,0.3-1.2,0.3-2.5,0.3
      c-1.3,0-0.8,0.7-1.8,0.9c-1,0.2-1.5,0.2-1.9,0.8c-0.3,0.5-1.2,0.6-1.5,0.2c-0.3-0.4-1.3,0.2-0.8,0.3c0.5,0.1,0,0.6-0.3,0.5
      c-0.3-0.1-1.2,0.6-0.7,0.5c0.5-0.1,0.8,0.4,0.5,0.7c-0.3,0.3-1.4-0.4-1.7-0.1c-0.3,0.3,0.6,0.7,1.1,0.6c0.5-0.1,0.8,0.3,0.9,0.7
      c0.1,0.4-1.2-0.2-1.5,0.1c-0.3,0.3,0.2,0.5,0.8,0.5c0.5,0.1,0.1,0.3,0.6,0.7c0,0,0.1,0.1,0.1,0.1c0.3,0.4-0.3,0.5-0.2,0.8
      c0.1,0.4-0.6,0.5-0.6,0.2c0-0.3-1.5-0.5-1.9-0.1c-0.3,0.4-0.6,0.7-0.9,0.3c-0.4-0.3-1.5,0.1-2.9,0.1c-1.4,0.1-4.3,0.2-5.1,0.4
      c-0.7,0.3-1.3,1.3-0.7,1.9c0.7,0.7,0,1,0,1.4s1.7,1.3,2.7,1.4c0.9,0.1,1.7,1.2,1.1,1.9c-0.6,0.7-2.2-0.2-3.3-1.1
      c-1.1-0.9-3.6-1.2-4.5-1.2c-0.8,0.1-0.3-0.8-1.4-0.7c-1.1,0-2,1-1.2,1c0.7,0,1.2-0.1,0.8,0.3c-0.4,0.3,0.2,0.3,1.1,0.6
      c0.9,0.4-0.7,0.9-1.6,0.3c-0.9-0.6-1.9,0-2.1,0.4c-0.3,0.5,1,1.8,2.6,2c1.6,0.2,1,0.7,1.8,1c0.7,0.3,0.2,0.6-0.3,0.6
      c-0.5,0-1.5-0.8-2.3-1.2c-0.8-0.4-2.9,0.2-3.3-0.3c-0.4-0.5,0.3-0.9-0.1-1.3c-0.4-0.3-0.1-1.1,0.5-1.9c0.5-0.8-0.1-2.2-0.8-2.4
      c-0.8-0.2-0.7,0.6-0.5,0.8c0.2,0.2,0.1,1.3-0.5,2.1c-0.6,0.7-2.3,0.7-2.3,1.2s-1.2,0.9-0.8,1.2c0.4,0.3,2,2.1,2.2,2.7
      c0.2,0.5-1.4,2.3-1.2,3.3c0.3,1-0.2,1.5,0.1,2c0.3,0.5,1,0.1,1.5,0.3c0.5,0.2,1.2-0.3,2.3-0.5c1.1-0.2,3,0.9,3.8,1.3
      c0.7,0.3,0.3,0.9,0.7,1.5c0.4,0.6-0.9,0.6-0.9,1.4c0,0.9,2.2,1.2,2.3,1.5c0.1,0.3-1.6,0.1-2.2-0.2c-0.6-0.2-0.7-1.1-0.9-1.2
      c-0.2-0.2,0.4-0.9,0.5-1.4c0.1-0.6-0.7-0.9-1-1.2c-0.3-0.4-0.7-0.9-1.2-0.8c-0.5,0.1-2.4,0.3-3,0.8c-0.6,0.5,0.1,2,0.4,2.7
      c0.3,0.7-1.7,1.7-1.6,2.3c0.1,0.6-0.6,0.8-1.5,1.3c-0.9,0.5-1.8,0.8-1.8,1.6c0,0.8-1.2,0.3-1.8,0c-0.6-0.3-1.4,0.2-2.3,0.2
      c-0.9,0-0.9-0.8-1.6-0.5c-0.7,0.3-1.2-0.3-1-0.8c0.2-0.5,1-0.1,1,0.3c0.1,0.4,0.5,0.2,1.1-0.1c0.6-0.3,0.3,0.4,1.1,0.5
      c0.8,0.1,0.4-0.4,1-0.4c0.6,0.1,0.7-0.1,0.5-0.5c-0.2-0.3,0.5-0.6,0.9-0.7c0.5-0.2,0.2-0.8,0.6-1c0.4-0.2,0.1-0.6,0.5-0.6
      c0.4,0,0.6-0.6,1-0.6s0.6-0.6,0.3-0.9c-0.2-0.3,0.1-1.2,0.5-1.3s0.4-0.5,0.1-0.7c-0.3-0.3-1.6-1.1-1.5-1.7c0.1-0.6-0.2-1.6-0.1-2
      c0.2-0.4,0-1.3,0-1.9c-0.1-0.5,0.3-1.1,0.5-2.1c0.2-1-1-2-1.6-2.2c-0.6-0.3-0.2-0.8,0.6-1.5c0.8-0.7,0.9-2.9,0.8-3.3
      c-0.1-0.5-1.8-0.9-2.4-0.8c-0.7,0.1-3.1-0.1-3.7-0.2c-0.6-0.1-0.8,0.5-1.1,1.1c-0.3,0.6-0.9,1.5-1.3,2.8c-0.4,1.3-1.9,1.7-2.6,2
      c-0.7,0.3-1.1,1.2-0.8,1.7c0.3,0.5,0.8,0.1,1.1,0.3c0.3,0.2,0,1.4-0.2,1.6c-0.2,0.2,0.4,0.6-0.1,0.9c-0.5,0.3-1,1-0.7,1.3
      c0.3,0.3,1.7,0.5,2.3,0.9c0.6,0.3,0.4,1,0.8,1.6c0.5,0.6,1,0.2,1.2,0.6c0.2,0.4-0.8,1.9-1.2,1.9c-0.4,0.1-1.9-1.5-2.4-1.9
      c-0.5-0.3-2.3-0.9-3.2-1.4c-0.9-0.5-1.6-0.4-2.6-1.1c-1-0.7-1.8-1-3.6-1c-1.8,0-3-0.4-3.6-0.3c-0.6,0.1-0.4-0.6-1.4-1.1
      c-1.1-0.5-1.8-1.1-2.4-0.8c-0.6,0.3-0.4,1.5,0.5,1.6c0.9,0.1,0.5,0.6,1.4,0.6c1-0.1,1.3,0.3,1.2,0.9c0,0.6,0.5,1.2,0.9,1.6
      c0.4,0.4,0.2,1.1-0.6,1.1c-0.8,0-1.7,0.1-1.3,0.7c0.3,0.5-0.2,0.7-1,0.4c-0.8-0.3-0.3-0.8,0-1.2c0.3-0.3-0.7-0.7-1.2-0.9
      c-0.5-0.2-2.5,1.3-3,1.5c-0.6,0.2-1.9-0.3-3.3,0c-1.4,0.3-1.3,1.4-1.8,1.3c-0.5-0.1-2.1,0.1-2.6-0.2c-0.6-0.3-0.1-0.5,0.5-0.5
      c0.6,0,0.8-0.2,0.5-0.6c-0.3-0.4,0.5-1,0.4-1.3c0-0.3-2.3,0.5-2.7,0.8c-0.4,0.3,0,0.9-0.4,1.1c-0.4,0.2-0.4-0.3-0.9-0.5
      c-0.5-0.2-3.9,0.6-4.5,1.3c-0.6,0.7-1.9,0.8-1.9,1.2c0,0.4-1.5,0.4-2.1,0.7c-0.6,0.3-0.2,1.5-0.4,2.1c-0.2,0.6-2.6,0.4-3.3,0.4
      c-0.7,0-1-1.4-1.6-1.5c-0.6-0.1-0.2-0.9-0.1-1.2c0.2-0.3,0.6-0.1,1.4-0.5c0.7-0.5,1.4,0.2,1.7-0.1c0.3-0.3-1.1-1.2-1.2-1.9
      c-0.1-0.7-1.6-0.9-2.5-0.7c-0.9,0.2-1.8,0-2.4-0.3c-0.6-0.3-0.5,0.5,0.3,0.8c0.9,0.2,0.4,0.9,0.6,1.4c0.1,0.4-0.2,1.4-0.8,2.2
      c-0.5,0.8-0.1,0.7,0.6,0.7c0.8,0,0.7,1,0.7,1.7c0,0.8-0.6,1-0.6,1.5s-0.6-0.3-0.8,0c-0.2,0.2-0.5-0.1-0.6-0.6
      c-0.1-0.5-1.3-0.2-2-0.4c-0.7-0.2-0.9,0.1-1.2,0.6c-0.3,0.5-1.2,0.7-1.7,0.8c-0.5,0.1-1.4,1-1.9,1.4c-0.5,0.3-0.6,1,0.1,1.7
      c0.7,0.7,0.9,1.2,0.8,1.5c-0.1,0.4-2,0.3-2.3-0.1c-0.3-0.4-1.6-0.5-2-0.5c-0.4,0.1-1.6-1.3-2.2-1.3c-0.6,0-1,0.7-1.2,1.1
      c-0.2,0.4,0.3,0.5,0.6,1.1c0.3,0.6,1.4,0.7,1.9,0.7c0.5,0,0.3,0.8,0.2,1.2c-0.2,0.3-1.2,0.6-1.5,0.2c-0.3-0.4-1.5,0-1.5-0.5
      c0-0.5-0.9-1.2-1.8-1c-0.9,0.1-1.1-0.4-1-1.1c0.1-0.7-0.5-1.1-0.5-1.4c0-0.3-0.4-1,0.1-1.3c0.5-0.3,0.1-0.8,0.1-1.2
      c0-0.4-0.8-1.1-1.5-1.1c-0.7,0-0.6-0.7-1.2-0.9c-0.6-0.1-1.8-1.2-1.9-1.6c-0.1-0.4-1-0.5-0.7-0.6c0.3-0.1,1,0.1,1.6,0.6
      c0.5,0.5,1.4,1,2.7,1.2c1.4,0.2,1.9,0.8,3.4,1.1c1.5,0.3,2.4,0.4,4.6,0.8c2.2,0.3,5.1-1.9,5.7-2.5c0.6-0.6-0.2-1.9-0.2-2.3
      c0-0.5-1-0.5-1.1-0.9c-0.1-0.3-0.8-1-1.6-1c-0.8,0-1-1-1.6-0.9c-0.7,0.1-1.1-0.3-2.9-1.4c-1.7-1.2-4.1-2-4.6-1.8
      c-0.4,0.2-0.7,0.3-1-0.1c-0.3-0.4-1-0.4-1.4,0c-0.5,0.3-0.9-0.3-1.8-0.1c-0.9,0.2-1.3-0.4-1.1-0.6c0.2-0.3,1.5,0,1.4-0.5
      c-0.1-0.4-0.5,0-1.3-0.5c-0.8-0.5-1.2-0.2-1.4,0.3c-0.2,0.4-0.8,0.3-1.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.5-0.3-1.4-0.3-1.5,0.1
      c-0.1,0.4-0.9,0.3-0.9-0.1c0-0.4-1.2-0.8-1.3-1c-0.1-0.2,0.5-0.2,1,0.1c0.5,0.3,0.8,0.1,1.3-0.4c0.5-0.5,1.2-0.1,1.3-0.5
      c0.1-0.4-0.8-0.7-1-1c-0.2-0.3-0.8-0.6-1.5-0.5c-0.6,0.1-0.7-0.1-1-0.5c-0.4-0.3-1.5,0.2-1.5,1c0,0.8-0.7,0.6-0.5,0.2
      c0.3-0.4-0.3-1,0.2-1.1c0.6-0.1,0.3-1-0.8-1.1c-1.1-0.1-1.5,0.8-1.2,1.1c0.3,0.3-0.7,1.5-1.2,1.5c-0.4,0,0.1-1.1,0-1.6
      c0-0.6-0.4-0.3-1.2,0.7c-0.8,0.9-1.4,1.8-1.9,1.9c-0.5,0.1-0.4-0.8,0.3-1.2c0.8-0.4,0.8-1.5,1.3-1.5c0.5,0,0.5-0.6-0.2-0.8
      c-0.7-0.2-0.8,0.6-1.1,0.9c-0.2,0.2-1.4-0.4-1.4-0.2c0,0.2-0.6,0.3-0.3,0.8c0.3,0.4-0.1,0.8-0.4,0.3c-0.3-0.5-1-0.1-1.4,0.5
      c-0.3,0.6-0.8,0.5-0.5,0.8c0.3,0.2,0.3,1-0.2,1.1c-0.5,0.1-0.3-1.4-0.7-1.3c-0.4,0.1-0.1-0.8,0.4-0.8c0.6,0,1.1-0.8,1-1
      c-0.1-0.3-0.7-0.3-0.7,0.2s-0.9,0.3-1.6,0.3c-0.7,0-0.7,0.8,0.1,1.3c0.8,0.5-0.1,0.8-0.6,0.4c-0.4-0.3-1-0.2-1.5,0
      c-0.4,0.2,1,0.7,1.1,1c0.1,0.3-0.8-0.3-0.9-0.1c-0.2,0.2-1-0.1-1-0.6c-0.1-0.5-1.4,0.2-0.6,0.4c0.7,0.2,0.5,0.6,0.5,1.3
      s-0.8,0.1-0.7-0.4c0.2-0.5-0.5-0.4-1.1-0.1c-0.6,0.3,0.1-0.6-0.1-1.1c-0.2-0.5-0.8,0-1.4,0.1c-0.6,0.1-0.9,0.1-0.5,0.5
      c0.4,0.4,0.3,1-0.2,0.9c-0.5-0.1-0.7,0-0.6,0.5c0.1,0.5-0.6,0.5-0.7,0.1c-0.1-0.4-1-0.3-1.3,0.2c-0.3,0.5-1.1,0.8-1,1.3
      c0.1,0.5,0.7-0.3,1.2-0.1c0.5,0.2,0,0.4,0.3,0.7c0.3,0.3,0.2,0.9-0.2,0.5c-0.4-0.4-0.9-0.5-1,0.1c-0.1,0.6-0.5-0.1-0.9-0.5
      c-0.3-0.4-0.7,0.8-1.1,0.5c-0.4-0.3,0.8-1.2,0.5-1.6c-0.3-0.4-0.5,0.3-1.2,0.9c-0.7,0.7-1.6,0.6-1.2,1c0.3,0.3-0.7,0.5-0.8,1
      c-0.2,0.6-1.5,0.8-2.4,1.3c-0.9,0.6,0.2,0.6,0.7,0.1c0.5-0.6,0.7-0.2,1.4-0.7c0.8-0.5,1.6-0.9,1.9-0.7c0.3,0.2,0.8,0.3,1.2-0.2
      c0.4-0.5,0.8-0.4,1.2-0.1c0.4,0.3-0.6,0.6-0.2,1c0.5,0.5-0.6,0.7-0.6,0.3c0-0.4-0.6-0.8-0.8-0.4c-0.2,0.4-0.5,0.8-0.9,0.8
      c-0.4,0-0.8,0.7-0.8,1.4c0,0.7-0.7,0-0.7,0.6c-0.1,0.6-1,1.7-1.8,2.5c-0.8,0.8-0.1,1-0.3,1.4c-0.3,0.5-1.2,0.1-1.5,0.3
      c-0.3,0.2,0.2,1.4-0.3,1.8c-0.4,0.4,0,0.9,0,1.3c0,0.4-1.1-0.3-1.2,0.2c-0.1,0.4-1.1,0.3-1.4,0.4c-0.3,0.1,0.6,0.8,1.1,1.2
      c0.5,0.4-0.5,0.7-0.6,0.3c-0.1-0.5-0.8,0.3-1.7,0.7c-0.9,0.4-0.5,1.2-1.2,1.2c-0.7,0-0.5,1.1-1,1.5c-0.5,0.3-0.3-0.8-1.1-0.8
      c-0.7,0-0.7,0.5-0.2,1.1c0.4,0.6-0.7,0.1-1.1,0.7c-0.4,0.5-1.6,0.3-1.9,0.7c-0.3,0.4,0.9,0.4,1,0.8c0.2,0.3-1,0.2-1.7,0
      c-0.6-0.1-0.5,0.8-1,0.7c-0.6-0.1-1.3,0.5-0.9,0.9c0.4,0.4-0.3,0.5-0.7,0.2c-0.4-0.3-0.8,0.4-0.9,0.9c-0.1,0.5,0.8,0.8,0.8,1
      c0,0.2-0.8,0.6-0.2,0.7c0.5,0.1,0.1,0.6-0.2,0.7c-0.3,0.1-0.3,0.5,0,0.9c0.3,0.4-0.6,0.5-0.1,1.2c0.5,0.7,0.8-0.2,1,0.3
      c0.2,0.5,0.8,0.1,1.4-0.5c0.6-0.6,0.9,0.6,0.3,0.6c-0.5,0-1.1,0.3-1,0.8c0.1,0.5-0.8,0.4-0.8-0.2c0-0.6-1.1,0.1-0.7,0.6
      c0.4,0.5-0.1,0.7-0.1,1.2s0.8,0.3,1.1-0.1c0.3-0.5,0.8-0.4,1,0.1c0.2,0.5-0.5,0.1-0.5,0.8c0,0.7-0.4,0.1-0.8,0.6
      c-0.4,0.5,1.5,1.5,2.1,1.6c0.6,0.1,1.1,0.8,2.3,0.6c1.2-0.2,3.4-2.3,3.6-2.7c0.3-0.4,0.9,0.1,1.4-0.3c0.4-0.3,0.2-1.2,0.5-1.4
      c0.3-0.2,0.5,1,0.8,1.1c0.3,0.1,0.5,0.3,0.5,0.9c0,0.1,0,0.1,0,0.2c0,0.7,0.2,1.4,0.9,2c0.6,0.5,0.1,1.3,0.7,2
      c0.5,0.7,0.4,1.2,1,1.8c0.6,0.5,0.7,0.8,0.4,1.5c-0.3,0.7,0.5,0.5,0.6,0.9c0,0.4-0.2,1.2,0,1.6c0.2,0.4,0.6,0,1.5,0
      c0.9,0,0.7-0.6,0.7-1.2c0-0.5,0.5-0.3,0.6-0.6c0.1-0.3,1-0.4,1.6-0.1c0.7,0.3,1-0.2,1.1-0.9c0.1-0.7,0.5,0.1,0.7,0.3
      c0.2,0.1,0.8-1.1,1.1-1.9c0.3-0.8,0-0.6-0.7,0.1c-0.7,0.8-0.3-0.8,0-1.4c0.3-0.6,0.2-2.4,0.3-2.8c0.1-0.4,0.3-1,1.1-1.2
      c0.8-0.2,1.9-1.2,1.8-1.6c-0.2-0.4,1.2-1.2,1.2-1.6c0-0.4-1.4-1.8-1.9-2.1c-0.5-0.3-1.5-0.1-1.4-0.4c0.1-0.3-0.1-1.6-0.2-2.2
      c-0.1-0.6,0.7-1.3,0.7-1.9c0-0.6,0-1.3,0.5-1.5c0.5-0.2,0.2-0.7,0.9-0.9c0.7-0.2,0.6-1.2,1.4-1.5c0.8-0.3,0.8-0.6,1.9-1.1
      c1-0.5,2.7-1.6,3-2.3c0.3-0.6-1-1.2-0.2-1.9c0.8-0.6,0.1-1.4,0.8-1.6c0.6-0.2,0.8-0.6,1.3-1c0.4-0.5,1,0,2.1-0.2
      c0.4-0.1,0.7,0,1.1,0c0.6,0.1,1.1,0.4,1.6,0.6c0.8,0.3,0.7,0.9,0.7,1.5c0,0.6,0.1,1-0.3,0.7c-0.4-0.3-0.8,0-1.1,0.9
      c-0.2,0.9-1.3,1.9-2,2c-0.7,0.1-0.6,0.8-1.2,0.9c-0.6,0.1-1.1,0.5-1,1.1c0,0.6-0.8,0.7-1.4,0.7c-0.6,0-0.4,1-0.9,1.2
      c-0.4,0.3-0.3,0.6,0.1,1.1c0.4,0.5,0.1,1,0.2,1.4c0.1,0.4,0.8,1.2,0.3,2.2c-0.4,1-0.9,2.1-0.5,2c0.4-0.1,1.2,0.8,1.8,0.7
      c0.6-0.1,0.6,0.8,1.2,0.6c0.6-0.2,0.6,0.5,2.1,0.5c1.6-0.1,4.8-1.4,6.1-1.4c0.7,0,1.3-0.1,1.8-0.2c0.3-0.4,0.6-0.9,0.9-1.2
      c-0.3,0.3-0.7,0.8-0.9,1.2c0.3-0.1,0.6-0.2,0.8-0.3c0.5-0.2,0.8,0.9,1.2,1.3c0.5,0.4,1.4,0,1.7,0.4c0.3,0.3-0.3,0.4-0.9,0.3
      c-0.7-0.1-0.8,0.3-1.9,0.6c-0.8,0.3-0.7,0.5-1,0.8c-0.1,0.1-0.2,0.1-0.4,0.2c-0.8,0.2-2.7-0.6-3.7-0.4c-1,0.2-3.2,0.2-3.6,0.7
      c-0.5,0.5-1.7,0.7-1.2,1.1c0.6,0.5,0,0.7,0.4,1.2c0.4,0.6,0.6,0.8,1.2,0.7c0.6-0.1,1,0.2,0.4,0.8c0,0,0,0,0,0
      c-0.5,0.6-0.1,1-0.2,1.9c0,0.9-1,0.8-1.6,0.8c-0.5,0-1.4-1.6-1.7-2c-0.3-0.4-1.2,0.5-1.9,0.9c-0.7,0.4-0.3,1.2-0.8,1.6
      c-0.5,0.4-0.8,1-0.3,1.9c0,0,0,0.1,0,0.1c0.4,0.8,0.3,1.6-0.2,2.1c-0.3,0.4-0.1,0.4,0.1,0.5c0.1,0.1,0.2,0.1,0.3,0.3
      c0.3,0.5-1,0.5-0.6,1.1c0.4,0.5-0.9,0.6-0.9,0c0-0.6-1.4-0.3-1.3,0.1c0,0.1,0,0.3-0.1,0.5c-0.2,0.3-0.5,0.6-1.1,0.6
      c-0.8,0-0.9-0.8-0.9-1.2c0-0.4-3.4,0.3-4.3,1.2c-0.9,0.9-3,0.5-3,1c0,0.4-0.4,0.5-0.6,0.4c-0.1,0-0.1-0.1-0.2-0.2
      c-0.1-0.5-0.4-0.6-1.2-0.6c-0.8,0,0.5-0.8,0.2-1.1c-0.3-0.4-1,0.7-1.7,0.5c-0.7-0.2-1.6,0.8-2.2,1.3c-0.6,0.5-1.5-0.1-1-0.8
      c0.5-0.7,0-0.6-0.7-0.3c-0.7,0.3-1.5-0.1-1.4-0.7c0-0.2,0-0.3-0.1-0.5c-0.3-0.4-0.8-0.8-0.8-1.1c0.1-0.4,0.6-0.1,1,0.2
      c0.4,0.3,1.3,0.4,1.4,0c0.1-0.3-0.1-1.5-0.7-1.2c-0.5,0.3-0.7,0.2-0.9-0.1c-0.2-0.3,0.4-0.8,0.5-1.2c0.1-0.5,1.1-0.3,1.2-0.7
      c0.1-0.4-0.6-0.8-0.9-0.9c-0.3-0.1-0.4-1.1-0.1-1.4c0.3-0.3,0.5-1.4,0.2-1.7c-0.3-0.3-1.3,0-1.4,0.8s-0.9,0.4-1.2,0.6
      c-0.2,0.2,0.5,0.5,0.1,0.9c-0.4,0.3-0.5-0.5-1.1-0.6c-0.6-0.1-0.7,0.9-1,1.4c-0.3,0.5-0.1,1.5,0,2.2c0.1,0.7,1,1.1,0.8,1.7
      c-0.1,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.5,0.7,0.3,0.9c-0.3,0.5,0.1,1,0.5,1.7c0.4,0.7-0.8,0.3-0.8,0.8c0,0.5-1.1,0-1.4-0.2
      c-0.3-0.2-1.5-0.1-1.5,0.2c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.4-0.5,0.2c-0.4-0.3-1.1-0.1-2,0.1c-0.9,0.2-1.1,1-0.8,1.4
      c0.3,0.3-0.8,0.5-0.9,0.1c-0.1-0.5-1-0.1-0.9,0.5c0.1,0.6-1,0.8-0.9,1.6c0.1,0.8-0.9,1.4-1.6,1.8c-0.1,0.1-0.2,0.1-0.3,0.2
      c-0.4,0.2-0.9,0.5-1.3,0.6c-0.1,0-0.3,0-0.4,0c-0.7,0-1.3,0.5-1.2,1.7c0.1,1.2-0.8,1.3-2,1.4c-1.2,0.1-0.8,1-1.3,1.4
      c-0.5,0.4-2.2-0.2-2.5-0.8c-0.2-0.6-1.8,0-1.1,0.8c0.7,0.8,0.6,1.8,0.3,2.1c-0.4,0.3-1.2-0.5-1.7-0.1c-0.5,0.4-0.6-0.3-1.2-0.6
      c-0.6-0.2-0.9,0.3-1.9,0.2c-1-0.1-1.6,0.5-1.2,0.9c0.4,0.4-0.1,0.8,0.2,1.2c0.3,0.4,1.6,0.3,2.6,0.8c1,0.5,1.2,0.1,1.7,0.5
      c0.5,0.4,0.6,0.1,0.6,0.9c0,0.7,0.6,1.4,1.5,1.7c1,0.3,0.2,0.9,0.5,1.5c0.3,0.6-0.4,1.6-0.3,2.3c0.1,0.8-0.5,3.3-0.9,3.5
      c0,0-0.1,0-0.1,0c-0.6,0.1-2.3-0.5-3.4-0.4c-1.2,0.1-5-0.2-6.5-0.1c-1.5,0.1-1.6-0.6-2.4-0.6c-0.8,0-0.5,1-1.6,1
      c-1.2,0-1.3,0.8-0.4,1.4c0.5,0.4,0.4,1.2,0.4,1.9c-0.1,0.6-0.1,1.1,0.1,1.3c0.4,0.6-0.5,3.8-1.2,4.7c-0.8,0.9,0,1.1,0.8,1.7
      c0.8,0.6,0.3,2.8,0.3,3.3s0.7,0.4,1.5,0.4c0.5,0,1-0.2,1.6-0.3c0.4-0.1,0.7-0.1,1,0.1c0.8,0.4,1.3,2.4,2.4,2.3
      c0.5-0.1,0.2-0.4,0.5-0.6c0.2-0.2,0.7-0.1,1.1-0.1c0.5,0,0.7-0.7,1.8-0.7c1.1,0,1.8-0.1,2.5-0.1c0.7,0,1.2-0.2,1.4-0.7
      c0.2-0.5,0.8-0.9,1.5-1s0.7-0.9,0.7-1.4c0.1-0.5,1.4-1.2,1.4-1.5c0.1-0.3-0.8-1.7,0-2.9s1.6-1.2,1.6-1.9c0-0.6,0.8-0.8,1.8-1
      c1-0.1,1.3-0.8,2.1-1.1c0.7-0.3,1-0.8,0.8-1.6c0-0.2-0.1-0.3-0.1-0.5c-0.3-1.1,0.7-1.8,1.6-1.9c1-0.1,3,0.4,3.6,0.8
      c0.5,0.3,1.3,0.3,2.3-0.8c0.5-0.5,0.9-0.7,1.2-0.8c0-0.2,0.1-0.5,0.2-0.7c0.1-0.3-0.5-0.3-0.9-0.3c-0.4,0-0.7-0.5-0.4-1
      c0.3-0.5-0.3-0.5-0.6-1c-0.3-0.5,0.1-0.3,0.4-0.5c0.3-0.1,0.6-0.7,0.4-0.8c-0.2-0.1-0.4-0.4-0.2-0.9c0.6-0.1,1.4-0.1,1.7-0.1
      c0.4,0,0.8-1.3,1-1.4c0,0,0,0,0,0c-0.2,0.1-0.6,1.4-1,1.4c-0.3,0-1.1,0.1-1.7,0.1c-0.2,0.5,0,0.8,0.2,0.9c0.2,0.1-0.1,0.7-0.4,0.8
      c-0.3,0.1-0.7,0-0.4,0.5c0.3,0.5,0.9,0.5,0.6,1c-0.3,0.5,0,0.9,0.4,1c0.4,0,1,0,0.9,0.3c-0.1,0.2-0.2,0.4-0.2,0.7
      c0.3-0.1,0.6-0.1,0.9-0.3c0.6-0.3,0.3-0.9,1.4-1.2c1-0.3,2.3,0.6,2.8,1.2c0.4,0.6,0.5,0.7,0.6,1.2c0.1,0.5,0.5,1.5,1.9,2.4
      c1.4,0.9,1.9,1.9,2.7,2.3c0.7,0.4,1.4,0.3,1.9,0.8c0.4,0.5,1,0.8,1.7,1.1c0.7,0.3,0.7,1.1,1.4,1.2c0.7,0.1,0.2,0.7,0.6,1.2
      c0.4,0.5,0.5,1.5,0.1,2.1c-0.4,0.6-0.2,1.6,0.1,1.6c0.3,0,0.9-1,1-1.6c0.1-0.6,0.7-0.5,1.1-1.2c0.5-0.6-0.7-0.8-0.9-1.5
      c-0.2-0.8,0.9-1.5,1.9-0.8c1,0.6,1.4,1.1,1.5,0.1c0.1-1-3.1-2.3-3.9-2.7c-0.8-0.5-0.3-1.2-1.4-1.2c-1.2,0.1-2.6-1.4-3-3.1
      c-0.4-1.7-2.3-1.8-2.8-2.8c-0.5-1,0.4-1.1,0-2c-0.2-0.5,0.3-0.9,0.9-1.1c0.5-0.2,1-0.3,1.2-0.2c0.2,0.1,0.3,0.3,0.3,0.6c0,0,0,0,0,0
      c0.1,0.4,0,0.8,0.4,0.8c0.5,0,1.5-0.1,1.5,0.6c0,0.7,1.3,2.2,1.7,2.7c0.5,0.5,1.5,0.4,1.6,0.8c0.1,0.4,0.8,0.8,1.7,0.9
      c0.2,0,0.5,0.2,0.8,0.3c0.3,0.2,0.7,0.5,1,0.7c0.1-0.3,0.1-0.5,0.1-0.6c-0.1-0.4,1.2-2.2,1.5-2.2c0.3,0,0.4-1.7,0.2-2.6
      c0-0.2-0.1-0.3-0.1-0.4c-0.2-0.5,0-1.2-0.6-1.4c-0.3-0.1-0.5-0.7-0.5-1.2c0.8-0.3,2-0.6,2-0.6s0,0,0,0c-0.1,0-1.2,0.4-2,0.6
      c0.1,0.5,0.2,1.1,0.5,1.2c0.6,0.2,0.4,0.9,0.6,1.4c0.2,0.5,0.2,3-0.2,3c-0.1,0-0.4,0.3-0.7,0.7c-0.4,0.5-0.8,1.2-0.7,1.5
      c0,0.1,0,0.3-0.1,0.6c0.5,0.4,1,0.8,1.4,1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.8,0.4,1.1,0.7,0.5,1.2c-0.6,0.5-0.7,2.4-0.1,2.9
      c0.6,0.5,0.7,1.4,1.3,1.7c0,0,0.1,0,0.1,0c0.6,0.2,1.1,1,1,1.7c-0.1,0.7,0.9,1,1.4,0.5s1.8,0.3,1.8,0.6s-1.3-0.4-2.1,0.2
      c-0.8,0.5,0.6,1.1,0.4,1.6c-0.2,0.6,0.3,1.4,1,1.4c0.7,0,1.4,1.1,1.8,0.9c0.4-0.2-0.5-1.7-0.3-2c0.1-0.2,1,0.4,1.4-0.1
      c0.4-0.5-0.3-0.8-0.9-0.8c-0.6,0,0-0.8,0.7-0.4c0.7,0.4,1.1-0.1,1.4-0.2c0.3-0.1,0.4-1.3-0.6-1.6c-1.1-0.3-0.9,0.8-1.6,0.1
      c-0.7-0.7,0.6-0.4,0.6-1c0-0.6-1.1-1.5-1.6-2.2c-0.5-0.7,0.7-1.1,1-0.5c0.3,0.5,1.1,0.4,1.6,0.2c0.5-0.2-0.5-1.1,0.4-1.5
      c0.9-0.3,0.9,0.3,1.2,0.4c0.3,0.1,0.6-0.7,1.5-0.7c0.5,0,0.9,0.3,1.3,0.6c0.4,0.3,0.8,0.6,1.1,0.6c0.8,0,1.1-0.9,1.8-1.1
      c0.7-0.3,2.3,0.1,2.6-0.3c0.4-0.3-1-0.8-1.5-1.2c-0.3-0.2-0.4-0.7-0.5-1c-0.1-0.2-0.1-0.4-0.3-0.5c-0.3-0.1-0.8-1.4-0.4-1.5
      c0.4-0.1,0.9-1.5,1.6-1.7c0.3-0.1,0.4-0.2,0.4-0.4c0.1-0.3,0-0.7,0-1c0-0.6,0.4-1.5,1-1.6c0.6-0.1,1.1-0.1,1-1.1c0,0,0-0.1,0-0.1
      c-0.1-0.9,0.1-0.9,0.9-1.5c0.8-0.6,0.9-1.8,1.7-2c0.8-0.1,1.1-0.5,2.2-0.3c1.1,0.2-0.6,0.9,0.2,1.3c0.8,0.4,3.1-0.1,3.2,0.5
      c0.1,0.6-2.7,1.2-2.6,1.4c0.1,0.3,1.5,0.6,2,0.9c0.5,0.3-0.1,1.2,0.1,1.5c0.2,0.3,1,0.1,1.6-0.4c0.6-0.4,1.1-0.3,1.9-0.8
      c0.8-0.5,2.4-0.1,2.6-0.4c0.2-0.3-0.5-0.6-2-0.5c-1.5,0.1-1.6-1.3-1.6-1.6c0-0.3,1.6-1.5,2.5-1.6c0.9-0.1,1.6-0.3,2.5-0.8
      c0.3-0.2,1-0.3,1.6-0.4c0.9-0.2,1.9-0.3,2,0c0.2,0.3-2,1.3-2.4,1.4c-0.4,0.1,0.8,0.8,0.8,1.1c0,0.3-1.3,0.4-1.2,1.1
      c0.1,0.7-0.4,0.8-1.1,0.8c-0.7,0-0.8,0.4-0.5,0.5c0.3,0.1,0.3,0.9,1.5,1.2c1.2,0.3,2.6,1.5,3.6,2.6c0.1,0.2,0.3,0.3,0.5,0.4
      c1.1,0.7,2.8,0.5,3,1.7c0.3,1.4,1,0.8,0.8,2c-0.1,0.2-0.2,0.5-0.5,0.7c-0.9,0.9-3,1.4-3.6,1.2c-0.8-0.3-2.3,0.1-3.4,0.1
      c-1.1,0-1.4-0.8-2.3-0.8c-0.9,0.1-0.9-0.8-1.7-0.8c-0.9,0-0.2-0.4-2.9-0.8c-2.7-0.3-5.1,1.1-5.9,1.8c-0.9,0.8-4,0-4.3,0.2
      c-0.3,0.2,0.1,1.1-1,1.5c-1,0.4-3.3,0-4.1,0.3c-0.8,0.3-1.1,1.5-0.4,1.4c0.7-0.1,1,0.3,0.8,0.5c-0.2,0.3,0.5,1.1,0.2,1.4
      c-0.4,0.3-0.4,1,0.1,1.4c0.5,0.3,0.6,1,0,1.1c-0.5,0.1,1,0.7,1,1c0,0.3,1.3,0.4,1.3,0.8c0,0.3,0.4,0.5,1,0.3
      c0.6-0.2,0.8,0.7,1.2,1.2c0.4,0.6,2.5,0.2,2.5-0.5c0-0.6,0.6-0.8,1.6-0.7c1,0.1,2.2,1.4,2.7,1.6c0.5,0.2,1.4-0.3,2-0.3
      c0.6,0,1.5-1,1.8-1.2c0.3-0.2,1,0.7,1.5,0.4c0.6-0.3,1.2-0.6,1.5-0.4c0.3,0.2-0.8,1.1-0.5,1.6c0.1,0.2,0.1,0.4,0,0.7
      c-0.1,0.4-0.2,0.7,0,1c0.3,0.4-0.1,0.8-0.2,1.4c0,0.1,0,0.2,0,0.3c0,0.6-0.9,1.8-1.5,2.9l0.9,0.4l0,0l-1-0.4
      c-0.1,0.3-0.3,0.6-0.3,0.9c-0.2,1-1.1,2.8-1.5,3.5c-0.1,0.2-0.2,0.3-0.2,0.3c-0.2,0.2-1.2-0.3-2.1-0.1c-0.9,0.2-1.6-0.1-2.5-0.5
      c-0.9-0.4-3.7,0.1-4.1,0.7c-0.4,0.5-1.3,0.5-2.2,0.2c-0.9-0.3-2-0.9-3.6-1c-1.5-0.1-2.7-0.3-3-0.7c0,0,0,0-0.1-0.1
      c-0.1-0.4-0.9-0.4-2.6-0.7c-1.7-0.3-1.1-1.4-3-1.5c-1.9-0.1-4.3,1.4-4.4,2.2c0,0.8,0.9,1.7,0.3,2.5c-0.6,0.8-2,0.9-3,0
      c-1-0.9-2.4-1.3-3.6-1.3c-1.2,0-2-0.8-1.9-1.4c0.1-0.6-0.3-0.9-1.3-1.1c-1-0.2-1.8-1-3.6-0.7c-0.9,0.2-1.7-0.1-2.3-0.5
      c-0.5-0.3-0.8-0.7-0.8-0.8c0-0.4-0.5-1-0.8-0.7c-0.3,0.3-1.4,0.1-1.5-0.7c-0.1-0.7,0.7-0.7,1.5-1.4c0.8-0.7,0.9-1.9,0.1-2.6
      c-0.9-0.7-0.3-1.1,0.3-1.7c0.6-0.6,0.1-1-0.3-0.7c-0.3,0.3-0.8,0.4-0.9-0.3c-0.1-0.7-2-0.8-2.7-0.1c-0.3,0.2-0.6,0.3-1.1,0.3
      c-0.8,0-1.6-0.3-1.8-0.4c-0.3-0.1-1.4,0.2-2,0.2c-0.6,0-2.2,1-2.7,0.5c-0.5-0.5-5.9,0.4-7.4,0.4c-1.5,0.1-2.1,1.6-3,1.6
      c-0.9,0-2.3,0.8-3.1,1.6c-0.2,0.2-0.5,0.3-0.7,0.3c-0.7,0-1.5-0.5-2.3-0.5c-1,0-2.6,0.5-3.3-0.3c-0.8-0.8-1.3-1.1-1.8-0.8
      c-0.6,0.3-1.6,3.2-2.1,4c-0.6,0.8-2.2,1.4-3,1.4c-0.8,0-0.9,1.2-1.5,1.7c-0.6,0.5-0.4,1.3-1.1,1.9c-0.7,0.6-0.4,2.3,0,3
      c0.4,0.6-1.3,2.6-2.3,3.2c-1,0.6-1.3,1.7-2.9,1.9c-0.6,0.1-1.1,0.4-1.4,0.7c-0.6,0.5-0.9,1.2-1.1,1.8c-0.3,0.9-2.5,2.3-2.6,3.6
      c-0.1,1.3-2.1,3.3-3.4,6.1c-0.4,0.8-0.5,1.4-0.6,1.8c-0.1,1.3,0.7,1.7,1.1,2.2c0.6,0.7-0.5,1.9,0.4,3.2c0.9,1.2-0.2,3.2-0.6,3.8
      c-0.2,0.4-0.3,1-0.3,1.7c-0.1,0.6-0.2,1.1-0.4,1.6c-0.6,1,0.3,2.3,0.7,2.8c0.1,0.1,0.1,0.2,0.1,0.4c0,0.3-0.2,0.8-0.3,1.2
      c0,0.3,0,0.6,0.2,0.9c0.1,0.1,0.1,0.2,0.2,0.2c0.5,0.6,0.6,0.9,1.6,1.2c1.1,0.3-0.1,0.9,0.8,1.5c0.1,0.1,0.2,0.1,0.2,0.2
      c0.7,0.5,0.6,1.1,1.4,1.7c0.7,0.5,1.7,1.3,2.2,2.4c0.1,0.2,0.2,0.5,0.3,0.7c0.5,1.6,0.9,1.9,1.6,2.5c0.3,0.2,0.9,0.6,1.6,1
      c0.8,0.5,1.8,1.1,2.5,1.7c1.4,1.1,3.6,2.5,4.6,2.9c0.1,0.1,0.3,0.1,0.5,0.1c1.2,0,3.3-0.8,5.8-1.3c1.6-0.3,2.9,0,3.8,0.2
      c0.8,0.2,1.3,0.5,1.5,0.5c0.3,0.1,2.8-1,3.8-1.5c0.8-0.4,1.7-0.6,2.3-1c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.2,0.7-0.4,1.1-0.4
      c0.5-0.1,1-0.1,1.3-0.1c0.6,0,1-0.8,1.3-0.4c0,0,0,0,0,0c0.3,0.5,2,0.6,2.9,0.8c0.9,0.2,1.9,1.5,1.9,2c0,0.5,0.6,1.4,1,1.7
      c0.4,0.2,1.5-0.4,2-0.4c0.5,0,2.3,0,2.6-0.3c0.1-0.1,0.2-0.1,0.3,0c0.3,0.2,0.5,0.7,0.8,1.1c0.4,0.6,1.2,0.1,1.5,0.3
      c0.3,0.2,0.7,1.5,0.5,1.9c-0.1,0.4-0.6,1.3-0.4,1.7c0,0,0,0,0,0.1c0.3,0.4,0.1,1.1-0.5,1.3c-0.6,0.2-0.6,0.8-0.1,0.8
      c0.3,0,0.2,0.3,0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.3,0.3,0.6,0.6,0.8,1c0.1,0.4-1.1,0.3-1.1,0.8c0,0.5-0.4,1-0.8,1.2
      c-0.3,0.3,0.9,1.4,1,1.7c0,0.4,0.3,0.7,1.4,1.8c1.1,1,0.5,1.3,1.9,2.4c0.1,0.1,0.1,0.1,0.2,0.2c0.8,0.6,1.3,1.3,1.7,2.1
      c0.4,0.7,0.5,1.5,0.7,2.2c0,0.1,0,0.1,0,0.2c0.4,1.3,1.5,3.6,1.7,4.1c0.3,0.6-0.3,1-0.3,2.1s1.1,2.9,1.2,4.1c0.1,1.2-0.7,2-1.4,2.8
      c-0.7,0.8-1.4,2.6-1.4,3.8c0,1.1-1,2.2-1,2.7c0,0.5-0.1,2.1-0.3,2.6c0,0,0,0.1,0,0.1c-0.1,0.6,0.5,1.7,1.1,2.6
      c0.7,0.9,1.5,2.6,1.9,4c0.4,1.4,1.7,3,2,4.1c0.3,1.1-0.2,2.6,0.1,3.3c0.3,0.7,0.5,1.9,0.7,3.1c0.2,1.2,0.3,3.7,1.3,4.7
      c0.4,0.4,0.8,0.9,1.2,1.6c0.6,1,1.2,2.2,1.5,3.1c0.5,1.5,1.6,3,1.9,3.9c0.3,0.9,0.6,1.7,0,1.8c-0.6,0.1-0.6,0.6,0,1.6
      c0.6,1,0.1,1.6,0.6,1.7c0.5,0.1,0.4,0.5,0.8,0.5c0.4,0,0.8,0.5,1,0.8c0.3,0.4,1,0.4,1.3-0.1c0.3-0.5,1.3-0.6,2.3-0.6
      c1,0,0.8-0.4,1.8-0.7c1-0.3,2.2-0.3,3,0c0.8,0.3,1.5,0,1.9-0.2s1.2,0.4,1.2-0.3c0.1-0.7,0.9-0.6,1.6-0.6c0.8,0,2.6-1.2,4-2.5
      c1.4-1.4,3.8-4.3,4.7-5.7c0.8-1.4,2.1-2.5,2.8-3.1c0.5-0.5,0.8-2.1,0.9-3.2c0-0.4,0.1-0.7,0.1-1c0.1-0.9-0.6-0.2-0.8-0.8
      c-0.2-0.6,1.4-1.7,3.1-2.3c1.7-0.6,2.3-1,2.5-1.5c0.2-0.6,0-2.2,0.2-2.8c0.2-0.6-0.1-1-0.3-2.5c-0.2-1.4-0.7-1.7-1-2.5
      c-0.2-0.8-0.2-1.4,0.3-1.5c0.5,0,0.5-0.3,0.9-1c0.4-0.8,0.9-0.5,1.6-1c0.8-0.6,0.8-1.3,2.1-2.3c1.2-1,2.6-0.9,3.8-1.6
      c1.2-0.7,3.3-3.6,3.1-4.1c-0.2-0.5-0.6-2.9-0.5-3.8c0.1-1-0.2-3.5-0.2-4.7c0,0,0,0,0-0.1c-0.1-1.1-1.3-0.6-1.4-1.8
      c-0.1-1.2-0.8-1.2-0.7-2c0-0.8,0.1-1.5-0.2-1.6c-0.3-0.1-0.1-0.8,0.3-1.5c0.5-0.7-0.8-0.8-1-1.6c-0.1-0.4,0.3-1.6,0.9-3
      c0.7-1.8,1.7-3.9,1.9-4.3c0.4-0.7,0.9-0.5,1.2-1c0.4-0.5,0.5,0.2,1.1-0.7c0-0.1,0.1-0.2,0.2-0.2c0.8-1,2.8-3,4.4-4.6
      c1.6-1.7,2.5-2.1,3.4-2.6c0.9-0.5,3.3-2.7,4.9-4.8c1-1.3,2.1-2.7,2.3-3.6c0.1-0.9,1.1-2.1,1.8-3c0.7-0.9,1.9-3.2,1.8-3.9
      c0-0.7,0.6-2.2,0.7-3.4c0.1-1.2-1-1.4-1.3-0.7c-0.3,0.7-2.8,1.2-4.4,1.2c-1.6,0-2.5,0.2-3.1,0.7c-0.6,0.6-2-0.1-2.5,0.5
      c-0.5,0.6-2.6,1.1-3.2-0.3c-0.3-0.6-0.7-0.9-1-1.1c-0.5-0.3-0.9-0.3-1-0.4c-0.2-0.2,0.6-0.8,1.1-0.9c0.5-0.1,0.3-0.8-0.2-1.2
      c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.4-1.2-1.2-2.1-2.3c-1.1-1.4-2.4-2.1-3.1-2.1c-0.7,0-0.8-1.2-1.2-1.2c-0.4-0.1-1.4-1.8-1.4-2.9
      c0-0.6-0.5-1.5-1.1-2.2c-0.4-0.5-0.9-1-1.2-1.1c-0.7-0.3-1.2-1.5-1.2-3.1c0-1.5-0.4-2.7-0.4-3.2c-0.1-0.5-0.8-1.5-1.7-2
      c-0.6-0.3-1-0.9-1.1-1.5c0-0.3-0.1-0.5,0-0.8c0.1-0.9-0.5-0.9-0.9-2.2c-0.4-1.3-2-3.2-2.1-4c-0.1-0.8-1-1.2-0.9-2
      c0-0.8-0.4-0.9-1.3-2.2c-0.9-1.3-1.2-3.5-0.9-3.6c0.3-0.1,1,1.9,1.3,2.5c0.3,0.6,1.3,2.6,2,2.6c0.7,0,1-2.9,1.2-2.8c0,0,0,0,0.1,0.1
      c0.1,0.4-0.1,2.1,0.3,2.2c0.4,0.1,0.7,0.9,1.6,1.9c0.9,1,2.8,3.7,2.8,4.4c0,0.7,0.3,1.4,1.5,2.2c1.2,0.8,1.2,1.7,1.8,2.2
      c0.6,0.5,0.5,1,0.5,2.2c0,1.2,0.6,2.7,1.6,3.3c1,0.6,1.8,1.3,2.3,2.6c0.5,1.2,1.1,2.3,1.8,3c0.7,0.7,0.3,1,0.7,1.5
      c0.2,0.3,0.5,0.7,0.6,1.1c0.2,0.4,0.3,0.9,0.2,1.2c-0.2,0.7,0,2.2,0.6,3.1c0.6,0.9,0.4,2.5,0.7,2.9c0.3,0.3,2.5,0.3,3.6-0.4
      c1-0.7,1.7-0.8,2.5-0.8c0.8,0,1.9-0.7,2.1-1c0.3-0.3,1.4-0.4,1.9-0.3c0.5,0.1,1-0.5,1.5-1c0.5-0.6,2.3-0.8,4.1-1.4s1.4-1.4,1.9-2
      c0.4-0.4,0.9-0.3,1.7-0.6c0.2-0.1,0.3-0.1,0.5-0.2c1.1-0.6,2.3-0.3,3.2-0.5c0.8-0.2,0-0.9,0.8-1.5c0.7-0.6,1.8-0.1,2.1-0.3
      c0.4-0.2,0.6-1.5,1.3-1.8c0.6-0.3,1.3-0.1,1.2-0.6s0.1-2.5,0.7-2.6c0.6-0.1,1.6-0.8,1.6-1.1c0-0.3,1.2-1.6,1.6-2.3
      c0.4-0.6,0.4-1-0.1-1.1c-0.5-0.1-1.4-1.6-1.7-2.1c-0.2-0.4-1.9-0.1-3.3-1.2c-0.6-0.4-0.9-1.3-1.1-2.1c-0.3-1.2-0.3-2.4-0.5-2.4
      c-0.3,0-1.8,1.5-2.3,2.6c-0.6,1.1-2.3,2-3.7,1.7c-1.4-0.3-1.9,0.3-2.3,0.6c-0.1,0.1-0.2,0-0.4-0.1c-0.3-0.2-0.7-0.8-0.8-1.3
      c0-0.2,0-0.4,0-0.5c0.4-0.6,0.6-2.3-0.2-2.5c-0.8-0.2-1.3,1.4-1.3,1.9c0,0.1-0.1,0.1-0.1,0c-0.4-0.3-1.4-1.7-1-2.1
      c0.4-0.5-0.2-1.5-0.9-2.1c-0.6-0.6-1.9-1.6-2-2.3c0-0.2-0.1-0.5-0.2-0.8c-0.3-0.9-0.9-2.2-0.9-2.5c0-0.1,0.1-0.2,0.2-0.3
      c0.2-0.2,0.5-0.4,0.9-0.5c0.2-0.1,0.3-0.1,0.5-0.1c0.5,0,0.5-0.6,0.9-0.2c0.4,0.3,1,0,1.4-0.1c0.4,0,1.5,2.1,2,3.1
      c0.5,1,0.5,1.4,1.1,1.8c0.7,0.4,1.4,0.2,2.4,1.2c1,1,3.4,1.6,4.5,1.7c1,0.1,1.8-1.1,3.2-0.9c1.3,0.3,1,2,1.5,2.6
      c0.5,0.6,5.9,1,6.8,1.3c0.3,0.1,1.1,0.2,2,0.2c1.6,0,3.5,0,3.7-0.3c0.3-0.3,1.1-0.5,1.5-0.2c0.4,0.3,2.4,0.4,3.2,0
      c1-0.5,1.5,0,1.6,0.5c0,0.5,0.5,0.7,0.9,1.1c0.4,0.4,0.1,0.9,0.4,1.3c0.3,0.5,0.9,0.4,1.7,0.8c0.1,0.1,0.2,0.1,0.3,0.2
      c0.6,0.5,0.8,1.3,1.8,1.7c1.1,0.4,2.1-0.7,2.1,0.1c0,0.8-2.7,0.7-2.7,0.9c-0.1,0.3,2.8,3.6,4.2,3.4c1.4-0.3,2.5-1.8,2.1-2
      c-0.4-0.2,0.2-1.2,0.6-1c0.4,0.2,0.1,1.7,0.5,2c0.4,0.3,0.3,1,0,1.8c-0.3,0.8,0.3,2.4,0.4,3.3c0.1,0.9,0.7,2.2,0.9,3.5
      c0.2,1.3,1,3.8,1.8,5.2c0.8,1.5,1.4,4.4,1.9,5.1c0.5,0.7,1.9,2.8,1.9,4.1s1.4,3.1,1.9,3.5c0.5,0.3,1.6-0.4,1.8-1.2
      c0.3-0.8,1.7-1.1,1.8-1.8c0.1-0.7,0.2-1.2,0.8-1.3c0.5-0.1,0.5-0.6,0.5-1.4c0-0.8,0.1-1.8,0.6-2.5c0.5-0.7,0.6-2.5,0.3-3.2
      c-0.3-0.7-0.1-1.7,0.1-2.5c0.3-0.7,0.2-1.6,0.7-1.6c0.6-0.1,1.4-0.6,1.9-1.3c0.6-0.8,2.3-1.9,3-2.4c0.6-0.5,2.6-2.2,3.2-3.2
      c0.6-1,2.2-1.3,3-2.1c0.8-0.8,0.5-1.2,0.6-2c0.1-0.8,2.6-1.2,3.5-1.2c0.2,0,0.3,0,0.5,0c0.5-0.1,0.8-0.4,1.3-0.4
      c0.6,0,1.5-0.1,1.6-0.8c0.1-0.7,0.8-1.2,1.2-1c0.4,0.2,1,1.8,1,2.7c0,0.3,0.1,0.6,0.3,1c0.4,0.8,1.1,1.6,1.8,1.9
      c1.1,0.5,1.9,2.7,2.2,4c0.3,1.2,0.1,2.5-0.3,3.2c-0.4,0.7,0.1,0.7,0.8,1.1c0.7,0.3,1.4,0.5,1.9-0.2c0.5-0.7,1.5-1.2,1.6-2
      c0.1-0.8,0.6,0.4,1,0.8c0.4,0.4,0.7,1.2,0.8,2.2c0.1,1,0.2,3.4,1,4.2c0.8,0.8,1,2.5,0.8,3.1c-0.2,0.6,0.4,1.6,0.3,2.3
      c0,0.1-0.1,0.3-0.1,0.5c-0.2,0.8-0.4,2.2-0.6,2.9c-0.2,0.9-0.4,1.7,0.1,1.7c0.5,0.1,1.6,1.2,2.6,2.5c0.2,0.3,0.4,0.6,0.6,1
      c0.5,1,0.5,2.1,0.5,3c0,1.2,1.7,3,2,3.8c0.3,0.8,0.8,1,1.9,1.8c1.1,0.8,3.3,2.1,3.8,1.9c0.4-0.2-0.7-2.1-1.3-2.8
      c-0.6-0.7-0.1-2.5,0-3.8c0-1.3-1.2-1.2-1.5-2.1c-0.3-0.9-0.8-0.4-1-1c0-0.1-0.1-0.2-0.1-0.3c-0.4-0.6-1.4-1.2-2.3-1.3
      c-1-0.1-1.4-1.6-1.4-2.2c0.1-0.7-0.7-1.2-0.7-1.8c0-0.6-0.6-0.6-1.2-0.9c-0.7-0.3,0.1-2.3,0.2-3.1c0.1-0.8,1.5-3.2,1.1-4
      c-0.4-0.8,1.4-1.3,1.7-0.5c0.2,0.8,0.3,1.2,1.5,1.2c1.2-0.1,1.2,0.8,2.1,1.1c0.1,0,0.1,0,0.1,0.1c0.7,0.3,0.4,1.2,0.5,1.8
      c0.1,0.6,0.8,0.2,1.2,0.2c0.4,0-0.3,0.6,0.1,1c0.3,0.4,1.4-0.3,1.6,0.1c0,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.5,0.4,0.9,0.7
      c0.5,0.3-0.6,0.8-0.6,1.7c0,0.9,0.7,1.2,1,0.8c0.3-0.4,1.3-1.2,1.8-1.4c0.4-0.1,0-0.8,0.5-0.8c0.5,0,0.3-0.7,0.5-1
      c0.2-0.4,0.5-0.2,1.2-0.2c0.7,0,1.7-0.6,2.7-1.3c1-0.6,1.2-0.8,1.5-2.6c0.4-1.8-0.4-4-0.5-4.5c0-0.6-1.4-3.1-1.9-3.1
      c-0.5,0-2.8-2.3-3.2-2.7c-0.4-0.4-0.3-1-0.9-1.5c-0.6-0.5-0.6-1.8-0.5-2.7c0.1-0.9,1.4-1.1,1.4-1.7c0-0.6,0.1-1.1,0.8-1.2
      c0.6,0,1.2-0.6,1.5-1c0-0.1,0.1-0.1,0.2-0.1c0.4-0.2,1,0.1,1.2-0.3c0.2-0.5,0.8-0.4,1.1,0.1c0.3,0.4,0.6,0.5,1.1,0
      c0.6-0.5,0.9,0.5,0.5,0.8c-0.3,0.3-0.1,1.2,0.4,1.7c0.6,0.5,0.6-0.3,0.5-0.8c-0.1-0.5,0.9-1,2.8-1.6c1.8-0.6,3.8-1.9,3.8-2.2
      c0-0.3,1.3,0.5,1.9,0.1c0.6-0.5,2.8-0.5,3.4-0.6c0.6,0,0.6-0.5,1.2-1c0.6-0.5,0.5-0.7,1.2-0.9c0.7-0.2,1.1-0.8,1.1-1.2
      c0-0.3,1.3-0.5,1.3-0.8c0-0.4,1-0.5,1-1c0-0.5,0.6-0.4,0.8-0.7c0.3-0.3-0.2-1.4,0.3-1.5c0.5-0.1-0.2-0.7-0.3-1
      c-0.1-0.3,0.9-0.3,1.4-0.6c0.5-0.3,0.8-0.8,0.9-1.4c0.1-0.5,0.9-1,1.3-1.2c0.4-0.1,0.5-0.9,0.4-1.6c-0.1-0.7,0.7-0.5,0.3-0.8
      c-0.3-0.3,0.1-1,0.8-1.2c0.7-0.2-0.4-0.5-0.8-0.2c-0.4,0.3-0.8-0.8-1.1-0.4c-0.3,0.4-1.3-0.3-0.7-0.4c0.6-0.1,1.6-0.9,2.1-1
      c0.4-0.1-0.6-1.1-1.2-1.1c-0.5,0-1.2-1.3-1.7-1.3c-0.6,0,0.4-0.4,1.1-0.1c0.7,0.3,1.4,0.6,1.7,0.3c0.3-0.3-1.1-1.4-1.7-1.9
      c-0.5-0.4,0-1-0.3-1.2c-0.3-0.2-1-1.7-1.2-2.4c-0.2-0.7-1.4-0.8-1.7-1.2c-0.3-0.4,0-1.4,0.9-1.8c0.9-0.4,0.5-1.1,0.9-1
      c0.4,0.1,0.8-0.1,1.1-0.8c0.3-0.7,0.9-0.3,1.2-0.6c0.3-0.3,1.7-0.3,2.2-0.7c0.5-0.3-0.5-1.4-1.1-1.2c-0.7,0.2-1.4-0.1-1.9-0.8
      c-0.5-0.7-1.7,1.3-2.6,1.7c-0.9,0.4-1.5-0.8-1.2-1.6c0.3-0.8-0.7-0.8-2-0.7c-1.3,0.1-0.8-2.3,0-2.4c0.8-0.1,1.9,0.7,2.4-0.7
      c0.5-1.4,1.2-0.3,2.3-1.6c1.1-1.3,2.4-2.1,3.3-1.3c1,0.8-1,1.9-1.3,2.7c-0.3,0.7,0.3,1-0.3,1.5c-0.6,0.6,0.2,0.7,1.4,0
      c1.1-0.7,2.5-1.6,3.6-2c0.3-0.1,0.6-0.1,0.9-0.1c0.9,0.1,1.8,0.6,2.2,1.1c0.6,0.7-0.6,1.5-1.2,2.1c-0.5,0.6,0.3,0.7,0.3,1.2
      c0,0.5,0.7,1,1,0.5c0.3-0.5,0.7-0.3,1.5-0.3c0.2,0,0.4,0.1,0.5,0.3c0.2,0.4,0.2,1,0.6,1c0.6,0.1,0.6,1-0.1,0.8
      c-0.7-0.1-0.7,0.9-0.1,1.8c0.6,0.9-1.1,1.9-0.6,2.4c0.5,0.5,0.1,1,0.3,1.3c0.2,0.3,2.7-0.1,3.7-1.2c1.1-1.1,1.4-1,1.9-0.8
      c0.6,0.1,1.1-0.4,0.8-0.8c-0.3-0.4-0.6-1.5-0.5-2.8c0-0.8-1-2.2-2-3.3c-0.7-0.8-1.5-1.5-1.7-1.6c-0.7-0.4-0.3-1.5,0.6-1.7
      c0.8-0.2,2.8-1.7,3.4-2.1c0.6-0.3,0.2-1.5,0.7-2.4c0.3-0.5,1.2-1.4,2.2-2.1c0.2-0.1,0.3-0.2,0.5-0.4v0c0.7-0.5,1.3-0.9,1.6-0.9
      c0.7,0,1.4,1.2,2.3,1.3c0.8,0,4.5-2.4,4.5-2.8c0-0.4,2.2-2.5,3.2-3.5c1-1.1,2.3-2.6,2.9-3.8c0.3-0.8,1.7-2.2,2.1-2.8
      c0.3-0.6,0.6-0.6,1.2-2c0.6-1.4,0.6-4.9,0.8-5.2c0.2-0.2,0.1-0.8,0.4-1.2c0.3-0.4,0.1-0.9,0.7-1.4c0.6-0.5,0.5-0.9,0.3-1.3
      c-0.1-0.4,0-1.2-0.1-1.5c-0.1-0.3-0.1-0.4,0.2-0.6c0.3-0.2-0.2-0.7-0.7-1c-0.5-0.2-1.1-0.4-1.2-1c-0.1-0.5-0.9-1.2-1.5-1.2
      c-0.6,0.1-1.8-0.3-1.7,0.1c0.1,0.4-0.1,0.8-0.4,0.7c-0.3-0.1-0.5,0.2-0.8,0.8c-0.4,0.6-1.7,0.6-1.1,0.1c0.6-0.6-0.2-0.6-0.1-1.2
      c0.1-0.6,0.6-1.2-0.1-0.8c-0.7,0.4-0.7,1.3-1.2,1.4c-0.5,0.1-0.4-1.6-0.2-2c0.1-0.4-1,0-2.2-0.1c-1.2-0.1-0.9-0.9,0.1-1.4
      c1-0.5,0.8-1,1.3-1.2c0.5-0.2,1.8-1,2.5-1.5c0.8-0.4,0.9-0.9,1.1-1.4c0.2-0.5,1.6-1.1,2.8-2.1c1.2-1,1.9-1.6,2.1-2.2
      c0.3-0.6,2.1-1.4,2.2-1.8c0-0.5,2.2-1.6,3.7-1.9c1.5-0.3,3.8,0.1,4.3,0.5c0.4,0.4,0.8,0.3,1,0c0.3-0.3,0.8-0.1,1.8,0
      c1,0.1,1.2-0.5,1.9-0.3c0.7,0.2,1.2,0.3,1.6-0.5c0.4-0.8,2.3-0.6,2.8-0.3c0.4,0.3,0.8,0.8,1.5,0.3c0.7-0.5,0.7,0.6,1.4,0.7
      c0.7,0.1,0.2,0.7-0.3,0.6s-1.3,0.3-0.6,0.7c0.7,0.4,1.4-0.3,2-0.3c0.5,0.1,1.3,0.1,2.1-0.3c0.7-0.5,0.9,0.4,1.4,0
      c0.5-0.4,1.5-0.5,2-0.5c0.5,0,0.1-0.6-0.8-0.6c-0.9,0-0.8-0.5-0.1-1.6c0.7-1.2,2-1.8,2.9-2.5c0.8-0.7,1.4-0.4,1.4-0.8
      c0-0.5,0.4-1.8,1-1.8c0.6,0,2.2,0.3,3.2-0.3c1-0.5,0.9,0.4,1.3,0.6c0.4,0.1,1-0.8,1.4-0.6c0.5,0.3-0.7,1-1,1.8
      c-0.3,0.8,0.6,0.5,1.1,0.8c0.5,0.2-0.6,0.6-0.5,0.8c0.1,0.2,1.1,0.2,2.4-1c1.3-1.3,2.4-1.6,3.2-1.5c0.8,0.1,0.3-0.7,0.4-1.9
      c0.1-1.2,2.5-1.5,3.4-1.2c0.8,0.3,0.8,0.7,0,0.5c-0.8-0.2-1.5,0.5-1.5,1.5c0,1-0.7,1.3-0.3,1.7c0.4,0.4-0.6,0.5-0.6,0.9
      c0,0.4,0,0.7-0.6,0.9c-0.6,0.2-2.5,0.4-2.6,1.1c-0.1,0.8-1.1,0.8-1.6,1.8c-0.5,0.9-2.4,1.5-3.6,3.3c-1.2,1.9-3.2,1.9-3.2,2.2
      c0,0.3-1.4,0.3-1.8,0.4c-0.5,0.1,0.4,1.2-0.7,2.4c-1.1,1.2-1.8,2.8-1.8,4.5c0,1.6,0.6,6.4,1.1,7.2c0.5,0.8,0.2,2.9,0.6,3.3
      c0.4,0.4,0.3,1.2,0.5,1.4c0.3,0.3,1.3-1.2,2.1-1.8c0.7-0.6,0.5-0.8,1-1.1c0.5-0.3,0.3-1.6,0.4-2c0.1-0.3,1-0.5,1.3-0.9
      c0.3-0.4,1-0.1,1.4-0.3c0.4-0.1,0-1.1-0.2-1.6c-0.1-0.5,1.2-1.3,1.9-1.8c0.7-0.5,1.6,0.3,2.3-0.4c0.8-0.7-0.1-1.6-0.3-1.9
      c-0.3-0.4,0.8-2.2,1.4-2.4c0.6-0.2,0.9,0.4,1.5-0.1c0.6-0.5-0.5-1.4-0.9-1.3c-0.4,0.1-0.6-1.5,0.4-2.2c1-0.7,0.6-0.9-0.1-1
      c-0.6-0.2-0.8,0.1-1.2,0.1c-0.5,0-1-1-0.2-2c0.8-1,1.5-0.9,1.7-1.7c0.2-0.8,1.2-2,1.5-2.5c0.3-0.4,1.3,0,1.7-0.1
      c0.4-0.1,0.3,0.8,0.8,0.3c0.5-0.4,1.3-1.9,2-1.9c0.7,0,0.2,1.1,0.4,1.7c0.2,0.6,0.6-0.2,1.8-1c1.3-0.9,4.1-0.8,4.9-0.4
      c0.8,0.4,1,1.4,1.4,1.2c0.7-0.2,0.1-1,0.8-1.2c0.7-0.2,1.9-0.9,2.8-1.6c0.9-0.7,0.7-0.2,1.2-0.8c0.5-0.6,1-0.3,1.1-0.8
      c0.1-0.4,1.5-1.1,3.3-1.5c1.8-0.4,4.2-1.6,4.1-2c-0.1-0.4,0.7-0.5,0.8-0.2c0.1,0.4,0.8,0.2,1.7,0.4c1,0.2,1.4,0.6,2.1-0.2
      c0.8-0.8-0.1-1-0.1-1.6c0-0.6-1.3-1.2-1.1-1.8c0.2-0.6-1-2.2-1.3-1.9c-0.3,0.3-1.2-0.3-1.2-0.7c0-0.5-0.1-1-0.6-0.7
      c-0.6,0.3-1.5,0.2-1.7-0.3c-0.2-0.5,1.1-0.9,1.9-0.6c0.9,0.3,0.6,0.7,1,1s1.6,0.1,2.2-0.1s2.3-0.8,2.6-1.3c0.3-0.5,0-0.7,0.5-1
      c0.6-0.3,0.1-0.9-0.3-1.2c-0.5-0.3-0.4-1,0.1-1s0.4-0.6,0.7-0.6c0.3,0,0.4,0.3,1-0.1c0.6-0.3,0,0.5-0.3,1c-0.3,0.5,0.6,1,0.7,1.3
      c0.1,0.3,1.3,0.4,2,0.1c0.7-0.4,2.9,0.5,3.1,1c0.1,0.6,0.5,1.2,1.4,1.7c0.9,0.5,1.8,0.3,1.9,0.7c0.2,0.4,0.5,0.7,1,0.5
      c0.5-0.1,0.8,0.5,1.2,0.1c0.4-0.3,0.6,0.2,1.1-0.2c0.5-0.4-1.1-0.7-0.9-1.1c0.2-0.3,0.5,0.3,1,0.2c0.4,0-0.1-0.9,0.2-1
      c0.3-0.1,0.1-1.8-0.3-1.9C697.6,72.5,697.9,71.9,698.6,72.4 M342.6,117.9C342.6,117.9,342.6,117.9,342.6,117.9
      C342.7,117.9,342.7,117.9,342.6,117.9C342.7,117.9,342.6,117.9,342.6,117.9 M343.3,116.2L343.3,116.2
      C343.3,116.2,343.3,116.2,343.3,116.2C343.3,116.2,343.3,116.2,343.3,116.2 M346.1,122.7c0,0,0,0.1,0,0.1
      C346.1,122.7,346.1,122.7,346.1,122.7L346.1,122.7 M349.4,126.2c0,0.3,0,0.8-0.3,0.8C349.3,126.9,349.3,126.5,349.4,126.2
      c0-0.3,0.2-0.4,0.5-0.4C349.6,125.8,349.4,125.9,349.4,126.2 M350.2,123C350.2,123,350.2,123,350.2,123L350.2,123
      C350.2,123,350.2,123,350.2,123 M351,125.6c0,0.3-0.2,0.3-0.5,0.3C350.7,125.9,351,125.8,351,125.6c0-0.2,0.1-0.3,0.2-0.3
      C351.1,125.3,351,125.4,351,125.6 M351.9,124.9c0,0-0.1,0.5-0.3,0.5c0,0,0,0,0,0C351.8,125.3,351.8,124.9,351.9,124.9l0.4-0.4
      c0,0,0,0,0,0L351.9,124.9z M353.7,124.3c-0.2,0.2-0.6,0.4-0.9,0.5C353.1,124.7,353.5,124.4,353.7,124.3c0.2-0.2,1.4-0.2,1.5-0.2h0
      C355.1,124,353.9,124,353.7,124.3 M360.5,113.9C360.5,113.9,360.5,113.9,360.5,113.9C360.5,113.9,360.5,113.9,360.5,113.9
      c0,0.1-0.1,0.1-0.1,0.2C360.4,114,360.5,114,360.5,113.9 M359.7,127.8c0.3,0,0.7,0.1,0.9,0.2C360.3,127.9,359.9,127.8,359.7,127.8
      M361.3,124.9c-0.8,0-1.3,0.6-1.7,0.6c0,0,0,0,0,0C359.9,125.5,360.5,124.9,361.3,124.9c0.8,0,1.2-0.5,1.3-0.4c0,0,0,0,0,0
      C362.5,124.5,362,124.9,361.3,124.9 M363.8,119.7c0,0,0,0.1,0.1,0.2C363.9,119.8,363.8,119.7,363.8,119.7 M367.5,117.6
      C367.5,117.6,367.5,117.6,367.5,117.6L367.5,117.6C367.5,117.6,367.5,117.6,367.5,117.6 M369.2,134.8
      C369.2,134.8,369.2,134.8,369.2,134.8c0,0,0.1,0,0.1,0C369.3,134.8,369.3,134.8,369.2,134.8 M371,135.8c0,0,0,0-0.1-0.1
      c-0.3-0.2-1.1-0.8-1.5-0.9C369.9,135,371,135.8,371,135.8C371,135.8,371,135.8,371,135.8 M371.8,139.1
      C371.8,139,371.8,139,371.8,139.1C371.8,139,371.8,139,371.8,139.1 M372,138.9C372,138.9,372,138.9,372,138.9L372,138.9
      C372,138.9,372,138.9,372,138.9 M372.8,138.9C372.8,138.9,372.8,138.9,372.8,138.9c0.1,0,0.1,0,0.1,0
      C372.9,138.9,372.8,138.9,372.8,138.9 M374.4,135.4C374.4,135.4,374.4,135.4,374.4,135.4C374.4,135.4,374.4,135.4,374.4,135.4
      L374.4,135.4 M374.4,135.2C374.4,135.2,374.4,135.2,374.4,135.2C374.4,135.2,374.4,135.2,374.4,135.2 M374.4,120.9
      C374.4,120.9,374.4,120.9,374.4,120.9C374.4,120.9,374.4,120.9,374.4,120.9C374.5,120.9,374.4,120.9,374.4,120.9 M374.9,118.8
      C374.9,118.8,374.9,118.7,374.9,118.8c0.1-0.2,0-0.7,0-1C375,118.2,375.1,118.7,374.9,118.8 M375.1,104.9
      C375.1,104.9,375.1,104.9,375.1,104.9C375.1,104.9,375.1,104.9,375.1,104.9C375.1,104.9,375.1,104.9,375.1,104.9 M382.3,136.9
      C382.3,136.9,382.3,136.8,382.3,136.9c0,0.1,0.1,0.3,0.1,0.4C382.4,137.1,382.3,137,382.3,136.9 M383.2,120.6c0.1,0,0.1,0,0.2-0.1
      C383.3,120.6,383.2,120.6,383.2,120.6 M384.2,95.9C384.2,95.9,384.2,95.9,384.2,95.9C384.2,95.9,384.2,95.9,384.2,95.9
      C384.2,95.9,384.2,95.9,384.2,95.9 M385.8,99.9C385.8,99.9,385.8,99.8,385.8,99.9C385.8,99.9,385.8,99.9,385.8,99.9
      C385.8,99.9,385.8,99.9,385.8,99.9 M385.8,127.9C385.8,127.9,385.9,127.8,385.8,127.9c0.1-0.1,0.2-0.2,0.3-0.4
      C386,127.7,385.9,127.8,385.8,127.9 M392.5,110.7C392.5,110.7,392.5,110.7,392.5,110.7C392.5,110.7,392.5,110.7,392.5,110.7
      M304.3,199.4C304.3,199.5,304.3,199.5,304.3,199.4C304.3,199.5,304.3,199.5,304.3,199.4c0-0.1,0-0.1,0-0.1S304.3,199.4,304.3,199.4
      M310.7,208C310.7,208,310.7,208,310.7,208C310.7,208,310.7,208,310.7,208C310.7,208,310.7,208,310.7,208 M314.8,209.8
      C314.8,209.8,314.8,209.8,314.8,209.8C314.8,209.8,314.8,209.8,314.8,209.8C314.8,209.8,314.8,209.8,314.8,209.8 M320.3,203.9
      L320.3,203.9C320.3,203.9,320.3,203.9,320.3,203.9C320.3,203.9,320.3,203.9,320.3,203.9 M330.6,203c0.1-0.1,0.2-0.4,0.4-0.5
      C330.8,202.6,330.7,202.9,330.6,203 M331,180.5c0.5,0.3,0.9,0.6,1.2,0.8C331.8,181.1,331.4,180.8,331,180.5 M332.8,202.7
      C332.8,202.7,332.8,202.7,332.8,202.7C332.8,202.7,332.8,202.7,332.8,202.7L332.8,202.7z M336.3,200.8
      C336.3,200.8,336.3,200.8,336.3,200.8C336.3,200.8,336.3,200.8,336.3,200.8C336.3,200.8,336.3,200.8,336.3,200.8 M338.3,200.9
      C338.3,200.9,338.3,200.9,338.3,200.9C338.3,200.9,338.3,200.9,338.3,200.9C338.3,200.9,338.3,200.9,338.3,200.9 M338,186.8
      C338,186.8,338,186.8,338,186.8C338,186.8,338,186.8,338,186.8c0.3-0.1,1-0.2,1.5-0.3C339,186.5,338.3,186.7,338,186.8 M350.2,163
      C350.2,163,350.3,163,350.2,163c0.1-0.1,0.2-0.1,0.2-0.1C350.3,162.9,350.3,163,350.2,163 M354.8,177.3L354.8,177.3
      C354.9,177.3,354.9,177.3,354.8,177.3C354.9,177.3,354.8,177.3,354.8,177.3 M357.7,197.2L357.7,197.2L357.7,197.2
      C357.7,197.2,357.7,197.2,357.7,197.2 M375,202.6C375,202.6,375,202.6,375,202.6C375,202.6,375,202.6,375,202.6
      C375,202.7,375,202.6,375,202.6 M375.3,259.7C375.3,259.7,375.3,259.7,375.3,259.7C375.3,259.7,375.3,259.7,375.3,259.7
      C375.3,259.7,375.3,259.7,375.3,259.7 M383,212.9l0.5,1.1l0,0.1L383,212.9z M390.3,217.3C390.3,217.3,390.4,217.3,390.3,217.3
      c0,0,0.1,0,0.2-0.1C390.4,217.3,390.4,217.3,390.3,217.3C390.3,217.3,390.3,217.3,390.3,217.3C390.3,217.3,390.3,217.3,390.3,217.3
      M390.5,226.3c-0.1,0-0.5,0.1-0.9,0.2c-0.2,0.1-0.4,0.1-0.6,0.2C389.5,226.6,390.4,226.4,390.5,226.3 M386.7,268.5
      C386.7,268.5,386.7,268.5,386.7,268.5C386.7,268.5,386.7,268.5,386.7,268.5C386.7,268.5,386.7,268.5,386.7,268.5 M387.8,238.3
      c-1-0.5-1.3-7.1-0.9-7.2c0.1,0,0.1,0,0.2,0.2c0.2,0.3,0.3,1.1,0.5,2c0.3,1.5,0.7,3.2,1.2,3.7c1,0.9,0.8,2,1,2.6
      c0.2,0.6,1.1,1.3,0.5,1.6c-0.4,0.2-0.7-0.3-1.1-0.8C388.7,239.5,388.3,238.6,387.8,238.3 M390.8,269.3L390.8,269.3 M392.3,276.9
      C392.3,276.9,392.3,276.9,392.3,276.9C392.3,276.9,392.3,276.9,392.3,276.9C392.3,277,392.3,276.9,392.3,276.9 M392.3,277
      c0,0.4,0.1,1,0.2,1.4C392.4,278.1,392.3,277.5,392.3,277 M392.5,278.7C392.5,278.8,392.5,278.8,392.5,278.7
      C392.5,278.8,392.5,278.8,392.5,278.7 M388.8,254.2c0,0,4-1.5,5.1-1.9C392.8,252.7,388.8,254.2,388.8,254.2 M394.2,252.2
      C394.3,252.2,394.3,252.2,394.2,252.2C394.3,252.2,394.3,252.2,394.2,252.2C394.3,252.2,394.3,252.2,394.2,252.2 M394.8,243.4
      C394.8,243.4,394.8,243.4,394.8,243.4c0,0.1,0.1,0.2,0.1,0.2C394.9,243.6,394.9,243.5,394.8,243.4 M398.6,252.1c0,0.1,0,0.3-0.1,0.3
      c-0.5,0.7-2-1.6-2.1-3c-0.2-1.4,0.8-2.1,0.2-3.5c-0.5-1.1-0.8-2.2-0.5-2.5c0.1-0.1,0.2-0.1,0.3-0.1c0.7,0,1.4,2.5,1.4,4
      c0,0.3,0,0.5-0.1,0.7C397.4,249,398.5,251.2,398.6,252.1 M393.6,224.1c0.8-0.2,1.8-0.1,2.6,0.1c0.8,0.2,1.3,0.5,1.3,0.7
      c-0.1,0.5-1.4,0.3-1.4,1.3c0,0.1,0,0.1,0,0.2c0,0.9-0.4,1.6-0.9,1.8c-0.6,0.3,0.8,1.1,0.1,1.2c-0.7,0.1-2.1-0.6-3.2-0.2
      c-0.7,0.3-0.6-1.5,0-3C392.5,225.2,393,224.2,393.6,224.1 M399.5,160.4C399.5,160.5,399.5,160.5,399.5,160.4c0,0.2,0,0.3,0,0.3
      C399.4,160.7,399.4,160.6,399.5,160.4c0-0.2,0-0.3,0.1-0.5C399.5,160.1,399.5,160.3,399.5,160.4 M399.5,159.6L399.5,159.6
      c0,0.1,0,0.2,0,0.3C399.5,159.8,399.5,159.7,399.5,159.6L399.5,159.6c0-0.3,0-0.5,0.1-0.8C399.6,159.1,399.6,159.4,399.5,159.6
      M399.7,158.2c0-0.1,0-0.2,0.1-0.4C399.7,158,399.7,158.1,399.7,158.2 M399.7,157.8c0.1-0.3,0.1-0.8,0.2-1.3
      C399.8,157,399.8,157.4,399.7,157.8 M406.7,158.8c0,0,0.1,0,0.1,0c0.7,0,0.9-0.1,1.5,0C407.7,158.8,407.6,158.8,406.7,158.8
      M412.5,147.4l0.7,0.2l0,0L412.5,147.4z M417.5,141.4C417.5,141.4,417.5,141.4,417.5,141.4C417.5,141.4,417.5,141.4,417.5,141.4
      C417.5,141.4,417.5,141.4,417.5,141.4c0,0,0.1,0,0.1,0C417.6,141.4,417.5,141.4,417.5,141.4 M418.9,138.3
      C418.9,138.2,418.9,138.2,418.9,138.3C418.9,138.2,418.9,138.3,418.9,138.3C418.9,138.3,418.9,138.3,418.9,138.3 M438,184.5
      c0,0-0.1,0-0.1,0.1C437.9,184.6,438,184.5,438,184.5 M437.8,184.6c-0.4,0.2-1.5,0.6-2.8,1C436.3,185.2,437.4,184.8,437.8,184.6
      M421.7,143.4c-0.1,0-0.1,0.1-0.2,0.1C421.5,143.4,421.6,143.4,421.7,143.4 M420.5,143.6c0.1,0,0.2,0,0.2,0
      C420.7,143.6,420.6,143.6,420.5,143.6 M420.9,143.6c0.1,0,0.2,0,0.4-0.1C421.1,143.6,421,143.6,420.9,143.6 M421.4,136.8
      C421.4,136.8,421.3,136.8,421.4,136.8C421.3,136.8,421.4,136.8,421.4,136.8C421.4,136.8,421.4,136.8,421.4,136.8 M421.4,165.6
      c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0C421.6,165.6,421.5,165.6,421.4,165.6 M421.8,143.3c0.1-0.1,0.3-0.1,0.4-0.2
      C422,143.2,421.9,143.3,421.8,143.3 M422.2,143.1c0.1-0.1,0.3-0.2,0.4-0.2C422.5,143,422.4,143,422.2,143.1 M422.7,142.8
      c0.3-0.2,0.6-0.4,0.8-0.6C423.2,142.5,423,142.7,422.7,142.8 M437.8,139.3c-0.7-0.1-1.3,0.6-2,0.4c-0.7-0.2-1.6-0.8-1.8,0
      c-0.2,0.8-0.5,1.7,0.4,1.6c0.9-0.1,0.8,0.6,0.1,1.2c-0.6,0.6,1-0.1,1.1,0.5c0.1,0.6,0.6,0.6,0.4,1.6c-0.1,0.6,0,1.8,0,2.6
      c0,0.5,0,0.9-0.1,1c-0.3,0.5-2.5,0.9-4.2,0.9c-1.7,0.1-1.8-2.1-3.6-2.1c-1.3,0-2.3-1.2-2.4-2.3c0-0.4,0.1-0.8,0.3-1.2
      c1-1.2,1.7-3.1,1.2-4.2c-0.2-0.4-1-1.3-1.8-2.3c-1.1-1.3-2.3-2.8-2.2-3.4c0.1-1,0.5-1.6-0.8-2.7c-1.2-1,0.1-1.7,1-2.8
      c0.8-1,2.4-1.4,3.5-2.1c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.4-0.3c1-0.9,2.8-1.7,4.1-1.3c1.3,0.3,2.4-0.2,3,0.6c0.6,0.8,0,2.3,0.2,2.9
      c0.2,0.6-1,0.6-1.9,0.5c-0.9-0.1-2.7,0.3-2.4,0.9s0.9,1.7-0.3,1.1c-1.2-0.6-1.6,0.6-1,0.6c0.6,0,1.5,0.8,1.5,1.3
      c0,0.5,0.1,1.7,0.6,1.5c0.6-0.2,0.6,0.9,1.4,0.8c0.8-0.1,1.6,0.2,0.9,1c-0.6,0.8,0.4,2.8,0.6,2.4c0.1-0.3,0-0.9,0.2-1.3
      c0.1-0.2,0.4-0.4,0.9-0.4c1.4,0.1,0.8,1.7,1.5,1.8C437.6,138.2,438.5,139.4,437.8,139.3 M449.8,130.4c-0.1,0.3-0.3,0.6-0.6,0.8
      c-1.4,1-2.8,0.9-2.4-0.4c0.5-1.3,0.5-2.8-0.2-2.8c-0.8,0-0.2,2.7-0.8,2.8c-0.5,0.2-0.7-1.9-0.3-3c0-0.1,0.1-0.2,0.2-0.3
      c0.6-0.9,1.5-0.2,1.8,0.1c0.3,0.2,0.1-1,0.8-0.9c0.7,0.1-0.1,1.2,0.5,1.2C449.1,127.8,450,129.3,449.8,130.4 M451.1,151.6
      C451.1,151.6,451.1,151.6,451.1,151.6C451.1,151.6,451.1,151.6,451.1,151.6C451.1,151.6,451.1,151.6,451.1,151.6 M451.3,126.3
      c-0.2,0.3-2.5-0.3-2.7-0.7C448.4,125,451.8,125.6,451.3,126.3 M460.3,146.9c0.2,0.1,0.4,0.2,0.6,0.2
      C460.7,147.1,460.5,147,460.3,146.9 M469,137.4C469,137.4,469,137.4,469,137.4S469,137.4,469,137.4C469,137.4,469,137.4,469,137.4
      M468.7,140.9c0.1,0,0.2,0,0.3,0C468.9,140.9,468.8,140.9,468.7,140.9 M473.5,148.6c-0.1,0-0.2,0.1-0.2,0.1
      C473.3,148.7,473.4,148.7,473.5,148.6c0.2-0.1,0.4-0.3,0.5-0.5C473.8,148.3,473.7,148.5,473.5,148.6 M473.3,142.7
      c0.6,0.1,2.4-0.2,2.5-0.2h0C475.8,142.5,473.9,142.8,473.3,142.7 M477.8,148.2C477.8,148.2,477.8,148.2,477.8,148.2
      c0.1,0,0.3-0.1,0.4-0.1C478,148.2,477.9,148.2,477.8,148.2 M485.6,155C485.6,155,485.6,155,485.6,155C485.6,155,485.6,155,485.6,155
      C485.6,155,485.6,155,485.6,155 M484.1,126.1c-1.6,0-5.2-0.8-6.7,0.8c-1.4,1.6-0.4,2.4-1,2.5c-0.7,0.1-1-2.4-0.2-3.2
      c0.8-0.8,3.4-1.2,5-0.8c1.6,0.4,4.5-0.3,4.9-0.2C486.6,125.3,485.8,126.1,484.1,126.1 M488.4,135.6c0,0.1,0,0.2,0,0.3
      C488.4,135.9,488.4,135.8,488.4,135.6 M489.3,163.6C489.3,163.6,489.3,163.6,489.3,163.6c0,0,0-0.1,0-0.1
      C489.3,163.5,489.3,163.6,489.3,163.6 M501.2,118.5C501.2,118.5,501.2,118.5,501.2,118.5C501.2,118.5,501.2,118.5,501.2,118.5
      C501.2,118.5,501.2,118.5,501.2,118.5 M502.5,119C502.5,119,502.5,119,502.5,119C502.5,119,502.5,119,502.5,119
      C502.5,119,502.5,119,502.5,119 M502.8,168.5C502.8,168.5,502.8,168.4,502.8,168.5C502.8,168.4,502.8,168.4,502.8,168.5
      C502.8,168.4,502.8,168.5,502.8,168.5 M504.2,169.8c0,0.1,0.1,0.2,0.2,0.3C504.3,170,504.2,169.9,504.2,169.8 M504.4,170.1
      c0.1,0.1,0.1,0.1,0.2,0.1C504.5,170.2,504.4,170.2,504.4,170.1 M509.9,168.7C509.9,168.7,509.9,168.7,509.9,168.7
      C509.9,168.7,509.9,168.7,509.9,168.7C509.9,168.7,509.9,168.7,509.9,168.7 M519.2,167.4C519.2,167.4,519.2,167.4,519.2,167.4
      C519.2,167.4,519.2,167.4,519.2,167.4 M524.9,183.6c0.1,0,0.2-0.1,0.3-0.1C525.1,183.6,525,183.6,524.9,183.6 M526.6,181.5
      C526.6,181.5,526.6,181.5,526.6,181.5C526.6,181.5,526.6,181.5,526.6,181.5C526.6,181.5,526.6,181.5,526.6,181.5 M539.2,195.9
      C539.2,195.9,539.2,195.9,539.2,195.9C539.2,195.9,539.2,195.9,539.2,195.9C539.2,195.9,539.2,195.9,539.2,195.9 M535.1,196.6
      c0.3,0.2,0.6,0.2,0.7,0.3C535.6,196.8,535.3,196.7,535.1,196.6 M541.6,108.6c-1.7,1.5-5,1.8-5,3c0,1.2-4,1.8-4.3,1.3
      c-0.3-0.5,3-0.6,3.7-2.3c0.8-1.7,3.7-2.6,5.2-4.9c1-1.6,1.9-4.2,2.5-4C544.4,101.9,543.3,107.2,541.6,108.6 M556.9,117.1
      c0.1,0,0.2,0,0.4,0.1C557.1,117.1,557,117.1,556.9,117.1 M557.6,117.2c-0.1,0-0.2-0.1-0.3-0.1C557.4,117.2,557.5,117.2,557.6,117.2
      C557.6,117.2,557.6,117.2,557.6,117.2c0.1,0,0.2,0.1,0.2,0.1C557.7,117.3,557.7,117.3,557.6,117.2"/>
    <path d="M347.3,139.1c-0.3,0.6,1,1.4,0.7,2.5c-0.3,1.1,0.1,2.5,0.8,1.9c0.7-0.7,1-0.3,1.4-0.8c0.4-0.5,0.1-2.3,0.3-3.2
      c0.3-0.9-1.2-1.3-1.8-0.7C348.1,139.3,347.5,138.7,347.3,139.1"/>
    <path d="M337.8,141.7c-0.1-1-2.1,0.3-1.7,0.6C337,142.9,337.9,142.7,337.8,141.7"/>
    <path d="M359.9,145.3c-1.2,0.3-1.7,0.3-2.4,0c-0.7-0.3-1.8,0.3-1.7,0.8c0.1,0.3,0.6,0.7,2,1.1c1.4,0.4,1.6,1.4,2.2,1.4
      c0.6,0,0.7-0.6,0.5-1.3c-0.1-0.8,0.7-1.7,0.8-1.8C361.5,145.3,361.1,145.1,359.9,145.3"/>
    <path d="M379.3,152.3c1.1,0,2.7-0.5,2.6-0.8c0-0.3-0.8,0.2-1.2-0.1c-0.5-0.3-2.5,0.2-3.1-0.3c-0.6-0.5-1.7,0.4-1.2,0.5
      C377.1,151.7,378.1,152.3,379.3,152.3"/>
    <path d="M367.7,95.3c-1.1,0-1.6,2.2-1.3,2.5C366.6,98.1,368.8,95.2,367.7,95.3"/>
    <path d="M356.1,100.6c-0.2-0.1-0.4,0.4-0.7,0.5c-0.3,0.1-0.6-1.1-0.7-0.5c-0.1,0.6-0.7-0.3-1.3,0.5c-0.6,0.8,0.3,1.5,0.8,1.8
      c0.4,0.3,0,0.6-0.5,0.5c-0.5-0.1-0.2,0.8,0.5,0.9c0.7,0.1,1.5-1.3,1.4-1.6C355.5,102.4,356.4,100.8,356.1,100.6"/>
    <path d="M396.6,152.3c-0.1-0.3,0.4-1,1-1.2c0.7-0.3,0.4-0.8,0-0.4c-0.4,0.4-1.5,0.8-2.8,0.8c-1.3,0.1-1.7,0.9-0.9,1.4
      C394.9,153.5,396.6,152.6,396.6,152.3"/>
    <path d="M518.9,222.1c-0.1,0.6,0.5,2.3,1.2,1.6C520.8,222.9,519,221.5,518.9,222.1"/>
    <path d="M521.7,227.1c-0.1,0.3,0.5,1.4,0.9,1.1C523.1,227.8,522.1,226.1,521.7,227.1"/>
    <path d="M536.1,233.5c-0.1-0.8,0.3-1.6,0.3-2c0-0.4,0.3-0.6,0.8-0.5c0.5,0.1,0.5-1.1,0.1-1.2c-0.4-0.1-0.6-1.6-1.6-1.6
      c-1,0-0.8,0.8-0.2,0.9c0.6,0.1,0.9,0.5,0.8,0.9c-0.2,0.4-0.4-0.4-1.2-0.3c-0.8,0.1-0.3-1-1.2-1c-0.9-0.1-0.8-1.7-1.7-1.7
      c-1,0-1-0.8-0.4-1.4c0.6-0.6-1-1.1-1-1.8c0-0.7-1-0.3-1-0.9c0-0.6-0.5-0.3-1-1s-0.8-0.1-1.2-0.6c-0.5-0.5-1.1-0.8-1.5-0.6
      c-0.4,0.2-1.3-0.8-1.7-1.7c-0.3-0.7-3-2-3.3-3c-0.3-1-1.3-1.4-2.8-1.3c-1.6,0.1-2.2-1.5-2.5-0.8c-0.5,1,1.7,3.2,2.7,3.8
      c1,0.6,1.5,2.7,2.7,2.8c1.2,0.1,1.3,3.8,2.1,3.9c0.9,0.2,2.7,3,3,4.5c0.3,1.5,2.2,2.3,2.8,3.4c0.6,1.1,3.2,2.6,3.5,3.1
      c0.3,0.5,0.7,1.5,1,1c0.3-0.5,1.5,0,2.1,0.1C536,236.4,536.2,234.3,536.1,233.5"/>
    <path d="M171.4,43.3c-0.5,1.1,1.1,1.1,1.2,2.4s1.7,1.7,3,1c1.2-0.8,4.7,0.2,4.7-0.8c0-1-3.6-2.7-5-2.4
      C174,43.7,171.9,42.1,171.4,43.3"/>
    <path d="M373.9,94.7c0.5,0,1.7-1,2-1.3c0.3-0.3-0.2-0.7-1.2-0.4C373.6,93.3,373.5,94.7,373.9,94.7"/>
    <path d="M434.9,200.1c0.6,0.7,1.9-0.1,2-0.4C436.9,199.4,434.1,199.3,434.9,200.1"/>
    <path d="M426.4,248.4c-0.3-0.9-0.5,0.3-0.7,0.6c-0.2,0.2-0.3,0.9-0.3,1.4c0.1,0.5-0.7,0.9-1.2,0.9c-0.5-0.1-1.2,0.2-1,0.9
      c0.2,0.6-0.3,0.5-0.1,1c0.3,0.5-0.6,1.2-1.4,1.2c-0.8,0.1-1.9,1.5-2.7,1.4c-0.9-0.1-1.7,0.6-2.5,0.5c-0.8-0.1-0.2,1.6-0.8,2.6
      c-0.6,1-0.3,2.3,0.2,3.5c0.5,1.3,0.9,2.3-0.5,4c-1.4,1.7-1.3,3.5-0.8,4.7c0.5,1.2,0.5,2.9,1.3,3.7c0.8,0.8,2.8,1.5,3.5,0.9
      c0.6-0.6,1.3,0.1,2-0.8c0.7-0.8,1.9-5.3,2.8-8.2c1-3,2.3-6.8,2.1-7.6c-0.1-0.8,0.7-1.3,0.3-2.2c-0.3-0.9,0.1-1.9,0.5-1.2
      c0.4,0.7,1,0.7,1.2-0.2c0.2-0.9-0.7-2.4-0.8-3.7C427.7,250.3,426.5,249,426.4,248.4"/>
    <path d="M487.9,205.7c-0.5,0.1-0.4,1.1-0.7,1.9c-0.3,0.8-0.2,1.5-0.1,2.6c0.1,1.1,0,2.3,1,2.9c1.1,0.7,3-1.2,3-2.8
      C491.2,208.7,488.4,205.7,487.9,205.7"/>
    <path d="M465.5,329.8c-0.3-0.6-2.4,1.4-0.7,2.2c0.8,0.3,1.1-0.4,1.6,0c0.5,0.4,1.5,0.3,1.7-0.8C468.3,330.2,465.8,330.3,465.5,329.8
      "/>
    <path d="M126.7,37.8c0.6-0.7-1.3-2.2-1.8-1C124.3,38.1,126,38.5,126.7,37.8"/>
    <path d="M129.3,57.4c0.5-0.6,2.7,1.2,2.8-0.1c0.1-1.3-2.4-1.9-3.5-2.6c-1-0.7-1.5-0.3-3.1-1.6c-1.7-1.2,0.4-1.8-0.8-2.8
      c-1.2-1-1.7-3.6-0.8-4.4c0.9-0.9,1.8-2.2,0.3-2.8c-1.6-0.6-4.1,0.5-3.8,0.9c0.3,0.3,2.4,1.6,2.4,2.1c0,0.5-2-1.5-2.7-1
      c-0.7,0.4-1.5-0.9-2.1,0c-0.6,0.9,0.8,3.9,1.3,4.8c0.5,0.9-0.5,0.9-1.2,1c-0.7,0.2-0.8-2.4-1.5-3.4c-0.7-1-3.5-2.1-3.6-1.4
      c-0.2,0.7,1.7,0.7,1.5,1.6c-0.3,1-1.3-0.6-2.3,0.2c-1,0.8-0.8,0.3-0.6-0.5c0.2-0.8-1.4-1.6-3.1-1.5c-1.7,0.2-0.8,1.4-1.3,1.8
      c-0.5,0.4-3.1-0.3-1.5-0.6c1.7-0.3,1-1.3,0.3-2.3c-0.6-1-2.3,0.2-5,1.1c-2.7,1-3.9,2-3.6,2.1c0.3,0.1,0.4,0.5-0.6,1.4
      c-1,0.9,0,1.6,1,1.6c1,0,0.1,0.8,0.8,1c0.6,0.2,3.7-1.2,4.6-0.6c0.9,0.6-5.1,1-5.1,2c0,1,3.3,1.6,5.7,1.2s6.7,0.3,6.7,1
      c0,0.6-2.9,0.4-5,0.3c-2.2-0.1-5.9,0.6-5.7,1.3c0.2,0.8,0.4,0.8,2.7,1.8c2.3,1,4.6-0.5,4.5,1c-0.1,1.5,1.2,1.8,4.3,1.9
      c3,0.1,4.4-1.4,5.9-1.3c1.5,0.1,2.2-0.2,2.9-1.2c0.7-1,1.3-0.4,1.5,0.1c0.2,0.5,2.1,0.4,2.5,0.9c1.1,1.1,4.8,0.8,6.2,0.1
      c1.4-0.7-0.6-2.6-1.3-1.7c-0.7,0.9-1.3,0.3-1.5-0.1C127.2,58.3,128.7,58,129.3,57.4"/>
    <path d="M149.2,38c0.6,1.7,1.3,0.5,2.3,1.2c1,0.7,2.4,1.1,3.2,0.4c0.8-0.7,1.1-1.2,1.4-0.6c0.4,1,2.3,1.1,6.5,1.2
      c4.2,0.1,3.1-1.7,4.3-1c1.2,0.7,4,0.7,5.2,0.4c1.2-0.3,1.8-2.2,1.7-3.1c-0.1-1-9.4-1.7-11-0.8c-1.7,1-4.3-0.4-5.6,0.3
      c-1.3,0.7-0.9-0.9-3.2-1c-2.3-0.1,0.2-1.3,0.6-1.8s-3.6-1.9-5.2-1.6c-1.7,0.3-2.6-0.5-4.2-1.2c-1.6-0.7-5.8-0.5-5,0.7
      c0.6,0.9,5.6,2.7,6.2,1.8c0.5-0.9,1.8,1,2.6,2S148.6,36.2,149.2,38"/>
    <path d="M172.2,77.6c0.2-0.9-2.9-1.2-2.7-2.2c0.2-1-2.9-1.7-3.8-2.3c-1-0.5-2.4-0.8-2.4-1.8c0-1-2.1-0.3-2.2,1.3
      c-0.1,1.6-1,1.2-0.5,2.7c0.4,1.5-1.9,1.8-1.7,2.6c0.3,0.8,1.4-0.1,2.4-0.1c1,0-0.3,1.5,1,1.6c1,0.1,2.9-1.1,3.3-1.8
      c0.4-0.7,1.3-1,2.5-0.1C169.4,78.4,172,78.5,172.2,77.6"/>
    <path d="M165.9,82.4c1.4,0.9,3.5-1.5,3.3-2.2C169.1,79.6,164.2,81.4,165.9,82.4"/>
    <path d="M147.2,27c-0.2-0.5-6.8,0.3-5.7,1C143.2,29.4,147.3,27.6,147.2,27"/>
    <path d="M375.5,92.1c0.2-0.4-1.7-0.2-1.2,0.1C374.6,92.6,375.3,92.6,375.5,92.1"/>
    <path d="M146.2,39.7c1.6-0.1,0.8-2-1-3.3c-1.7-1.3-5.6,1.4-5,1.9C141.1,38.9,144.6,39.8,146.2,39.7"/>
    <path d="M225.1,59.4c0.9,0.8,3.5,0.1,3.5-0.8c0.1-0.9-1-0.3-1.6-1.4c-0.6-1.1-3.5-1.6-3.8-0.7c-0.1,0.3-1,0.9-0.3,1.7
      C223.7,58.9,224.2,58.6,225.1,59.4"/>
    <path d="M287.6,56.4c-0.8,0.4-2.3,0.5-3.5,0.2c-1.2-0.3-3-1.3-4.3-0.5c-1.3,0.8-0.5-1.1,0.6-1.3c1.1-0.2,0.6-0.7,0.3-1.9
      c-0.3-1.2,1.9-0.4,2.8,0.9c0.9,1.3,2.7,1.9,4.3,1.4c1.6-0.5,0.1-1.7,0.7-2.6c0.6-0.9-4.8-3.6-5.1-4.5c-0.3-0.9,1.4-0.3,2.9,0.3
      c1.5,0.6,1.9-1.2,1.9-2c0-0.8-3.2-0.9-4.7-0.1c-1.4,0.8-2.8-1-0.9-1.3c2-0.2,0.2-1,0.8-1.6c0.6-0.6,3.1,1.7,4.3,1.3
      c1.3-0.4,2.2,0.1,3.3-0.8c1.1-0.9-2-1.7-2.6-2.4c-0.6-0.8,3.2-0.5,4.3-0.4c1.2,0.1,1.4-1.7,0.5-1.3c-0.9,0.3-4.2-1.2-3.5-2.2
      c0.7-1,2,0.2,3.4-0.6c1.4-0.8,0.1-3.6-0.9-3.6s-3.5-0.3-3.5-0.9c0-0.5-2-1-1.4-1.5c0.6-0.5,1.3,0.6,2.7-0.3c1.3-1,4.1,0.2,5.3,0
      c1.2-0.2-0.5-2.5-1.2-2.1c-0.7,0.4-3.1,0.5-3.2-0.7c-0.2-1.2,2.3,0,2.7-0.6c0.4-0.6-3.4-2.1-3.9-0.9c-0.6,1.2-2.7,0.8-1.6,0.1
      c1.1-0.7,1.3-2.7,1.2-3.6c-0.1-1,3.9-1,3.5-2.7c-0.5-1.6,1.9-2,3.2-2c1.3,0-0.1-1.7-1.3-1.6c-1.3,0.2-2.6,1.6-3.2,1
      c-0.6-0.6,1.5-1.6,2.7-1.6c1.2,0,3.9-0.1,4.9-0.7c1-0.6-1.7-1.1-3.6-0.9c-1.9,0.2-1.9-0.6,0.9-0.6c2.8-0.1,2.3-0.6,4.3-0.9
      c2-0.2,1.3-1,2.5-1.1c1.2-0.1,4.4-1.4,4.4-2c0-0.6-4.6-1.9-6.7-1.9c-2.1,0-3.9,0.3-4,1.9c-0.1,1.5-2.8-0.3-4,0.2S292.2,9,291,9.6
      c-1.2,0.6-2.4,2-3.8,2.5c-1.4,0.6-2.4,2-3.3,2.1c-0.9,0.1,1.9-2.7,3.2-4c1.4-1.4,0.6-3-1.2-2.8c-1.9,0.2-1.4,1.3-2.5,1.6
      c-1,0.2-6,2.7-6.3,2c-0.2-0.8,4.1-2,4.1-2.5c-0.1-0.5-5.5-0.6-7.9-0.2c-2.4,0.3-6.7,1.7-6.8,1c-0.1-0.7,5-1.7,7.5-2
      c2.6-0.3,7.2,0.2,9.2-0.6c2-0.8,4.9-0.9,5.4-1.5c0.5-0.6-2.3-1.5-3.6-1.4c-1.4,0.1-2.8-0.1-2.7-0.8c0.1-0.7-2.1-0.9-2.4-1.4
      c-0.2-0.5-4.6-0.1-5.5-0.7c-0.9-0.6-3.6-0.9-6-0.7c-2.3,0.2-7.1,0.1-8.2,0.2s-1.8,0.5-2.8,0.5c-1-0.1-2.5,0.3-1.9,0.9
      c1,1.2-1.6,1.6-1.4,0.8c0.2-0.9-2.3-1.3-3-0.6c-0.9,0.7-4.7-0.8-5.3,0c-0.6,0.8-5.2,0.5-6.5,0.8c-1.3,0.2,2.1,1.2,2,1.8
      c-0.1,0.6-4.3,0-3.5,1c0.9,1,3.6,1.8,5,3.1c1.3,1.3-1,0.5-2.5-0.5c-1.5-1-3.4-0.6-4.3-1.6c-0.9-1-4.4-1.7-5.3-1.2
      c-1,0.7,2.4,2,2.4,2.7c0,0.8-2.3-0.2-2.7,0.1c-0.4,0.3-2.4-2-3.6-2c-1.2,0-0.2,1-0.2,2.5s-2.3,2.3-1.6,1.3c0.6-1,0.2-3.5-1-4
      c-1.2-0.5-4.3,1-6,0.9c-1.6-0.1-3.8,0.5-2.4,1.3c1.4,0.8-0.1,1.3-1.3,0.4c-1.2-0.9-5.2,0-4.4,0.6c0.8,0.6,1.1,2.3,0.6,3
      c-0.5,0.7-2.2-0.7-3.8-0.6c-1.6,0.1-9.3,3.8-8.9,4.8c0.5,1,3.8,0.2,4.8,0.8c1,0.6-0.3,2.7-1.6,3.6c-1.3,0.9-6,0.1-6.1,1.1
      c-0.1,1-7.5,1.2-7.5,2.9c0,0.6,0.3,1.3,1.2,1.4c1,0.2,2.3-0.1,3.2,0.9c0.9,1,3.5,1.1,5.3,0.4c1.8-0.7,3.1,0.1,3.1,1.2
      c-0.1,1-4.1-0.4-5.3,0.3c-1.2,0.8-5,0.1-4.8,0.9c0.2,0.8,2,0.6,3.5,0.9c1.5,0.3,0.1,0.9,0.1,1.6c-0.1,0.6,0.8,0.5,2.3,1.3
      c1.5,0.8,4,1.2,3.2,0.3c-0.8-0.9,1.6-0.8,2-0.2c0.4,0.5,1.5-0.8,2.6-0.5c1.1,0.3,1.5-1,2.4-0.5c0.9,0.6,4.9,0.9,6.3,1.7
      c1.4,0.8,3.3,0.9,3.1,2c-0.2,1.1,1.2,1.9,2.7,2.6c1.6,0.7,1.9,2.2,1.8,3.2c-0.1,1,1.7,1.7,1.4,2.1c-0.3,0.5-0.1,1.1,1,2
      c1,0.9-1.6,1.6-1,2.4c0.6,0.8-0.9,2.4,0.9,2.7c1.9,0.3,1.6-1.3,3-1.3c1.4,0-0.1,1.2,0.7,2c0.8,0.8,2.3,0.6,3.8,1.8
      c1.4,1.2,0.5,1.8-0.8,0.8c-1.3-1-5.2-0.8-5.2-0.4c0,0.3,4.3,2.8,5.3,2.4c1-0.3,2.3,1.4,1.7,2c-0.6,0.6-0.3,2-0.4,2.7
      c-0.1,0.7-1.6,0-2.5,0.1c-0.9,0.1-1.6,0.4-1.6,1.4c0,1-1.4,1.7-1.6,2.9c-0.2,1.2,1.2,1.2,1.9,1.7c0.8,0.5-1.4,0.9-1.5,1.7
      c-0.1,0.9,2,2.4,2.7,2.8c0.8,0.4,0.1,2.4,0.3,3.4c0.3,1,1.3-0.2,1.2,1c-0.1,1.3,0.9,1.2,1,2c0.1,0.8,1.9,0.6,1.5,1.7
      c-0.3,1,0.5,1.8,0.9,2.4c0.4,0.6,1.9,1.6,2.2,2.3c0.3,0.8,1.2,1.7,2.2,1.4c1-0.3,1.1,0.7,1.9,0.6c0.8-0.1,2.1,0.3,2.3,1
      c0.2,0.6,2.7,1.2,3.4,1.3c0.7,0.1,0.8-1.2,1.4-1.5c0.6-0.3,0.4-2.9,1-3.1c0.6-0.2,0.4-2.8-0.2-3.1s-0.3-1.2,1-1
      c1.3,0.2,0.9-1.2,1.5-1.3c0.6-0.2,0.3-1.4,0.8-1.5c0.5-0.1,0.4-0.9,0.1-1.4c-0.3-0.5,0.1-0.8,0.8-0.9c0.7-0.1,0.5-1.1-0.4-1.4
      c-0.9-0.3-0.6-1.4,0.3-0.8c0.9,0.6,1.6,0.2,1.1-0.5s0.6-1,1.9-1.2c1.2-0.1,1.9-0.8,1.6-1.7c-0.2-0.9,1.4-0.9,1-0.1
      c-0.4,0.8-0.2,1.6,0.4,1c0.6-0.6,1.7-0.2,3.6-0.8s3.9-2.2,4.3-3.8c0.5-1.6,2.7-1.4,2.4-2.4c-0.3-1,0.4-1.4,1.9-0.8
      c1.4,0.7,0.3-0.5,1.9-0.5c1.6,0,1.3-0.6,2.9-0.6c1.6-0.1,5.6-0.8,7-1.9c1.4-1.2,4.3-2.1,5.6-2.9C288.1,56.6,288.4,56,287.6,56.4"/>
    <path d="M548.9,40.7c-0.2,0.5-1.5,0.5-1.2,0.9c0.6,0.8,3.5,0.3,3.5-0.6C551.4,40.1,549.2,40.2,548.9,40.7"/>
    <path d="M221.1,113.5c0.8-0.5-0.1-1-0.9-0.6c-0.9,0.3-3.2,3.5-3.2,4.4c0,0.9-0.1,1.9-0.9,1.9c-0.8-0.1-1.7,0.7-1.1,1.2
      c0.6,0.5-1.7,1.8-0.6,2.4c0.8,0.4,2-0.2,3-0.1c1,0.1,1.9,0.2,2.8-0.5c0.9-0.6,0.9,0.6,1.8,0.5c0.9-0.1-0.5,0.8-0.5,1.4
      c0.1,0.6,1-0.3,1.7-0.7s1.6,0,1.2,0.6c-0.3,0.6,1.9,1.2,2.4,0.2c0.5-0.9-0.5-2.4-0.9-1.7c-0.3,0.6-0.9-0.5-0.1-1
      c0.8-0.6-0.1-0.8-0.8-0.5c-0.7,0.3-0.5-1.6,0.1-1.9c0.6-0.3-1.6-1-1.6-0.4c0,0.6-1.6,0.2-1.7-0.3c-0.2-0.6-1.3-0.8-0.9-1.2
      c0.4-0.4-1.3-0.6-1.5,0.1c-0.2,0.7-0.7-0.5,0.2-1.4C220.6,114.7,220.3,113.9,221.1,113.5"/>
    <path d="M153.7,27.7c-1.6-0.8-3.5,0.9-1.9,1.5C153.2,29.7,155.2,28.5,153.7,27.7"/>
    <path d="M188.4,53.4c0.9-0.1,1.9-0.8,1.6-1.6c-0.3-0.8-2.5-0.9-3,0.1c-0.4,1-2.3-1-2.3-1.8c0-0.8-2.8-0.1-2.4-1.4
      c0.3-1.3-3.2-1.6-4.2-1.6c-1,0-2.3,0.8-2.2,1.6c0.2,0.9-1,0.5-1.2-0.4c-0.2-1-2.5,1-3.2,1c-0.7,0,0.4-2.5,0.1-3.3
      c-0.3-0.8-0.9-1-1.4-2.3s-3.1-0.8-3.8,0c-0.7,0.8-3,0.4-4.2,1.6c-1.2,1.2,0.1,2.4,0.6,2.9c0.5,0.4-1.8,1.1-1,1.8
      c0.8,0.7,2,0.3,2.1,1.5c0.1,1.1-2.9-0.3-3.3-1.3c-0.4-1,0.2-1.8-0.4-2.5c-0.6-0.7-0.1-1.6,1-2.8c1.1-1.2,2-1,2-1.7
      c0-0.7-4.9-0.7-7.7,1.9c-2.9,2.6-2,6.1-1.8,6.8c0.2,0.7,2.7,0.3,4.2,0.8c1.5,0.4,0.7,1-0.4,0.9c-1.1-0.2-3-0.5-3,0.3
      c0.1,0.8,2.4,2.3,3.8,1.9c1.4-0.3,1.4-0.3,2,0.4c0.6,0.8,2.3,0.5,3.9,0.6s3.6,0.7,4.3,0.8c0.8,0.1,1.7-0.4,2-0.9
      c0.3-0.4,3.5,0.7,4.4,0.6c1-0.1,0.3-1-0.3-1.2c-0.6-0.3-0.3-1.4,0.4-1c0.8,0.4,2.1,0.7,2.3,1.5c0.2,0.7,1,0.3,1,1
      c0.1,0.6,2.9,1.3,3,2c0.1,0.7-2.7,1-1.9,1.6s2-0.8,3-0.9c1-0.1,1,2.3,1.8,1.9c1.1-0.5,1.9,0.4,3,1.9c1,1.5-0.1,2.9,0,3.4
      c0.1,0.5,2.1,0.3,3.2-0.3c1.1-0.6,2.2,0.7,3,1.5c0.9,0.8-3.2,2.1-2.9,1.4c0.3-0.7-2.4-2.8-5-1.8c-2.5,1-0.8,2.4-0.5,3.3
      c0.3,0.9-2.3,1.7-4.6,1c-2.3-0.8-1.7,0.5-2.6,0.5c-1,0-2,1.5-1.1,2.4c0.9,1,2.8,0.1,4.3,0.2c1.6,0.1,1.7,0.5,1.9-0.3
      c0.2-0.9,1.8-0.4,3-0.2c1.1,0.3,0.9,1.8,2.3,2c1.4,0.2,0.3,1.6,1.1,2.5c0.9,1,3.6,0.4,4.7,1.5c1,1,5.5,3.1,6.1,2.3
      c0.7-0.9-3.8-4.9-5.1-5.2c-1.3-0.3,1.3-0.8,3,0.5s4.1,1.9,5.3,0.3c1.2-1.6-1-1.1-1-2.3c0-1.1-0.8-2.7-2-2.7c-1.2,0-4.3-2.4-3.2-3
      c1.1-0.5-0.3-1.2,0.5-2.2c0.9-1,1.7,0.9,2.8,1c1,0.1,1.2,1.4,2.9,2.8c1.6,1.4,1.8,0.4,2.1-0.5c0.3-1,2.1-0.2,2-1.1
      c-0.1-1,1.5-2.1,2.5-2.5c1-0.4-0.9-1.5-2.3-1.4c-1.4,0.1-1.7-0.9-1.7-1.5c0-0.6-2.6-2.1-3.5-1.8c-0.9,0.3-2.3-1.1-3.5-1.2
      c-1.1-0.1-2.8-1-2.6-2.2c0.2-1.2,2.3,0.3,2.7-0.6c0.4-1-2.2-0.4-2.3-1.1c-0.1-0.7,0.6-0.4,1.1-0.9c0.5-0.4-0.4-1.3-1-1.6
      c-0.6-0.3-0.8,0.7-1.4,0.7c-0.6,0-0.1-1,0.3-1.6c0.4-0.6-2-1.3-3-1c-1,0.3-1.4-0.3-1.4-1c0-0.7-1.8,0-2.5,0.5
      C188.4,54.6,187.6,53.5,188.4,53.4"/>
    <path d="M182.3,65.8c1-0.2,0.8-1.8,0.3-2.6c-0.4-0.6-1.6-0.7-2.5-0.6c-1,0.1-2.6,1.6-1.7,3C179.1,67.1,181.2,66,182.3,65.8"/>
    <path d="M173.6,84.3c0.6-0.1,1.8-2.3,0.3-2.2C172.3,82.2,172.6,84.5,173.6,84.3"/>
    <path d="M535.8,196.9c-0.2-0.1-0.5-0.2-0.7-0.3C535.3,196.7,535.6,196.8,535.8,196.9"/>
    <path d="M557.6,117.2c0.1,0,0.2,0.1,0.2,0.1C557.7,117.3,557.7,117.3,557.6,117.2C557.6,117.2,557.6,117.2,557.6,117.2"/>
    <path d="M526.6,181.5C526.6,181.5,526.6,181.5,526.6,181.5C526.6,181.5,526.6,181.5,526.6,181.5
      C526.6,181.5,526.6,181.5,526.6,181.5"/>
    <path d="M304.3,199.4c0-0.1,0-0.1,0-0.1S304.3,199.4,304.3,199.4"/>
    <path d="M304.3,199.4C304.3,199.5,304.3,199.5,304.3,199.4C304.3,199.5,304.3,199.5,304.3,199.4"/>
    <polygon points="399.8,156.5 398.9,156.1 399.9,156.5 "/>
    <path d="M539.2,195.9C539.2,195.9,539.2,195.9,539.2,195.9C539.2,195.9,539.2,195.9,539.2,195.9
      C539.2,195.9,539.2,195.9,539.2,195.9"/>
    <path d="M509.9,168.7C509.9,168.7,509.9,168.7,509.9,168.7C509.9,168.7,509.9,168.7,509.9,168.7
      C509.9,168.7,509.9,168.7,509.9,168.7"/>
    <path d="M435,185.6c1.3-0.4,2.4-0.8,2.8-1C437.4,184.8,436.3,185.2,435,185.6"/>
    <path d="M437.9,184.6c0.1,0,0.1,0,0.1-0.1C438,184.5,437.9,184.6,437.9,184.6"/>
    <path d="M357.7,197.2C357.7,197.2,357.7,197.2,357.7,197.2C357.7,197.2,357.7,197.2,357.7,197.2z"/>
    <path d="M392.5,278.8C392.5,278.8,392.5,278.8,392.5,278.8C392.5,278.8,392.5,278.8,392.5,278.8"/>
    <path d="M399.7,157.8c0,0.2,0,0.3-0.1,0.4C399.7,158.1,399.7,158,399.7,157.8"/>
    <path d="M399.6,158.9c0,0.2,0,0.5-0.1,0.8C399.6,159.4,399.6,159.1,399.6,158.9"/>
    <path d="M504.6,170.3c-0.1,0-0.1-0.1-0.2-0.1C504.4,170.2,504.5,170.2,504.6,170.3"/>
    <path d="M392.5,278.4c0-0.4-0.1-1-0.2-1.4C392.3,277.5,392.4,278.1,392.5,278.4"/>
    <path d="M406.8,158.8c0,0-0.1,0-0.1,0c0.8,0,0.9-0.1,1.6,0C407.7,158.8,407.6,158.8,406.8,158.8"/>
    <path d="M399.9,156.5c-0.1,0.4-0.1,0.9-0.2,1.3C399.8,157.4,399.8,157,399.9,156.5"/>
    <path d="M392.3,276.9C392.3,276.9,392.3,277,392.3,276.9C392.3,276.9,392.3,276.9,392.3,276.9C392.3,276.9,392.3,276.9,392.3,276.9"
      />
    <line x1="390.8" y1="269.3" x2="390.8" y2="269.3"/>
    <path d="M386.7,268.5C386.7,268.5,386.7,268.5,386.7,268.5C386.7,268.5,386.7,268.5,386.7,268.5
      C386.7,268.5,386.7,268.5,386.7,268.5"/>
    <path d="M399.4,160.5C399.5,160.5,399.5,160.5,399.4,160.5c0,0.1,0,0.2,0,0.3C399.4,160.7,399.4,160.6,399.4,160.5"/>
    <path d="M350.2,163c0.1,0,0.1-0.1,0.2-0.1C350.4,162.9,350.3,163,350.2,163C350.3,163,350.2,163,350.2,163"/>
    <path d="M154.8,195.9C154.8,195.9,154.8,195.9,154.8,195.9C154.7,195.9,154.7,196,154.8,195.9C154.7,196,154.7,195.9,154.8,195.9"/>
    <path d="M211.5,214.8C211.5,214.8,211.5,214.8,211.5,214.8C211.5,214.8,211.5,214.8,211.5,214.8
      C211.5,214.8,211.5,214.8,211.5,214.8"/>
    <path d="M191.7,232.8c0.4-0.1,0.7,0.1,1,0.1C192.3,232.9,192,232.7,191.7,232.8"/>
    <polyline points="193.5,259.6 193.5,259.6 193.5,259.6 "/>
    <path d="M332.8,202.7L332.8,202.7C332.8,202.7,332.8,202.7,332.8,202.7L332.8,202.7"/>
  </svg>
);