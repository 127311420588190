import { LinkButtonProps, useLang, LinkButton } from '@shapeable/ui';
import React from 'react';
import { classNames } from '@shapeable/utils';
import styled, { css } from 'styled-components'; const cls = classNames('map-slim-button');
import { Classable, EntityTypeName, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { WorldMap } from './world-map';
import { useMapUtils } from '@shapeable/maps';
import { DottedChevronRightHeavyIconGlyph } from '@shapeable/icons';

// -------- Types -------->

export type MapLinkButtonProps = Classable & HasChildren & LinkButtonProps & {
  entityTypeNames?: EntityTypeName[],
  showImage?: boolean;
}

export const MapLinkButtonDefaultProps: MapLinkButtonProps = {
  href: '#map',
  showImage: true,
  entityTypeNames: [],
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    flex-grow: 1;
    .shp--button {
      height: 38px;
      box-sizing: border-box;
    }
  `,
});

const WorldMapStyles = breakpoints({
  base: css`
    ${theme.FILL('light')};
    width: 44px;
    height: 24px;
  `,
});


// -------- Components -------->

const My = {
  Container: styled(LinkButton)<ContainerProps>`${ContainerStyles}`,
  WorldMap: styled(WorldMap)`${WorldMapStyles}`,

};

export const MapLinkButton: React.FC<MapLinkButtonProps> = (props) => {
  const { className, children, showImage, entityTypeNames, ...rest } = props;
  const t = useLang();

  const { showEntityTypes } = useMapUtils();

  const buttonOnClick = (event: any) => {
    event.preventDefault();
    showEntityTypes(entityTypeNames);
  };


  return (
    <My.Container 
      {...rest} 
      onClick={buttonOnClick}
      className={cls.name(className)}
      variant='slim' 
      icon={DottedChevronRightHeavyIconGlyph}
      preIcon={showImage && <My.WorldMap />}
    >
      {children || t('View On Map')}
    </My.Container>
  )
};

MapLinkButton.defaultProps = MapLinkButtonDefaultProps;