import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Menu, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useActiveEntity } from '@shapeable/ui';
import { DARK_BLUE, MEDIUM_BLUE, OCEAN_BLUE, ORANGE } from '../../theme';
import { SideNavigationLink } from './side-navigation-link';
import { classNames, isSameEntityPath } from '@shapeable/utils';

const cls = classNames('side-navigation-list');

// -------- Types -------->

export type SideNavigationListProps = Classable & HasChildren & { 
  entity: Menu;
};

export const SideNavigationListDefaultProps: Omit<SideNavigationListProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 400;
  `,
});

const ItemStyles = breakpoints({
  base: css`
    margin: 0 !important;
    padding: ${theme.UNIT(2)} 0;
    ${theme.H_DOT_BG_END('line-mid', 1.5, 4)};
  `,
});


const ItemLinkStyles = breakpoints({
  base: css`
    display: flex;
    font-size: ${theme.FONT_SIZE(16)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.ul<ContainerProps>`${ContainerStyles}`,
    Item: styled.li.attrs(cls.attr('item'))`${ItemStyles}`,
      ItemLink: styled(SideNavigationLink)`${ItemLinkStyles}`,

};

export const SideNavigationList: Shapeable.FC<SideNavigationListProps> = (props) => {
  const { className, children, entity } = props;
  const currentEntity = useActiveEntity();

  const linkStyle: React.CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };

  return (
    <My.Container className={cls.name(className)}>
      {
        entity &&
        entity.items.map(item => {
          return (
            <My.Item className={cls.sub("item")} key={item.path}>
              <My.ItemLink 
                variant='default' 
                hoverColor={ORANGE} 
                activeColor={OCEAN_BLUE} 
                showActiveSidebar={true} 
                sidebarWidth={5} style={linkStyle} 
                className={cls.sub("item-link")} 
                isActive={isSameEntityPath(currentEntity, item)} 
                entity={item} 
              />
            </My.Item>
          )
        })
      }
    </My.Container>
  )
};

SideNavigationList.defaultProps = SideNavigationListDefaultProps;
SideNavigationList.cls = cls;