import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Site } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, useLang } from '@shapeable/ui';
import { useSite } from '../../hooks/use-site';
import { CountBubble } from '../elements/count-bubble';
import { classNames } from '@shapeable/utils';

const cls = classNames('site-metrics-bubbles');

// -------- Types -------->

export type SiteMetricsBubblesProps = Classable & HasChildren & { 
};

export const SiteMetricsBubblesDefaultProps: Omit<SiteMetricsBubblesProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  tablet: css`
    flex-direction: row;
    margin-top: ${theme.UNIT(4)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(10)};
  `,
});


const BubbleStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
    width: 100%;
  `,
  tablet: css`
    width: 150px;
    height: 150px;
    margin-bottom: 0;
    margin-right: ${theme.UNIT(6)};
  `,
  desktop: css`
    width: 180px;
    height: 180px;
    margin-right: ${theme.UNIT(10)};
  `,
  desktopLarge: css`
    width: 200px;
    height: 200px;
    margin-right: ${theme.UNIT(10)};
  `,

});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Bubble: styled(CountBubble)`${BubbleStyles}`,
};

export const SiteMetricsBubbles: Shapeable.FC<SiteMetricsBubblesProps> = (props) => {
  const { className } = props;
  
  const { metrics } = useSite();
  const { totalRestored, countRestorationProjects, countPeople } = metrics;

  const t = useLang();

  return (

    <My.Container className={cls.name(className)}>
    {
      !!totalRestored && <My.Bubble count={totalRestored}>{t("Area Restored (ha)")}</My.Bubble>
    }
    {
      !!countRestorationProjects && <My.Bubble count={countRestorationProjects}>{t("Number of Restoration Projects")}</My.Bubble>
    }
    {
      !!countPeople && <My.Bubble count={countPeople}>{t("Size of Community (People)")}</My.Bubble>
    }
    </My.Container>
  )
};

SiteMetricsBubbles.defaultProps = SiteMetricsBubblesDefaultProps;
SiteMetricsBubbles.cls = cls;