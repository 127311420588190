import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  EntityMultiValueList, EntityValue, 
  ContentNode, useLink, PlainLinkButton, useLang 
} from '@shapeable/ui';
import { RestorationProject } from '@shapeable/kelp-forest-alliance-types';
import { useMapUtils } from '@shapeable/maps';
import { ChevronRightIcon, ChevronRightIconGlyph } from '@shapeable/icons';

// -------- Types -------->

export type RestorationProjectTooltipProps = Classable & HasChildren & {
  entity?: RestorationProject;
  onLinkClick?: React.MouseEventHandler;
}

export const RestorationProjectTooltipDefaultProps: RestorationProjectTooltipProps = {
  
};

// -------- Child Component Props -------->

type ValueProps = {
  hasLink: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)};
    font-family: ${theme.FONT('sans')};
    color: ${theme.COLOR('strong')};
  `,
});

const OrganisationStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
    padding-bottom: ${theme.UNIT(4)};
    ${theme.H_DOT_BG_END()};
  `,
});

const SpeciesStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
    padding-bottom: ${theme.UNIT(4)};
    ${theme.H_DOT_BG_END()};
  `,
});

const ValueStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};

    ${({ hasLink }: ValueProps ) => hasLink && css`
      cursor: pointer;

      &:hover {
        color: ${theme.COLOR('link-hover')};
      }
    `}
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(12)};
    line-height: 1.3em;
    padding-bottom: ${theme.UNIT(3)};
    ${theme.H_DOT_BG_END()};
    color: ${theme.COLOR('text')};
  `,
});

const LinkStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(14)};
    align-items: center;
    display: flex;
    ${theme.TRANSITION('color')}

    ${theme.FILL('link')};
    &:hover {
      color: ${theme.COLOR('link-hover')} !important;
      ${theme.FILL('link-hover')};
    }
  `,
});

const IconStyles = breakpoints({
  base: css`
    width: 12px;
    height: 12px;
    margin-right: ${theme.UNIT(2)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<any>`${ContainerStyles}`,
    Value: styled(EntityValue)<ValueProps>`${ValueStyles}`,
    Link: styled(PlainLinkButton)`${LinkStyles}`,  
      Icon: styled(ChevronRightIconGlyph)`${IconStyles}`,
    Description: styled(ContentNode)`${DescriptionStyles}`,
    Organisation: styled(EntityValue)`${OrganisationStyles}`,
    Species: styled(EntityMultiValueList)`${SpeciesStyles}`,
  };

export const RestorationProjectTooltip: React.FC<RestorationProjectTooltipProps> = (props) => {
  const { className, entity, onLinkClick } = props;
  const { place, organisation, species = [], openGraph } = entity;
  
  const description = openGraph && openGraph.description;

  const { navigate } = useLink();
  const { hideMap } = useMapUtils();

  const t = useLang();

  const hasLink = process.env.DISABLE_PROJECTS !== "true";

  const valueOnClick = hasLink ? ((event: React.MouseEvent) => {
    if (onLinkClick) {
      onLinkClick(event);
    }
    navigate(entity.path);
    hideMap({ deselect: true });
  }) : undefined;

  const hasOrganisation = !!organisation;

  return (
    <My.Container className={className}>
      <My.Value hasLink={hasLink} onClick={valueOnClick} showImage={false} entity={entity} />
      <My.Description entity={description} />
      {
        hasOrganisation &&
        <My.Organisation imageStyle='logo' entity={organisation} />
      }
      {
        <My.Species items={species} />
      }
      {
        hasLink &&
        <My.Link onClick={valueOnClick}><My.Icon size={24} />{t('View Project Details')}</My.Link>
      }
    </My.Container>
  )
};

RestorationProjectTooltip.defaultProps = RestorationProjectTooltipDefaultProps;