import React from 'react';
import styled, { css } from 'styled-components'; 
import { Classable, HasChildren, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityTooltipProps, useEntity } from '@shapeable/ui';
import { OrganisationPledge } from '@shapeable/kelp-forest-alliance-types';
import { OrganisationTooltip } from './organisation-tooltip';
import { classNames } from '@shapeable/utils';
const cls = classNames('organisation-pledge-tooltip');

// -------- Types -------->

export type OrganisationPledgeTooltipProps = Classable & HasChildren & EntityTooltipProps & { 
  entity?: OrganisationPledge;
};

export const OrganisationPledgeTooltipDefaultProps: Omit<OrganisationPledgeTooltipProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled(OrganisationTooltip)<ContainerProps>`${ContainerStyles}`,
};

export const OrganisationPledgeTooltip: React.FC<OrganisationPledgeTooltipProps> = (props) => {
  const { className, children, ...rest } = props;
  const entity = useEntity(props.entity);
  const org = entity.organisation;

  return (
    <My.Container {...rest} entity={org} className={cls.name(className)}>
    {children}
    </My.Container>
  )
};

OrganisationPledgeTooltip.defaultProps = OrganisationPledgeTooltipDefaultProps;