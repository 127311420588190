import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, useLang } from '@shapeable/ui';
import { BlockMetricLayout } from '../entities/block-metric-layout';
import { useSite } from '../../hooks/use-site';
import { MetricValue } from '../elements/metric-value';
import { OCEAN_BLUE } from '../../theme';
import { EntityTotalCircleTrailBarChart } from '../entities/entity-total-circle-trail-bar-chart';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ValueStyles = breakpoints({
  base: css`
    
  `,
});

const ChartStyles = breakpoints({
  base: css`
    width: 100%;
    margin-top: ${theme.UNIT(4)};
  `,
});

const TextBubbleStyles = breakpoints({
  base: css`
    cursor: pointer;
    color: ${theme.COLOR('dark')};
    background: ${theme.COLOR('white')};
    box-sizing: border-box;
    border: 2px solid ${theme.COLOR('dark')};
    transition: 0s;
    &:hover {
      color: ${OCEAN_BLUE};
      border-color: ${OCEAN_BLUE};
    }
  `,
});


const CircleMenuStyles = breakpoints({
  base: css`
  `,
});



// -------- Components -------->

const My = {
  Container: styled(BlockMetricLayout)<ContainerProps>`${ContainerStyles}`,
  Value: styled(MetricValue)`${ValueStyles}`,
  Chart: styled(EntityTotalCircleTrailBarChart)`${ChartStyles}`,
};

export const BlockLayoutAreaProtectedByCountryChart: BlockLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { metrics } = useSite();
  const { totalProtected, topProtectedByCountry = [] } = metrics;
  const t = useLang();

  return (
    <My.Container showContent entity={entity} className={className}>
      <My.Value comingSoon suffix={t('ha protected')} value={totalProtected} />
      {/* <My.Chart
        color={OCEAN_BLUE}
        backgroundColor={CONTENT_BACKGROUND} 
        items={topProtectedByCountry} 
      /> */}

      {children}
    </My.Container>
  )
};