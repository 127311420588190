import React from 'react';
import { IconComponent } from '@shapeable/types'

export const StatusIbIcon: IconComponent = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_2705_16672)">
      <path d="M16 2.78261C23.3043 2.78261 29.2174 8.69565 29.2174 16C29.2174 23.3043 23.3043 29.2174 16 29.2174C8.69565 29.2174 2.78261 23.3043 2.78261 16C2.78261 8.69565 8.69565 2.78261 16 2.78261ZM16 0C7.16522 0 0 7.16522 0 16C0 24.8348 7.16522 32 16 32C24.8348 32 32 24.8348 32 16C32 7.16522 24.8348 0 16 0Z" fill="black"/>
      <path d="M9.7002 19.9999V18.3059H10.9602V11.9219H9.7002V10.2279H14.3482V11.9219H13.0882V18.3059H14.3482V19.9999H9.7002Z" fill="black"/>
      <path d="M16.0092 9.63989H18.0812V13.8819H18.1512C18.2538 13.4712 18.4872 13.1352 18.8512 12.8739C19.2245 12.6126 19.6492 12.4819 20.1252 12.4819C21.0585 12.4819 21.7632 12.8086 22.2392 13.4619C22.7152 14.1152 22.9532 15.0672 22.9532 16.3179C22.9532 17.5686 22.7152 18.5252 22.2392 19.1879C21.7632 19.8412 21.0585 20.1679 20.1252 20.1679C19.8825 20.1679 19.6538 20.1306 19.4392 20.0559C19.2245 19.9906 19.0285 19.8972 18.8512 19.7759C18.6832 19.6452 18.5385 19.4959 18.4172 19.3279C18.2958 19.1506 18.2072 18.9639 18.1512 18.7679H18.0812V19.9999H16.0092V9.63989ZM19.4392 18.4879C19.8312 18.4879 20.1532 18.3526 20.4052 18.0819C20.6665 17.8019 20.7972 17.4286 20.7972 16.9619V15.6879C20.7972 15.2212 20.6665 14.8526 20.4052 14.5819C20.1532 14.3019 19.8312 14.1619 19.4392 14.1619C19.0472 14.1619 18.7205 14.2599 18.4592 14.4559C18.2072 14.6426 18.0812 14.9039 18.0812 15.2399V17.4099C18.0812 17.7459 18.2072 18.0119 18.4592 18.2079C18.7205 18.3946 19.0472 18.4879 19.4392 18.4879Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_2705_16672">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);