import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { MapPinIcon } from '../elements/map-pin-icon';
const cls = classNames('restoration-project-marker');

// -------- Types -------->

export type RestorationProjectMarkerProps = Classable & HasChildren & { 
  entity?: Entity;
  size?: number;
  pinSize?: number;
  isSelected?: boolean;
};

export const RestorationProjectMarkerDefaultProps: Omit<RestorationProjectMarkerProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  size: number;
  isSelected: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    cursor: pointer;
    position: relative;
    opacity: 1.0;
    &:hover {
      filter: brightness(1.1);
    }

    ${({ size }: ContainerProps ) => css`
      width: ${size}px;
      height: ${size}px;
    `}

    ${({ isSelected }: ContainerProps ) => isSelected && css`
      .shp--map-marker-icon__fill {
        fill: ${theme.COLOR('link-hover')};
      }
    `}
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const RestorationProjectMarker: Shapeable.FC<RestorationProjectMarkerProps> = (props) => {
  const { className, children, entity, size, pinSize, isSelected } = props;
  
  return (
    <My.Container 
      size={pinSize || size}
      isSelected={isSelected}
      className={cls.name(className)}>
      <MapPinIcon />
    </My.Container>
  )
};

RestorationProjectMarker.defaultProps = RestorationProjectMarkerDefaultProps;
RestorationProjectMarker.cls = cls;